<template>
  <div style="overflow: auto;">
    <v-client-table class="hiaTable no-count feeSchedule" ref="feeScheduleListGrid" id="feeScheduleListGrid" :data="tableData" :columns="calcColumns" :options="options">
      <template slot="description" slot-scope="props">
        <span :title="props.row.description">{{truncateDescription(props.row.description)}}</span>
      </template>
      <template slot="facilityFeeScheduleAmount" slot-scope="props">
        {{localFormatMoney(props.row.facilityFeeScheduleAmount)}}
      </template>
      <template slot="nonFacilityFeeScheduleAmount" slot-scope="props">
        {{localFormatMoney(props.row.nonFacilityFeeScheduleAmount)}}
      </template>
      <template slot="oppsFacilityFeeAmount" slot-scope="props">
        {{localFormatMoney(props.row.oppsFacilityFeeAmount)}}
      </template>
      <template slot="oppsNonFacilityFeeAmount" slot-scope="props">
        {{localFormatMoney(props.row.oppsNonFacilityFeeAmount)}}
      </template>
      <template slot="nonInstitutionalTherapyReductionAmount" slot-scope="props">
        {{localFormatMoney(props.row.nonInstitutionalTherapyReductionAmount)}}
      </template>
      <template slot="institutionalTherapyReductionAmount" slot-scope="props">
        {{localFormatMoney(props.row.institutionalTherapyReductionAmount)}}
      </template>
    </v-client-table>
  </div>
</template>

<script>

  import money from '@/mixins/money'

  export default {
    name: 'physicianFeeScheduleList',
    mixins: [money],
    props: ['tableData'],
    data() {
      return {
        options: {
          // see the options API
          filterable: false,
          orderBy: {
            column: 'lineSequence',
            ascending: true
          },
          perPage: 25,
          texts: {
            filter: '',
            filterBy: '...'
          },
          headings: {
            cptCode: function (h) { return 'CPT' },
            description: function (h) { return 'Description' },
            statusCode: function (h) { return 'Status Code' },
            workRvu: function (h) { return 'Work RVU' },
            nonFacilityRvu: function (h) { return 'Non-Facility RVU' },
            facilityRvu: function (h) { return 'Facility RVU' },
            mpRvu: function (h) { return 'MP RVU' },
            totalNonFacilityRvu: function (h) { return 'Total Non-Facility RVU' },
            totalFacilityRvu: function (h) { return 'Total Facility RVU' },
            pctcIndicator: function (h) { return 'PCTC Indicator' },
            globalSurgery: function (h) { return 'Global Surgery' },
            multipleSurgeryIndicator: function (h) { return 'Multiple Surgery Indicator' },
            billateralSurgery: function (h) { return 'Bilateral Surgery' },
            assistantAtSurgery: function (h) { return 'Assistant at Surgery' },
            coSurgeons: function (h) { return 'Co-Surgeons' },
            teamSurgery: function (h) { return 'Team Surgery' },
            endoscopicBaseCode: function (h) { return 'Endoscopic Base Code' },
            diagnosticImagingFamily: function (h) { return 'Diagnostic Imaging Family' },
            facilityFeeScheduleAmount: function (h) { return 'Facility Fee Schedule Amount' },
            nonFacilityFeeScheduleAmount: function (h) { return 'Non-Facility Fee Schedule Amount' },
            oppsFacilityFeeAmount: function (h) { return 'OPPS Facility Fee Amount' },
            oppsNonFacilityFeeAmount: function (h) { return 'OPPS Non-Facility Fee Amount' },
            oppsIndicator: function (h) { return 'OPPS Indicator' },
            nonInstitutionalTherapyReductionAmount: function (h) { return 'Non-Institutional Therapy Reduction Amount' },
            institutionalTherapyReductionAmount: function (h) { return 'Institutional Therapy Reduction Amount' },
            byReport: function (h) { return 'By Report' }
          }
        },
        columns: ['cptCode', 'description', 'statusCode', 'workRvu', 'nonFacilityRvu', 'facilityRvu', 'mpRvu', 'totalNonFacilityRvu', 'totalFacilityRvu', 'pctcIndicator', 'globalSurgery', 'multipleSurgeryIndicator', 'billateralSurgery', 'assistantAtSurgery', 'coSurgeons', 'teamSurgery', 'endoscopicBaseCode', 'diagnosticImagingFamily', 'facilityFeeScheduleAmount', 'nonFacilityFeeScheduleAmount', 'oppsFacilityFeeAmount', 'oppsNonFacilityFeeAmount', 'oppsIndicator', 'nonInstitutionalTherapyReductionAmount', 'institutionalTherapyReductionAmount', 'byReport']
      }
    },
    methods: {
      truncateDescription(description) {
        if (description) {
          return description.substring(0, 50) + '...'
        }
        return null
      },
      getDescription(cptId, codeLevel) {

      },
      localFormatMoney(input) {
        // this wraps the formatMoney helper from formatMoney.js so we can call it from the HTML binding
        return '$' + this.formatMoney(input, 2)
      }
    },
    computed: {
      calcColumns() {
        if (!this.isTrucodeEnabled()) {
          const noTCColumns = this.columns
          // console.log('index', noTCColumns.findIndex(a => a === 'cptCode'))
          noTCColumns.splice(noTCColumns.findIndex(a => a === 'facilityFeeScheduleAmount'), 1)
          noTCColumns.splice(noTCColumns.findIndex(a => a === 'nonFacilityFeeScheduleAmount'), 1)
          noTCColumns.splice(noTCColumns.findIndex(a => a === 'oppsFacilityFeeAmount'), 1)
          noTCColumns.splice(noTCColumns.findIndex(a => a === 'oppsNonFacilityFeeAmount'), 1)
          noTCColumns.splice(noTCColumns.findIndex(a => a === 'nonInstitutionalTherapyReductionAmount'), 1)
          noTCColumns.splice(noTCColumns.findIndex(a => a === 'institutionalTherapyReductionAmount'), 1)
          // console.log(noTCColumns)
          return noTCColumns
        } else {
          return this.columns
        }
      }
    }
  }
</script>

<style slot-scoped>
  .feeSchedule table {
    min-width: 1800px !important;
  }

  .feeSchedule th,
  .feeSchedule td {
    word-break: keep-all;
  }
</style>
