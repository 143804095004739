<template>
  <el-form ref="form" label-position="left" label-width="70%" :rules="rules" :model="form" size="mini">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row id="typeProblems">
          <el-col :span="17">
            <h4 class="headerWithBorder">Type and Number of Presenting Problems</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{decisionMaking.typeAndNumberOfPresentingProblems}}
              <span v-if="isLevel2"> / {{level2DecisionMaking.typeAndNumberOfPresentingProblems}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-left: 70%" v-if="isLevel2">
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 1</h5>
            </el-col>
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 2</h5>
            </el-col>
          </div>
          <el-form-item label="Single self-limited or Minor problem: stable, improved or worsening">
            <el-col :span="isLevel2 ? 12 : 24">
              <hia-el-select style="margin-right: 2px" :disabled="disabled" v-model="decisionMaking.numberPresentingSingleSelfMinor"
                v-on:input="putProReviewCalculate('numberPresentingSingleSelfMinor', decisionMaking)" :clearable="true" :filterable="true" itemkey="key" label="value"
                :dropdowns="numberDropDown">
              </hia-el-select>
            </el-col>
            <el-col :span="12" v-if="isLevel2">
              <hia-el-select style="margin-left: 2px" :disabled="disabled" v-model="level2DecisionMaking.numberPresentingSingleSelfMinor"
                v-on:input="putProReviewCalculate('numberPresentingSingleSelfMinor', level2DecisionMaking)" :clearable="true" :filterable="true" itemkey="key" label="value"
                :dropdowns="numberDropDown">
              </hia-el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="Established problem (to examiner): stable, improved, resolving/resolved">

            <el-col :span="isLevel2 ? 12 : 24">
              <hia-el-select style="margin-right: 2px" :disabled="disabled" v-model="decisionMaking.establishedProblemStable"
                v-on:input="putProReviewCalculate('establishedProblemStable', decisionMaking)" :clearable="true" :filterable="true" itemkey="key" label="value"
                :dropdowns="numberDropDown2">
              </hia-el-select>
            </el-col>
            <el-col :span="12" v-if="isLevel2">
              <hia-el-select style="margin-left: 2px" :disabled="disabled" v-model="level2DecisionMaking.establishedProblemStable"
                v-on:input="putProReviewCalculate('establishedProblemStable', level2DecisionMaking)" :clearable="true" :filterable="true" itemkey="key" label="value"
                :dropdowns="numberDropDown2">
              </hia-el-select>
            </el-col>

          </el-form-item>
          <el-form-item label="Established problem (to examiner): worsening, inadequately controlled">
            <el-col :span="isLevel2 ? 12 : 24">
              <hia-el-select style="margin-right: 2px" :disabled="disabled" v-model="decisionMaking.establishedProblemWorsening"
                v-on:input="putProReviewCalculate('establishedProblemWorsening', decisionMaking)" :clearable="true" :filterable="true" itemkey="key" label="value"
                :dropdowns="numberDropDown2">
              </hia-el-select>
            </el-col>
            <el-col :span="12" v-if="isLevel2">
              <hia-el-select style="margin-left: 2px" :disabled="disabled" v-model="level2DecisionMaking.establishedProblemWorsening"
                v-on:input="putProReviewCalculate('establishedProblemWorsening', level2DecisionMaking)" :clearable="true" :filterable="true" itemkey="key" label="value"
                :dropdowns="numberDropDown2">
              </hia-el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="New problem (to examiner): no additional work up planned">
            <el-col :span="isLevel2 ? 12 : 24">
              <input :disabled="disabled" id="newProblemNoWorkUp" type="checkbox" v-model="decisionMaking.newProblemNoWorkUp"
                v-on:change="putProReviewCalculate('newProblemNoWorkUp', decisionMaking)">
            </el-col>
            <el-col :span="12" v-if="isLevel2">
              <input :disabled="disabled" id="newProblemNoWorkUp" type="checkbox" v-model="level2DecisionMaking.newProblemNoWorkUp"
                v-on:change="putProReviewCalculate('newProblemNoWorkUp', level2DecisionMaking)">
            </el-col>
          </el-form-item>
          <el-form-item label="New problem (to examiner): with additional assessment, consult or diagnostic studies">
            <el-col :span="isLevel2 ? 12 : 24">
              <input :disabled="disabled" id="newProblemAdditionalWork" type="checkbox" v-model="decisionMaking.newProblemAdditionalWork"
                v-on:change="putProReviewCalculate('newProblemAdditionalWork', decisionMaking)">
            </el-col>
            <el-col :span="12" v-if="isLevel2">
              <input :disabled="disabled" id="newProblemAdditionalWork" type="checkbox" v-model="level2DecisionMaking.newProblemAdditionalWork"
                v-on:change="putProReviewCalculate('newProblemAdditionalWork', level2DecisionMaking)">
            </el-col>
          </el-form-item>
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-row>
          <el-col :span="17">
            <h4 class="headerWithBorder">Table of Risk</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{decisionMaking.levelOfRisk}}
              <span v-if="isLevel2"> / {{level2DecisionMaking.levelOfRisk}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row id="proRiskDefinitions">
          <div style="margin-left: 15%" v-if="isLevel2">
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 1</h5>
            </el-col>
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 2</h5>
            </el-col>
          </div>
          <el-form-item label="Presenting Problem(s)">
            <el-row :gutter="5">
              <el-col :span="isLevel2 ? 12 : 24">
                <hia-el-select :disabled="disabled" v-model="decisionMaking.presentingProblem" v-on:input="putProReviewCalculate('presentingProblem', decisionMaking)"
                  placeholder="Presenting Problems" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.ProRiskDefinitionsPP.list">
                </hia-el-select>
              </el-col>
              <el-col :span="12" v-if="isLevel2">
                <hia-el-select :disabled="disabled" v-model="level2DecisionMaking.presentingProblem"
                  v-on:input="putProReviewCalculate('presentingProblem', level2DecisionMaking)" placeholder="Presenting Problems" :clearable="true" :filterable="true"
                  itemkey="key" label="value" :dropdowns="dropdowns.ProRiskDefinitionsPP.list">
                </hia-el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="Diagnostic Procedures Ordered">
            <el-row :gutter="5">
              <el-col :span="isLevel2 ? 12 : 24">
                <hia-el-select :disabled="disabled" v-model="decisionMaking.diagnosticProceduresOrdered"
                  v-on:input="putProReviewCalculate('diagnosticProceduresOrdered', decisionMaking)" placeholder="Dx Procs Ordered" :clearable="true" :filterable="true"
                  itemkey="key" label="value" :dropdowns="dropdowns.ProRiskDefinitionsDPO.list">
                </hia-el-select>
              </el-col>
              <el-col :span="12" v-if="isLevel2">
                <hia-el-select :disabled="disabled" v-model="level2DecisionMaking.diagnosticProceduresOrdered"
                  v-on:input="putProReviewCalculate('diagnosticProceduresOrdered', level2DecisionMaking)" placeholder="Dx Procs Ordered" :clearable="true" :filterable="true"
                  itemkey="key" label="value" :dropdowns="dropdowns.ProRiskDefinitionsDPO.list">
                </hia-el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="Management Options Selected">
            <el-row :gutter="5">
              <el-col :span="isLevel2 ? 12 : 24">
                <hia-el-select :disabled="disabled" v-model="decisionMaking.mangementOptionsSelected"
                  v-on:input="putProReviewCalculate('mangementOptionsSelected', decisionMaking)" placeholder="Mgmt Options" :clearable="true" :filterable="true" itemkey="key"
                  label="value" :dropdowns="dropdowns.ProRiskDefinitionsMOS.list">
                </hia-el-select>
              </el-col>
              <el-col :span="12" v-if="isLevel2">
                <hia-el-select :disabled="disabled" v-model="level2DecisionMaking.mangementOptionsSelected"
                  v-on:input="putProReviewCalculate('mangementOptionsSelected', level2DecisionMaking)" placeholder="Mgmt Options" :clearable="true" :filterable="true"
                  itemkey="key" label="value" :dropdowns="dropdowns.ProRiskDefinitionsMOS.list">
                </hia-el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-row style="margin: 10px 10px 0px 0px;">
          <el-col :span="17">
            <h4 class="headerWithBorder">Amount and Complexity of Data</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{decisionMaking.amtAndComplexityOfData}}
              <span v-if="isLevel2"> / {{level2DecisionMaking.amtAndComplexityOfData}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="isLevel2 ? 20 : 24">
            <el-col>
              <input :disabled="disabled" id="amtAndComplexClinicTests" type="checkbox" v-model="decisionMaking.amtAndComplexClinicTests"
                v-on:change="putProReviewCalculate('amtAndComplexClinicTests', decisionMaking)"> Review and/or order of
              clinical tests
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexCptradiology" type="checkbox" v-model="decisionMaking.amtAndComplexCptradiology"
                v-on:change="putProReviewCalculate('amtAndComplexCptradiology', decisionMaking)"> Review and/or order
              of tests in CPT radiology section
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexCptmedicine" type="checkbox" v-model="decisionMaking.amtAndComplexCptmedicine"
                v-on:change="putProReviewCalculate('amtAndComplexCptmedicine', decisionMaking)"> Review and/or order of
              tests in CPT medicine section
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexDiscPerfPhys" type="checkbox" v-model="decisionMaking.amtAndComplexDiscPerfPhys"
                v-on:change="putProReviewCalculate('amtAndComplexDiscPerfPhys', decisionMaking)"> Discussion of test
              results with performing physician
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexIndReview" type="checkbox" v-model="decisionMaking.amtAndComplexIndReview"
                v-on:change="putProReviewCalculate('amtAndComplexIndReview', decisionMaking)"> Independent review of
              image, tracing, or specimen
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexDecisionOldRecords" type="checkbox" v-model="decisionMaking.amtAndComplexDecisionOldRecords"
                v-on:change="putProReviewCalculate('amtAndComplexDecisionOldRecords', decisionMaking)"> Decision to
              obtain old records and/or obtain history from someone other than patient
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexReviewOldRecords" type="checkbox" v-model="decisionMaking.amtAndComplexReviewOldRecords"
                v-on:change="putProReviewCalculate('amtAndComplexReviewOldRecords', decisionMaking)"> Review and
              summarization of old records and/or obtaining history from someone other than patient
            </el-col>
          </el-col>
          <el-col :span="4" v-if="isLevel2">
            <h5 class="headerWithBorder" style="margin: 5px 0px 5px 0px;">Level 2</h5>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexClinicTests" type="checkbox" v-model="level2DecisionMaking.amtAndComplexClinicTests"
                v-on:change="putProReviewCalculate('amtAndComplexClinicTests', level2DecisionMaking)">
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexCptradiology" type="checkbox" v-model="level2DecisionMaking.amtAndComplexCptradiology"
                v-on:change="putProReviewCalculate('amtAndComplexCptradiology', level2DecisionMaking)">
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexCptmedicine" type="checkbox" v-model="level2DecisionMaking.amtAndComplexCptmedicine"
                v-on:change="putProReviewCalculate('amtAndComplexCptmedicine', level2DecisionMaking)">
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexDiscPerfPhys" type="checkbox" v-model="level2DecisionMaking.amtAndComplexDiscPerfPhys"
                v-on:change="putProReviewCalculate('amtAndComplexDiscPerfPhys', level2DecisionMaking)">
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexIndReview" type="checkbox" v-model="level2DecisionMaking.amtAndComplexIndReview"
                v-on:change="putProReviewCalculate('amtAndComplexIndReview', level2DecisionMaking)">
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexDecisionOldRecords" type="checkbox" v-model="level2DecisionMaking.amtAndComplexDecisionOldRecords"
                v-on:change="putProReviewCalculate('amtAndComplexDecisionOldRecords', level2DecisionMaking)">
            </el-col>
            <el-col>
              <input :disabled="disabled" id="amtAndComplexReviewOldRecords" type="checkbox" v-model="level2DecisionMaking.amtAndComplexReviewOldRecords"
                v-on:change="putProReviewCalculate('amtAndComplexReviewOldRecords', level2DecisionMaking)">
            </el-col>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-row>
          <el-col :span="24">
            <h4 class="headerWithBorder" style="margin: 10px 10px 0px 0px;">EM Scoring Summary</h4>
          </el-col>
        </el-row>
        <el-row style="padding: 20px 0px 0px 0px;">
          <el-col :span="17">
            <h4 class="headerWithBorder blue">History Type</h4>
          </el-col>
          <el-col :span="7">
            <div class="blueResultsBox">{{history.historyLevel}}
              <span v-if="isLevel2"> / {{level2History.historyLevel}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="padding: 20px 0px 0px 0px;">
          <el-col :span="17">
            <h4 class="headerWithBorder blue">Exam Type</h4>
          </el-col>
          <el-col :span="7">
            <div class="blueResultsBox">{{examLevel}}
              <span v-if="isLevel2"> / {{level2ExamLevel}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="padding: 20px 0px 0px 0px;">
          <el-col :span="17">
            <h4 class="headerWithBorder blue">Decision Complexity</h4>
          </el-col>
          <el-col :span="7">
            <div class="blueResultsBox">{{decisionMaking.decisionComplexity}}
              <span v-if="isLevel2"> / {{level2DecisionMaking.decisionComplexity}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row id="timeBasedCalculationRow" style="padding: 20px 0px 0px 0px;">
          <el-col :span="6">
            <h4 class="headerWithBorder blue">Time Based Calculation</h4>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="10">
              <el-col :span="2">
                <input :disabled="disabled" id="timeBasedCalculation" type="checkbox" v-model="decisionMaking.timeBasedCalculation"
                  v-on:change="putProReviewCalculate('timeBasedCalculation', decisionMaking)">
              </el-col>
              <el-col :span="11">
                <el-form-item label="Total Time Spent">
                  <el-input :disabled="disabled" id="totalTimeSpent" type="number" size="mini" v-model="decisionMaking.timeSpentTotal"
                    v-on:change.native="putProReviewCalculate('timeSpentTotal', decisionMaking)">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="Time Spent Counseling">
                  <el-input :disabled="disabled" id="timeSpentCounseling" type="number" size="mini" v-model="decisionMaking.timeSpentCounseling"
                    v-on:change.native="putProReviewCalculate('timeSpentCounseling', decisionMaking)">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row id="timeBasedCalculationRow" v-if="isLevel2" style="padding: 20px 0px 0px 0px;">
          <el-col :span="6">
            <h4 class="headerWithBorder blue">Level 2 Time Based Calculation</h4>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="10">
              <el-col :span="2">
                <input :disabled="disabled" id="timeBasedCalculation" type="checkbox" v-model="level2DecisionMaking.timeBasedCalculation"
                  v-on:change="putProReviewCalculate('timeBasedCalculation', level2DecisionMaking)">
              </el-col>
              <el-col :span="11">
                <el-form-item label="Total Time Spent">
                  <el-input :disabled="disabled" id="totalTimeSpent" type="number" size="mini" v-model="level2DecisionMaking.timeSpentTotal"
                    v-on:change.native="putProReviewCalculate('timeSpentTotal', level2DecisionMaking)">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="Time Spent Counseling">
                  <el-input :disabled="disabled" id="timeSpentCounseling" type="number" size="mini" v-model="level2DecisionMaking.timeSpentCounseling"
                    v-on:change.native="putProReviewCalculate('timeSpentCounseling', level2DecisionMaking)">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

      </el-col>
    </el-row>
  </el-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProReviewDecisionMaking',
    props: ['history', 'exam', 'isLevel2', 'level2DecisionMaking', 'examLevel', 'level2ExamLevel', 'decisionMaking', 'level2Exam', 'level2History', 'changedField', 'disabled'],
    data() {
      return {
        form: {},
        rules: {
          addCoder: [
            { required: true, message: 'Please Set Coder' }
          ]
        },
        numberDropDown: [
          { key: 0, value: 0 },
          { key: 1, value: 1 },
          { key: 2, value: 2 }
        ],
        numberDropDown2: [
          { key: 0, value: 0 },
          { key: 1, value: 1 },
          { key: 2, value: 2 },
          { key: 3, value: 3 },
          { key: 4, value: 4 },
          { key: 5, value: 5 },
          { key: 6, value: 6 },
          { key: 7, value: 7 },
          { key: 8, value: 8 },
          { key: 9, value: 9 }
        ]
      }
    },
    created: function () {
    },
    methods: {
      ...mapActions('proReviews/', ['PUT_PRO_DECISION_MAKING']),
      async putProReviewCalculate(changedField, decisionMaking) {
        const payload = { decisionMaking: decisionMaking, changedField: changedField }
        try {
          await this.PUT_PRO_DECISION_MAKING(payload)
          this.$message({
            message: 'Decision Making Update Successful',
            type: 'success'
          })
        } catch {
          this.$message({
            message: 'Decision Making Update Error',
            type: 'error'
          })
        }
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview']),
      ...mapState('dropdowns/', ['dropdowns'])
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .resultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #63b344;
    margin: -8px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
  }

  .blueResultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #005695;
    margin: -8px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
  }

  .blue {
    color: #005695 !important;
    font-weight: bold !important;
  }
</style>
<style>
  #proRiskDefinitions .el-form-item__label {
    width: 10% !important;
  }

  #proRiskDefinitions .el-form-item__content {
    margin-left: 15% !important;
  }

  #timeBasedCalculationRow .el-form-item__label {
    width: 60% !important;
  }

  #timeBasedCalculationRow .el-form-item__content {
    margin-left: 60% !important;
  }

  #timeBasedCalculationRow h4 {
    padding-top: 4px;
  }
</style>