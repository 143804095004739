<template>
  <div v-loading="feeScheduleLoader" element-loading-spinner="atom-audit-loader">
    <template>
      <el-dialog width="90%" title="Physician Fee Schedule" :visible="showDialog" :before-close="closeDialog">
        <div v-if="physicianFeeSchedule !== undefined">
          <el-row :gutter="20">
            <el-col :span="24">
              <div>
                <!-- @tab-click="handleTabClick" -->
                <el-collapse v-model="activeTabName">
                  <el-collapse-item title="Original" name="original">
                    <physicianFeeScheduleList :tableData="getData(0)"></physicianFeeScheduleList>
                  </el-collapse-item>
                  <el-collapse-item title="Recommended" name="recommended">
                    <physicianFeeScheduleList :tableData="getData(1)"></physicianFeeScheduleList>
                  </el-collapse-item>
                  <!-- <el-collapse-item title="Level 2" name="level2">
                    <physicianFeeScheduleList :tableData="getData(2)"></physicianFeeScheduleList>
                  </el-collapse-item> -->
                </el-collapse>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <center>Physician Fee Schedule Unavailable</center>
        </div>
      </el-dialog>
    </template>
  </div>

</template>
<script>
  import { mapState } from 'vuex'
  import physicianFeeScheduleList from './physicianFeeScheduleList'

  export default {
    name: 'PhysicianFeeSchedule',
    props: ['proRecordId', 'showDialog'],
    components: {
      physicianFeeScheduleList
    },
    data() {
      return {
        physicianFeeSchedule: [],
        codeLevel: 0,
        activeTabName: ['original', 'recommended'],
        feeScheduleLoader: false
      }
    },
    methods: {
      getData(codeLevel) {
        let dataForCodeLevel = this.proReview.proRecordCptsPhysicianFeeSchedule.filter(x => x.codeLevel === codeLevel)
        dataForCodeLevel = dataForCodeLevel.map(x => {
          const cpt = this.proReview.proRecordCpts.find(y => y.cptId === x.cptId)

          if (codeLevel === 0) {
            x.description = cpt.originalDescCalc
          }

          if (codeLevel === 1) {
            x.description = cpt.recommendedDescCalc ?? cpt.originalDescCalc
          }

          if (codeLevel === 2) {
            x.description = cpt.level2DescCalc ?? cpt.recommendedDescCalc ?? cpt.originalDescCalc
          }

          return x
        })

        return dataForCodeLevel
      },
      closeDialog(done) {
        this.$emit('closeDialog')
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview'])
    }
  }

</script>

<style slot-scoped>
>>> .VuePagination__count {
  display: none;
}
</style>
