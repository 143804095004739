const truCodeHelper = {
  methods: {
    getTruCodeQuery(focusedCode, type, book = null) {
      const option = 'None'
      const mockUser = true
      const isDx = (type === 'DX')
      const isCptApc = (type === 'CPTAPC')

      const isExternalInjuryCode = function () {
        if (focusedCode[0] === 'V' || focusedCode[0] === 'W' || focusedCode[0] === 'X' || focusedCode[0] === 'Y') {
          return true
        }
        return false
      }

      const isHCPCS = function () {
        return isNaN(focusedCode[0])
      }

      if (book === null) {
        if (isDx && !isExternalInjuryCode()) {
          book = 'ICD10CM_DX'
        } else if (isDx && isExternalInjuryCode()) {
          book = 'ICD10CM_E'
        } else if (!isDx && !isCptApc) {
          book = 'ICD10PCS_PR'
        } else if (isCptApc && !isHCPCS()) {
          book = 'CPT4'
        } else if (isCptApc && isHCPCS()) {
          book = 'HCPCS'
        }
      }

      return {
        term: focusedCode, book: book, option: option, mockUser: mockUser
      }
    }
  }
}

export default truCodeHelper
