<template>
  <div>
    <el-dialog v-if="cpt !== null" class="modDialog" :visible="showApcModal" :title="`Edit APCs for CPT Line ${cpt.lineSequence}`" width="80%" :before-close="
        () => {
          showApcModal = false
        }
      " :close-on-click-modal="false" :show-close="false">
      <div v-loading="loading" element-loading-spinner="atom-audit-loader">
        <el-form size="mini" label-position="left" label-width="140px">
          <el-row style="margin-bottom: 20px;">
            <el-col :span="12">
              <el-form-item label="Original CPT">
                <div style="width: 100%;">
                  <el-input style="width: 112px; display: inline-block;" inputId="originalCptMod" v-model="cpt.originalCpt" disabled> </el-input>
                  <span style=" margin-left: 5px; margin-top: 3px; display: inline-block" ref="originalModifier"
                    v-html="cptModifiers(cpt.ascRecommendationsModifiers, 'originalModifier')"> </span>
                </div>
                <div>
                  <div style="margin-top: 3px; display: inline-block" class="overflow" :id="'OrDesc_Mod_' + cpt.cptId" @click="expandDesc(cpt.cptId, true)">{{
                    cpt.originalDescCalc }}</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Recommended CPT">
                <div style="width: 100%;">
                  <el-input style="width: 112px; display: inline-block;" :placeholder="cpt.originalCpt" inputId="recCptMod" v-model="cpt.recommendedCpt" disabled
                    :class="{ deleteCode: cpt.level2DeleteCode || cpt.deleteCode }"> </el-input>
                  <span style="margin-left: 5px; margin-top: 3px; display: inline-block" ref="originalModifier"
                    v-html="recCptModifers(cpt.ascRecommendationsModifiers, 'originalModifier', 'recommendedModifier')"> </span>
                </div>
                <div style="margin-top: 3px; display: inline-block" class="overflow" :id="'RecDesc_Mod_' + cpt.cptId" @click="expandDesc(cpt.cptId, true)">{{ !cpt.deleteCode ?
                  cpt.recDescCalc : 'Delete Code' }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row>
          <el-col :span="12">
            <h4 class="headerWithBorder">Original APC</h4>
          </el-col>
          <el-col :span="12">
            <h4 class="headerWithBorder">Recommended APC</h4>
          </el-col>
        </el-row>

        <div class="hiaTable">
          <table class="VueTables__table table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Orig. APC</th>
                <th>Orig. APC Wt.</th>
                <th>Orig. APC Pay Rt.</th>
                <th>Orig. APC Payment</th>
                <th>Rec. APC</th>
                <th>Rec. APC Wt.</th>
                <th>Rec. APC Pay Rt.</th>
                <th>Rec. APC Payment</th>
                <th style="width: 50px;">Actions</th>
                <th>APC Reason for Change</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="word-break: break-word;">
                  <template v-if="!isTrucodeEnabled()">
                    <hia-el-select :disabled="disabled" @input="handleApcChange('original')" v-model="localCpt.origApc" placeholder="Orig. APC" itemkey="apc" label="label"
                      :dropdowns="apcList" clearable filterable> </hia-el-select>
                  </template>
                  <template v-if="isTrucodeEnabled()">
                    {{localCpt.origApc ? `${localCpt.origApc} | ${localCpt.origApcDescription}` : null}}
                  </template>
                </td>
                <td>
                  <template v-if="!isTrucodeEnabled()">
                    <input :disabled="disabled" v-model="localCpt.origApcWeight" style="width: 70px;" type="number" step=".0001" placeholder="Orig. Wt."
                      @change="calcPayment(cpt)" />
                  </template>
                  <template v-if="isTrucodeEnabled()">
                    {{localCpt.origApcWeight}}
                  </template>
                </td>
                <td>
                  <template v-if="!isTrucodeEnabled()">
                    <hia-el-select :disabled="disabled" style="width: 80px; height: 18px; margin-top: -4px;" v-model="localCpt.origApcPayRate" placeholder="Orig. Pay Rt."
                      itemkey="key" label="value" :dropdowns="dropdowns.ApcPaymentRates.list" clearable filterable @input="calcPayment(cpt)"> </hia-el-select>
                  </template>
                  <template v-if="isTrucodeEnabled()">
                    {{localCpt.origApcPayRate ? (localCpt.origApcPayRate * 100) + '%' : null}}
                  </template>
                </td>
                <td>
                  <div style="margin-top: 2px;"><span v-if="localCpt.origApcPayment">${{ localCpt.origApcPayment === 0 || localCpt.origApcPayment > 0 ?
                      localCpt.origApcPayment.toFixed(2) : '' }}</span></div>
                </td>
                <td :class="{ deleteCode: localCpt.deleteApc }" style="word-break: break-word;">
                  <template v-if="!isTrucodeEnabled()">
                    <hia-el-select :disabled="localCpt.deleteApc || disabled" @input="handleApcChange('recommended', $event)" :value="localCpt.recApc" itemkey="apc"
                      label="label" :dropdowns="apcList" clearable filterable> </hia-el-select>
                  </template>
                  <template v-if="isTrucodeEnabled()">
                    {{localCpt.recApcDescription === 'DELETE APC' ? 'DELETE APC' : localCpt.recApc ? `${localCpt.recApc} | ${localCpt.recApcDescription}` : null}}
                  </template>
                </td>
                <td :class="{ deleteCode: localCpt.deleteApc }">
                  <template v-if="!isTrucodeEnabled()">
                    <input :disabled="localCpt.deleteApc || disabled" v-model="localCpt.recApcWeight" type="number" step=".0001" style="width: 112px;"
                      @change="calcPayment(cpt)" />
                  </template>
                  <template v-if="isTrucodeEnabled()">
                    {{localCpt.recApcWeight ? localCpt.recApcWeight : null}}
                  </template>
                </td>
                <td :class="{ deleteCode: localCpt.deleteApc }">
                  <template v-if="!isTrucodeEnabled()">
                    <hia-el-select style="width: 80px; height: 18px; margin-top: -4px;" :disabled="localCpt.deleteApc || disabled" v-model="localCpt.recApcPayRate"
                      itemkey="key" label="value" :dropdowns="dropdowns.ApcPaymentRates.list" clearable filterable @input="calcPayment(cpt)"> </hia-el-select>
                  </template>
                  <template v-if="isTrucodeEnabled()">
                    {{localCpt.recApcPayRate ? (localCpt.recApcPayRate * 100) + '%' : null}}
                  </template>
                </td>
                <td>
                  <div v-if="!localCpt.deleteApc" style="margin-top: 2px;"><span v-if="getRecApcPayment()">${{ getRecApcPayment() }}</span></div>
                  <div v-if="localCpt.deleteApc" style="margin-top: 2px;">DELETE APC</div>
                </td>
                <td>
                  <div style="margin-top: 5px;">
                    <template v-if="!isTrucodeEnabled()">
                      <iconCheckBox style="float: left" v-model="localCpt.deleteApc" title="Delete APC" icon="mdi mdi-alpha-d" :disabled="disabled" @change="handleDelete">
                      </iconCheckBox>
                    </template>
                    <iconCheckBox style="float: left" v-model="localCpt.apcQuery" title="Query Recommended" icon="mdi mdi-alpha-q"
                      :disabled="disabled || (!localCpt.recApc && !localCpt.deleteApc)" @change="isDirty = true">
                    </iconCheckBox>
                  </div>
                </td>
                <td>
                  <hia-el-select style="width: 80px; height: 18px; margin-top: -4px;" :disabled="!rowChange() || disabled" v-model="localCpt.apcReasonForChange"
                    placeholder="APC Reason for Change" itemkey="key" label="value" :dropdowns="dropdowns.outpatientReasonsForChangeApc.list" clearable filterable
                    @input="isDirty = true"> </hia-el-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <span slot="footer">
        <el-button-group>
          <el-button class="hiaButton" @click="cancel" size="mini" type="text" plain round>{{ isDirty ? 'Cancel' : 'Close' }}</el-button>
          <el-button ref="saveApcButton" class="hiaButton" @click="save($event)" size="mini" plain round>Save</el-button>
        </el-button-group>
      </span>
    </el-dialog>

    <el-popover ref="apcPopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="apcPopoverContent"
      @after-enter="handleHidePopOver('apcPopover')"> </el-popover>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import iconCheckBox from '@/components/controls/iconCheckBox'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { isAfter, parseISO } from 'date-fns'

  export default {
    name: 'outpatientReviewModifiers',
    props: ['cpt', 'showApcModal'],
    mixins: [HiaAuthorization],
    data: function () {
      return {
        toggleGrid: true,
        loading: false,
        isDirty: false,
        // disabled: false,
        theSortable: null,
        apcPopoverContent: '',
        showAddPopover: false,
        localCpt: {},
        addRow: [{ codeType: null, originalModifier: null, recommendedModifier: null }],
        columns: ['origApc', 'origApcWeight', 'origApcPayRate', 'origApcPayment', 'recApc', 'recApcWeight', 'recApcPayRate', 'recApcPayment', 'actions'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'modLineSequenceCol',
            codeType: 'modCodeTypeCol',
            originalModifier: 'oneHundredPx',
            originalDesc: 'descriptionCol',
            recommendedModifier: 'oneHundredPx',
            recDesc: 'descriptionCol',
            reasonForChange: 'reasonForChangeCol',
            actions: 'modActionsCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            codeType: 'Type',
            originalModifier: 'Mod',
            originalDesc: 'Description',
            recommendedModifier: 'Rec. Mod',
            recDesc: 'Description',
            delete: '',
            add: ''
          }
        }
      }
    },
    components: {
      iconCheckBox
    },
    methods: {
      ...mapActions('outpatientReviews/', ['GET_APCS']),
      ...mapActions('managedLists/blendedRates/', ['GET_BLENDED_RATES']),
      ...mapMutations('outpatientReviews/', ['SET_OUTPATIENT_CPT_ROW']),
      async handleApcChange(level, value) {
        if (level === 'original') {
          if (this.localCpt.origApc) {
            const apcListRow = this.apcList.find(x => x.apc === this.localCpt.origApc)
            if (apcListRow.apcWeight) {
              this.localCpt.origApcWeight = apcListRow.apcWeight
            }
          }
        }

        if (level === 'recommended') {
          if (!this.localCpt.recApc && value) {
            this.localCpt.recApc = value
            const apcListRow = this.apcList.find(x => x.apc === this.localCpt.recApc)
            if (apcListRow.apcWeight) {
              this.localCpt.recApcWeight = apcListRow.apcWeight
            }
          }

          if (this.localCpt.recApc && !value) {
            const continueOperation = await this.continueApcChange(value)
            if (!continueOperation) {
              return
            }
            this.localCpt.recApc = value
          }
        }

        this.calcPayment()
      },
      async continueApcChange(value) {
        if (!value) {
          if (this.localCpt.apcQuery && !this.localCpt.apcReasonForChange) {
            try {
              await this.$confirm('This will clear the Query Button, continue? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
              this.localCpt.apcQuery = false
              return true
            } catch (e) {
              return false
            }
          }

          if (!this.localCpt.apcQuery && this.localCpt.apcReasonForChange) {
            try {
              await this.$confirm('This will clear the APC Reason for Change, continue? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
              this.localCpt.apcReasonForChange = null
              return true
            } catch (e) {
              return false
            }
          }

          if (this.localCpt.apcQuery && this.localCpt.apcReasonForChange) {
            try {
              await this.$confirm('This will clear the Query Button and the APC Reason for Change, continue? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
              this.localCpt.apcQuery = false
              this.localCpt.apcReasonForChange = null
            } catch (e) {
              return false
            }
          }
        }
        return true
      },
      calcPayment() {
        this.isDirty = true
        const blendedRate = this.blendedRatesList.find(x => x.blendedRateType === 'APC' && x.clientId === this.outpatientReview.clientId && isAfter(parseISO(this.outpatientReview.dateOfService), parseISO(x.effectiveDate)))

        if (blendedRate) {
          if (this.localCpt.origApcWeight && (this.localCpt.origApcPayRate || this.localCpt.origApcPayRate === 0)) {
            this.localCpt.origApcPayment = Math.round(blendedRate.blendedRate * this.localCpt.origApcWeight * this.localCpt.origApcPayRate * 100) / 100
          } else {
            this.localCpt.origApcPayment = null
          }
          if (this.localCpt.recApcWeight && (this.localCpt.recApcPayRate || this.localCpt.recApcPayRate === 0)) {
            this.localCpt.recApcPayment = Math.round(blendedRate.blendedRate * this.localCpt.recApcWeight * this.localCpt.recApcPayRate * 100) / 100
          } else {
            this.localCpt.recApcPayment = null
          }
        }
      },
      rowChange() {
        if (this.localCpt.recApc || this.localCpt.recApcWeight || this.localCpt.recApcPayRate || this.localCpt.deleteApc) {
          return true
        }
        return false
      },
      async handleDelete() {
        const continueOperation = await this.continueDeleteChange()
        if (continueOperation) {
          this.isDirty = true
          this.calcPayment()
        }
      },
      async continueDeleteChange() {
        if (this.localCpt.deleteApc && (this.localCpt.recApc > 0 || this.localCpt.recApcPayRate || this.localCpt.recApcPayRate === 0 || this.localCpt.recApcWeight || this.localCpt.recApcWeight === 0)) {
          try {
            await this.$confirm('This will clear the Recommended APC, Recommended Weight and Recommended Payment Rate, continue? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            this.localCpt.recApc = null
            this.localCpt.recApcWeight = null
            this.localCpt.recApcPayRate = null
            return true
          } catch (e) {
            this.localCpt.deleteApc = !this.localCpt.deleteApc
            return false
          }
        }

        if (!this.localCpt.deleteApc && (this.localCpt.apcQuery && this.localCpt.apcReasonForChange)) {
          try {
            await this.$confirm('This will clear the Query Button and the APC Reason for Change, continue? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            this.localCpt.apcQuery = false
            this.localCpt.apcReasonForChange = null
            return true
          } catch (e) {
            this.localCpt.deleteApc = !this.localCpt.deleteApc
            return false
          }
        }

        if (!this.localCpt.deleteApc && (this.localCpt.apcQuery && !this.localCpt.apcReasonForChange)) {
          try {
            await this.$confirm('This will clear the Query Button, continue? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            this.localCpt.apcQuery = false
            return true
          } catch (e) {
            this.localCpt.deleteApc = !this.localCpt.deleteApc
            return false
          }
        }

        if (!this.localCpt.deleteApc && (!this.localCpt.apcQuery && this.localCpt.apcReasonForChange)) {
          try {
            await this.$confirm('This will clear the APC Reason for Change, continue? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            this.localCpt.apcReasonForChange = null
            return true
          } catch (e) {
            this.localCpt.deleteApc = !this.localCpt.deleteApc
            return false
          }
        }
        return true
      },
      save(e) {
        if (!this.isTrucodeEnabled()) {
          if ((this.localCpt.origApc || this.localCpt.origApcWeight || this.localCpt.origApcPayRate) && !(this.localCpt.origApc && this.localCpt.origApcWeight && (this.localCpt.origApcPayRate || this.localCpt.origApcPayRate === 0))) {
            this.apcPopoverContent = 'When setting Original APC, all three of Original APC, Original Weight and Original Payment Rate are required.'
            this.openapcPopover(e)
            return
          }

          if ((this.localCpt.recApc || this.localCpt.recApcWeight || this.localCpt.recApcPayRate) && !(this.localCpt.recApc && this.localCpt.recApcWeight && (this.localCpt.recApcPayRate || this.localCpt.recApcPayRate === 0))) {
            this.apcPopoverContent = 'When setting Recommended APC, all three of Recommended APC, Recommended Weight and Recommended Payment Rate are required.'
            this.openapcPopover(e)
            return
          }
        }

        this.$emit('save', this.localCpt)
        this.isDirty = false
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      cancel() {
        this.$emit('cancel')
      },
      getRecPayRatePlaceHolder() {
        if (this.localCpt.origApcPayRate || this.localCpt.origApcPayRate === 0) {
          return `${this.localCpt.origApcPayRate * 100}%`
        }
        return 'Rec. APC Pay Rate'
      },
      getRecApcPayment() {
        if ((this.localCpt.recApcPayment === 0 || this.localCpt.recApcPayment > 0) && this.localCpt.recApc) {
          return this.localCpt.recApcPayment.toFixed(2)
        }
      },
      openapcPopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.apcPopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      cptModifiers(modifiers, version) {
        // remove blank mods
        modifiers = modifiers.filter(function (n) {
          if (n[version] !== undefined && n[version] !== null) {
            return true
          }
          return false
        })
        if (modifiers !== null) {
          const modLength = modifiers.length
          let modifer = ''
          let comma = ''
          if (modLength) {
            const cptId = modifiers[0].cptid
            for (let i = 0; i < modLength; i++) {
              if (modifiers[i][version] !== null && modifiers[i][version].trim().length > 1) {
                comma = modLength > 1 && i + 1 !== modLength ? ',' : ''
                modifer += '<span id="' + version + '_' + i + '" cptId="' + cptId + '" title="' + this.modDescription(modifiers[i][version]) + '">' + modifiers[i][version] + '</span>' + comma
              }
            }
          }
          // modifer += '&nbsp;'
          return modifer
        }
        return null
      },
      recCptModifers(row, originalModifier, recommendedModifier) {
        if (row.length) {
          let modifiers = []
          row.forEach((v, i) => {
            const row = v
            row.displayMod = v[recommendedModifier] || v[originalModifier]
            modifiers.push(row)
          })
          modifiers = modifiers.filter(function (n) {
            if (n.displayMod !== undefined && n.displayMod !== null) {
              return true
            }
            return false
          })
          if (modifiers !== null) {
            const modLength = modifiers.length
            let modifer = ''
            let comma = ''
            if (modLength) {
              const cptId = modifiers[0].cptid
              for (let i = 0; i < modLength; i++) {
                if (modifiers[i].displayMod !== null && modifiers[i].displayMod.trim().length > 1) {
                  comma = modLength > 1 && i + 1 !== modLength ? ',' : ''
                  modifer += '<span id="recommendedModifier_' + i + '" cptId="' + cptId + '" title="' + this.modDescription(modifiers[i].displayMod) + '">' + modifiers[i].displayMod + '</span>' + comma
                }
              }
            }
            // modifer += '&nbsp;'
            return modifer
          }
        }
        return null
      },
      modDescription(modifier) {
        if (modifier !== null && modifier.length) {
          let desc = ''
          for (const i in this.dropdowns.modifiers.list) {
            if (modifier === this.dropdowns.modifiers.list[i].key) {
              desc = this.dropdowns.modifiers.list[i].value
                .split('|')[1]
                .trim()
                .toLowerCase()
              break
            }
          }
          return desc
        }
      },
      expandDesc(cptId, isMod = false) {
        // const targetId = $event.currentTarget.id
        // console.log(targetId)
        const orTargetId = isMod ? 'OrDesc_Mod_' + cptId : 'OrDesc_' + cptId
        const recTargetId = isMod ? 'RecDesc_Mod_' + cptId : 'RecDesc_' + cptId

        const orEl = document.getElementById(orTargetId)
        const recEl = document.getElementById(recTargetId)
        orEl.classList.toggle('overflow')
        recEl.classList.toggle('overflow')
      },
      setLocalCptTrucode() {
        const orig = this.cpt.ascRecommendationsCptsGrouping.find(x => x.codeLevel === 0)
        if (orig) {
          this.localCpt.origApc = orig.apc
          this.localCpt.origApcDescription = orig.apcDescription
          this.localCpt.origApcWeight = orig.weight
          this.localCpt.origApcPayRate = orig.percentage
          this.localCpt.origApcPayment = orig.payment
        }

        const rec = this.cpt.ascRecommendationsCptsGrouping.find(x => x.codeLevel === 1)
        if (rec) {
          this.localCpt.recApc = rec.apc
          this.localCpt.recApcDescription = rec.apcDescription
          this.localCpt.recApcWeight = rec.weight
          this.localCpt.recApcPayRate = rec.percentage
          this.localCpt.recApcPayment = rec.payment
        }
      }
    },
    mounted: async function () {
      this.localCpt = JSON.parse(JSON.stringify(this.cpt))
      if (this.isTrucodeEnabled()) {
        this.setLocalCptTrucode()
      }

      if (this.blendedRatesList.length === 0) {
        this.loading = true
        try {
          await this.GET_BLENDED_RATES()
          this.loading = false
        } catch (e) {
          this.lading = false
          console.log(e)
        }
      }
    },
    watch: {
      isLevel2(val) {
        this.$nextTick(() => {
          if (this.theSortable) {
            this.attachSortable()
          }
        })
      },
      'cpt.recApcPayment': function (val) {
        this.localCpt = JSON.parse(JSON.stringify(this.cpt))
        if (this.isTrucodeEnabled()) {
          this.setLocalCptTrucode()
        }
      },
      'cpt.origApcPayment': function () {
        this.localCpt = JSON.parse(JSON.stringify(this.cpt))
        if (this.isTrucodeEnabled()) {
          this.setLocalCptTrucode()
        }
      },
      'outpatientReview.dateOfService': async function () {
        await this.GET_APCS(this.outpatientReview.dateOfService)
      }
    },
    computed: {
      ...mapState('outpatientReviews/', ['outpatientReview', 'apcList']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/blendedRates/', ['blendedRatesList']),
      disabled() {
        return !!this.readOnly() || this.outpatientReview.reviewStatusId !== 2
      },
      isLevel2: function () {
        return this.outpatientReview.reviewLevel > 1
      }
    }
  }
</script>

<style scoped>
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #b4bccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #1f2d3d;
    display: block;
    font-size: 12px;
    height: 22px;
    outline: 0;
    padding: 3px 3px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  th {
    word-break: break-word;
  }

  .overflow {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 48px;
    text-overflow: ellipsis;
    cursor: zoom-in;
  }
</style>