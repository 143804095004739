import { render, staticRenderFns } from "./OutpatientReviewFooter.vue?vue&type=template&id=581bbeaf&scoped=true&"
import script from "./OutpatientReviewFooter.vue?vue&type=script&lang=js&"
export * from "./OutpatientReviewFooter.vue?vue&type=script&lang=js&"
import style0 from "./OutpatientReviewFooter.vue?vue&type=style&index=0&id=581bbeaf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581bbeaf",
  null
  
)

export default component.exports