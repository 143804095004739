import { render, staticRenderFns } from "./proReviewDecisionMaking2021.vue?vue&type=template&id=fcd292ba&scoped=true&"
import script from "./proReviewDecisionMaking2021.vue?vue&type=script&lang=js&"
export * from "./proReviewDecisionMaking2021.vue?vue&type=script&lang=js&"
import style0 from "./proReviewDecisionMaking2021.vue?vue&type=style&index=0&id=fcd292ba&scoped=true&lang=css&"
import style1 from "./proReviewDecisionMaking2021.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcd292ba",
  null
  
)

export default component.exports