<template>
  <div>
    <div class="rich-text-wrapper" style="position: relative">
      <i v-if="value" class="mdi mdi-dots-horizontal ellipsis" @click="show = true"></i>
      <div class="rich-text" v-html="value"></div>
    </div>
    <el-dialog :visible.sync="show" :title="title" width="70%" @open="handleOpen" :before-close="handleClose" :modal="true" :append-to-body="true" :destroy-on-close="true">

      <div v-if="!showTinyMce" v-html="value"></div>
      <Tinymce v-if="showTinyMce" id="dialogEdit" :height="250" v-model="value">
      </Tinymce>

      <span slot="footer">

        <template v-if="!editable || readOnly()">
          <el-button @click="show = false" size="mini" plain round>Close</el-button>
        </template>
        <el-button-group v-else>
          <el-button key="button1" v-if="!showTinyMce" @click="showTinyMce = true" size="mini" type="text" plain round>Edit</el-button>
          <el-button key="button2" v-if="showTinyMce" @click="handleCancel" type="text" plain round>Cancel</el-button>
          <el-button key="button3" v-if="showTinyMce" @click="handleConfirm" plain round>Confirm</el-button>
          <el-button key="button4" v-if="!showTinyMce" @click="handleClose" size="mini" plain round>Close</el-button>
        </el-button-group>

      </span>

    </el-dialog>

  </div>
</template>

<script>
  import Tinymce from '@/components/controls/Tinymce'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'hiaRichText',
    props: ['value', 'title', 'editable'],
    mixins: [HiaAuthorization],
    data() {
      return {
        show: false,
        showTinyMce: false,
        localBackup: null
      }
    },
    components: {
      Tinymce
    },
    mounted: function () {
    },
    created: function () {
    },
    methods: {
      handleClose() {
        this.show = false
        this.showTinyMce = false
        this.value = this.localBackup
      },
      handleCancel() {
        this.value = this.localBackup
        this.showTinyMce = false
        this.show = false
      },
      handleConfirm() {
        this.$emit('input', this.value)
        this.$emit('change')
        this.showTinyMce = false
        this.show = false
      },
      handleOpen() {
        this.localBackup = this.value
      }
    },
    computed: {
    },
    watch: {
    }
  }

</script>

<style scoped>
.rich-text {
  border: 1px solid #dcdfe6;
  background: #e5eef4;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0 20px 0px;
  min-height: 30px;
  font-size: 14px;
  overflow-x: auto;
}

.ellipsis {
  position: absolute;
  top: 2px;
  right: 5px;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
}

.rich-text-wrapper > i {
  visibility: hidden;
}

.rich-text-wrapper:hover > i {
  visibility: visible;
}
</style>
