<template>
  <div>
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Recommendation Library</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/managed-lists/recommendation-library' }">Recommendation Library List</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.$route.params.id }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <div v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
    <template v-if="!loading">
      <el-form ref="editRecommendationForm" size="mini" label-position="left" label-width="160px" v-loading="putLoading" element-loading-spinner="atom-audit-loader" :rules="rules" :model="recommendationRecord">
        <el-row>
          <el-col :span="12">
            <el-form-item prop="title" label="Title">
              <el-input size="mini" type="text" @change="putRecommendation" v-model="recommendationRecord.title" id="title"></el-input>
            </el-form-item>
            <el-form-item prop="recommendationType" label="Recommendation Type">
              <hia-el-select v-model="recommendationRecord.recommendationType" placeholder="Recommendation Type" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationTypes.list" @input="putRecommendation"> </hia-el-select>
            </el-form-item>
            <el-form-item prop="recordType" label="Record Type">
              <hia-el-select v-model="recommendationRecord.recordType" placeholder="Record Type" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationRecordTypes.list" @input="putRecommendation"> </hia-el-select>
            </el-form-item>
            <el-form-item prop="codeGroup" label="Code Group">
              <hia-el-select v-model="recommendationRecord.codeGroup" placeholder="Code Group" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationCodeGroups.list" @input="putRecommendation"> </hia-el-select>
            </el-form-item>
            <el-form-item prop="recommendation" label="Recommendation">
              <Tinymce id="recommendation" @change="putRecommendation" v-model="recommendationRecord.recommendation"></Tinymce>
            </el-form-item>
            <el-form-item prop="createdBy" label="Created By">
              <el-input v-if="!recommendationRecord.createdBy" disabled size="mini" type="text" value="System"></el-input>
              <hia-el-select v-else v-model="recommendationRecord.createdBy" placeholder="User" :disabled="true" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.consultants.list" @input="putRecommendation"></hia-el-select>
            </el-form-item>
            <el-form-item label="Created Date">
              <el-input disabled v-model="createdDateComputed" size="mini" type="text" id="invite"></el-input>
            </el-form-item>
            <el-form-item prop="modifiedBy" label="Modified By">
              <hia-el-select v-model="recommendationRecord.modifiedBy" placeholder="" :disabled="true" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.consultants.list" @input="putRecommendation"></hia-el-select>
            </el-form-item>
            <el-form-item label="Modified Date">
              <el-input disabled v-model="modifiedDateComputed" size="mini" type="text" id="invite"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
  </div>
</template>

<script>
  import { format, parseISO } from 'date-fns'
  import { mapState, mapActions } from 'vuex'
  import Tinymce from '@/components/controls/Tinymce'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'RecommendationLibrary',
    mixins: [HiaAuthorization],
    data() {
      return {
        loading: true,
        putLoading: false,
        dropdownsNeeded: ['RecommendationTypes', 'RecommendationRecordTypes', 'RecommendationCodeGroups', 'clients', 'consultants'],
        rules: {
          title: [{ required: true, message: 'Title is required', trigger: 'change' }],
          recommendation: [
            {
              required: true,
              message: 'Recommendation is required',
              trigger: 'change'
            }
          ]
        }
      }
    },
    methods: {
      ...mapActions('recommendationLibrary/', ['GET_RECOMMENDATION_RECORD', 'PUT_RECOMMENDATION_RECORD']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      intialize() {
        this.loading = true
        Promise.all([this.GET_RECOMMENDATION_RECORD(this.$route.params.id), this.GET_DROPDOWNS(this.dropdownsNeeded)]).then(() => {
          this.loading = false
        })
      },
      async putRecommendation(form) {
        this.$refs.editRecommendationForm.validate(async valid => {
          if (valid) {
            try {
              this.putLoading = true
              this.recommendationRecord.modifiedBy = this.currentTenantUserId
              await this.PUT_RECOMMENDATION_RECORD(this.recommendationRecord)
              this.putLoading = false
              this.$message({
                message: 'Recommendation Library Updated Successfully',
                type: 'success'
              })
            } catch (err) {
              this.putLoading = false
              const message = err.message ? `Error Updating Recommendation Library: ${err.message}` : 'Error Updating Recommendation Library'
              this.$message({
                message: message,
                type: 'error'
              })
            }
          }
        })
      }
    },
    computed: {
      ...mapState('recommendationLibrary/', ['recommendationRecord']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user', 'currentTenantUserId', 'baseApiUrl']),
      createdDateComputed() {
        if (!this.recommendationRecord.createdDate) {
          return null
        }
        return format(parseISO(this.recommendationRecord.createdDate), 'M/dd/yyyy h:mm a')
      },
      modifiedDateComputed() {
        if (!this.recommendationRecord.modifiedDate) {
          return null
        }
        return format(parseISO(this.recommendationRecord.modifiedDate), 'M/dd/yyyy h:mm a')
      }
    },
    created: function() {
      this.intialize()
    },
    components: {
      Tinymce
    }
  }
</script>
