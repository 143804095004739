<template>
  <el-row :gutter="10">
    <el-col :lg="toggleReseach && isTrucodeEnabled() ? 18 : 24" :md="24" :sm="24">
      <el-col :span="16">
        <h3 class="el-page-header">Review Details</h3>
        <div v-if="!loading">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/projects/' }">Projects</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/projects/${outpatientReview.projectID}` }"> {{ outpatientReview.project.projectID }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/reviews/outpatient/${$route.params.ascTableId}` }"> {{ outpatientReview.patientNumber }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="24" v-if="loading">
        <div style="margin-top: 20px;" v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
      </el-col>
      <el-col class="top-margin" v-show="!loading">
        <el-card class="recordContainer" style="padding: 0px 0px 20px 0px;">
          <el-col style="margin: 0px 0px 10px 0px;" :span="24" v-if="!loading">
            <el-button-group class="pull-right" id="outpatientButtons">
              <el-dropdown @command="handleStatus">
                <el-button class="hiaButton" size="mini" :type="getStatusButtonType(outpatientReview)" plain round><i :class="getCurrentReviewStatus(outpatientReview).iconClass" style="margin-right:4px;"></i>{{ getCurrentReviewStatus(outpatientReview).status }}</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="status.reviewStatusId === outpatientReview.reviewStatusId || disabled || (isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4))" v-for="status in reviewStatus" :key="status.reviewStatusId" :command="status.reviewStatusId"> <i :class="status.iconClass"></i>{{ status.status }} {{ isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4) ? ` - Validation Errors` : `` }} </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button :disabled="!isValidationError()" :class="isValidationError() ? '' : 'hiaButton'" :type="isValidationError() ? 'danger' : ''" size="mini" @click="toggleRequiredFieldsErrorList = true" plain round :title="isValidationError() ? 'validation errors' : 'record is valid'"> <i :class="isValidationError() ? 'mdi mdi-alert' : 'mdi mdi-check-bold greenIcon'"></i> Validations</el-button>
              <el-button class="hiaButton" icon="el-icon-document" size="mini" @click="openWorksheet" plain round>Worksheet</el-button>
              <el-button class="hiaButton" icon="el-icon-collection" v-if="isTrucodeEnabled()" size="mini" @click="openCodeBooks()" plain round>Codebook </el-button>
            </el-button-group>
          </el-col>
          <el-col>
            <el-collapse id="reviewAccordion" v-model="activeNames" class="ipCollapse">
              <el-collapse-item title="Demographics" name="1">
                <OutpatientReviewDemographics ref="opDemographics" class="collapseContent" @requiredFieldsValidation="requiredFieldsValidation"></OutpatientReviewDemographics>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template slot="title">
                  <span style="width: 100%;">
                    Diagnosis Codes
                    <span class="codeTotalHeader"> - {{ outpatientReview.ascRecommendationsDx.length }} Total</span>
                    <span @click.stop="1 === 1" style="float: right; height:30px; " v-show="activeNames.some(s => s === '2')">
                      <el-dropdown ref="dxDropdown" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <span class="actionsHamburger mdi mdi-menu"></span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="addMultipleCodes">Add Multiple Codes</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </template>
                <OutpatientReviewDx @onCodeFocus="handleDxPxGridCodeFocus" @ellipsisClick="handleEllipsisClick" @handleCloseAddMultipleCodes="handleCloseAddMultipleCodes" @handleClosePoaAssignments="handleClosePoaAssignments" :showAddMultipleCodes="showAddMultipleCodes" :showPoaAssignments="showPoaAssignments" @handleSaveToLocalStorageForTruCode="saveToLocalStorageForTruCode"> </OutpatientReviewDx>
              </el-collapse-item>
              <el-collapse-item title="Procedure Codes" name="3" v-if="outpatientReview.outpatientGrouperId === 1">
                <template slot="title">
                  <span style="width: 100%;">
                    Procedure Codes
                    <span class="codeTotalHeader"> - {{ outpatientReview.ascRecommendationsPx.length }} Total</span>
                    <span @click.stop="1 === 1" style="float: right; height:30px;" v-show="activeNames.some(s => s === '3')">
                      <el-dropdown ref="pxDropdown" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <span class="actionsHamburger mdi mdi-menu"></span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="pxAddMultipleCodes">Add Multiple Codes</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </template>
                <OutpatientReviewPx @onCodeFocus="handleDxPxGridCodeFocus" @ellipsisClick="handleEllipsisClick" @handleCloseAddMultipleCodes="handleCloseAddMultipleCodes" :showAddMultipleCodes="showPxAddMultipleCodes" @handleSaveToLocalStorageForTruCode="saveToLocalStorageForTruCode"></OutpatientReviewPx>
              </el-collapse-item>
              <el-collapse-item title="CPT Codes" name="4">
                <template slot="title">
                  <span style="width: 100%;">
                    CPT Codes
                    <span class="codeTotalHeader"> - {{ outpatientReview.ascRecommendationsCptsApcs.length }} Total</span>
                    <span @click.stop="1 === 1" style="float: right; height:30px;" v-show="activeNames.some(s => s === '4')">
                      <el-dropdown ref="cptDropdown" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <span class="actionsHamburger mdi mdi-menu"></span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="cptAddMultipleCodes">Add Multiple Codes</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </template>
                <OutpatientReviewCpts @onCodeFocus="handleDxPxGridCodeFocus" @ellipsisClick="handleEllipsisClick" @handleCloseAddMultipleCodes="handleCloseAddMultipleCodes" :showAddMultipleCodes="showCptAddMultipleCodes" @handleSaveToLocalStorageForTruCode="saveToLocalStorageForTruCode"></OutpatientReviewCpts>
              </el-collapse-item>
              <el-collapse-item title="Audit Findings" name="5">
                <template slot="title">
                  <span style="width: 100%;">
                    Audit Findings
                    <span class="codeTotalHeader"> - {{ auditFindings ? auditFindings.length : 0 }} Findings</span>
                  </span>
                </template>
                <AuditFindings :disabled="readOnly()" class="collapseContent" :recordId="outpatientReview.ascTableId" recordType="OP"> </AuditFindings>
              </el-collapse-item>
              <el-collapse-item name="8">
                <template slot="title">
                  <span style="width: 100%;">
                    Coder Responses
                    <span class="codeTotalHeader"> - {{ outpatientReview.rebuttal ? outpatientReview.rebuttal.rebuttalComments.length : 0 }} Comments</span>
                  </span>
                </template>
                <Rebuttals :disabled="readOnly()" style="margin: 5px 0px 0px 10px;" class="collapseContent" :recordId="outpatientReview.ascTableId" recordType="OP"> </Rebuttals>
              </el-collapse-item>
              <!-- <el-collapse-item name="9">
                <template slot="title">
                  Required Fields
                  <template v-if="requiredFieldsErrorList.length > 0">
                    <span style="margin: 0px 0px 0px 10px;"><i class="mdi mdi-alert" style="color: #f56c6c"></i> - {{ requiredFieldsErrorList.length }} Total </span>
                  </template>
                  <template v-else>
                    <span style="margin: 0px 0px 0px 10px;"><i class="el-icon-check" style="color: #63b344"></i> </span>
                  </template>
                </template>
                <div>
                  <el-table :data="requiredFieldsErrorList" height="auto">
                    <el-table-column prop="group" label="Group" width=""></el-table-column>
                    <el-table-column prop="fieldLabel" label="Field" width=""></el-table-column>
                    <el-table-column prop="message" label="Error Message" width=""></el-table-column>
                  </el-table>
                </div>
              </el-collapse-item> -->
            </el-collapse>
            <OutpatientReviewFooter ref="OutpatientReviewFooter" style="margin: 30px 0px 30px 0px;" @handleTableCommands="handleTableCommands"></OutpatientReviewFooter>
          </el-col>
        </el-card>
      </el-col>
    </el-col>
    <el-col class="sticky" v-if="isTrucodeEnabled()" :lg="6" :md="24" :sm="24" v-show="toggleReseach">
      <ResearchPane v-if="!loading" :review="outpatientReview" :isLevel2="isLevel2" :truCodeAuthKey="outpatientReview.truCodeAuthKey" :truCodeQuery="truCodeQuery" :parent="'outpatient'" :showReviewRadioButtons="true" @onChangedFieldResearchPane="handleChangedFieldResearchPane" @onReferenceClick="handleTruCodeReferenceClick" @contentClicked="handleTruCodeCodeClick" @onReviewMode="handleReviewMode" encounterType="outpatient" :changedFieldResearchPane="changedFieldResearchPane"> </ResearchPane>
    </el-col>
    <div id="dialogs">
      <el-dialog :visible.sync="showInProgressWarning" title="Confirm Record Status" width="30%" :close-on-click-modal="false" :show-close="true" v-if="!loading" :before-close="cancelStatusConfirm">
        <div v-loading="confirmStatusLoading" element-loading-spinner="atom-audit-loader">
          <el-row>
            <el-col :span="12">
              <div v-for="status in reviewStatus" :key="status.reviewStatusId" style="margin: -15px 0px 20px 15px;">
                <el-radio :disabled="isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4)" v-model="confirmStatusId" :label="status.reviewStatusId"> <i :class="status.iconClass" style="margin-right: 6px;"></i>{{ status.status }} </el-radio>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="text-align: center; margin: -30px 0px 0px 0px;" v-if="isValidationError()">
                <p style="font-weight: bold; margin: 0px 0px 0px 0px;font-size: 15px;">Validation Errors</p>
                <p style="margin: 5px 0px 5px 0px;word-break: break-word;">The following validations are required for these statuses:</p>
                <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;">
                  <li style="text-align: left;"><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
                  <li style="text-align: left;"><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
                </ul>
                <el-button type="danger" size="mini" @click="toggleRequiredFieldsErrorList = true" plain round> <i class="mdi mdi-alert"></i> Validations</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div style="margin: 40px 0px 0px 0px;height: 15px;" class="inpatientButtons">
          <el-button-group style="float: right; padding-bottom: 20px;">
            <el-button class="hiaButton" plain round type="text" @click="cancelStatusConfirm">Cancel</el-button>
            <el-button class="hiaButton" plain round @click="handleConfirmStatus">Continue</el-button>
          </el-button-group>
        </div>
      </el-dialog>
      <el-dialog title="Validation Errors" :visible.sync="toggleRequiredFieldsErrorList" width="50%">
        <div>
          <p style="margin: 5px 0px 5px 0px;word-break: break-word;">The following validations are required for these statuses:</p>
          <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;">
            <li><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
            <li><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
          </ul>
          <el-table :data="requiredFieldsErrorList">
            <el-table-column prop="group" label="Group" width=""></el-table-column>
            <el-table-column prop="fieldLabel" label="Field" width=""></el-table-column>
            <el-table-column prop="message" label="Error Message" width=""></el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </el-row>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { format, parseISO, isDate } from 'date-fns'
  import OutpatientReviewDemographics from './OutpatientReviewDemographics'
  import OutpatientReviewDx from './OutpatientReviewDx'
  import OutpatientReviewPx from './OutpatientReviewPx'
  import OutpatientReviewCpts from './OutpatientReviewCpts'
  // import OutpatientReviewRecommendations from './OutpatientReviewRecommendations'
  import OutpatientReviewFooter from './OutpatientReviewFooter'
  import Rebuttals from '../Rebuttals'
  import ResearchPane from '@/components/controls/ResearchPane'
  import truCodeHelper from '@/mixins/trucode-helper'
  import reviewStatusHelper from '@/mixins/reviewStatusHelper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import AuditFindings from '../AuditFindings'
  import csvGenerator from '@/mixins/csvGenerator'

  export default {
    name: 'OutpatientReview',
    mixins: [truCodeHelper, HiaAuthorization, reviewStatusHelper, csvGenerator],
    data: function() {
      return {
        toggleRequiredFieldsErrorList: false,
        requiredFieldsErrorList: [],
        requiredFieldAlertToggle: false,
        togglePrincipalDxAnalysis: false,
        showPxAddMultipleCodes: false,
        showCptAddMultipleCodes: false,
        showAddMultipleCodes: false,
        showPoaAssignments: false,
        codebooksWindow: undefined,
        referencesWindow: undefined,
        worksheetWindow: undefined,
        changedFieldResearchPane: '',
        truCodeQuery: {},
        floatResearch: true,
        toggleReseach: true,
        activeNames: [],
        loading: true,
        format: format,
        parseISO: parseISO,
        showInProgressWarning: false,
        confirmStatusId: 0,
        confirmStatusLoading: false,
        routeNext: () => {},
        dropdownsNeeded: ['reviewLevels', 'consultants', 'dcSummaryAvailableAtCoding', 'dcSummaryAvailableAtReview', 'patientStatus', 'payors', 'patientSex', 'outpatientPatientTypes', 'ambulatoryPatientTypes', 'reviewStatus', 'clients', 'RebuttalStatus', 'allReasonsForChange', 'outpatientReasonsForChange', 'outpatientReasonsForChangeHeader', 'outpatientReasonsForChangeDx', 'outpatientReasonsForChangePx', 'outpatientReasonsForChangeCpt', 'outpatientReasonsForChangeMod', 'outpatientReasonsForChangeApc', 'codeTypes', 'modifiers', 'YesNoNAUnknown', 'outpatientGroupers', 'ascPaymentIndicators', 'ApcPaymentRates', 'recordTags']
      }
    },
    components: {
      OutpatientReviewDemographics,
      OutpatientReviewDx,
      OutpatientReviewPx,
      OutpatientReviewCpts,
      // OutpatientReviewRecommendations,
      OutpatientReviewFooter,
      AuditFindings,
      Rebuttals,
      ResearchPane
    },
    methods: {
      ...mapActions('outpatientReviews/', ['GET_OUTPATIENT_REVIEW', 'POST_OUTPATIENT_DX', 'PUT_OUTPATIENT_DX', 'POST_OUTPATIENT_PX', 'PUT_OUTPATIENT_PX', 'POST_OUTPATIENT_CPT', 'PUT_OUTPATIENT_CPT', 'GET_APCS', 'PUT_OUTPATIENT_REVIEW']),
      ...mapMutations('rebuttals/', ['SET_REBUTTAL']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      ...mapMutations('auditFindings/', ['SET_FINDINGS']),
      async initialize() {
        try {
          this.loading = true
          await Promise.all([this.GET_OUTPATIENT_REVIEW(this.$route.params.ascTableId), this.GET_DROPDOWNS(this.dropdownsNeeded)])
          if (!this.isTrucodeEnabled()) {
            await this.GET_APCS(this.outpatientReview.dateOfService)
          }
          // update vuex rebuttal - rebuttals and findings are managed in vuex after this call
          this.SET_REBUTTAL(this.outpatientReview.rebuttal)
          this.SET_FINDINGS(this.outpatientReview.auditFindings)
          this.saveToLocalStorageForTruCode(this.outpatientReview)
          this.requiredFieldsValidation()
          this.loading = false
        } catch (e) {
          // show error message
          this.loading = false
          console.log(e)
        }
      },
      async handleStatus(command) {
        try {
          // on Complete check valid required fields
          if (command === 4 || command === 3) {
            this.requiredFieldsValidation()
          }

          if (!this.isValidationError() || command === 2 || command === 1) {
            this.outpatientReview.reviewStatusId = parseInt(command)
            const payload = { outpatientReview: this.outpatientReview, changedField: 'reviewStatus' }
            await this.PUT_OUTPATIENT_REVIEW(payload)
            this.saveToLocalStorageForTruCode(this.outpatientReview)
            this.$message({
              message: 'Record Updated Successfully',
              type: 'success'
            })
            // this.$refs.opDemographics.$refs.form.clearValidate()
          }
        } catch (e) {
          this.$message({
            message: 'Record Update Error',
            type: 'error'
          })
        }
      },
      cancelStatusConfirm() {
        this.showInProgressWarning = false
      },
      async handleConfirmStatus() {
        this.confirmStatusLoading = true
        try {
          const copy = JSON.parse(JSON.stringify(this.outpatientReview))
          copy.reviewStatusId = this.confirmStatusId
          const payload = { outpatientReview: copy, changedField: 'reviewStatus' }
          await this.PUT_OUTPATIENT_REVIEW(payload)
          this.saveToLocalStorageForTruCode(this.outpatientReview)
          this.showInProgressWarning = false
          this.confirmStatusLoading = false
          this.routeNext(true)
          this.$message({
            message: 'Record Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.confirmStatusLoading = false
          this.$message({
            message: 'Record Update Error',
            type: 'error'
          })
        }
      },
      formatDate(date) {
        if (!date) {
          return ''
        }

        if (isDate(date)) {
          return format(date, 'MM-dd-yyyy')
        }

        return format(parseISO(date), 'MM-dd-yyyy')
      },
      toggleResearch() {
        this.toggleReseach = !this.toggleReseach
      },
      convertReviewModetoInt(mode) {
        let revmodeint
        switch (mode) {
          case 'original':
            revmodeint = 0
            break
          case 'recommended':
            revmodeint = 1
            break
          case 'level2':
            revmodeint = 2
            break
          default:
            revmodeint = 0
        }
        return revmodeint
      },
      saveToLocalStorageForTruCode(review, truCodeQuery) {
        if (!review.ascTableId) {
          return
        }
        // Because of IE/Edge issues, store a subset of the review instead of the whole thing
        const codeBookBus = {
          reviewSubset: {
            ascTableId: review.ascTableId,
            dischargeDate: review.dateOfService,
            ascRecommendationsDx: review.ascRecommendationsDx,
            ascRecommendationsPx: review.ascRecommendationsPx,
            ascRecommendationsCptsApcs: review.ascRecommendationsCptsApcs,
            project: review.project,
            disableDxPx: false,
            reviewLevel: review.reviewLevel,
            disabled: !!this.readOnly() || this.outpatientReview.reviewStatusId !== 2
          },
          truCodeQuery: truCodeQuery,
          parent: 'Outpatient Review',
          from: 'Outpatient Review',
          reviewLevel: review.reviewLevel
        }

        this.$ls.set(`codeBookBus_op_${review.ascTableId}`, JSON.stringify(codeBookBus))
        this.$ls.set('truCodeAuthKey', review.truCodeAuthKey)
      },
      handleChangedFieldResearchPane() {
        this.changedFieldResearchPane = ''
      },
      handleTruCodeReferenceClick(refEvent) {
        let url
        if (refEvent.code && refEvent.articleId) {
          url = '/trucode/references?code=' + refEvent.code + '&book=' + refEvent.book + '&articleId=' + refEvent.articleId
        } else if (refEvent.code) {
          url = '/trucode/references?code=' + refEvent.code + '&book=' + refEvent.book
        } else {
          url = '/trucode/references?book=' + refEvent.book
        }
        const cacheBuster = new Date().getTime()
        this.referencesWindow = window.open(url + '&dt=' + cacheBuster, 'reference', 'height=812,width=1600')
      },
      handleDxPxGridCodeFocus(focusedCodeObject) {
        const truCodeQuery = this.getTruCodeQuery(focusedCodeObject.code, focusedCodeObject.type)
        this.truCodeQuery = truCodeQuery
        this.saveToLocalStorageForTruCode(this.outpatientReview, truCodeQuery)
      },
      handleEllipsisClick(code) {
        if (!this.codebooksWindow || !this.codebooksWindow.parent) {
          this.openCodeBooks()
        }
      },
      handleTruCodeCodeClick(event) {
        const truCodeQuery = this.getTruCodeQuery(event.code, null, event.codeType)
        this.saveToLocalStorageForTruCode(this.outpatientReview, truCodeQuery)
        if (!this.codebooksWindow || !this.codebooksWindow.parent) {
          this.openCodeBooks()
        }
      },
      handleReviewMode(reviewMode) {
        this.$ls.set('reviewMode', reviewMode)
      },
      setupLocalStorageListenerForCodeBook() {
        // try to turn off any previous listeners so that they don't double up
        try {
          this.$ls.off(`codeBookBus_op_${this.$route.params.ascTableId}`)
        } catch {
          // nothing - its ok if this fails
        }

        this.$ls.on(`codeBookBus_op_${this.$route.params.ascTableId}`, storedData => {
          this.parseOutpatientReviewDataFromLocalStorage(storedData)
        })
      },
      async parseOutpatientReviewDataFromLocalStorage(storedData) {
        const codeBookBus = JSON.parse(storedData)
        const payload = {}
        if (codeBookBus.parent !== 'Outpatient Review') {
          return
        }

        // use "from" to avoid infinite loops
        if (codeBookBus.reviewSubset && codeBookBus.reviewSubset.ascTableId === this.outpatientReview.ascTableId && codeBookBus.from === 'CodeBook') {
          switch (codeBookBus.event) {
            case 'putDxCode':
              try {
                payload.dxRow = codeBookBus.payload.dxCode
                await this.PUT_OUTPATIENT_DX(payload)
                this.$message({
                  message: 'DX Code Updated Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'DX Code Updated Error',
                  type: 'error'
                })
              }
              break
            case 'postDxCode':
              try {
                codeBookBus.payload.dxCode[0].level2NewPrimary = false
                codeBookBus.payload.dxCode[0].newPrimary = false
                await this.POST_OUTPATIENT_DX([codeBookBus.payload.dxCode[0]])
                this.$message({
                  message: 'DX Code Added Successful',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'DX Code Added Error',
                  type: 'error'
                })
              }
              break
            case 'putPxCode':
              try {
                payload.pxRow = codeBookBus.payload.pxCode
                await this.PUT_OUTPATIENT_PX(payload)
                this.$message({
                  message: 'PX Code Updated Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'px Code Updated Error',
                  type: 'error'
                })
              }
              break
            case 'postPxCode':
              try {
                await this.POST_OUTPATIENT_PX([codeBookBus.payload.pxCode[0]])
                this.$message({
                  message: 'PX Code Added Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'px Code Added Error',
                  type: 'error'
                })
              }
              break
            case 'putCptCode':
              try {
                await this.PUT_OUTPATIENT_CPT({ cptRow: codeBookBus.payload.cptCode, changedField: codeBookBus.payload.changedField, ascTableId: this.outpatientReview.ascTableId })
                this.$message({
                  message: 'CPT Code Updated Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'CPT Code Update Error',
                  type: 'error'
                })
              }
              break
            case 'postCptCode':
              try {
                await this.POST_OUTPATIENT_CPT(codeBookBus.payload.cptCodes)
                this.$message({
                  message: 'CPT Code Added Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'CPT Code Added Error',
                  type: 'error'
                })
              }
              break
          }
        }

        this.saveToLocalStorageForTruCode(this.outpatientReview)
        if (codeBookBus.referenceEvent) {
          this.handleTruCodeReferenceClick(codeBookBus.referenceEvent)
        }
      },
      createWindowEventListeners() {
        window.onunload = event => {
          // clear the localStorage listner
          this.$ls.off(`codeBookBus_op_${this.$route.params.ascTableId}`)
          this.handleCloseOrBackEvent()
        }

        window.onpopstate = event => {
          this.handleCloseOrBackEvent()
        }
      },
      handleCloseOrBackEvent() {
        // first clear the window callbacks so they don't run for other components since they are attached to the
        // global window object
        window.onunload = undefined
        window.onpopstate = undefined
        this.closeCodeBookAndReferences()
        this.$ls.off(`codeBookBus_op_${this.outpatientReview.ascTableId}`)
        this.$ls.remove(`codeBookBus_op_${this.outpatientReview.ascTableId}`)
      },
      closeCodeBookAndReferences() {
        if (this.codebooksWindow) {
          this.codebooksWindow.close()
        }
        if (this.referencesWindow) {
          this.referencesWindow.close()
        }
        if (this.worksheetWindow) {
          this.worksheetWindow.close()
        }
      },
      openCodeBooks() {
        // in case users have opened codebooks already via another page, then also do a save here
        const codeBookBus = JSON.parse(this.$ls.get(`codeBookBus_op_${this.outpatientReview.ascTableId}`))
        if (codeBookBus.parent !== 'Outpatient Review') {
          this.saveToLocalStorageForTruCode(this.outpatientReview)
        }
        const cacheBuster = new Date().getTime()
        this.codebooksWindow = window.open(`/trucode/codebooks?dt=${cacheBuster}&type=op&id=${this.outpatientReview.ascTableId}`, 'codebook', 'height=850,width=1600')
      },
      openWorksheet() {
        const cacheBuster = new Date().getTime()
        this.worksheetWindow = window.open(`/reviews/reports/outpatient/worksheets?fullscreen=true&dt=${cacheBuster}&ids=${this.outpatientReview.ascTableId}`, 'worksheet', 'height=850,width=1600')
      },
      handleAction(action, e) {
        if (this.readOnly()) {
          return false
        }
        if (action === 'poaAssignments') {
          this.showPoaAssignments = true
        }
        if (action === 'addMultipleCodes') {
          this.showAddMultipleCodes = true
        }
        if (action === 'pxAddMultipleCodes') {
          this.showPxAddMultipleCodes = true
        }
        if (action === 'cptAddMultipleCodes') {
          this.showCptAddMultipleCodes = true
        }
      },
      handleCloseAddMultipleCodes(payload) {
        this.showAddMultipleCodes = false
        this.showPxAddMultipleCodes = false
        this.showCptAddMultipleCodes = false
      },
      handleClosePoaAssignments(payload) {
        this.showPoaAssignments = false
      },
      openPrincipalDXAnalysis() {
        this.togglePrincipalDxAnalysis = !this.togglePrincipalDxAnalysis
      },
      confirmLeave(next) {
        this.showInProgressWarning = true
        this.confirmStatusId = this.outpatientReview.reviewStatusId
        this.routeNext = next
      },
      requiredFieldsValidation() {
        this.$refs.opDemographics.$refs.form.validate(async (valid, fields) => {
          // generate error list
          this.requiredFieldsErrorList = []
          if (this.outpatientReview.requiredFields) {
            const errorFields = Object.keys(fields)
            const requiredFieldsData = this.outpatientReview.requiredFields.requiredFieldsData
            requiredFieldsData.forEach((item, index, array) => {
              if (errorFields.some(v => v === item.field)) {
                this.requiredFieldsErrorList.push(item)
              }
            })
          }
        })
      },
      isValidationError() {
        return !!this.requiredFieldsErrorList.length
      },
      handleTableCommands(command) {
        const visibleComponent = 'OutpatientReviewFooter'
        if (command.ref === 'ipSummary' || command.ref.includes('opSummary_') || command.ref.includes('proSummary_') || command.ref.includes('ascSummary_')) {
          const component = this.$refs[visibleComponent].$refs[command.ref][0] ? this.$refs[visibleComponent].$refs[command.ref][0].$refs[command.ref] : this.$refs[visibleComponent].$refs[command.ref].$refs[command.ref]
          this.tableToCsv(component, `${command.csvName}.csv`, command.headers, command.excludeHeaders)
        }
      }
    },
    async beforeRouteLeave(to, from, next) {
      if (this.outpatientReview.reviewStatusId === 2 && !this.readOnly()) {
        this.confirmLeave(next)
      } else {
        next()
      }
    },
    activated: function() {
      this.initialize()
      this.createWindowEventListeners()
      this.setupLocalStorageListenerForCodeBook()
    },
    deactivated: function() {
      this.activeNames = []
      this.handleCloseOrBackEvent()
    },
    computed: {
      ...mapState('outpatientReviews/', ['outpatientReview']),
      ...mapState('user/', ['user']),
      ...mapState('auditFindings/', ['auditFindings']),
      disabled: function() {
        return !!this.readOnly()
      },
      headerTable: function() {
        return [
          {
            client: this.outpatientReview.clientSmall ? this.outpatientReview.clientSmall.clientName : '',
            reviewDate: this.outpatientReview.reviewDate,
            patientNumber: this.outpatientReview.patientNumber,
            admitDate: this.outpatientReview.admitDate,
            dischargeDate: this.outpatientReview.dischargeDate
            // startedBy: this.outpatientReview.reviewAccountsComplete[0].userName,
            // startDate: this.outpatientReview.reviewAccountsComplete[0].startDate
          }
        ]
      },
      isLevel2: function() {
        if (this.outpatientReview.reviewLevel !== '1' && this.outpatientReview.reviewLevel !== 1) {
          return true
        }
        return false
      }
    }
  }
</script>

<style scoped>
  #outpatientButtons .el-dropdown {
    float: left;
  }

  .el-button-group > .el-dropdown > .el-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-left-color: #dcdfe6;
  }

  .el-button-group > .el-dropdown > .el-button--primary {
    border-left-color: #99bbd5;
  }

  .el-button-group > .el-dropdown > .el-button--success {
    border-left-color: #cbe5bf;
  }

  .el-button-group > .el-dropdown > .el-button--warning {
    border-left-color: #f5dab1;
  }

  .el-button-group > .el-dropdown > .el-button--info {
    border-left-color: #d3d4d6;
  }

  #outpatientButtons .el-button--primary:hover,
  #outpatientButtons .el-button--success:hover,
  #outpatientButtons .el-button--warning:hover,
  #outpatientButtons .el-button--info:hover {
    color: white !important;
  }

  #outpatientButtons .el-dropdown {
    margin-right: -1px;
  }

  #outpatientButtons .el-button--danger {
    border-left-color: #fbc4c4 !important;
  }

  .collapseContent {
    margin: 15px 0px 0px 5px;
  }

  >>> .hiaTable tr td {
    border: none !important;
  }

  >>> .hiaTable th {
    border: none !important;
  }

  .sticky {
    position: sticky;
    top: 5px;
  }

  .greenIcon {
    color: #63b344;
  }
</style>
