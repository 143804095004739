const reviewStatusHelper = {
  data: function () {
    return {
      reviewStatus: [
        { reviewStatusId: 1, status: 'Not Started', iconClass: 'mdi mdi-stop' },
        { reviewStatusId: 2, status: 'In Progress', iconClass: 'mdi mdi-play' },
        { reviewStatusId: 5, status: 'Preliminary', iconClass: 'mdi mdi-asterisk' },
        { reviewStatusId: 3, status: 'Ready for Coder', iconClass: 'mdi mdi-account' },
        { reviewStatusId: 4, status: 'Completed', iconClass: 'mdi mdi-check-bold' }
      ]
    }
  },
  methods: {
    getStatusButtonType(review) {
      const status = this.getCurrentReviewStatus(review)
      if (status) {
        switch (status.status) {
          case 'Not Started':
            return 'primary'
          case 'In Progress':
            return 'warning'
          case 'Preliminary':
            return 'info'
          case 'Ready for Coder':
            return 'info'
          case 'Completed':
            return 'success'
        }
      }
    },
    getCurrentReviewStatus(review) {
      if (review) {
        const status = this.reviewStatus.find(x => x.reviewStatusId === review.reviewStatusId)
        return status
      }
    }
  }
}

export default reviewStatusHelper
