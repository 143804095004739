<template>
  <div>
    <el-row :gutter="20" v-loading="loading" element-loading-spinner="atom-audit-loader">
      <el-col :span="6">
        <h4 class="headerWithBorder" style="margin-top: 20px;">Worksheet Findings</h4>
        <v-client-table v-if="toggleGrid" class="hiaTable" id="findingsGrid" :data="auditFindings" element-loading-spinner="atom-audit-loader" name="findingsGrid"
          ref="findingsGrid" :columns="calcFindingsColumns" :options="findingsGridOptions" @row-click="handleRowClick">

          <template slot="lineSequence" slot-scope="props">
            <span class="dragIcon drag-handle">{{props.row.lineSequence}}</span>
          </template>

          <template slot="actions" slot-scope="props">
            <i v-if="!disabled" style="font-size: 16px; margin-left: 5px; cursor: pointer; color: #005695; opacity: .6;" title="Delete Worksheet Finding"
              class="el-icon-close delete-button" @click="deleteFinding(props.row)"></i>
          </template>
          <template slot="reasonForChange" slot-scope="props">
            {{props.row.reasonForChangeId ? GET_VALUEFROMKEY('allReasonsForChange', props.row.reasonForChangeId) : 'General'}}
          </template>
          <template slot="codeType" slot-scope="props">
            {{props.row.codeTypeId ? GET_VALUEFROMKEY('codeTypes', props.row.codeTypeId) : ''}}
          </template>
        </v-client-table>

        <el-popover placement="top-start" title="Add Finding" width="300" trigger="click" v-model="showAddFindingPopover">
          <el-form size="mini" id="addForm" inline ref="addForm" label-width="250px" label-position="left">
            <el-form-item prop="codeType" label="Code Type" v-if="recordType !== 'IP'">
              <hia-el-select style="margin-top: 10px; width: 280px;" v-model="addForm.codeType" placeholder="Code Type" :filterable="true" itemkey="key" label="value"
                :dropdowns="dropdowns.codeTypes.list">
              </hia-el-select>
            </el-form-item>
            <el-form-item prop="reasonForChange" label="Reason for Change">
              <hia-el-select style="margin-top: 10px; width: 280px;" v-model="addForm.reasonForChange" placeholder="Reason for Change" :filterable="true" itemkey="key"
                label="value" :dropdowns="calcReasonsForChange">
              </hia-el-select>
            </el-form-item>
            <center>
              <el-button-group style="margin-top: 10px;">
                <el-button class="hiaButton" plain round type="text" size="mini" @click="closeNewFindingPopover">Cancel</el-button>
                <el-button class="hiaButton" round :disabled="!addForm.reasonForChange || (['OP', 'PRO'].includes(recordType) && !addForm.codeType)" size="mini"
                  @click="addWorkSheetFinding({})">Add
                </el-button>
              </el-button-group>
            </center>
          </el-form>

          <el-button slot="reference" :disabled="disabled" style="margin-top: 5px; margin-bottom: 10px; float: right;" size="mini" icon="el-icon-plus" round class="hiaButton">
            Add
          </el-button>
        </el-popover>

        <h4 class="headerWithBorder" style="margin-top: 40px;">Reason for Change Summary</h4>
        <v-client-table class="hiaTable" :data="findingSummary" name="findingSummary" ref="findingSummary" :columns="calcRfcSummaryColumns" :options="rfcSummaryOptions">
          <template slot="actions" slot-scope="props">
            <div style="min-width: 64px; text-align: right;">
              <el-popover :ref="`findingSummary${props.row.reasonForChangeId}${props.row.codeTypeId}`" placement="top-start" :title="getRFCSummaryPopoverTitle(props.row)"
                width="500" trigger="click" @after-enter="handleHidePopOver(`findingSummary${props.row.reasonForChangeId}${props.row.codeTypeId}`)">
                <div style="display: flex; align-items: center; word-break: break-word;"
                  v-for="(item, index) in getChangesSummary(props.row.reasonForChangeId, props.row.codeTypeId)" :key="index">
                  <div :id="`audit_desc_${index}`" @click="expandDesc(index)" contenteditable="true" class="overflow"
                    style="min-width: 415px; margin-right: 20px; flex-grow: 4;font-size: 12px;" v-html="item"></div>
                  <div style="min-width: 65px; text-align: center; flex-grow: 1">
                    <el-button plain round class="hiaButton pull-right" icon="el-icon-document-copy" @click="copyItem(item)">Copy</el-button>
                  </div>
                </div>
                <el-button plain round class="hiaButton pull-right" icon="el-icon-document-copy" @click="copySummary(props.row)">Copy All</el-button>
                <i slot="reference" style="margin-right: 0px; color: #005695;cursor: pointer;" class="mdi mdi-table-eye table-icon" title="Change Summary"></i>
              </el-popover>
              <i :class="{'icon-disabled': disabled, 'icon-enabled': !disabled}" v-if="!findingExists(props.row) && props.row.reasonForChangeId" title="Add Finding"
                style="color: #005695;" class="el-icon-plus table-icon" @click="if (!disabled) { addWorkSheetFinding(props.row) }"></i>
              <i v-if="!findingExists(props.row) && !props.row.reasonForChangeId" title="Recommended Code Changes Without Reason for Change"
                style="color: #e6a23c; cursor: not-allowed;" class="el-icon-warning-outline table-icon"></i>
              <i v-if="findingExists(props.row)" title="Finding Already Added" style="color: #13ce66;cursor: not-allowed;" class="el-icon-check table-icon"></i>
            </div>
          </template>
          <template slot="reasonForChange" slot-scope="props">
            {{getReasonForChangeDescription(props.row)}}
          </template>
          <template slot="codeType" slot-scope="props">
            {{props.row.codeTypeId ? GET_VALUEFROMKEY('codeTypes', props.row.codeTypeId) : ''}}
          </template>
        </v-client-table>
      </el-col>

      <el-col :span="18">
        <el-row :gutter="20">
          <div style="margin-top: 20px;" :class="{'disabled-rich-text': disabledRichText}">
            <el-col :span="actionTakenSpan">
              <h4 class="headerWithBorder">Action Taken
                <ul class="editorButtons pull-right">
                  <li>
                    <el-button title="Expand Action Taken" v-if="actionTakenSpan===8" style="padding: 3px !important;" round size="mini"
                      @click="actionTakenSpan=24;supportingDocSpan=0;codingRefSpan=0"><i class="mdi mdi-arrow-expand"></i></el-button>
                    <el-button title="Collapse Action Taken" v-if="actionTakenSpan!==8" style="padding: 3px !important;" round size="mini"
                      @click="actionTakenSpan=8;supportingDocSpan=8;codingRefSpan=8"><i class="mdi mdi-arrow-collapse"></i></el-button>
                  </li>
                  <li v-if="systemManagement()">
                    <el-button title="Add Action Taken to Library" style="padding: 3px !important;" round size="mini" icon="el-icon-document-add"
                      @click="triggerShowAddRecommendationForm('ACTIONTAKEN')"></el-button>
                  </li>
                  <li>
                    <el-button icon="mdi mdi-library" style="padding: 3px !important;" round v-if="!disabledRichText && !disabled" type="primary" size="mini"
                      @click="recommendationPickerActionTaken = true">
                      Library
                    </el-button>
                  </li>
                </ul>
              </h4>
              <Tinymce id="actionTaken" ref="actionTaken" v-model="activeFinding.findingDetails" :disabled="disabledRichText || disabled" @change="putActiveFinding()">
              </Tinymce>
            </el-col>
            <el-col :span="supportingDocSpan">
              <h4 class="headerWithBorder">Loc. of Supporting Doc.
                <ul class="editorButtons pull-right">
                  <li>
                    <el-button title="Expand Location of Supporting Documentation" v-if="actionTakenSpan===8" style="padding: 3px !important;" round size="mini"
                      @click="actionTakenSpan=0;supportingDocSpan=24;codingRefSpan=0"><i class="mdi mdi-arrow-expand"></i></el-button>
                    <el-button title="Collapse Location of Supporting Documentation" v-if="actionTakenSpan!==8" style="padding: 3px !important;" round size="mini"
                      @click="actionTakenSpan=8;supportingDocSpan=8;codingRefSpan=8"><i class="mdi mdi-arrow-collapse"></i></el-button>
                  </li>
                </ul>
              </h4>
              <Tinymce id="locSupDoc" ref="locSupDoc" v-model="activeFinding.supportingDoc" :disabled="disabledRichText || disabled" @change="putActiveFinding()"></Tinymce>
            </el-col>
            <el-col :span="codingRefSpan">
              <h4 class="headerWithBorder">Coding Ref
                <ul class="editorButtons pull-right">
                  <li>
                    <el-button title="Expand Reference" v-if="actionTakenSpan===8" style="padding: 3px !important;" round size="mini"
                      @click="actionTakenSpan=0;supportingDocSpan=0;codingRefSpan=24"><i class="mdi mdi-arrow-expand"></i></el-button>
                    <el-button title="Collapse Reference" v-if="actionTakenSpan!==8" style="padding: 3px !important;" round size="mini"
                      @click="actionTakenSpan=8;supportingDocSpan=8;codingRefSpan=8"><i class="mdi mdi-arrow-collapse"></i></el-button>
                  </li>
                  <li v-if="systemManagement()">
                    <el-button title="Add Coding Ref to Library" style="padding: 3px !important;" round size="mini" icon="el-icon-document-add"
                      @click="triggerShowAddRecommendationForm('CODINGREF')"></el-button>
                  </li>
                  <li>
                    <el-button icon="mdi mdi-library" style="padding: 3px !important;" round v-if="!disabledRichText && !disabled" type="primary" size="mini"
                      @click="recommendationPickerCodingRef = true">
                      Library
                    </el-button>
                  </li>
                </ul>
              </h4>
              <Tinymce id="codingRef" ref="codingRef" v-model="activeFinding.reference" :disabled="disabledRichText || disabled" @change="putActiveFinding()"></Tinymce>
            </el-col>
          </div>
        </el-row>

        <el-row :gutter="20" style="margin-top: 20px;">
          <el-form size="mini" label-position="left" label-width="180px">
            <el-col :span="12">
              <el-form-item label="Reason for Change">
                <hia-el-select @input="handleActiveFindingChange" :disabled="disabledRichText || disabled" v-model="activeFinding.reasonForChangeId"
                  placeholder="Reason for Change" :filterable="true" itemkey="key" label="value" :dropdowns="calcReasonsForChange">
                </hia-el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Code Type" v-if="recordType !== 'IP'">
                <hia-el-select @input="handleActiveFindingChange" :disabled="disabledRichText || disabled" v-model="activeFinding.codeTypeId" placeholder="Code Type"
                  :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list">
                </hia-el-select>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </el-col>
    </el-row>

    <RecommendationLibraryPicker v-if="recommendationPickerActionTaken" :showDialog="recommendationPickerActionTaken" :recordTypes="['IP','OP', 'PRO']"
      recommendationType="ACTIONTAKEN" v-model="activeFinding.findingDetails" @change="handleLibraryChange('actionTaken')" @close="recommendationPickerActionTaken = false">
    </RecommendationLibraryPicker>

    <RecommendationLibraryPicker v-if="recommendationPickerCodingRef" :showDialog="recommendationPickerCodingRef" :recordTypes="['IP','OP', 'PRO']"
      recommendationType="CODINGREF" v-model="activeFinding.reference" @change="handleLibraryChange('codingRef')" @close="recommendationPickerCodingRef = false">
    </RecommendationLibraryPicker>
    <div class="dialogs">
      <el-dialog title="Add to Recommendation Library" :visible.sync="showAddRecommendationForm" :close-on-click-modal="false">
        <AddRecommendation ref="addRecommendation" v-if="showAddRecommendationForm" :recordType="recordType" :recommendationType="recommendationType"
          @triggerShowAddRecommendationForm="triggerShowAddRecommendationForm" @closeShowAddRecommendationForm="closeShowAddRecommendationForm"></AddRecommendation>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { parseISO, format } from 'date-fns'
  import dateHelper from '@/mixins/date-helper'
  import Tinymce from '@/components/controls/Tinymce'
  import Sortable from 'sortablejs'
  import RecommendationLibraryPicker from '@/views/reviews/recommendationLibrary/RecommendationLibraryPicker'
  import AddRecommendation from '@/views/reviews/recommendationLibrary/AddRecommendation'

  export default {
    name: 'AuditFindings',
    mixins: [dateHelper],
    props: {
      recordType: {
        type: String,
        required: true,
        validator: (value) => {
          return ['IP', 'OP', 'PRO'].indexOf(value) !== -1
        }
      },
      recordId: {
        type: Number
      }
    },
    data: function () {
      return {
        recommendationType: '',
        showAddRecommendationForm: false,
        format: format,
        parseISO: parseISO,
        loading: false,
        toggleGrid: true,
        theSortable: null,
        showAddFindingPopover: false,
        showFindingSummaryPopover: false,
        recommendationPickerActionTaken: false,
        recommendationPickerCodingRef: false,
        actionTakenSpan: 8,
        codingRefSpan: 8,
        supportingDocSpan: 8,
        addForm: {
          reasonForChange: null,
          codeType: null
        },
        addFindingCodeType: null,
        findingsGridOptions: {
          filterable: false,
          filterByColumn: false,
          headings: {
            lineSequence: '#',
            reasonForChange: 'Reason for Change',
            codeType: 'Code Type',
            actions: ''
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...',
            noResults: 'No Worksheet Findings Added'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' },
          rowClassCallback: row => {
            if (row.lineSequence === this.activeFinding.lineSequence) {
              return 'activeFinding'
            } else {
              return 'inactiveFinding'
            }
          }
        },
        rfcSummaryOptions: {
          filterable: false,
          filterByColumn: false,
          // orderBy: {
          //   column: 'count',
          //   ascending: false
          // },
          headings: {
            reasonForChange: 'Reason for Change',
            occurances: 'Occurances',
            actions: ''
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('auditFindings/', ['POST_FINDING', 'DELETE_FINDING', 'PUT_FINDING', 'PUT_FINDINGS_FOR_RESEQUENCE', 'GET_FINDING']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      ...mapMutations('auditFindings/', ['SET_ACTIVE_FINDING']),
      initialize() {
      },
      async deleteFinding(row) {
        const codeType = this.GET_VALUEFROMKEY('codeTypes', row.codeTypeId)
        const rfc = this.GET_VALUEFROMKEY('allReasonsForChange', row.reasonForChangeId)

        let message
        if (codeType && rfc) {
          // outpatient and pro
          message = `Permanently delete worksheet finding for ${codeType}, ${rfc}?`
        } else {
          // inpatient
          message = `Permanently delete worksheet finding for ${rfc}?`
        }

        await this.$confirm(message, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          this.loading = true
          const payload = {
            recordType: this.recordType,
            id: row.id
          }
          await this.DELETE_FINDING(payload)
          this.loading = false

          this.SET_ACTIVE_FINDING(this.auditFindings.length > 0 ? this.auditFindings[0] : {})
          this.$message({
            message: 'Audit Finding Deleted Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Audit Finding Deleted  Error',
            type: 'error'
          })
        }
      },
      getSpan(item) {
        return 8
      },
      closeNewFindingPopover() {
        this.addForm.reasonForChange = null
        this.addForm.codeType = null
        this.showAddFindingPopover = false
      },
      groupByReasonForChange(arr) {
        const report = arr.reduce((accum, x) => {
          if (!x.hasRecommendation) {
            return accum
          }

          x.reasonForChange = x.reasonForChange === '' ? null : x.reasonForChange

          const row = accum.find(y => y.reasonForChangeId === x.reasonForChange && y.codeTypeId === x.codeType)
          if (row) {
            row.count++
          } else {
            accum.push({
              reasonForChangeId: x.reasonForChange,
              codeTypeId: x.codeType,
              count: 1
            })
          }
          return accum
        }, [])

        report.sort((a, b) => a.count > b.count ? -1 : 1)

        return report
      },
      getRFCSummaryPopoverTitle(row) {
        return 'Change Summary'
        // if (row.codeTypeId && row.reasonForChangeId) {
        //   return `Reason for Change Summary for ${this.GET_VALUEFROMKEY('allReasonsForChange', row.reasonForChangeId)}, ${this.GET_VALUEFROMKEY('codeTypes', row.codeTypeId)}`
        // }
      },
      getReasonForChangeDescription(row) {
        return row.reasonForChangeId ? this.GET_VALUEFROMKEY('allReasonsForChange', row.reasonForChangeId) : '<Not Specified>'
      },
      findingExists(row) {
        if (this.recordType === 'IP') {
          return this.auditFindings.some(x => x.reasonForChangeId === row.reasonForChangeId)
        }
        return this.auditFindings.some(x => x.reasonForChangeId === row.reasonForChangeId && x.codeTypeId === row.codeTypeId)
      },
      getProChangesSummary(reasonForChangeId, codeTypeId) {
        const dxs = this.proReview.proRecordDxs.filter(x => x.reasonForChange === reasonForChangeId && x.codeType === codeTypeId && (x.recommendedDx || x.deleteCode || x.newPrimary)).map(val => {
          if (val.newPrimary && val.originalDx && !val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''}</span> resequenced to new primary</b>&nbsp;</p>`
          }

          if (val.newPrimary && val.originalDx && val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''}</span> changed to <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''}</span> and resequenced to new primary</b>&nbsp;</p>`
          }

          if (val.originalDx && val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''}</span> changed to <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''}</span> </b>&nbsp;</p>`
          }
          if (!val.originalDx && val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase"> ${val.recommendedDx} ${val.recDescCalc ?? ''} </span> added</b>&nbsp;</p>`
          }
          if (val.deleteCode && val.originalDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> deleted</b>&nbsp;</p>`
          }
          return ''
        })

        const cpts = this.proReview.proRecordCpts.filter(x => x.reasonForChange === reasonForChangeId && x.codeType === codeTypeId && (x.recommendedCpt || x.deleteCode)).map(val => {
          if (val.originalCpt && val.recommendedCpt) {
            return `<p><b>CPT <span style="text-transform: uppercase">${val.originalCpt} ${val.originalDescCalcMed ?? ''}</span> changed to <span style="text-transform: uppercase">${val.recommendedCpt} ${val.recDescCalcMed ?? ''} </span> </b>&nbsp;</p>`
          }
          if (!val.originalCpt && val.recommendedCpt) {
            return `<p><b>CPT <span style="text-transform: uppercase">${val.recommendedCpt} ${val.recDescCalcMed ?? ''} </span> added</b>&nbsp;</p>`
          }
          if (val.deleteCode && val.originalCpt) {
            return `<p><b>CPT <span style="text-transform: uppercase">${val.originalCpt} ${val.originalDescCalcMed ?? ''} </span> deleted</b>&nbsp;</p>`
          }
          return ''
        })

        let mods = this.proReview.proRecordCpts.map(cpt => {
          const modChanges = cpt.proRecordModifiers.filter(x => x.reasonForChange === reasonForChangeId && x.codeType === codeTypeId && (x.recommendedModifier || x.deleteCode)).map(val => {
            if (val.originalModifier && val.recommendedModifier) {
              return `<p><b>Modifier <span style="text-transform: uppercase"> ${val.originalModifier} ${this.modDescription(val.originalModifier)} </span> changed to <span style="text-transform: uppercase">${val.recommendedModifier} ${this.modDescription(val.recommendedModifier)} </span> </b>&nbsp;</p>`
            }
            if (!val.originalModifier && val.recommendedModifier) {
              return `<p><b>Modifier <span style="text-transform: uppercase">${val.recommendedModifier} ${this.modDescription(val.recommendedModifier)} </span> added</b>&nbsp;</p>`
            }
            if (val.deleteCode && val.originalModifier) {
              return `<p><b>Modifier <span style="text-transform: uppercase">${val.originalModifier} ${this.modDescription(val.originalModifier)} </span> deleted</b>&nbsp;</p>`
            }
            return ''
          })
          return modChanges
        })

        mods = mods.flat()

        return [].concat(dxs, cpts, mods)
      },
      getOPChangesSummary(reasonForChangeId, codeTypeId) {
        const dxs = this.outpatientReview.ascRecommendationsDx.filter(x => x.reasonForChange === reasonForChangeId && x.codeType === codeTypeId && (x.recommendedDx || x.deleteCode || x.newPrimary)).map(val => {
          if (val.newPrimary && val.originalDx && !val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> resequenced to new primary</b>&nbsp;</p>`
          }

          if (val.newPrimary && val.originalDx && val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''} </span> and resequenced to new primary</b>&nbsp;</p>`
          }

          if (val.originalDx && val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase"> ${val.originalDx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''} </span> </b>&nbsp;</p>`
          }
          if (!val.originalDx && val.recommendedDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''} </span> added</b>&nbsp;</p>`
          }
          if (val.deleteCode && val.originalDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> deleted</b>&nbsp;</p>`
          }
          return ''
        })

        const pxs = this.outpatientReview.ascRecommendationsPx.filter(x => x.reasonForChange === reasonForChangeId && x.codeType === codeTypeId && (x.recommendedPx || x.deleteCode)).map(val => {
          if (val.originalPx && val.recommendedPx) {
            return `<p><b>Px <span style="text-transform: uppercase">${val.originalPx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase">${val.recommendedPx} ${val.recDescCalc ?? ''} </span> </b>&nbsp;</p>`
          }
          if (!val.originalPx && val.recommendedPx) {
            return `<p><b>Px <span style="text-transform: uppercase">${val.recommendedPx} ${val.recDescCalc ?? ''} </span> added</b>&nbsp;</p>`
          }
          if (val.deleteCode && val.originalPx) {
            return `<p><b>Px <span style="text-transform: uppercase">${val.originalPx} ${val.originalDescCalc ?? ''} </span> deleted</b>&nbsp;</p>`
          }
          return ''
        })

        const cpts = this.outpatientReview.ascRecommendationsCptsApcs.filter(x => x.reasonForChange === reasonForChangeId && x.codeType === codeTypeId && (x.recommendedCpt || x.deleteCode)).map(val => {
          if (val.originalCpt && val.recommendedCpt) {
            return `<p><b>CPT <span style="text-transform: uppercase">${val.originalCpt} ${val.originalDescCalcMed ?? ''} </span> changed to <span style="text-transform: uppercase"> ${val.recommendedCpt} ${val.recDescCalcMed ?? ''} </span> </b>&nbsp;</p>`
          }
          if (!val.originalCpt && val.recommendedCpt) {
            return `<p><b>CPT <span style="text-transform: uppercase">${val.recommendedCpt} ${val.recDescCalcMed ?? ''} </span> added</b>&nbsp;</p>`
          }
          if (val.deleteCode && val.originalCpt) {
            return `<p><b>CPT <span style="text-transform: uppercase"> ${val.originalCpt} ${val.originalDescCalcMed ?? ''} </span> deleted</b>&nbsp;</p>`
          }
          return ''
        })

        let mods = this.outpatientReview.ascRecommendationsCptsApcs.map(cpt => {
          const modChanges = cpt.ascRecommendationsModifiers.filter(x => x.reasonForChange === reasonForChangeId && x.codeType === codeTypeId && (x.recommendedModifier || x.deleteCode)).map(val => {
            if (val.originalModifier && val.recommendedModifier) {
              return `<p><b>Modifier <span style="text-transform: uppercase">${val.originalModifier} ${this.modDescription(val.originalModifier)} </span> changed to <span style="text-transform: uppercase">${val.recommendedModifier} ${this.modDescription(val.recommendedModifier)} </span></b>&nbsp;</p>`
            }
            if (!val.originalModifier && val.recommendedModifier) {
              return `<p><b>Modifier <span style="text-transform: uppercase"> ${val.recommendedModifier} ${this.modDescription(val.recommendedModifier)} </span> added</b>&nbsp;</p>`
            }
            if (val.deleteCode && val.originalModifier) {
              return `<p><b>Modifier <span style="text-transform: uppercase">${val.originalModifier} ${this.modDescription(val.originalModifier)} </span> deleted</b>&nbsp;</p>`
            }
            return ''
          })
          return modChanges
        })

        mods = mods.flat()

        // TODO PCS codes

        return [].concat(dxs, pxs, cpts, mods)
      },
      getIPChangesSummary(reasonForChangeId, codeTypeId) {
        const dxs = this.inpatientReview.drgRecommendationsDx.filter(x => x.reasonForChange === reasonForChangeId && (x.recommendedDx || x.deleteCode || x.newPrimary || x.recommendedPoa)).map(val => {
          if (val.newPrimary && val.originalDx && !val.recommendedDx) {
            if (val.recommendedPoa) {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> resequenced to new primary and POA changed to ${val.recommendedPoa}</b>&nbsp;</p>`
            } else {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> resequenced to new primary</b>&nbsp;</p>`
            }
          }

          if (val.newPrimary && val.originalDx && val.recommendedDx) {
            if (val.recommendedPoa) {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''}</span>, resequenced to new primary and POA changed to ${val.recommendedPoa}</b>&nbsp;</p>`
            } else {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''} </span> and resequenced to new primary</b>&nbsp;</p>`
            }
          }

          if (val.originalDx && val.recommendedDx) {
            if (val.recommendedPoa) {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase"> ${val.recommendedDx} ${val.recDescCalc ?? ''} </span> and POA changed to ${val.recommendedPoa} </b>&nbsp;</p>`
            } else {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase"> ${val.recommendedDx} ${val.recDescCalc ?? ''} </span> </b>&nbsp;</p>`
            }
          }
          if (!val.originalDx && val.recommendedDx) {
            if (val.recommendedPoa) {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''} </span> added with POA of ${val.recommendedPoa}</b>&nbsp;</p>`
            } else {
              return `<p><b>Dx <span style="text-transform: uppercase">${val.recommendedDx} ${val.recDescCalc ?? ''} </span> added</b>&nbsp;</p>`
            }
          }
          if (val.deleteCode && val.originalDx) {
            return `<p><b>Dx <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> deleted</b>&nbsp;</p>`
          }

          if (val.recommendedPoa) {
            return `<p><b>Recommended POA for <span style="text-transform: uppercase">${val.originalDx} ${val.originalDescCalc ?? ''} </span> changed to ${val.recommendedPoa}</b>&nbsp;</p>`
          }
          return ''
        })

        const pxs = this.inpatientReview.drgRecommendationsPx.filter(x => x.reasonForChange === reasonForChangeId && (x.recommendedPx || x.deleteCode)).map(val => {
          if (val.originalPx && val.recommendedPx) {
            return `<p><b>Px <span style="text-transform: uppercase">${val.originalPx} ${val.originalDescCalc ?? ''} </span> changed to <span style="text-transform: uppercase">${val.recommendedPx} ${val.recDescCalc ?? ''}</span> </b>&nbsp;</p>`
          }
          if (!val.originalPx && val.recommendedPx) {
            return `<p><b>Px <span style="text-transform: uppercase">${val.recommendedPx} ${val.recDescCalc ?? ''} </span> added</b>&nbsp;</p>`
          }
          if (val.deleteCode && val.originalPx) {
            return `<p><b>Px <span style="text-transform: uppercase">${val.originalPx} ${val.originalDescCalc ?? ''} </span> deleted</b>&nbsp;</p>`
          }
          return ''
        })

        return [].concat(dxs, pxs)
      },
      getChangesSummary(reasonForChangeId, codeTypeId) {
        if (this.recordType === 'PRO') {
          return this.getProChangesSummary(reasonForChangeId, codeTypeId)
        }
        if (this.recordType === 'OP') {
          return this.getOPChangesSummary(reasonForChangeId, codeTypeId)
        }
        if (this.recordType === 'IP') {
          return this.getIPChangesSummary(reasonForChangeId)
        }
      },
      modDescription(modifier, deleteCode = false) {
        if (deleteCode) {
          return 'Delete Modifier'
        }

        if (modifier !== null && modifier.length) {
          let desc = ''
          for (const i in this.dropdowns.modifiers.list) {
            if (modifier === this.dropdowns.modifiers.list[i].key) {
              desc = this.dropdowns.modifiers.list[i].value.split('|')[1].trim()
              break
            }
          }
          return desc
        }
      },
      async addWorkSheetFinding(row) {
        const finding = JSON.parse(JSON.stringify(row))

        if (!finding.reasonForChangeId) {
          finding.reasonForChangeId = this.addForm.reasonForChange
          finding.codeTypeId = this.addForm.codeType
        }

        finding.lineSequence = this.auditFindings.length + 1
        finding.findingDetails = this.getChangesSummary(row.reasonForChangeId, row.codeTypeId).join('')

        if (this.recordType === 'PRO') {
          finding.proRecordId = this.recordId
        }
        if (this.recordType === 'OP') {
          finding.ascTableId = this.recordId
        }
        if (this.recordType === 'IP') {
          finding.drgTableId = this.recordId
        }

        try {
          this.loading = true
          await this.POST_FINDING({
            recordType: this.recordType,
            finding: finding
          })

          this.loading = false
          this.$message({
            message: 'Audit Finding Added Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Audit Finding Added Error',
            type: 'error'
          })
        }

        this.closeNewFindingPopover()
      },
      async putActiveFinding() {
        try {
          this.loading = true
          await this.PUT_FINDING(this.activeFinding)
          this.loading = false
          this.$message({
            message: 'Audit Finding Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Audit Finding Updated Error',
            type: 'error'
          })
        }
      },
      handleLibraryChange(mceRef) {
        const mce = this.$refs[mceRef]
        if (mce) {
          mce.setDirty(false)
        }
        this.putActiveFinding()
      },
      handleActiveFindingChange() {
        const index = this.auditFindings.findIndex(x => x.lineSequence === this.activeFinding.lineSequence)
        this.$set(this.auditFindings, index, this.activeFinding)
        this.putActiveFinding()
      },
      async handleRowClick(e) {
        try {
          this.loading = true
          await this.GET_FINDING(e.row.id)
          this.loading = false
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Audit Finding Error',
            type: 'error'
          })
        }
        this.SET_ACTIVE_FINDING(e.row)
      },
      copyItem(item) {
        this.copyValue(item)
      },
      copySummary(row) {
        const summary = this.getChangesSummary(row.reasonForChangeId, row.codeTypeId)
        this.copyValue(summary.join(''))
      },
      copyValue(value) {
        const tempDiv = document.createElement('div')
        tempDiv.style = 'position: absolute; left: -1000px; top: -1000px'
        tempDiv.innerHTML = value
        document.body.appendChild(tempDiv)

        window.getSelection().removeAllRanges()
        const range = document.createRange()
        range.selectNode(tempDiv)
        window.getSelection().addRange(range)
        document.execCommand('copy')
        window.getSelection().removeAllRanges()

        const copied = document.execCommand('copy')
        document.body.removeChild(tempDiv)

        if (copied) {
          this.$message({
            message: 'Value Copied',
            type: 'success'
          })
        }

        this.showFindingSummaryPopover = false
      },
      attachSortable() {
        const gridElementName = 'findingsGrid'
        const gridDiv = document.getElementById(gridElementName)
        if (gridDiv) {
          const tbody = gridDiv.getElementsByTagName('tbody')
          this.theSortable = Sortable.create(tbody[0], {
            animation: 200,
            draggable: 'tr.VueTables__row',
            handle: '.drag-handle',
            onEnd: (e) => {
              this.handleDragStop(e)
            }
          })
        }
      },
      updateLineSequence(array) {
        array.map((o, i) => {
          o.lineSequence = (i + 1)
          return o
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const frames = document.getElementsByTagName('iframe')

        const handler = (e) => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }

          // remove the listener from all frames
          for (let j = 0; j < frames.length; j++) {
            const frame = frames[j]
            frame.contentDocument.removeEventListener('click', handler, false)
          }
        }

        for (let i = 0; i < frames.length; i++) {
          const frame = frames[i]
          frame.contentDocument.addEventListener('click', handler, false)
        }

        // remove the listener from all frames if the user clicks on the main body
        document.addEventListener('click', (e) => {
          for (let j = 0; j < frames.length; j++) {
            const frame = frames[j]
            frame.contentDocument.removeEventListener('click', handler, false)
          }
        }, false)
      },
      async handleDragStop(e) {
        const temp = this.auditFindings[e.oldIndex]
        this.auditFindings.splice(e.oldIndex, 1)
        this.auditFindings.splice(e.newIndex, 0, temp)
        this.updateLineSequence(this.auditFindings)

        // force the grid to re-initialize by destroying and recreating it
        // and then reattaching the sortable
        this.toggleGrid = false
        this.theSortable.destroy()
        this.$nextTick(() => {
          this.toggleGrid = true
          this.$nextTick(() => {
            this.attachSortable()
          })
        })

        this.loading = true
        try {
          await this.PUT_FINDINGS_FOR_RESEQUENCE(this.auditFindings)
          this.loading = false
        } catch (e) {
          this.loading = true
        }
      },
      expandDesc(index) {
        // const targetId = $event.currentTarget.id
        // console.log(targetId)
        const orTargetId = 'audit_desc_' + index

        const orEl = document.getElementById(orTargetId)
        orEl.classList.toggle('overflow')
      },
      async triggerShowAddRecommendationForm(recommendationType) {
        try {
          this.loading = true
          await this.GET_FINDING(this.activeFinding.id)
          this.loading = false
          this.recommendationType = recommendationType
          this.showAddRecommendationForm = !this.showAddRecommendationForm
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Audit Finding Error',
            type: 'error'
          })
        }
      },
      closeShowAddRecommendationForm() {
        this.showAddRecommendationForm = !this.showAddRecommendationForm
      }
    },
    mounted: function () {
      this.initialize()
      this.$nextTick(() => {
        this.attachSortable()
      })
    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview']),
      ...mapState('proReviews/', ['proReview']),
      ...mapState('outpatientReviews/', ['outpatientReview']),
      ...mapState('auditFindings/', ['auditFindings', 'activeFinding']),
      ...mapState('user/', ['user']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapGetters('dropdowns/', ['GET_VALUEFROMKEY']),
      calcReasonsForChange() {
        if (this.recordType === 'PRO') {
          return this.dropdowns.proReasonsForChange.list
        }
        if (this.recordType === 'OP') {
          return this.dropdowns.outpatientReasonsForChange.list
        }
        if (this.recordType === 'IP') {
          return this.dropdowns.inpatientReasonsForChange.list
        }
        return []
      },
      calcFindingsColumns() {
        if (this.recordType === 'IP') {
          return ['lineSequence', 'reasonForChange', 'actions']
        }
        return ['lineSequence', 'codeType', 'reasonForChange', 'actions']
      },
      calcRfcSummaryColumns() {
        if (this.recordType === 'IP') {
          return ['reasonForChange', 'count', 'actions']
        }
        return ['codeType', 'reasonForChange', 'count', 'actions']
      },
      findingSummary() {
        let reasonForChangeSummary = []
        if (this.recordType === 'PRO') {
          let mods = []
          this.proReview.proRecordCpts.forEach(x => {
            mods = mods.concat(x.proRecordModifiers)
          })
          reasonForChangeSummary = this.groupByReasonForChange(this.proReview.proRecordDxs.concat(this.proReview.proRecordCpts, mods))
          // this.cacheFindingImages(this.proReview.findingImages)
        }

        if (this.recordType === 'OP') {
          let mods = []
          this.outpatientReview.ascRecommendationsCptsApcs.forEach(x => {
            mods = mods.concat(x.ascRecommendationsModifiers)
          })
          reasonForChangeSummary = this.groupByReasonForChange(this.outpatientReview.ascRecommendationsDx.concat(this.outpatientReview.ascRecommendationsPx, this.outpatientReview.ascRecommendationsCptsApcs, mods))
          // this.cacheFindingImages(this.outpatientReview.findingImages)
        }

        if (this.recordType === 'IP') {
          reasonForChangeSummary = this.groupByReasonForChange(this.inpatientReview.drgRecommendationsDx.concat(this.inpatientReview.drgRecommendationsPx))
          // this.cacheFindingImages(this.inpatientReview.findingImages)
        }
        return reasonForChangeSummary
      },
      disabled() {
        return !!this.readOnly() || (this.proReview.reviewStatusId !== 2 && this.recordType === 'PRO') || (this.outpatientReview.reviewStatusId !== 2 && this.recordType === 'OP') || (this.inpatientReview.reviewStatusId !== 2 && this.recordType === 'IP')
      },
      disabledRichText() {
        if (!this.proReview || !this.auditFindings) {
          return true
        }
        return this.auditFindings.length < 1
      },
      tinyMceImagePath() {
        return 'reviews/audit-findings/' + this.recordType + '/' + this.recordId + '/'
      }
    },
    components: {
      Tinymce,
      RecommendationLibraryPicker,
      AddRecommendation
    }
  }
</script>

<style scoped>
  >>>.activeFinding {
    background: rgba(255, 196, 12, 0.2) !important;
    /* border-top: 1px solid blue;
    border-bottom: 1px solid blue;
    border-left: 1px solid blue; */
  }

  >>>.inactiveFinding {
    cursor: pointer;
    /* font-weight: 300; */
    /* border-right: 1px solid blue; */
  }

  >>>.VuePagination__count {
    display: none;
  }

  .disabled-rich-text {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .link-message {
    margin: 3px 0px;
    background-color: #ecf5ff;
    display: inline-block;
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 3px;
  }

  .drag-handle {
    cursor: move;
    display: block;
    float: left;
  }

  .icon-disabled {
    cursor: not-allowed;
  }

  .icon-enabled {
    cursor: pointer;
  }

  .table-icon {
    font-size: 16px;
    margin: 2px 8px 0px 8px;
    font-weight: 900;
    opacity: 0.9;
  }

  .editorButtons {
    margin-top: -5px;
  }

  .editorButtons li {
    margin-left: 8px;
  }

  .overflow {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 80px;
    text-overflow: ellipsis;
    cursor: zoom-in;
  }

  >>>.upper {
    text-transform: uppercase;
  }
</style>