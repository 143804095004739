<template>
  <div>
    <el-dialog v-if="cpt !== null" class="modDialog" :visible="showAscModal" :title="`Edit ASCs for CPT Line ${cpt.lineSequence}`" width="80%" :before-close="
        () => {
          showAscModal = false
        }
      " :close-on-click-modal="false" :show-close="false">
      <div v-loading="loading" element-loading-spinner="atom-audit-loader">
        <el-form size="mini" label-position="left" label-width="140px">
          <el-row style="margin-bottom: 20px;">
            <el-col :span="12">
              <el-form-item label="Original CPT">
                <div style="width: 100%;">
                  <el-input style="width: 112px; display: inline-block;" inputId="originalCptMod" v-model="cpt.originalCpt" disabled> </el-input>
                  <span style=" margin-left: 5px; margin-top: 3px; display: inline-block" ref="originalModifier"
                    v-html="cptModifiers(cpt.ascRecommendationsModifiers, 'originalModifier')"> </span>
                </div>
                <div>
                  <div style="margin-top: 3px; display: inline-block" class="overflow" :id="'OrDesc_Mod_' + cpt.cptId" @click="expandDesc(cpt.cptId, true)">{{
                    cpt.originalDescCalc }}</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Recommended CPT">
                <div style="width: 100%;">
                  <el-input style="width: 112px; display: inline-block;" :placeholder="cpt.originalCpt" inputId="recCptMod" v-model="cpt.recommendedCpt" disabled
                    :class="{ deleteCode: cpt.level2DeleteCode || cpt.deleteCode }"> </el-input>
                  <span style="margin-left: 5px; margin-top: 3px; display: inline-block" ref="originalModifier"
                    v-html="recCptModifers(cpt.ascRecommendationsModifiers, 'originalModifier', 'recommendedModifier')"> </span>
                </div>
                <div style="margin-top: 3px; display: inline-block" class="overflow" :id="'RecDesc_Mod_' + cpt.cptId" @click="expandDesc(cpt.cptId, true)">{{ !cpt.deleteCode ?
                  cpt.recDescCalc : 'Delete Code' }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row>
          <el-col :span="12">
            <h4 class="headerWithBorder">Original ASC</h4>
          </el-col>
          <el-col :span="12">
            <h4 class="headerWithBorder">Recommended ASC</h4>
          </el-col>
        </el-row>

        <div class="hiaTable">
          <table class="VueTables__table table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Orig. ASC</th>
                <th>Orig. ASC Wt.</th>
                <th>Orig. ASC Pay Rt.</th>
                <th>Orig. ASC Payment</th>
                <th>Rec. ASC</th>
                <th>Rec. ASC Wt.</th>
                <th>Rec. ASC Pay Rt.</th>
                <th>Rec. ASC Payment</th>
                <th style="width: 50px;">Actions</th>
                <th>ASC Reason for Change</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <hia-el-select @input="handleAscChange('original')" v-model="localCpt.origPaymentIndicator" placeholder="Orig. ASC" itemkey="key" label="value"
                    :dropdowns="dropdowns.ascPaymentIndicators.list" clearable filterable> </hia-el-select>
                </td>
                <td><input v-model="localCpt.origAscWeight" style="width: 70px;" type="number" step=".0001" placeholder="Orig. Wt." @change="calcPayment(cpt)" /></td>
                <td>
                  <hia-el-select style="width: 80px; height: 18px; margin-top: -4px;" v-model="localCpt.origAscPayRate" placeholder="Orig. Pay Rt." itemkey="key" label="value"
                    :dropdowns="dropdowns.ApcPaymentRates.list" clearable filterable @input="calcPayment(cpt)"> </hia-el-select>
                </td>
                <td>
                  <div style="margin-top: 2px;">${{ localCpt.origAscPayment === 0 || localCpt.origAscPayment > 0 ? localCpt.origAscPayment.toFixed(2) : '' }}</div>
                </td>

                <td :class="{ deleteCode: localCpt.deleteAsc }">
                  <hia-el-select :disabled="localCpt.deleteAsc" @input="handleAscChange('recommended')" v-model="localCpt.recPaymentIndicator" itemkey="key" label="value"
                    :dropdowns="dropdowns.ascPaymentIndicators.list" clearable filterable> </hia-el-select>
                </td>
                <td :class="{ deleteCode: localCpt.deleteAsc }"><input :disabled="localCpt.deleteAsc" v-model="localCpt.recAscWeight" type="number" step=".0001"
                    style="width: 112px;" @change="calcPayment(cpt)" /></td>
                <td :class="{ deleteCode: localCpt.deleteAsc }">
                  <hia-el-select style="width: 80px; height: 18px; margin-top: -4px;" :disabled="localCpt.deleteAsc" v-model="localCpt.recAscPayRate" itemkey="key"
                    label="value" :dropdowns="dropdowns.ApcPaymentRates.list" clearable filterable @input="calcPayment(cpt)"> </hia-el-select>
                </td>
                <td>
                  <div v-if="!localCpt.deleteAsc" style="margin-top: 2px;">${{ getRecAscPayment() }}</div>
                  <div v-if="localCpt.deleteAsc" style="margin-top: 2px;">DELETE ASC</div>
                </td>
                <td>
                  <div style="margin-top: 5px;">
                    <iconCheckBox style="float: left" v-model="localCpt.deleteAsc" title="Delete ASC" icon="mdi mdi-alpha-d" :disabled="disabled" @change="handleDelete">
                    </iconCheckBox>
                  </div>
                </td>
                <td>
                  <hia-el-select style="width: 80px; height: 18px; margin-top: -4px;" :disabled="!rowChange()" v-model="localCpt.apcReasonForChange"
                    placeholder="ASC Reason for Change" itemkey="key" label="value" :dropdowns="dropdowns.outpatientReasonsForChangeApc.list" clearable filterable
                    @input="isDirty = true"> </hia-el-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <span slot="footer">
        <el-button-group>
          <el-button class="hiaButton" @click="cancel" size="mini" type="text" plain round>{{ isDirty ? 'Cancel' : 'Close' }}</el-button>
          <el-button ref="saveAscButton" class="hiaButton" @click="save($event)" size="mini" plain round>Save</el-button>
        </el-button-group>
      </span>
    </el-dialog>

    <el-popover ref="ascPopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="ascPopoverContent"
      @after-enter="handleHidePopOver('ascPopover')"> </el-popover>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import iconCheckBox from '@/components/controls/iconCheckBox'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { isAfter, parseISO } from 'date-fns'

  export default {
    name: 'outpatientReviewModifiers',
    props: ['cpt', 'showAscModal'],
    mixins: [HiaAuthorization],
    data: function () {
      return {
        toggleGrid: true,
        loading: false,
        isDirty: false,
        // disabled: false,
        theSortable: null,
        ascPopoverContent: '',
        showAddPopover: false,
        localCpt: {},
        addRow: [{ codeType: null, originalModifier: null, recommendedModifier: null }],
        columns: ['origPaymentIndicator', 'origAscWeight', 'origAscPayRate', 'origAscPayment', 'recPaymentIndicator', 'recAscWeight', 'recAscPayRate', 'recAscPayment', 'actions'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'modLineSequenceCol',
            codeType: 'modCodeTypeCol',
            originalModifier: 'oneHundredPx',
            originalDesc: 'descriptionCol',
            recommendedModifier: 'oneHundredPx',
            recDesc: 'descriptionCol',
            reasonForChange: 'reasonForChangeCol',
            actions: 'modActionsCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            codeType: 'Type',
            originalModifier: 'Mod',
            originalDesc: 'Description',
            recommendedModifier: 'Rec. Mod',
            recDesc: 'Description',
            delete: '',
            add: ''
          }
        }
      }
    },
    components: {
      iconCheckBox
    },
    methods: {
      ...mapActions('outpatientReviews/', ['GET_APCS']),
      ...mapActions('managedLists/blendedRates/', ['GET_BLENDED_RATES']),
      ...mapMutations('outpatientReviews/', ['SET_OUTPATIENT_CPT_ROW']),
      handleAscChange(level) {
        // if (level === 'original') {
        //   if (this.localCpt.origPaymentIndicator) {
        //     const ascListRow = this.apcList.find(x => x.asc === this.localCpt.origPaymentIndicator)
        //     if (ascListRow.ascWeight) {
        //       this.localCpt.origAscWeight = ascListRow.ascWeight
        //     }
        //   }
        // }

        // if (level === 'recommended') {
        //   if (this.localCpt.recPaymentIndicator) {
        //     const ascListRow = this.apcList.find(x => x.asc === this.localCpt.recPaymentIndicator)
        //     if (ascListRow.ascWeight) {
        //       this.localCpt.recAscWeight = ascListRow.ascWeight
        //     }
        //   }
        // }

        this.calcPayment()
      },
      calcPayment() {
        this.isDirty = true
        const blendedRate = this.blendedRatesList.find(x => x.blendedRateType === 'ASC' && x.clientId === this.outpatientReview.clientId && isAfter(parseISO(this.outpatientReview.dateOfService), parseISO(x.effectiveDate)))

        if (blendedRate) {
          if (this.localCpt.origAscWeight && (this.localCpt.origAscPayRate || this.localCpt.origAscPayRate === 0)) {
            this.localCpt.origAscPayment = Math.round(blendedRate.blendedRate * this.localCpt.origAscWeight * this.localCpt.origAscPayRate * 100) / 100
          } else {
            this.localCpt.origAscPayment = null
          }
          if (this.localCpt.recAscWeight && (this.localCpt.recAscPayRate || this.localCpt.recAscPayRate === 0)) {
            this.localCpt.recAscPayment = Math.round(blendedRate.blendedRate * this.localCpt.recAscWeight * this.localCpt.recAscPayRate * 100) / 100
          } else {
            this.localCpt.recAscPayment = null
          }
        }
      },
      rowChange() {
        if (this.localCpt.recPaymentIndicator || this.localCpt.recAscWeight || this.localCpt.recAscPayRate || this.localCpt.deleteAsc) {
          return true
        }
        return false
      },
      async handleDelete() {
        if (this.localCpt.deleteAsc && (this.localCpt.recPaymentIndicator > 0 || this.localCpt.recAscPayRate || this.localCpt.recAscPayRate === 0 || this.localCpt.recAscWeight || this.localCpt.recAscWeight === 0)) {
          try {
            await this.$confirm('This will clear the Recommended ASC, Recommended Weight and Recommended Payment Rate, continue ? ', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            this.localCpt.recPaymentIndicator = null
            this.localCpt.recAscWeight = null
            this.localCpt.recAscPayRate = null
          } catch (e) {
            this.localCpt.deleteAsc = !this.localCpt.deleteAsc
            return
          }
        }
        this.isDirty = true
        this.calcPayment()
      },
      save(e) {
        if ((this.localCpt.origPaymentIndicator || this.localCpt.origAscWeight || this.localCpt.origAscPayRate) && !(this.localCpt.origPaymentIndicator && this.localCpt.origAscWeight && (this.localCpt.origAscPayRate || this.localCpt.origAscPayRate === 0))) {
          this.ascPopoverContent = 'When setting Original ASC, all three of Original ASC, Original Weight and Original Payment Rate are required.'
          this.openascPopover(e)
          return
        }

        if ((this.localCpt.recPaymentIndicator || this.localCpt.recAscWeight || this.localCpt.recAscPayRate) && !(this.localCpt.recPaymentIndicator && this.localCpt.recAscWeight && (this.localCpt.recAscPayRate || this.localCpt.recAscPayRate === 0))) {
          this.ascPopoverContent = 'When setting Recommended ASC, all three of Recommended ASC, Recommended Weight and Recommended Payment Rate are required.'
          this.openascPopover(e)
          return
        }
        this.$emit('save', this.localCpt)
        this.isDirty = false
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      cancel() {
        this.$emit('cancel')
      },
      getRecPayRatePlaceHolder() {
        if (this.localCpt.origAscPayRate || this.localCpt.origAscPayRate === 0) {
          return `${this.localCpt.origAscPayRate * 100}%`
        }
        return 'Rec. ASC Pay Rate'
      },
      getRecAscPayment() {
        if ((this.localCpt.recAscPayment === 0 || this.localCpt.recAscPayment > 0) && this.localCpt.recPaymentIndicator) {
          return this.localCpt.recAscPayment.toFixed(2)
        }
        // if (this.localCpt.origAscPayment >= 0) {
        //   return this.localCpt.origAscPayment.toFixed(2)
        // }
      },
      openascPopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.ascPopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      cptModifiers(modifiers, version) {
        // remove blank mods
        modifiers = modifiers.filter(function (n) {
          if (n[version] !== undefined && n[version] !== null) {
            return true
          }
          return false
        })
        if (modifiers !== null) {
          const modLength = modifiers.length
          let modifer = ''
          let comma = ''
          if (modLength) {
            const cptId = modifiers[0].cptid
            for (let i = 0; i < modLength; i++) {
              if (modifiers[i][version] !== null && modifiers[i][version].trim().length > 1) {
                comma = modLength > 1 && i + 1 !== modLength ? ',' : ''
                modifer += '<span id="' + version + '_' + i + '" cptId="' + cptId + '" title="' + this.modDescription(modifiers[i][version]) + '">' + modifiers[i][version] + '</span>' + comma
              }
            }
          }
          // modifer += '&nbsp;'
          return modifer
        }
        return null
      },
      recCptModifers(row, originalModifier, recommendedModifier) {
        if (row.length) {
          let modifiers = []
          row.forEach((v, i) => {
            const row = v
            row.displayMod = v[recommendedModifier] || v[originalModifier]
            modifiers.push(row)
          })
          modifiers = modifiers.filter(function (n) {
            if (n.displayMod !== undefined && n.displayMod !== null) {
              return true
            }
            return false
          })
          if (modifiers !== null) {
            const modLength = modifiers.length
            let modifer = ''
            let comma = ''
            if (modLength) {
              const cptId = modifiers[0].cptid
              for (let i = 0; i < modLength; i++) {
                if (modifiers[i].displayMod !== null && modifiers[i].displayMod.trim().length > 1) {
                  comma = modLength > 1 && i + 1 !== modLength ? ',' : ''
                  modifer += '<span id="recommendedModifier_' + i + '" cptId="' + cptId + '" title="' + this.modDescription(modifiers[i].displayMod) + '">' + modifiers[i].displayMod + '</span>' + comma
                }
              }
            }
            // modifer += '&nbsp;'
            return modifer
          }
        }
        return null
      },
      modDescription(modifier) {
        if (modifier !== null && modifier.length) {
          let desc = ''
          for (const i in this.dropdowns.modifiers.list) {
            if (modifier === this.dropdowns.modifiers.list[i].key) {
              desc = this.dropdowns.modifiers.list[i].value
                .split('|')[1]
                .trim()
                .toLowerCase()
              break
            }
          }
          return desc
        }
      },
      expandDesc(cptId, isMod = false) {
        // const targetId = $event.currentTarget.id
        // console.log(targetId)
        const orTargetId = isMod ? 'OrDesc_Mod_' + cptId : 'OrDesc_' + cptId
        const recTargetId = isMod ? 'RecDesc_Mod_' + cptId : 'RecDesc_' + cptId

        const orEl = document.getElementById(orTargetId)
        const recEl = document.getElementById(recTargetId)
        orEl.classList.toggle('overflow')
        recEl.classList.toggle('overflow')
      }
    },
    mounted: async function () {
      this.localCpt = JSON.parse(JSON.stringify(this.cpt))
      if (this.blendedRatesList.length === 0) {
        this.loading = true
        try {
          await this.GET_BLENDED_RATES()
          this.loading = false
        } catch (e) {
          this.lading = false
          console.log(e)
        }
      }
    },
    watch: {
      isLevel2(val) {
        this.$nextTick(() => {
          if (this.theSortable) {
            this.attachSortable()
          }
        })
      },
      'cpt.recAscPayment': function () {
        this.localCpt = JSON.parse(JSON.stringify(this.cpt))
      },
      'cpt.origAscPayment': function () {
        this.localCpt = JSON.parse(JSON.stringify(this.cpt))
      },
      'outpatientReview.dateOfService': async function () {
        await this.GET_ASCS(this.outpatientReview.dateOfService)
      }
    },
    computed: {
      ...mapState('outpatientReviews/', ['outpatientReview', 'apcList']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/blendedRates/', ['blendedRatesList']),
      disabled() {
        return !!this.readOnly() || this.outpatientReview.reviewStatusId !== 2
      },
      isLevel2: function () {
        return this.outpatientReview.reviewLevel > 1
      }
    }
  }
</script>

<style scoped>
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #b4bccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #1f2d3d;
    display: block;
    font-size: 12px;
    height: 22px;
    outline: 0;
    padding: 3px 3px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  th {
    word-break: break-word;
  }

  .overflow {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 48px;
    text-overflow: ellipsis;
    cursor: zoom-in;
  }
</style>