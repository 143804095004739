<template>
  <div>
    <!-- <el-card> -->
    <h4 class="headerWithBorder">Pricing and Grouping</h4>
    <el-form size="mini" label-position="left" label-width="80px" v-if="isTrucodeEnabled()">
      <el-row :gutter="40" v-if="!hideGroupers">
        <el-col :span="7">
          <el-form-item size="mini" label="Grouper" disabled>
            <hia-el-select disabled v-model="proReview.outpatientGrouperId" placeholder="Outpatient Grouper" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.outpatientGroupers.list"></hia-el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="infoSection" :gutter="20">
      <el-col :lg="19" :md="17">
        <el-table :data="getFooterArray()" class="tableStyle" stripe :span-method="calculateSpan">
          <el-table-column prop="level" label="Level"> </el-table-column>
          <el-table-column prop="rvuSum" label="Work RVU Total"> </el-table-column>
          <el-table-column prop="mues" label="Practioner MUEs">
            <template slot-scope="scope">
              <template v-if="getMUEsByReviewLevel(scope.row).length > 0">
                <span style="display: block; color: #337AB7;" v-for="item in getMUEsByReviewLevel(scope.row)" :key="item.description" :title="`${item.description}`"><i class="mdi mdi-alert" style="color: #f79624;"></i> {{ item.code }}</span>
              </template>
              <i v-if="getMUEsByReviewLevel(scope.row).length === 0" title="None" class="mdi mdi-check" style="color: #63b344;"></i>
            </template>
          </el-table-column>
          <el-table-column prop="ncci" label="Practitioner NCCI PTP Edits">
            <template slot-scope="scope">
              <template v-if="getNCCIByReviewLevel(scope.row).length > 0">
                <span style="display: block; color: #337AB7;" v-for="item in getNCCIByReviewLevel(scope.row)" :key="item.description" :title="`${item.description}`"><i class="mdi mdi-alert" style="color: #f79624;"></i> {{ item.code }}</span>
              </template>
              <i v-if="getNCCIByReviewLevel(scope.row).length === 0" title="None" class="mdi mdi-check" style="color: #63b344;"></i>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :lg="5" :md="7">
        <h4 class="headerWithBorder" style="margin: 12px 0px 12px 0px; padding-bottom: 11px; font-size: 12px; font-weight: bold;">Audit Result</h4>
        <el-button type="danger" v-if="proReview.auditResult === 'RVU Change'" title="The record contains recommendations that affect the RVU " plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ proReview.auditResult }}</el-button>
        <el-button type="warning" v-if="proReview.auditResult === 'FYI'" title="The record contains recommendations that do not affect the grouping" plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ proReview.auditResult }}</el-button>
        <el-button type="success" v-if="proReview.auditResult === 'No Change'" title="There are no recommended changes on the record" plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ proReview.auditResult }}</el-button>
      </el-col>
    </el-row>

    <el-dialog title="Record Summary" :visible.sync="summaryDialogVisible" destroy-on-close width="75%">
      <div style="min-height: 475px;" v-loading="summaryLoading" element-loading-spinner="atom-audit-loader">
        <template v-if="!summaryLoading">
          <div v-for="row in summaryData" :key="row.patientType" style="margin-bottom: 50px;">
            <!-- <h4 style="font-weight: bold;">{{ row.codeDesc }}</h4> -->
            <PROSummary :ref="`proSummary_${row.codeDesc}`" :item="row" :title="`${row.codeDesc}`" @handleTableCommands="handleTableCommandsModal"></PROSummary>
          </div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import formatMoney from '@/mixins/money'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { mapState, mapActions } from 'vuex'
  import PROSummary from '../../reports/dashboards/PRO_Summary'
  import axios from '@/utilities/axios-global'

  export default {
    name: 'proReviewFooter',
    mixins: [formatMoney, HiaAuthorization],
    data: function() {
      return {
        hideGroupers: false,
        summaryLoading: true,
        summaryDialogVisible: false,
        summaryData: {}
      }
    },
    components: {
      PROSummary
    },
    methods: {
      ...mapActions('proReviews/', ['PUT_PRO_REVIEW']),
      async showSummary() {
        this.summaryDialogVisible = true
        const payload = {
          proRecordIds: this.proReview.proRecordId,
          includeAccts: false,
          statType: 'test'
        }
        try {
          this.summaryLoading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/proBasicStat', payload)
          this.summaryData = response.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.summaryLoading = false
        }
      },
      localFormatMoney(value, decimalPlaces) {
        return '$' + this.formatMoney(value, decimalPlaces)
      },
      calculateSpan({ row, column, rowIndex, columnIndex }) {
        if (column.label === 'Audit Result') {
          return {
            rowspan: 2,
            colspan: 1
          }
        }
        return {
          rowspan: 1,
          colspan: 1
        }
      },
      getFooterArray() {
        let footer = []

        const orgRvuSum = this.proReview.proRecordCpts.reduce((a, b) => {
          const val = this.getPhysicianFeeScheduleData(b, 'workRvu', 0)
          if (val) {
            return parseInt(a) || parseFloat(a) ? (parseFloat(a) + val).toFixed(2) : (0 + val).toFixed(2)
          }
          return a
        }, '')

        const recRvuSum = this.proReview.proRecordCpts.reduce((a, b) => {
          const val = this.getPhysicianFeeScheduleData(b, 'workRvu', 1)
          if (val) {
            return parseInt(a) || parseFloat(a) ? (parseFloat(a) + val).toFixed(2) : (0 + val).toFixed(2)
          }
          return a
        }, '')

        footer = [
          {
            level: 'Original',
            rvuSum: orgRvuSum || 'N/A'
          },
          {
            level: 'Recommended',
            rvuSum: recRvuSum || 'N/A'
          }
        ]

        return footer
      },
      getPhysicianFeeScheduleData(cpt, field, level) {
        const data = this.proReview.proRecordCptsPhysicianFeeSchedule.find(f => f.cptId === cpt.cptId && f.codeLevel === level)
        return data ? data[field] : ''
      },
      getMUEsByReviewLevel(row) {
        let rawMUEEdits = []
        const proccessedMUEedits = []
        const reviewLevel = row.level === 'Original' ? 'orAscResponse_Object' : 'recAscResponse_Object'
        if (this.proReview[reviewLevel]) {
          rawMUEEdits = this.proReview[reviewLevel].outpatient_calculation_results.apc_calculation_result.edits.edit.filter(x => x.type === 'Practioner MUEs')
          if (rawMUEEdits.length) {
            rawMUEEdits.forEach((item, i, a) => {
              const htmlString = new DOMParser().parseFromString(item.description, 'text/html')
              const cptCode = htmlString.querySelector('a:first-of-type').innerText
              const description = item.description.replace(/<[^>]*>/g, '')
              proccessedMUEedits.push({ code: cptCode, description: description })
            })
          }
        }

        return proccessedMUEedits
      },
      getNCCIByReviewLevel(row) {
        const proccessedNCCIedits = []
        const reviewLevel = row.level === 'Original' ? 'edits' : 'recEdits'
        if (this.proReview.proRecordCpts.length) {
          this.proReview.proRecordCpts.forEach((item, i, a) => {
            if (item[reviewLevel].length) {
              let compDescription = ''
              let cptCode = ''
              item[reviewLevel]
                .filter(f => f.type === 'Practitioner NCCI PTP Edits')
                .forEach((ncciEdit, j, b) => {
                  const description = ncciEdit.description.replace(/<[^>]*>/g, '')
                  // const details = sub.details.replace(/<[^>]*>/g, '')
                  compDescription += `${description}\n`
                  cptCode = ncciEdit.code
                })
              if (compDescription.length) {
                proccessedNCCIedits.push({ lineSequence: item.lineSequence, code: cptCode, description: compDescription })
              }
            }
          })
        }
        return proccessedNCCIedits
      },
      handleTableCommandsModal(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['baseApiUrl']),
      disabled() {
        return !!this.readOnly() || this.proReview.reviewStatusId !== 2
      }
    }
  }
</script>

<style scoped>
  .tableStyle {
    font-size: 12px;
    width: 100%;
    margin: 0px 0px 10px 0px;
  }

  .infoSection {
    margin: 10px 0px 0px 0px;
  }

  >>> .el-table .cell {
    white-space: nowrap;
  }
</style>
