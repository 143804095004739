const inputValidations = {
  methods: {
    digitsOnlyInput(e) {
      let key = e.keyCode || e.which
      // The numpad keys lie between 96 to 105; subtracting 48 reverts to basic digits
      if (key >= 96 && key <= 105) {
        key -= 48
      }
      // need to convert to UTF-16 so regex can pickup backspace/tab etc because the event keycodes produced by js are not recognized in regex
      key = String.fromCharCode(key)
      if (!/[\t\d\b]/g.test(key)) {
        e.preventDefault()
      }
    }
  }
}

export default inputValidations
