<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-input :disabled="disabled" size="mini" type="text" id="provSelinp" v-model="calcCDIName" :readonly="true">
          <el-button slot="append" class="search" icon="el-icon-search" size="mini" @click="searchCDIs" :disabled="disabled">
          </el-button>
          <el-button slot="append" class="clear" icon="el-icon-circle-close" @click="selectCDI({cdiStaffId: null})" size="mini" :disabled="disabled">
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-dialog :id="getUniqueDialogId()" title="Select CDI Staff" :visible.sync="showSearch" width="60%" :append-to-body="true" @opened="handleOpen">
      <el-popover trigger="click" placement="right" v-model="addCDIPopOverVisible">
        <el-form size="mini" ref="cdiForm" label-position="top" label-width="120px" :rules="cdiRules" :model="cdiForm">
          <el-form-item size="mini" label="Add CDI" prop="addCDI" id="addCDIInput">
            <el-input :disabled="disabled" size="mini" :maxlength="50" type="text" id="addCDI" v-model="cdiForm.addCDI">
            </el-input>
          </el-form-item>
        </el-form>
        <br>
        <el-button-group style="margin: 0px 0px 0px 10px">
          <el-button type="text" @click="addCDIPopOverVisible = false; cdiForm.addCDI = ''" size="mini" class="hiaButton" plain round>
            Cancel</el-button>
          <el-button class="hiaButton" plain round @click="addCDI()" size="mini">Confirm</el-button>
        </el-button-group>
        <div slot="reference" class="addButton">
          <el-button class="hiaButton" plain round size="mini" icon="el-icon-plus" title="Add CDI">Add New
            CDI
          </el-button>
        </div>
      </el-popover>
      <el-form size="mini" label-position="left" label-width="150px" v-loading="cdisLoading" element-loading-spinner="atom-audit-loader">
        <v-server-table class="hiaTable" v-loading="tableLoading" @loading="tableLoading = true" element-loading-spinner="atom-audit-loader" @loaded="loaded"
          name="cdiListGrid" ref="cdiListGrid" id="cdiListGrid" :columns="cdiColumns" :options="cdioptions">
          <template slot="name" slot-scope="props">
            <el-button tabindex="0" class="select-button" type="text" :disabled="props.row.inactive" @click="selectCDI(props.row)" size="mini">
              {{props.row.name + (props.row.inactive ? ' (Inactive)': '')}}</el-button>
          </template>
        </v-server-table>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'
  export default {
    name: 'cdiSelector',
    props: {
      value: Number,
      isNew: Boolean,
      cdiStaff: Object,
      disabled: Boolean
    },
    data() {
      return {
        addCDIPopOverVisible: false,
        cdiForm: {
          addCDI: ''
        },
        cdiRules: {
          addCDI: [
            { required: true, message: 'Please Set CDI' }
          ]
        },
        cdisLoading: false,
        showSearch: false,
        tableLoading: false,
        alreadyOpened: false,
        cdiColumns: ['name'],
        cdioptions: {
          requestFunction: async (data) => {
            try {
              const payload = {
                data: data
              }

              return await this.LOAD_CDISTAFF_PAGINATED(payload)
            } catch (err) {
              console.log('error', err)
            }
          },
          filterByColumn: true,
          headings: {
            name: 'CDI Name'
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        cdiIndex: -1
      }
    },
    methods: {
      ...mapMutations('dropdowns/', ['PUSH_CDISTAFF']),
      ...mapActions('managedLists/cdiStaff/', ['LOAD_CDISTAFF_PAGINATED', 'POST_CDISTAFF']),
      getUniqueDialogId() {
        return `cdiSelectorDialog${this._uid}`
      },
      handleOpen() {
        const dialogId = this.getUniqueDialogId()
        const input = document.querySelector(`#${dialogId} #cdiListGrid input[name="vf__name"]`)
        if (input) {
          input.focus()
        }
      },
      loaded() {
        this.tableLoading = false
        const dialog = document.getElementById(this.getUniqueDialogId())
        const tds = dialog.getElementsByTagName('td')
        for (let i = 0; i < tds.length; i++) {
          tds[i].tabIndex = -1
        }
      },
      searchCDIs() {
        this.showSearch = true
        if (this.alreadyOpened) {
          this.$refs.cdiListGrid.refresh()
        }
        this.alreadyOpened = true
      },
      selectCDI(row) {
        const cdiStaffId = row.cdiStaffId
        this.$emit('input', cdiStaffId)
        this.$emit('selected', row)
        this.showSearch = false
        if (this.isNew && this.$refs.cdiListGrid) {
          this.cdiIndex = this.$refs.cdiListGrid.data.findIndex(f => f.cdiStaffId === cdiStaffId)
        }
      },
      addCDI() {
        this.$refs.cdiForm.validate(async (valid) => {
          if (valid) {
            try {
              const payload = {
                cdiStaff: {
                  name: this.cdiForm.addCDI
                }
              }
              const response = await this.POST_CDISTAFF(payload)
              if (this.isNew) {
                this.$refs.cdiListGrid.data.push(response)
              }
              this.addCDIPopOverVisible = false
              this.$refs.cdiForm.resetFields()
              this.PUSH_CDISTAFF({ cdiStaffId: response.cdiStaffId, name: payload.name })
              this.selectCDI(response)
            } catch (err) {

            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    computed: {
      calcCDIName: {
        get: function () {
          if (this.cdiStaff) {
            return this.cdiStaff.name
          }
          return null
        }
      }
    }
  }
</script>

<style scoped>
  .project-selector-buttons>>>.el-button.el-button--default.el-button--mini {
    padding: 4px 9px;
    margin-top: -1px;
  }

  .coderButtons {
    padding: 1px 0px 0px 5px;
  }

  .addButton {
    width: 115px;
    margin: 0px 0px 10px 0px;
  }

  >>>.select-button {
    border: 2px solid rgba(255, 255, 255, 0);
    padding: 7px 5px 7px 5px;
    width: 100%;
    text-align: left;
  }

  >>>.select-button:focus {
    border: 2px solid #409eff;
    border-radius: 0px;
  }
</style>