<template>
  <div>
    <el-row v-if="loadingComplete" element-loading-spinner="atom-audit-loader">
      <el-row v-if="proReview.emguidelines === '2021' || proReview.emguidelines === '2023'">
        <el-col :span="24">
          <el-form ref="form" label-position="left" size="mini" label-width="220px">
            <el-form-item label="Exam Present">
              <el-col> <input :disabled="disabled" type="radio" name="examPresent" :value="true" v-model="proReview.examPresent" @input="checkOverrides('examPresent', $event)" />Yes <input :disabled="disabled" type="radio" name="examPresent" :value="false" v-model="proReview.examPresent" @input="checkOverrides('examPresent', $event)" />No </el-col>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-col :span="10">
        <el-menu class="el-menu-vertical-demo" :default-active="defaultActiveIndex" @select="handleClick" :disabled="examDisabled">
          <div v-for="examType in dropdowns.proExam_ExamTypes.list" :key="examType.key">
            <el-menu-item :key="examType.key" :index="'exel_' + examType.key" class="" :disabled="examDisabled">
              <span v-text="examType.value"></span>
            </el-menu-item>
          </div>
        </el-menu>
      </el-col>
      <el-col :span="6">
        <div v-for="examSystemsAreas in examSystemAreas" :key="examSystemsAreas.key">
          <input type="checkbox" :disabled="examDisabled" v-model="selectedAreas[examSystemsAreas.key]" @change="systemAreaChange" />
          <span :class="systemAreasShaded[examSystemsAreas.key] ? 'active' : ''">{{ examSystemsAreas.value }}</span>
        </div>
      </el-col>
      <el-col :span="8">
        <div v-for="question in examQuestions" :key="question.id">
          <input type="checkbox" :disabled="examDisabled" v-model="selectedQuestions[question.id]" @change="selectedExamQuestionChange(question.id, selectedQuestions[question.id])" />
          <span :class="question.shaded ? 'active' : ''">{{ question.elementDescription }}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'ProReviewExam97',
    props: ['proRecordId', 'codeLevel', 'details', 'defaultExamType', 'disabled', 'loadingComplete'],
    data() {
      return {
        loading: false,
        dropdownsNeeded: ['proExam_ExamTypes', 'proExam_SystemAreas', 'proExam_ExamTypesSystemAreasCrossover'],
        examSystemAreas: [],
        systemAreasShaded: [],
        selectedAreas: [],
        examQuestions: [],
        selectedQuestions: [],
        examTypeId: 0,
        systemAreasLoading: false,
        defaultActiveIndex: ''
      }
    },
    created: function() {
      if (this.defaultExamType !== null) {
        const navId = 'exel_' + this.defaultExamType
        this.defaultActiveIndex = navId
        this.updateExamType(this.defaultExamType)
      }
    },
    mounted: function() {
      this.updateSelectedQuestions()
      if (this.defaultExamType !== null) {
        const navId = 'exel_' + this.defaultExamType
        this.defaultActiveIndex = navId
        this.updateExamType(this.defaultExamType)
      }
    },
    methods: {
      ...mapActions('proReviews/', ['GET_EXAM_ELEMENTS', 'PUT_PRO_REVIEW', 'POST_PRORECORD_EXAMDETAILS', 'DELETE_PRORECORD_EXAMDETAILS']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      updateSelectedQuestions() {
        if (!this.proReview.examDetails) {
          return
        }
        this.selectedQuestions = []
        this.proReview.examDetails
          .filter(a => a.codeLevel === this.codeLevel)
          .forEach(element => {
            this.selectedQuestions[element.examElementId] = true
          })
      },
      async putProReviewCalculate(newValue, proRecordExamDetails) {
        try {
          if (newValue) {
            await this.POST_PRORECORD_EXAMDETAILS(proRecordExamDetails)
          } else {
            await this.DELETE_PRORECORD_EXAMDETAILS(proRecordExamDetails)
          }

          this.$message({
            message: 'Exam Update Successful',
            type: 'success'
          })
        } catch {
          this.$message({
            message: 'Exam Update Error',
            type: 'error'
          })
        }
      },
      async putProReview_ChangeSelectedExamType(examTypeId) {
        if (this.codeLevel === 1) {
          this.proReview.level1ExamTypeId = examTypeId
        } else {
          this.proReview.level2ExamTypeId = examTypeId
        }

        const payload = {
          proReview: this.proReview,
          changedField: 'examType'
        }
        try {
          await this.PUT_PRO_REVIEW(payload)
          this.$message({
            message: 'Exam Update Successful',
            type: 'success'
          })
        } catch {
          this.$message({
            message: 'Exam Update Error',
            type: 'error'
          })
        }
      },
      async putProReview(changedField) {
        try {
          this.loading = true
          const payload = { proReview: this.proReview, changedField: changedField }
          this.defaultActiveIndex = ''
          this.examSystemAreas = []
          this.examQuestions = []
          await this.PUT_PRO_REVIEW(payload)
          this.loading = false
          this.$message({
            message: 'Exam Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Exam Update Error',
            type: 'error'
          })
        }
      },
      async checkOverrides(changedField, event) {
        if (event.target.value === 'false' && this.proReview.examPresent) {
          try {
            await this.$confirm('This will reset Exam Present scoring.  Proceed?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            })
          } catch {
            this.proReview.examPresent = !this.proReview.examPresent
            return
          }
        }
        this.proReview.examPresent = event.target.value
        this.putProReview(changedField)
      },
      getAvailableSystemAreas(examTypeId) {
        this.examTypeId = examTypeId
        const retArray = []
        const row = this.dropdowns.proExam_ExamTypesSystemAreasCrossover.list.find(e => e.key === examTypeId)
        if (row) {
          const systemAreas = row.value.split(';')
          systemAreas.forEach(area => {
            retArray.push(this.dropdowns.proExam_SystemAreas.list.filter(e => e.key === parseInt(area))[0])
          })
          return retArray
        }
        return []
      },
      getSystemAreasShaded(examTypeId) {
        const shadedArray = []
        this.examSystemAreas.forEach(area => {
          const shaded = this.examElements.filter(e => e.examTypeId === examTypeId && e.examSystemAreaId === area.key)[0].shaded
          shadedArray[area.key] = shaded
        })
        return shadedArray
      },
      getAvaliableQuestions(examTypeId, selectedAreas) {
        const retArray = []
        for (let i = 0; i < selectedAreas.length; i++) {
          if (selectedAreas[i] === true) {
            const questions = this.examElements.filter(e => e.examTypeId === examTypeId && e.examSystemAreaId === i)
            questions.forEach(question => {
              retArray.push(question)
            })
          }
        }
        return retArray
      },
      updateExamType(examTypeId) {
        this.selectedAreas = []
        this.examSystemAreas = this.getAvailableSystemAreas(examTypeId)
        this.systemAreasShaded = this.getSystemAreasShaded(examTypeId)
        const availableAreas = []
        this.examSystemAreas.forEach(element => {
          availableAreas[element.key] = true
        })
        const availableQuestions = this.getAvaliableQuestions(this.examTypeId, availableAreas)
        availableQuestions.forEach(question => {
          if (this.selectedQuestions[question.id]) {
            // this.selectedAreas.push(element.key)
            this.selectedAreas[question.examSystemAreaId] = true
          }
        })
        this.systemAreasLoading = false
        this.examQuestions = this.getAvaliableQuestions(this.examTypeId, this.selectedAreas)
      },
      handleClick(index, indexPath) {
        if (this.examDisabled) {
          return false
        }
        const examTypeId = parseInt(index.replace('exel_', ''))
        this.systemAreasLoading = true
        if (!this.loading) {
          this.putProReview_ChangeSelectedExamType(examTypeId)
        }
        this.updateExamType(examTypeId)
      },
      systemAreaChange(newValue) {
        if (!this.systemAreasLoading) {
          this.examQuestions = this.getAvaliableQuestions(this.examTypeId, this.selectedAreas)
          // this.updateSelectedQuestions()
        }
      },
      selectedExamQuestionChange(questionId, newValue) {
        const proRecordExamDetails = {
          proRecordId: this.proReview.proRecordId,
          examElementId: questionId,
          codeLevel: this.codeLevel
        }
        this.putProReviewCalculate(newValue, proRecordExamDetails)
      }
    },
    computed: {
      ...mapState('proReviews/', ['examElements', 'proReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      examDisabled: function() {
        return this.disabled || ((this.proReview.emguidelines === '2021' || this.proReview.emguidelines === '2023') && !this.proReview.examPresent)
      }
    },
    watch: {
      loadingComplete(isLoadingComplete) {
        // we need this because we are using v-show and keep-alive and we need a way to run
        // the below code after the initial network calls are made from the parent
        if (isLoadingComplete) {
          this.updateSelectedQuestions()
          if (this.defaultExamType !== null) {
            const navId = 'exel_' + this.defaultExamType
            this.defaultActiveIndex = navId
            this.updateExamType(this.defaultExamType)
          }
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .resultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    color: #63b344;
    margin: -6px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
  }

  .blueResultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    color: #005695;
    margin: -6px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
  }

  >>> .active {
    background: rgba(109, 207, 246, 0.5);
  }

  >>> .el-menu-item {
    line-height: 35px;
    white-space: normal;
    min-height: 18px;
    max-height: 35px;
  }

  .el-menu-item.is-active {
    color: #005695 !important;
  }

  .element.style {
    padding-left: 2px;
  }
</style>
