<template>
  <div>
    <!-- <el-card> -->
    <h4 class="headerWithBorder">Pricing and Grouping</h4>
    <el-form size="mini" label-position="left" label-width="80px" v-if="isTrucodeEnabled()">
      <el-row :gutter="40" v-if="!hideGroupers">
        <el-col :span="7">
          <el-form-item size="mini" label="Grouper" disabled>
            <hia-el-select disabled v-model="outpatientReview.outpatientGrouperId" placeholder="Outpatient Grouper" :clearable="false" :filterable="true" itemkey="key"
              label="value" :dropdowns="dropdowns.outpatientGroupers.list"></hia-el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="infoSection" :gutter="20">
      <el-col :lg="19" :md="17">
        <el-table :data="getFooterArray()" class="tableStyle" stripe :span-method="calculateSpan">
          <el-table-column prop="level" label="Level"> </el-table-column>
          <el-table-column prop="grouperVersion" label="Grouper Version" v-if="isTrucodeEnabled()"> </el-table-column>
          <el-table-column prop="reimbursement" :label="outpatientReview.outpatientGrouperId === 1 ? 'APC Total Reimbursement' : 'ASC Total Reimbursement'"> </el-table-column>
        </el-table>
      </el-col>
      <el-col :lg="5" :md="7">
        <h4 class="headerWithBorder" style="margin: 12px 0px 12px 0px; padding-bottom: 11px; font-size: 12px; font-weight: bold;">Audit Result</h4>
        <el-button type="success" v-if="outpatientReview.auditResult === 'No Change'" title="There are no recommended changes on the record" plain round size="mini"
          @click="showSummary" icon="mdi mdi-table-eye"> {{ outpatientReview.auditResult }}</el-button>
        <el-button type="warning" v-if="outpatientReview.auditResult === 'FYI'"
          :title="`The record contains recommendations that do not affect the ${outpatientReview.outpatientGrouperId === 1 ? `Outpatient` : `Ambulatory`} grouper`" plain round
          size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ outpatientReview.auditResult }}</el-button>
        <el-button type="danger" v-if="outpatientReview.auditResult === 'APC Financial Change'" title="The record contains recommendations that change the APC reimbursement"
          plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ outpatientReview.auditResult }}</el-button>
        <el-button type="danger" v-if="outpatientReview.auditResult === 'ASC Financial Change'" title="The record contains recommendations that change the ASC reimbursement"
          plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ outpatientReview.auditResult }}</el-button>
      </el-col>
    </el-row>

    <el-dialog title="Record Summary" :visible.sync="summaryDialogVisible" destroy-on-close width="75%">
      <div style="min-height: 475px;" v-loading="summaryLoading" element-loading-spinner="atom-audit-loader">
        <template v-if="!summaryLoading">
          <div v-for="row in summaryData" :key="row.patientType" style="margin-bottom: 50px;">
            <OPSummary :ref="`opSummary_${row.codeDesc}`" :item="row" v-if="outpatientReview.outpatientGrouperId === 1" :title="`${row.codeDesc}`"
              @handleTableCommands="handleTableCommandsModal"></OPSummary>
            <ASCSummary :ref="`ascSummary_${row.codeDesc}`" :item="row" v-else :title="`${row.codeDesc}`" @handleTableCommands="handleTableCommandsModal"></ASCSummary>
          </div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import formatMoney from '@/mixins/money'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { mapState, mapActions } from 'vuex'
  import OPSummary from '../../reports/dashboards/OP_Summary'
  import ASCSummary from '../../reports/dashboards/ASC_Summary'
  import axios from '@/utilities/axios-global'

  export default {
    name: 'OutpatientReviewFooter',
    mixins: [formatMoney, HiaAuthorization],
    data: function () {
      return {
        hideGroupers: false,
        summaryLoading: true,
        summaryDialogVisible: false,
        summaryData: {}
      }
    },
    components: {
      OPSummary,
      ASCSummary
    },
    methods: {
      ...mapActions('outpatientReviews/', ['PUT_OUTPATIENT_REVIEW']),
      async showSummary() {
        this.summaryDialogVisible = true
        const payload = {
          ascTableIds: this.outpatientReview.ascTableId,
          includeAccts: false,
          statType: 'test'
        }
        try {
          this.summaryLoading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/opBasicStat', payload)
          this.summaryData = response.data
        } catch (err) {
          console.log(err)
          this.tableData = []
        } finally {
          this.summaryLoading = false
        }
      },
      putOutpatientReview: function (changedField) {
        const payload = { outpatientReview: this.outpatientReview, changedField: changedField }
        try {
          this.PUT_OUTPATIENT_REVIEW(payload)
          this.$message({
            message: 'Outpatient Record Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: 'Outpatient Update Error',
            type: 'error'
          })
        }
      },
      localFormatMoney(value, decimalPlaces) {
        return '$' + this.formatMoney(value, decimalPlaces)
      },
      calculateSpan({ row, column, rowIndex, columnIndex }) {
        if (column.label === 'Audit Result') {
          return {
            rowspan: 2,
            colspan: 1
          }
        }
        return {
          rowspan: 1,
          colspan: 1
        }
      },
      getFooterArray() {
        let footer = []
        if (this.outpatientReview.orResponse_Object && this.outpatientReview.recResponse_Object && this.outpatientReview.orResponse_Object.outpatient_calculation_results.apc_calculation_result && this.outpatientReview.recResponse_Object.outpatient_calculation_results.apc_calculation_result && this.outpatientReview.orResponse_Object.outpatient_calculation_results.apc_calculation_result.apc_grouping_result && this.outpatientReview.recResponse_Object.outpatient_calculation_results.apc_calculation_result.apc_grouping_result) {
          footer = [
            {
              level: 'Original',
              grouperVersion: this.outpatientReview.orResponse_Object.outpatient_calculation_results.apc_calculation_result.apc_grouping_result.grouper_version || '',
              reimbursement: '$' + this.formatMoney(this.outpatientReview.orResponse_Object.outpatient_calculation_results.apc_calculation_result.apc_pricing_result.total_reimbursement, 2)
            },
            {
              level: 'Recommended',
              grouperVersion: this.outpatientReview.recResponse_Object.outpatient_calculation_results.apc_calculation_result.apc_grouping_result.grouper_version || '',
              reimbursement: '$' + this.formatMoney(this.outpatientReview.recResponse_Object.outpatient_calculation_results.apc_calculation_result.apc_pricing_result.total_reimbursement, 2)
            }
          ]
        }

        if (this.outpatientReview.orResponse_Object && this.outpatientReview.recResponse_Object && this.outpatientReview.orResponse_Object.outpatient_calculation_results.asc_calculation_result && this.outpatientReview.recResponse_Object.outpatient_calculation_results.asc_calculation_result) {
          footer = [
            {
              level: 'Original',
              grouperVersion: this.outpatientReview.orResponse_Object.outpatient_calculation_results.asc_calculation_result.asc_grouping_result.grouper_version,
              reimbursement: '$' + this.formatMoney(this.outpatientReview.orResponse_Object.outpatient_calculation_results.asc_calculation_result.asc_pricing_result.total_reimbursement, 2)
            },
            {
              level: 'Recommended',
              grouperVersion: this.outpatientReview.recResponse_Object.outpatient_calculation_results.asc_calculation_result.asc_grouping_result.grouper_version,
              reimbursement: '$' + this.formatMoney(this.outpatientReview.recResponse_Object.outpatient_calculation_results.asc_calculation_result.asc_pricing_result.total_reimbursement, 2)
            }
          ]
        }

        return footer
      },
      handleTableCommandsModal(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('outpatientReviews/', ['outpatientReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['baseApiUrl']),
      disabled() {
        return !!this.readOnly() || this.outpatientReview.reviewStatusId !== 2
      }
    }
  }
</script>

<style scoped>
  .tableStyle {
    font-size: 12px;
    width: 100%;
    margin: 0px 0px 10px 0px;
  }

  .infoSection {
    margin: 10px 0px 0px 0px;
  }

  >>>.el-table .cell {
    white-space: nowrap;
  }
</style>