const DropdownHelper = {
  methods: {
    getDropdownLabel(dropdownList, key) {
      for (let i = 0; i < dropdownList.length; i++) {
        if (dropdownList[i].key === key) {
          return dropdownList[i].value
        }
      }
      return ''
    }
  }
}

export default DropdownHelper
