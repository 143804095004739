<template>
  <div>
    <div v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader" style="height: 300px;">
    </div>
    <el-form v-if="!loading" :model="addRecommendationForm" size="mini" ref="addRecommendationForm" :rules="addRecommendationForm.rules" label-position="left" label-width="160px">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="title" label="Title">
            <el-input size="mini" type="text" v-model="addRecommendationForm.title" id="title"></el-input>
          </el-form-item>
          <el-form-item prop="recommendationType" label="Recommendation Type">
            <hia-el-select v-model="addRecommendationForm.recommendationType" placeholder="Recommendation Type" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationTypes.list">
            </hia-el-select>
          </el-form-item>
          <el-form-item prop="recordType" label="Record Type">
            <hia-el-select v-model="addRecommendationForm.recordType" placeholder="Record Type" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationRecordTypes.list">
            </hia-el-select>
          </el-form-item>
          <el-form-item prop="codeGroup" label="Code Group">
            <hia-el-select v-model="addRecommendationForm.codeGroup" placeholder="Code Group" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationCodeGroups.list">
            </hia-el-select>
          </el-form-item>
          <el-form-item prop="recommendation" label="Recommendation">
            <Tinymce id="recommendation" v-model="addRecommendationForm.recommendation"></Tinymce>
          </el-form-item>
          <el-form-item>
            <el-button-group class="pull-right">
              <el-button class="hiaButton" plain round size="mini" type="text" @click="closeShowAddRecommendationForm">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="addRecommendation()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Tinymce from '@/components/controls/Tinymce'

  export default {
    name: 'AddRecommendation',
    props: {
      recordType: { type: String },
      recommendationType: { type: String }
    },
    data() {
      return {
        loading: false,
        addRecommendationForm: {
          title: '',
          recommendationType: '',
          recordType: '',
          recommendation: '',
          rules: {
            title: [
              { required: true, message: 'Title is required', trigger: 'change' }
            ],
            recommendationType: [
              { required: true, message: 'Recommendation Type is required', trigger: 'change' }
            ],
            recommendation: [
              { required: true, message: 'Recommendation is required', trigger: 'change' }
            ]
          }
        },
        dropdownsNeeded: ['RecommendationTypes', 'RecommendationRecordTypes', 'RecommendationCodeGroups']
      }
    },
    created: function () {
      this.initialize()
    },
    methods: {
      ...mapActions('recommendationLibrary/', ['POST_RECOMMENDATION_RECORD']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      async initialize() {
        this.addRecommendationForm.recommendationType = this.recommendationType
        this.addRecommendationForm.recordType = this.recordType
        this.addRecommendationForm.recommendation = this.recommendationType === 'ACTIONTAKEN' ? this.activeFinding.findingDetails : this.activeFinding.reference
        this.loading = true
        await Promise.all([this.GET_DROPDOWNS(this.dropdownsNeeded)])
        this.loading = false
      },
      addRecommendation() {
        this.$refs.addRecommendationForm.validate(async (valid) => {
          if (valid) {
            try {
              const newRecommendation = {
                title: this.addRecommendationForm.title,
                recordType: this.addRecommendationForm.recordType,
                recommendationType: this.addRecommendationForm.recommendationType,
                recommendation: this.addRecommendationForm.recommendation,
                codeGroup: this.addRecommendationForm.codeGroup,
                createdBy: this.currentTenantUserId
              }

              await this.POST_RECOMMENDATION_RECORD(newRecommendation)
              this.closeShowAddRecommendationForm()
              this.$refs.addRecommendationForm.resetFields()
              this.$message({
                message: 'Recommendation Library Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Recommendation Library Added Error',
                type: 'error'
              })
            } finally {
              this.showAddRecommendationForm = false
              this.$refs.addRecommendationForm.resetFields()
            }
          }
        })
      },
      closeShowAddRecommendationForm() {
        this.$emit('closeShowAddRecommendationForm')
      }
    },
    computed: {
      ...mapState('auditFindings/', ['auditFindings', 'activeFinding']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user', 'currentTenantUserId'])
    },
    components: {
      Tinymce
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
