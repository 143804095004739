<template>
  <el-form :model="outpatientReview" :inline-message="true" size="mini" ref="form" label-position="left" label-width="120px" v-loading="loading"
    :rules="mapRequiredFieldsRules()" element-loading-spinner="atom-audit-loader" :validate-on-rule-change="false">
    <el-row :gutter="20">
      <el-col :span="12">
        <h4 class="headerWithBorder">Review Information</h4>

        <el-form-item size="mini" label="Facility" prop="clientId">
          <hia-el-select v-model="outpatientReview.clientId" :disabled="disabled" placeholder="Facility" :clearable="false" :filterable="true" itemkey="key" label="value"
            :dropdowns="dropdowns.clients.list" @input="putOutpatientReview('facility')"></hia-el-select>
        </el-form-item>

        <el-form-item size="mini" ref="dateFormItem" label="Project" prop="projectID">
          <projectSelector @input="putOutpatientReview('project')" :clearable="false" @selected="handleProjectSelected" v-model="outpatientReview.projectID"
            :project="outpatientReview.project" :disabled="disabled" :defaultQuery="{ projectTypeId: outpatientReview.project.projectTypeId }"> </projectSelector>
        </el-form-item>

        <el-form-item size="mini" label="Patient Type" prop="patientTypeId">
          <hia-el-select :disabled="disabled" @input="putOutpatientReview('patientTypeId')" v-model="outpatientReview.patientTypeId" placeholder="Patient Type"
            :filterable="true" :itemkey="'key'" :label="'value'" :dropdowns="isAsc ? dropdowns.ambulatoryPatientTypes.list : dropdowns.outpatientPatientTypes.list">
          </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Auditor" prop="consultantNumber">
          <hia-el-select :disabled="disabled" @input="putOutpatientReview('ConsNum')" v-model="outpatientReview.consultantNumber" placeholder="Auditor" :clearable="true"
            :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.consultants.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Review Date" prop="reviewDate">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="revDate" :clearable="false" v-model="outpatientReview.reviewDate"
            @change="putOutpatientReview('revDate')" format="MM/dd/yyyy"> </el-date-picker>
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <!-- <el-col>
              <input :disabled="disabled" id="prebill" type="checkbox" v-model="outpatientReview.prebill" @change="putOutpatientReview('prebill')">
              Prebill
            </el-col>

            <el-col>
              <input :disabled="disabled" id="noUBAvailable" type="checkbox" v-model="outpatientReview.noUbavailable" @change="putOutpatientReview('noUbavailable')">
              No UB Available
            </el-col> -->
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
      </el-col>

      <el-col :span="12">
        <h4 class="headerWithBorder">Original Coding Details</h4>

        <el-form-item label="Original Patient Status" prop="originalPatientStatus">
          <hia-el-select :disabled="disabled" v-on:input="putOutpatientReview('originalPatientStatus')" v-model="outpatientReview.originalPatientStatus"
            placeholder="Org Pat Status" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.patientStatus.list"> </hia-el-select>
        </el-form-item>

        <!-- <el-row>
          <el-col>
            <input :disabled="disabled" id="coderQuery" type="checkbox" v-model="outpatientReview.coderQuery" v-on:change="putOutpatientReview('coderQuery')">
            Coder Query
          </el-col>
        </el-row> -->
      </el-col>
    </el-row>

    <el-row :gutter="20" class="top-margin">
      <el-col :span="12">
        <h4 class="headerWithBorder">Chart Demographic</h4>

        <el-form-item size="mini" label="MR Number" prop="mrNumber">
          <el-input :disabled="disabled" size="mini" :maxlength="15" type="text" id="mrNumber" v-model="outpatientReview.mrNumber"
            @change.native="putOutpatientReview('mrNumber')"> </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Patient Number" prop="patientNumber">
          <div :class="disabled ? 'el-input el-input--mini is-disabled' : 'el-input el-input--mini'">
            <input id="patientNumber" :class="'el-input__inner'" :disabled="disabled" maxlength="15" type="text" :value="outpatientReview.patientNumber"
              @change="putPatientNumber($event, 'patientNumber')" />
          </div>
        </el-form-item>

        <el-form-item size="mini" label="Payor" prop="payorNumber">
          <hia-el-select :disabled="disabled" @input="putOutpatientReview('payorNumber')" v-model="outpatientReview.payorNumber" placeholder="Payor" :clearable="true"
            :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.payors.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Payor Detail" prop="payorDetailId">
          <payorDetailSelector :disabled="disabled || !outpatientReview.payorNumber" @input="putOutpatientReview('payorDetailId')" v-model="outpatientReview.payorDetailId"
            :payorNumberId="outpatientReview.payorNumber"
            :payorNumberlabel="dropdowns.payors.list.find(f => f.key === outpatientReview.payorNumber) ? dropdowns.payors.list.find(f => f.key === outpatientReview.payorNumber).value : null"
            :payorDetail="outpatientReview.payorDetail"> </payorDetailSelector>
        </el-form-item>

        <el-form-item size="mini" label="Date of Service" prop="dateOfService">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="dateOfService" v-model="outpatientReview.dateOfService"
            @change="putOutpatientReview('dateOfService')" format="MM/dd/yyyy"> </el-date-picker>
        </el-form-item>

        <!-- <el-form-item size="mini" label="Admit Date">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="obsAdmitDate" v-model="outpatientReview.obsAdmitDate" @change="putOutpatientReview('obsAdmitDate')"
            format="MM/dd/yyyy"></el-date-picker>
        </el-form-item> -->

        <el-form-item size="mini" label="Patient Age" prop="patientAge">
          <el-row>
            <el-col :span="patientAgeZero() ? 12 : 24">
              <el-input @keydown.native="digitsOnlyInput($event)" :disabled="disabled" size="mini" type="text" maxlength="3" id="patientAge" v-model="patientAgeYears"
                @change.native="putOutpatientReview('patientAge')"></el-input>
            </el-col>
            <el-col :span="12" v-if="patientAgeZero()">
              <el-input disabled size="mini" type="text" id="patientAgeDays" :value="patientAgeDays"></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item size="mini" label="Patient DOB" v-if="patientAgeZero()" prop="patientDob">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="patientDob" v-model="outpatientReview.patientDob" @change="putOutpatientReview('patientDob')"
            format="MM/dd/yyyy"></el-date-picker>
        </el-form-item>

        <el-form-item size="mini" label="Newborn Weight (grams)" v-if="patientAgeZero()" prop="newbornWeight">
          <el-input :disabled="disabled" size="mini" type="number" id="patientWeight" v-model="outpatientReview.newbornWeight" @change="handleNewbornWeight"> </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Patient Sex" prop="sex">
          <hia-el-select :disabled="disabled" @input="putOutpatientReview('sex')" v-model="outpatientReview.sex" placeholder="Patient Sex" :clearable="true" :filterable="true"
            itemkey="key" label="value" :dropdowns="dropdowns.patientSex.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Provider" prop="providerId">
          <providerSelector :disabled="disabled" v-model="outpatientReview.providerId" @input="putOutpatientReview('providerId')" :provider="outpatientReview.provider">
          </providerSelector>
        </el-form-item>

        <el-form-item size="mini" label="Coder" prop="coderId">
          <coderSelector :disabled="disabled" v-model="outpatientReview.coderId" @input="putOutpatientReview('coder')" :clientcoder="outpatientReview.clientCoder">
          </coderSelector>
        </el-form-item>

        <el-form-item size="mini" label="Service Line" prop="clientServiceLineId">
          <serviceLineSelector :disabled="disabled" v-model="outpatientReview.clientServiceLineId" @input="putOutpatientReview('serviceLine')"
            :serviceLine="outpatientReview.serviceLine"> </serviceLineSelector>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <h4 class="headerWithBorder">Audit Notes</h4>

        <el-form-item size="mini" label="Reason For Change" prop="reasonForChange">
          <hia-el-select :disabled="disabled" @input="putOutpatientReview('DemographicsreasonForChange')" v-model="outpatientReview.reasonForChange" clearable
            placeholder="Reason for Change" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.outpatientReasonsForChangeHeader.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Comments" prop="comments">
          <el-input :disabled="disabled" type="textarea" :rows="5" placeholder="" v-model="outpatientReview.comments" @change="putOutpatientReview('comments')"> </el-input>
          <!-- <textarea style="width: 100%;" class="el-textarea__inner" :disabled="disabled" type="textarea" :rows="5" id="comments" v-model.lazy="outpatientReview.comments" @change="putOutpatientReview('comments')"></textarea> -->
        </el-form-item>

        <el-form-item size="mini" label="Record Tags" prop="identifierSelectionsArray">
          <el-select class="multiSelect" :disabled="disabled" size="mini" v-model="outpatientReview.identifierSelectionsArray" filterable multiple placeholder="Tags"
            @visible-change="tagsSelectHideShow($event)" @remove-tag="tagsSelectRemoveTag($event)">
            <el-option v-for="item in dropdowns.recordTags.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-popover ref="savePopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent"
      @after-enter="handleHidePopOver('savePopover')"> </el-popover>
  </el-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { differenceInDays, parseISO } from 'date-fns'
  import inpatientHelper from '@/mixins/inpatientHelper'
  import inputValidations from '@/mixins/inputValidations'

  import coderSelector from '@/components/controls/coderSelector'
  import projectSelector from '@/components/controls/projectSelector'

  import providerSelector from '@/components/controls/providerSelector'

  import serviceLineSelector from '@/components/controls/serviceLineSelector'
  import payorDetailSelector from '@/components/controls/payorDetailSelector'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'OutpatientReviewDemographics',
    mixins: [inpatientHelper, inputValidations, HiaAuthorization],
    components: {
      providerSelector,
      serviceLineSelector,
      payorDetailSelector,
      coderSelector,
      projectSelector
    },
    data() {
      return {
        savePopoverContent: '',
        loading: false,
        backupIdentifierSelectionsArray: []
        // disabled: false
      }
    },
    methods: {
      ...mapActions('outpatientReviews/', ['PUT_OUTPATIENT_REVIEW']),
      async putOutpatientReview(changedField) {
        if (changedField === 'payorNumber') {
          this.outpatientReview.payorDetailId = null
          this.outpatientReview.payorDetail = {}
        }

        try {
          this.loading = true
          const payload = { outpatientReview: this.outpatientReview, changedField: changedField }
          await this.PUT_OUTPATIENT_REVIEW(payload)
          this.loading = false
          this.$emit('requiredFieldsValidation', {})
          this.$message({
            message: 'Demographics Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: e.message ? e.message : 'Demographics Update Error',
            type: 'error'
          })
        }
      },
      handleNewbornWeight() {
        this.outpatientReview.newbornWeight = !isNaN(this.outpatientReview.newbornWeight) ? parseInt(this.outpatientReview.newbornWeight) : null
        this.putOutpatientReview('newbornWeight')
      },
      patientAgeZero() {
        if (this.outpatientReview.patientAge === 0 || this.outpatientReview.patientAge === '0') {
          return true
        }
        return false
      },
      handleProjectSelected(project) {
        this.outpatientReview.project = project
      },

      tagsSelectHideShow(show) {
        if (show) {
          this.backupIdentifierSelectionsArray = this.outpatientReview.identifierSelectionsArray
        } else {
          if (JSON.stringify(this.backupIdentifierSelectionsArray) !== JSON.stringify(this.outpatientReview.identifierSelectionsArray)) {
            this.identifiersOnChange()
          }
        }
      },
      tagsSelectRemoveTag() {
        this.identifiersOnChange(this.outpatientReview)
      },
      identifiersOnChange() {
        this.outpatientReview.identifierSelectionsArray.forEach(element => {
          // if element doesn't exist in, add it with add flag
          const matchedElements = this.outpatientReview.identifierSelections.some(function (i) {
            return i.identifierID === element
          })
          if (!matchedElements) {
            const newValue = {
              id: 0,
              identifierID: element,
              add: true
            }

            this.outpatientReview.identifierSelections.push(newValue)
          }
        })

        this.outpatientReview.identifierSelections.forEach(element => {
          // if element doesn't exists in Array, set deleted flag
          if (!this.outpatientReview.identifierSelectionsArray.includes(element.identifierID)) {
            element.delete = true
          }
        })

        this.putOutpatientReview('recordTags')
      },
      mapRequiredFieldsRules() {
        const requiredFields = this.outpatientReview.requiredFields
        if (requiredFields && requiredFields.requiredFieldsData) {
          const requiredFieldsData = requiredFields.requiredFieldsData
          const rules = {}
          requiredFieldsData.forEach((item, index, array) => {
            if (item.required) {
              rules[item.field] = [
                {
                  required: item.required,
                  trigger: 'change',
                  message: item.message
                }
              ]
            }
          })
          return rules
        }
        return {}
      },
      putPatientNumber(event, field) {
        if (!event.target.value) {
          this.savePopoverContent = 'Patient Number must always contain a value.'
          this.openSavePopover(event)
          event.target.value = this.outpatientReview.patientNumber
          return false
        }
        this.outpatientReview.patientNumber = event.target.value
        this.putOutpatientReview(field)
      },
      openSavePopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.savePopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      }
    },
    computed: {
      ...mapState('managedLists/projects/', ['projectPK', 'project']),
      ...mapState('outpatientReviews/', ['outpatientReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user']),
      disabled() {
        return !!this.readOnly() || this.outpatientReview.reviewStatusId !== 2
      },
      patientAgeYears: {
        get: function () {
          return this.outpatientReview.patientAge
        },
        set: function (val) {
          this.outpatientReview.patientAge = val
          if (this.outpatientReview.patientAge !== 0) {
            this.outpatientReview.patientDob = null
          }
        }
      },
      patientAgeDays: function () {
        const a = parseISO(this.outpatientReview.admitDate)
        const b = parseISO(this.outpatientReview.patientDob)
        const diff = differenceInDays(a, b)
        if (diff) {
          return `${diff} days`
        } else {
          return '0 days'
        }
      },
      disableSubClients: function () {
        if (this.dropdowns.SubClients.length < 1) {
          return true
        }
        return false
      },
      reviewLevelComputed: {
        get: function () {
          return this.outpatientReview.reviewLevel.toString()
        },
        set: function (newValue) {
          this.outpatientReview.reviewLevel = parseInt(newValue)
        }
      },
      isAsc() {
        return this.outpatientReview.outpatientGrouperId === 2
      }
    }
  }
</script>