<template>
  <div class="addMultipleCodes">
    <el-dialog class="addMultipleCodesDialog" :visible="show" :title="`Add Multiple ${type} Codes`" width="50%" :before-close="handleClose" :close-on-click-modal="false"
      :show-close="false">
      <el-row :gutter="10">
        <el-col :span="24">
          <p>
            Paste or input each code on a new line.
            <br />
            <el-switch :disabled="disabled" v-model="isRecommended" active-text="Recommended" inactive-text="Original">
            </el-switch>
          </p>
        </el-col>
      </el-row>
      <span v-if="codeError" class="errorText">{{errorText}}</span>

      <el-form size="mini" ref="form" label-position="left" label-width="120px" v-if="codeTypeRequired">
        <el-row style="margin-bottom: 10px;">
          <el-col :span="12">
            <el-form-item size="mini" label="Code Type">
              <hia-el-select :disabled="disabled" v-model="codeType" placeholder="Code Type" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list" filterable>
              </hia-el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-input :disabled="disabled" class="codeTextArea" type="textarea" :rows="16" placeholder="add codes" v-model="codeTextArea">
      </el-input>

      <span slot="footer">
        <el-button-group>
          <el-button class="hiaButton" @click="handleClose" size="mini" type="text" plain round>Cancel</el-button>
          <el-button class="hiaButton" :disabled="disabled || !codeTextArea.length" @click="postAddMultipleCodes" size="mini" plain round>Add Codes</el-button>
        </el-button-group>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'addMultipleCodes',
    props: {
      show: { type: Boolean, default: false },
      review: { type: Object },
      type: { type: String, default: 'DX' },
      disabled: { type: Boolean, default: false },
      codeTypeRequired: { type: Boolean, default: false },
      defaultCodeType: { type: Number }
    },
    data() {
      return {
        isRecommended: false,
        codeError: false,
        codeType: null,
        errorText: '',
        codeTextArea: '',
        localCodes: []
      }
    },
    mounted: function () {
    },
    created: function () {
      this.codeType = this.defaultCodeType
    },
    methods: {
      handleClose(done) {
        this.clearForm()
        this.$emit('close')
      },
      postAddMultipleCodes(e) {
        this.cleanCodes()

        if (this.codeTypeRequired && !this.codeType) {
          this.codeError = true
          this.errorText = 'Code Type must be populated to add codes \n'
          return
        }

        if (!this.codeError && this.localCodes.length) {
          const payload = {
            type: this.type,
            codes: this.localCodes,
            isRecommended: this.isRecommended,
            codeType: this.codeType
          }
          this.clearForm()
          this.$emit('postAddMultipleCodes', payload)
          this.$emit('close')
        }
      },
      cleanCodes() {
        this.codeError = false
        const codeLength = ['DX', 'PX'].some(s => s === this.type) ? 7 : 5
        const codes = this.codeTextArea.split('\n')
        this.errorText = 'Invalid Code Length: \n'
        if (codes.length) {
          // check code length - DX,PX =6, CPT = 7
          codes.forEach((v, i) => {
            codes[i] = v.replace(/[^a-z0-9]/gi, '').trim().toUpperCase()
            if (codes[i].length > codeLength) {
              this.errorText += `Line# ${i + 1} - ${v} \n`
              this.codeError = true
            }
          })
          this.localCodes = codes.filter(f => f.length)
        }
      },
      clearForm() {
        this.errorText = ''
        this.codeTextArea = ''
        this.codeError = false
        this.isRecommended = false
        this.localCodes = []
      }
    },
    computed: {
      ...mapState('dropdowns/', ['dropdowns'])
    },
    watch: {
    }
  }

</script>

<style scoped>
  .errorText {
    white-space: pre;
    color: rgb(245, 108, 108);
  }

  >>>.codeTextArea textarea {
    text-transform: uppercase;
  }
</style>
