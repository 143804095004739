<template>
  <div>
    <transition-group name="fade">
      <el-form ref="form" id="form" label-position="left" label-width="250px" size="mini" key="1">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Coder Response Link" v-if="recordType === 'IP'">
              <span class="link-message" v-if="!inpatientReview.clientCoder">Link not available, no coder assigned to record</span>
              <span class="link-message" v-if="inpatientReview.clientCoder && !inpatientReview.clientCoder.user">Link not available, assigned coder {{ inpatientReview.clientCoder.name }} does not have an associated user account</span>

              <div v-if="inpatientReview.clientCoder && inpatientReview.clientCoder.user" style="margin: 5px 0px 5px 0px; font-size: 13px;">
                <a :href="`/my-projects/inpatient/${recordId}`" target="_blank">{{ getCoderLinkLabel() }}</a>
                <input style="display: none;" type="text" ref="coderLink" :value="getCoderLinkLabel()" />
                <i style="margin-left: 10px; cursor: pointer; color:#606266;" title="Copy to clipboard" class="mdi mdi-content-copy" @click="copyLink()"></i>
              </div>
            </el-form-item>

            <el-form-item label="Coder Response Link" v-if="recordType === 'OP'">
              <span class="link-message" v-if="!outpatientReview.clientCoder">Link not available, no coder assigned to record</span>
              <span class="link-message" v-if="outpatientReview.clientCoder && !outpatientReview.clientCoder.user">Link not available, assigned coder {{ outpatientReview.clientCoder.name }} does not have an associated user account</span>

              <div v-if="outpatientReview.clientCoder && outpatientReview.clientCoder.user" style="margin: 5px 0px 5px 0px; font-size: 13px;">
                <a :href="`/my-projects/outpatient/${recordId}`" target="_blank">{{ getCoderLinkLabel() }}</a>
                <input style="display: none;" type="text" ref="coderLink" :value="getCoderLinkLabel()" />
                <i style="margin-left: 10px; cursor: pointer; color:#606266;" title="Copy to clipboard" class="mdi mdi-content-copy" @click="copyLink()"></i>
              </div>
            </el-form-item>

            <el-form-item label="Response Link" v-if="recordType === 'PRO'">
              <span class="link-message" v-if="!proReview.clientCoder">Link not available, no coder assigned to record</span>
              <span class="link-message" v-if="proReview.clientCoder && !proReview.clientCoder.user">Link not available, assigned coder {{ proReview.clientCoder.name }} does not have an associated user account</span>

              <div v-if="proReview.clientCoder && proReview.clientCoder.user" style="margin: 5px 0px 5px 0px; font-size: 13px;">
                <a :href="`/my-projects/pro/${recordId}`" target="_blank">{{ getCoderLinkLabel() }}</a>
                <input style="display: none;" type="text" ref="coderLink" :value="getCoderLinkLabel()" />
                <i style="margin-left: 10px; cursor: pointer; color:#606266;" title="Copy to clipboard" class="mdi mdi-content-copy" @click="copyLink()"></i>
              </div>
            </el-form-item>
          </el-col>

          <template>
            <el-col v-show="!newMode" :lg="12" :sm="24">
              <el-form-item label="Response Date">
                <el-date-picker :disabled="disabled" :clearable="false" size="mini" type="date" id="rebuttalDate" v-model="rebuttal.rebuttalDate" @change="putRebuttal(rebuttal)" format="MM/dd/yyyy"> </el-date-picker>
              </el-form-item>
              <el-form-item :label="recordType === 'PRO' ? 'Coder/Provider agrees with changes' : 'Coder agrees with changes'">
                <el-col style="margin-bottom: 6px;"> <input :disabled="disabled" type="radio" name="codes" :value="true" v-model="rebuttal.agreeWithCodes" v-on:change="putRebuttal(rebuttal)" /> Yes <input :disabled="disabled" type="radio" name="codes" :value="false" v-model="rebuttal.agreeWithCodes" v-on:change="putRebuttal(rebuttal)" /> No </el-col>
              </el-form-item>
              <el-form-item label="Auditor Response Required">
                <el-col> <input :disabled="disabled" type="radio" name="auditor" :value="true" v-model="rebuttal.auditorResponseRequired" @change="putRebuttal(rebuttal)" /> Yes <input :disabled="disabled" type="radio" name="auditor" :value="false" v-model="rebuttal.auditorResponseRequired" @change="putRebuttal(rebuttal)" /> No </el-col>
              </el-form-item>
              <el-form-item label="Final Outcome">
                <hia-el-select :disabled="disabled" v-model="rebuttal.codesStatus" placeholder="Rebuttal Status" @input="putRebuttal(rebuttal)" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RebuttalStatus.list"></hia-el-select>
              </el-form-item>
            </el-col>
          </template>
        </el-row>

        <template>
          <el-row v-show="!newMode" :gutter="20">
            <el-col :span="24">
              <h4 class="headerWithBorder" style="margin-top: 20px;"><strong>Comments</strong></h4>
              <div style="margin-left: 20px;">
                <div v-for="comment in rebuttal.rebuttalComments" :key="comment.commentId">
                  {{ comment.user.fullName }} on <i>{{ format(parseISO(comment.createdDate), 'M/d/yyyy hh:mm a') }}</i> <span style="font-weight: bold;" :title="format(parseISO(comment.modifiedDate), 'M/d/yyyy hh:mm a')" v-if="comment.edited"><small>edited</small></span>

                  <HiaRichText :disabled="disabled" @change="putComment(comment)" :editable="user.auth0Id === comment.user.auth0Id" v-model="comment.comment" :title="`${comment.user.fullName} on ${format(parseISO(comment.createdDate), 'M/d/yyyy hh:mm a')}`"> </HiaRichText>
                </div>

                <div style="margin: 30px 0px 0px 0px;" v-loading="loading" element-loading-spinner="atom-audit-loader">
                  <Tinymce :disabled="disabled" placeholder="Add comment" id="responseDocumentation" v-model="newComment" :imagePath="tinyMceImagePath" height="250"> </Tinymce>
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
      </el-form>
    </transition-group>
    <el-button icon="el-icon-plus" :disabled="disabled" style="margin: 10px 0px 12px 0px;" v-loading="loading" element-loading-spinner="atom-audit-loader" v-if="newMode" class="hiaButton" plain round @click="postRebuttal" size="mini">Add Response</el-button>
    <el-popover ref="savePopover" style="word-break: normal;margin-left: 10px;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" content="Comment field is empty" v-model="showSavePopover" @after-enter="handleHidePopOver('savePopover')">
      <el-button icon="el-icon-plus" style="margin: 20px 0px 12px 15px;" slot="reference" v-if="!newMode" class="hiaButton" plain round size="mini" @click="addComment">Add Comment</el-button>
    </el-popover>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { parseISO, format } from 'date-fns'
  import dateHelper from '@/mixins/date-helper'
  import Tinymce from '@/components/controls/Tinymce'
  import HiaRichText from '@/components/controls/HiaRichText'

  export default {
    name: 'Rebuttals',
    mixins: [dateHelper],
    props: {
      recordType: {
        type: String,
        required: true,
        validator: value => {
          return ['IP', 'OP', 'PRO'].indexOf(value) !== -1
        }
      },
      disabled: {
        type: Boolean
      },
      recordId: {
        type: Number
      }
    },
    data: function() {
      return {
        format: format,
        parseISO: parseISO,
        loading: false,
        newMode: true,
        newComment: '',
        showSavePopover: false,
        dropdownsNeeded: ['RebuttalStatus']
      }
    },
    methods: {
      ...mapMutations('rebuttals', ['SET_REBUTTAL']),
      ...mapActions('rebuttals/', ['POST_REBUTTAL', 'DELETE_REBUTTAL', 'PUT_REBUTTAL', 'POST_REBUTTAL_COMMENT', 'PUT_REBUTTAL_COMMENT']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      initialize() {
        this.GET_DROPDOWNS(this.dropdownsNeeded).then(() => {
          if (this.rebuttal && this.rebuttal.rebuttalId) {
            this.newMode = false
          }
          this.loading = false
        })
      },
      async putComment(comment) {
        try {
          this.loading = true
          await this.PUT_REBUTTAL_COMMENT(comment)
          this.loading = false
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Error Editing Comment',
            type: 'error'
          })
          console.log(e)
        }
      },
      async addComment() {
        if (!this.newComment) {
          this.showSavePopover = true
          return
        }

        try {
          this.loading = true
          await this.POST_REBUTTAL_COMMENT({
            rebuttalId: this.rebuttal.rebuttalId,
            comment: this.newComment
          })
          this.loading = false
          this.newComment = null
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Error Adding Comment',
            type: 'error'
          })
          console.log(e)
        }
      },
      async putRebuttal() {
        try {
          await this.PUT_REBUTTAL(this.rebuttal)

          this.$message({
            message: 'Coder Response Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: 'Error Updating Coder Response',
            type: 'error'
          })
        }
      },
      async postRebuttal() {
        this.SET_REBUTTAL({
          agreeWithCodes: true,
          rebuttalDate: new Date().toISOString(),
          drgTableId: this.recordType === 'IP' ? this.recordId : null,
          proRecordId: this.recordType === 'PRO' ? this.recordId : null,
          ascTableId: this.recordType === 'OP' ? this.recordId : null,
          rebuttalComments: []
        })

        try {
          this.loading = true
          await this.POST_REBUTTAL(this.rebuttal)
          this.newMode = false
          this.loading = false
          this.$message({
            message: 'Coder Response Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: 'Error Updating Coder Response',
            type: 'error'
          })
        }
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      getCoderLinkLabel() {
        if (this.recordType === 'IP') {
          return `${window.location.origin}/my-projects/inpatient/${this.recordId}`
        }
        if (this.recordType === 'OP') {
          return `${window.location.origin}/my-projects/outpatient/${this.recordId}`
        }
        if (this.recordType === 'PRO') {
          return `${window.location.origin}/my-projects/pro/${this.recordId}`
        }
      },
      copyLink() {
        const tempInput = document.createElement('input')
        tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
        tempInput.value = this.getCoderLinkLabel()
        document.body.appendChild(tempInput)
        tempInput.select()
        const copied = document.execCommand('copy')
        document.body.removeChild(tempInput)

        if (copied) {
          this.$message({
            message: 'Coder Link Copied',
            type: 'success'
          })
        }
      }
    },
    mounted: function() {
      this.initialize()
    },
    watch: {
      rebuttal: {
        deep: true,
        handler: function() {
          if (this.rebuttal && this.rebuttal.rebuttalId) {
            this.newMode = false
          } else {
            this.newMode = true
          }
        }
      }
    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview']),
      ...mapState('proReviews/', ['proReview']),
      ...mapState('outpatientReviews/', ['outpatientReview']),
      ...mapState('rebuttals/', ['rebuttal']),
      ...mapState('user/', ['user']),
      ...mapState('dropdowns/', ['dropdowns']),
      tinyMceImagePath() {
        return 'reviews/rebuttals/' + this.recordType + '/' + this.recordId + '/'
      }
    },
    components: {
      Tinymce,
      HiaRichText
    }
  }
</script>

<style scoped>
  >>> .VuePagination__count {
    display: none;
  }

  >>> .active {
    background: rgba(255, 196, 12, 0.2) !important;
  }

  .commentRichText {
    border: 1px solid #dcdfe6;
    background: #e5eef4;
    border-radius: 4px;
    padding: 5px;
    margin: 10px 0 20px 0px;
    min-height: 30px;
  }

  button >>> .el-loading-mask {
    margin-top: -42px;
  }

  button >>> .atom-audit-loader {
    background-size: 75px 75px;
  }

  .link-message {
    margin: 3px 0px;
    background-color: #ecf5ff;
    display: inline-block;
    padding: 3px 10px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 3px;
  }
</style>
