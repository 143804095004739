const inpatientHelper = {
  methods: {
    getGroupingResultValue(reviewType, reviewLevel, value, groupingResults) {
      if (!groupingResults) {
        return
      }

      const gr = groupingResults.filter(function (obj) {
        return (obj.reviewLevel === reviewLevel) && (obj.grouperType === this.grouper)
      }, { grouper: this.convertDrgTypeToGrouperType(reviewType) })[0]
      if ((gr !== undefined)) {
        return gr[value]
      } else {
        return ''
      }
    },
    convertDrgTypeToGrouperType(ipReviewType) {
      let grouperType = 'CMS'
      if ((ipReviewType === 'APR-DRG') || (ipReviewType === 'APR')) {
        grouperType = 'APR'
      } else if ((ipReviewType === 'AP-DRG') || (ipReviewType === 'AP')) {
        grouperType = 'AP'
      } else if (ipReviewType === 'TRICARE') {
        grouperType = 'TRICARE'
      }
      return grouperType
    }
  }
}

export default inpatientHelper
