<template>
  <div>
    <el-tabs v-model="tabActiveName" @tab-click="handleClick">
      <el-tab-pane :disabled="diabledTab" label="Original" name="original"></el-tab-pane>
      <el-tab-pane :disabled="diabledTab" label="Recommended" name="recommended"></el-tab-pane>
      <!-- <el-tab-pane :disabled="!isLevel2" label="Level 2" name="level2"></el-tab-pane> -->
    </el-tabs>
    <el-table :data="truCodeData" v-loading="principalDxLoader" element-loading-spinner="atom-audit-loader" style="width: 100%; font-size: 12px;">
      <el-table-column label="Line" width="80">
        <template slot-scope="scope">
          {{(scope.$index + 1)}}
        </template>
      </el-table-column>
      <el-table-column prop="pdx" label="Code" width="100"></el-table-column>
      <el-table-column prop="external_tag" label="Description"></el-table-column>
      <!-- <el-table-column class-name="tableWrap" prop="mdc_description" label="Description" :formatter="escapeHtml"></el-table-column> -->
      <el-table-column prop="drg" label="DRG" width="70"></el-table-column>
      <el-table-column class-name="tableWrap" prop="drg_description" label="DRG Description" :formatter="escapeHtml"></el-table-column>
      <el-table-column prop="drg_weight" label="DRG Weight" width="120"></el-table-column>
      <el-table-column prop="drg_reimbursement" label="Reimbursement" :formatter="drgReimbursementFormat" width="130"></el-table-column>
      <!-- <el-table-column prop="drg_reimbursement" label="Reimbursement" width="130"></el-table-column> -->
    </el-table>
  </div>
</template>

<script>
  import money from '@/mixins/money'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'principalDx',
    props: {
      show: { type: Boolean, default: false }
    },
    mixins: [money],
    data() {
      return {
        principalDxData: {},
        truCodeData: [],
        reviewMode: 'original',
        principalDxLoader: false,
        tabActiveName: 'original',
        isLevel2: false,
        drgtableId: 0,
        diabledTab: false,
        principalDxParent: ''
      }
    },
    mounted: function () {
      this.initialize()
    },
    methods: {
      ...mapActions('inpatientReviews/', ['GET_PRINCIPALDXANALYZE']),
      initialize() {
        this.drgtableId = this.inpatientReview.drgTableId
        this.fetchPrincipalDxAnalyze()
      },
      async fetchPrincipalDxAnalyze() {
        try {
          this.principalDxLoader = true
          const payload = {
            drgtableId: this.drgtableId
          }
          const response = await this.GET_PRINCIPALDXANALYZE(payload)
          // update vuex rebuttal - rebuttals are managed in vuex after this call
          this.principalDxData = response

          // set principal dx data
          this.setPrincipalDxAnalyzeData()
          this.principalDxLoader = false
        } catch (e) {
          // show error message
          this.principalDxLoader = false
          console.log(e)
        }
      },
      setPrincipalDxAnalyzeData() {
        switch (this.reviewMode) {
          case 'original':
            try {
              this.truCodeData = JSON.parse(this.principalDxData.original)
              this.truCodeData = this.truCodeData.pdx_analysis_results !== null ? this.truCodeData.pdx_analysis_results.pdx_analysis_result : []
              this.principalDxLoader = false
              this.tabActiveName = 'original'
            } catch (er) {
              this.principalDxLoader = false
              this.diabledTab = true
            }
            break
          case 'recommended':
            try {
              this.truCodeData = JSON.parse(this.principalDxData.recommended)
              this.truCodeData = this.truCodeData.pdx_analysis_results !== null ? this.truCodeData.pdx_analysis_results.pdx_analysis_result : []
              this.principalDxLoader = false
              this.tabActiveName = 'recommended'
            } catch (er) {
              this.principalDxLoader = false
              this.diabledTab = true
            }
            break
          case 'level2':
            try {
              this.truCodeData = JSON.parse(this.principalDxData.level2)
              this.truCodeData = this.truCodeData.pdx_analysis_results !== null ? this.truCodeData.pdx_analysis_results.pdx_analysis_result : []
              this.principalDxLoader = false
              this.tabActiveName = 'level2'
            } catch (er) {
              this.principalDxLoader = false
              this.diabledTab = true
            }
            break
        }
      },
      setupLocalStorageListener() {
        this.$ls.on('reviewMode', storedData => {
          this.reviewMode = storedData
          this.setPrincipalDxAnalyzeData()
        })
      },
      handleClick(tab, event) {
        this.reviewMode = tab.name
        this.setPrincipalDxAnalyzeData()
      },
      decode(str) {
        const txt = document.createElement('textarea')
        txt.innerHTML = str
        return txt.value
      },
      escapeHtml(row, column) {
        return this.decode(row[column.property])
      },
      drgReimbursementFormat(row, column) {
        return '$' + this.formatMoney(row.drg_reimbursement, 2)
      }

    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview'])
    },
    watch: {
      show() {
        if (this.show) {
          this.initialize()
        }
      }
    }
  }

</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style>
/*.tableWrap .cell{
  word-break: keep-all;
}*/
</style>
