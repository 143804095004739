<template>
  <el-row :gutter="10">
    <el-col :lg="toggleReseach && isTrucodeEnabled() ? 18 : 24" :md="24" :sm="24">
      <el-col :span="16">
        <h3 class="el-page-header">Review Details</h3>
        <div v-if="!loading">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/projects/' }">Projects</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/projects/${proReview.projectID}` }"> {{ proReview.project.projectID }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/reviews/pro/${$route.params.proRecordId}` }"> {{ proReview.patientNumber }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="24" v-if="loading">
        <div style="margin-top: 20px;" v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
      </el-col>
      <el-col class="top-margin" v-show="!loading">
        <el-card style="padding: 0px 0px 20px 0px;">
          <el-col style="margin: 0px 0px 10px 0px;" :span="24" v-if="!loading">
            <el-button-group class="pull-right" id="proButtons">
              <el-dropdown @command="handleStatus">
                <el-button class="hiaButton" size="mini" :type="getStatusButtonType(proReview)" plain round><i :class="getCurrentReviewStatus(proReview).iconClass"
                    style="margin-right:4px;"></i>{{ getCurrentReviewStatus(proReview).status }}</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :disabled="status.reviewStatusId === proReview.reviewStatusId || disabled || (isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4))"
                    v-for="status in reviewStatus" :key="status.reviewStatusId" :command="status.reviewStatusId"> <i :class="status.iconClass"></i>{{ status.status }} {{
                    isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4) ? ` - Validation Errors` : `` }} </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button :disabled="!isValidationError()" :class="!isValidationError() ? 'hiaButton' : ''" :type="isValidationError() ? 'danger' : ''" size="mini"
                @click="toggleRequiredFieldsErrorList = true" plain round :title="isValidationError() ? 'validation errors' : 'record is valid'"> <i
                  :class="isValidationError() ? 'mdi mdi-alert' : 'mdi mdi-check-bold greenIcon'"></i> Validations</el-button>
              <el-button class="hiaButton" icon="el-icon-document" size="mini" @click="openWorksheet" plain round>Worksheet</el-button>
              <el-button class="hiaButton" icon="el-icon-document" size="mini" @click="physicianFeeScheduleVisible = true" plain round>Phys Fee Schedule</el-button>
              <el-button class="hiaButton" icon="el-icon-collection" v-if="isTrucodeEnabled()" size="mini" @click="openCodeBooks()" plain round>Codebook</el-button>
            </el-button-group>
          </el-col>
          <el-col>
            <el-collapse id="reviewAccordion" v-model="activeNames" class="ipCollapse">
              <el-collapse-item title="Demographics" name="1">
                <proReviewDemographics ref="proDemographics" class="collapseContent" @requiredFieldsValidation="requiredFieldsValidation"></proReviewDemographics>
              </el-collapse-item>
              <el-collapse-item title="History" name="history" id="history">
                <template slot="title">
                  <span style="width: 100%;">History
                    <template v-if="!proReview.decisionMaking2021 && !proReview.decisionMaking2023">
                      <span class="collapse-header">{{ getHistoryByLevel(1).historyLevel }}
                        <span v-if="isLevel2"> / {{ getHistoryByLevel(2).historyLevel }}</span>
                      </span>
                    </template>
                  </span>
                </template>
                <proReviewHistory :disabled="readOnly() || proReview.reviewStatusId !== 2" class="collapseContent" :isLevel2="isLevel2"> </proReviewHistory>
              </el-collapse-item>

              <el-collapse-item title="ExamNew" name="exam" id="examNew">
                <template slot="title">
                  <span style="width: 100%;">Exam
                    <template v-if="!proReview.decisionMaking2021 && !proReview.decisionMaking2023">
                      <span class="collapse-header">{{ getExamByLevelandExamType97(1, proReview.level1ExamTypeId).examScore }}
                        <span v-if="isLevel2"> / {{ getExamByLevelandExamType97(2, proReview.level2ExamTypeId).examScore }}</span>
                      </span>
                    </template>
                  </span>
                </template>
                <el-row>
                  <el-col :span="isLevel2 ? 12 : 24">
                    <el-card>
                      <proReviewExam97 :key="proReview.proRecordId" :disabled="readOnly() || proReview.reviewStatusId !== 2" :codeLevel="1" :details="proReview.examDetails"
                        :defaultExamType="proReview.level1ExamTypeId" :loadingComplete="loadingComplete"></proReviewExam97>
                    </el-card>
                  </el-col>
                </el-row>
              </el-collapse-item>

              <el-collapse-item title="Decision Making" name="decisionMaking" id="decisionMaking" v-if="!proReview.decisionMaking2021 && !proReview.decisionMaking2023">
                <template slot="title">
                  <span style="width: 100%;">Decision Making
                    <span class="collapse-header">{{ getDecisionMakingByLevel(1).decisionComplexity }}
                      <span v-if="isLevel2"> / {{ getDecisionMakingByLevel(2).decisionComplexity }}</span>
                    </span>
                  </span>
                </template>
                <proReviewDecisionMaking class="collapseContent" :disabled="readOnly() || proReview.reviewStatusId !== 2" :history="getHistoryByLevel(1)"
                  :level2History="getHistoryByLevel(2)" :exam="getExamByLevelandExamType97(1, proReview.level1ExamTypeId)"
                  :level2Exam="getExamByLevelandExamType97(2, proReview.level2ExamTypeId)" :decisionMaking="getDecisionMakingByLevel(1)"
                  :level2DecisionMaking="getDecisionMakingByLevel(2)" :examLevel="getExamByLevelandExamType97(1, proReview.level1ExamTypeId).examScore"
                  :level2ExamLevel="getExamByLevelandExamType97(2, proReview.level2ExamTypeId).examScore" :isLevel2="isLevel2"> </proReviewDecisionMaking>
              </el-collapse-item>

              <el-collapse-item :title="proReview.decisionMaking2023 ? 'Decision Making 2023' : proReview.decisionMaking2021 ? 'Decision Making 2021' : ''" name="d2021"
                id="decisionMaking" v-if="proReview.decisionMaking2021 || proReview.decisionMaking2023">
                <template slot="title">
                  <span style="width: 100%;">{{ proReview.decisionMaking2023 ? 'Decision Making 2023' : proReview.decisionMaking2021 ? 'Decision Making 2021' : '' }}
                    <span class="collapse-header">{{ getDecisionMakingByLevel(1).decisionComplexity2021 }}
                      <span v-if="isLevel2"> / {{ getDecisionMakingByLevel(2).decisionComplexity2021 }}</span>
                    </span>
                  </span>
                </template>
                <proReviewDecisionMaking2021 :disabled="readOnly() || proReview.reviewStatusId !== 2" class="collapseContent" :decisionMaking="getDecisionMakingByLevel(1)"
                  :level2DecisionMaking="getDecisionMakingByLevel(2)" :examLevel="getExamByLevelandExamType97(1, proReview.level1ExamTypeId).examScore"
                  :level2ExamLevel="getExamByLevelandExamType97(2, proReview.level2ExamTypeId).examScore" :isLevel2="isLevel2"></proReviewDecisionMaking2021>
              </el-collapse-item>

              <el-collapse-item title="Checklists" name="10" v-if="dropdowns.clientChecklistsPro.list.length > 0">
                <template slot="title">
                  <span style="width: 100%;">
                    Checklists
                    <span class="codeTotalHeader"> - {{ auditChecklists.length }} Items</span>
                  </span>
                </template>
                <ApplyChecklist recordType="PRO" :disabled="readOnly() || proReview.reviewStatusId !== 2"></ApplyChecklist>
              </el-collapse-item>

              <el-collapse-item name="2">
                <template slot="title">
                  <span style="width: 100%;">
                    Diagnosis Codes
                    <span class="codeTotalHeader"> - {{ proReview.proRecordDxs.length }} Total</span>
                    <span @click.stop="1 === 1" style="float: right; height:30px; " v-show="activeNames.some(s => s === '2')">
                      <el-dropdown ref="dxDropdown" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <span class="actionsHamburger mdi mdi-menu"></span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="addMultipleCodes">Add Multiple Codes</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </template>
                <proReviewDx @onCodeFocus="handleDxPxGridCodeFocus" @ellipsisClick="handleEllipsisClick" @handleCloseAddMultipleCodes="handleCloseAddMultipleCodes"
                  @handleClosePoaAssignments="handleClosePoaAssignments" :showAddMultipleCodes="showAddMultipleCodes" :showPoaAssignments="showPoaAssignments"
                  @handleSaveToLocalStorageForTruCode="saveToLocalStorageForTruCode"> </proReviewDx>
              </el-collapse-item>
              <el-collapse-item title="CPT Codes" name="3">
                <template slot="title">
                  <span style="width: 100%;">
                    CPT Codes
                    <span class="codeTotalHeader"> - {{ proReview.proRecordCpts.length }} Total</span>
                    <span @click.stop="1 === 1" style="float: right; height:30px;" v-show="activeNames.some(s => s === '3')">
                      <el-dropdown ref="cptDropdown" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <span class="actionsHamburger mdi mdi-menu"></span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="cptAddMultipleCodes">Add Multiple Codes</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </template>
                <proReviewCpts @onCodeFocus="handleDxPxGridCodeFocus" @ellipsisClick="handleEllipsisClick" @handleCloseAddMultipleCodes="handleCloseAddMultipleCodes"
                  :showAddMultipleCodes="showCptAddMultipleCodes" @handleSaveToLocalStorageForTruCode="saveToLocalStorageForTruCode"></proReviewCpts>
              </el-collapse-item>

              <el-collapse-item name="8">
                <template slot="title">
                  <span style="width: 100%;">
                    Audit Findings
                    <span class="codeTotalHeader"> - {{ auditFindings ? auditFindings.length : 0 }} Findings</span>
                  </span>
                </template>
                <AuditFindings :disabled="readOnly()" class="collapseContent" :recordId="proReview.proRecordId" recordType="PRO"> </AuditFindings>
              </el-collapse-item>
              <el-collapse-item name="9">
                <template slot="title">
                  <span style="width: 100%;">
                    Responses
                    <span class="codeTotalHeader"> - {{ proReview.rebuttal ? proReview.rebuttal.rebuttalComments.length : 0 }} Comments</span>
                  </span>
                </template>
                <Rebuttals :disabled="readOnly()" style="margin: 5px 0px 0px 10px;" class="collapseContent" :recordId="proReview.proRecordId" recordType="PRO"> </Rebuttals>
              </el-collapse-item>
            </el-collapse>
            <proReviewFooter ref="proReviewFooter" style="margin: 30px 0px 30px 0px;" @handleTableCommands="handleTableCommands"></proReviewFooter>
          </el-col>
        </el-card>
      </el-col>
    </el-col>
    <el-col class="sticky" v-if="isTrucodeEnabled()" :lg="6" :md="24" :sm="24" v-show="toggleReseach">
      <ResearchPane v-if="!loading" :review="proReview" :isLevel2="isLevel2" :truCodeAuthKey="proReview.truCodeAuthKey" :truCodeQuery="truCodeQuery"
        :showReviewRadioButtons="true" :parent="'pro'" @onChangedFieldResearchPane="handleChangedFieldResearchPane" @onReferenceClick="handleTruCodeReferenceClick"
        @contentClicked="handleTruCodeCodeClick" @onReviewMode="handleReviewMode" encounterType="pro" :changedFieldResearchPane="changedFieldResearchPane"> </ResearchPane>
    </el-col>
    <div class="dialogs">
      <physicianFeeSchedule v-if="physicianFeeScheduleVisible" id="physicianFeeScheduleDialog" :proRecordId="proReview.proRecordId" :showDialog="physicianFeeScheduleVisible"
        @closeDialog="physicianFeeScheduleVisible = false"> </physicianFeeSchedule>
      <el-dialog :visible.sync="showInProgressWarning" title="Confirm Record Status" width="30%" :close-on-click-modal="false" :show-close="true" v-if="!loading"
        :before-close="cancelStatusConfirm">
        <div v-loading="confirmStatusLoading" element-loading-spinner="atom-audit-loader">
          <el-row>
            <el-col :span="12">
              <div v-for="status in reviewStatus" :key="status.reviewStatusId" style="margin: -15px 0px 20px 15px;">
                <el-radio :disabled="isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4)" v-model="confirmStatusId"
                  :label="status.reviewStatusId"> <i :class="status.iconClass" style="margin-right: 6px;"></i>{{ status.status }} </el-radio>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="text-align: center; margin: -30px 0px 0px 0px;" v-if="isValidationError()">
                <p style="font-weight: bold; margin: 0px 0px 0px 0px;font-size: 15px;">Validation Errors</p>
                <p style="margin: 5px 0px 5px 0px;word-break: break-word;">The following validations are required for these statuses:</p>
                <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;">
                  <li style="text-align: left;"><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
                  <li style="text-align: left;"><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
                </ul>
                <el-button type="danger" size="mini" @click="toggleRequiredFieldsErrorList = true" plain round> <i class="mdi mdi-alert"></i> Validations</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div style="margin: 40px 0px 0px 0px;height: 15px;" class="inpatientButtons">
          <el-button-group style="float: right; padding-bottom: 20px;">
            <el-button class="hiaButton" plain round type="text" @click="cancelStatusConfirm">Cancel</el-button>
            <el-button class="hiaButton" plain round @click="handleConfirmStatus">Continue</el-button>
          </el-button-group>
        </div>
      </el-dialog>
      <el-dialog title="Required Fields" :visible.sync="toggleRequiredFieldsErrorList" width="50%">
        <div>
          <p style="margin: 5px 0px 5px 0px;word-break: break-word;">The following validations are required for these statuses:</p>
          <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;">
            <li><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
            <li><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
          </ul>
          <el-table :data="requiredFieldsErrorList">
            <el-table-column prop="group" label="Group" width=""></el-table-column>
            <el-table-column prop="fieldLabel" label="Field" width=""></el-table-column>
            <el-table-column prop="message" label="Error Message" width=""></el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </el-row>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { format, parseISO, isDate } from 'date-fns'
  import proReviewDemographics from './proReviewDemographics'
  import proReviewDx from './proReviewDx'
  import proReviewCpts from './proReviewCpts'
  import proReviewHistory from './proReviewHistory'
  import proReviewExam97 from './proReviewExam97'
  import proReviewDecisionMaking from './proReviewDecisionMaking'
  import proReviewDecisionMaking2021 from './proReviewDecisionMaking2021'
  import physicianFeeSchedule from './physicianFeeSchedule'
  import Rebuttals from '../Rebuttals'
  import ResearchPane from '@/components/controls/ResearchPane'
  import truCodeHelper from '@/mixins/trucode-helper'
  import reviewStatusHelper from '@/mixins/reviewStatusHelper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import AuditFindings from '../AuditFindings'
  import proReviewFooter from './proReviewFooter'
  import csvGenerator from '@/mixins/csvGenerator'
  import ApplyChecklist from '../ApplyChecklist'

  export default {
    name: 'ProReview',
    mixins: [truCodeHelper, HiaAuthorization, reviewStatusHelper, csvGenerator],
    data: function () {
      return {
        toggleRequiredFieldsErrorList: false,
        requiredFieldsErrorList: [],
        requiredFieldAlertToggle: false,
        togglePrincipalDxAnalysis: false,
        showPxAddMultipleCodes: false,
        showAddMultipleCodes: false,
        showCptAddMultipleCodes: false,
        showPoaAssignments: false,
        codebooksWindow: undefined,
        referencesWindow: undefined,
        worksheetWindow: undefined,
        physicianFeeScheduleVisible: false,
        changedFieldResearchPane: '',
        truCodeQuery: {},
        floatResearch: true,
        toggleReseach: true,
        activeNames: [],
        loading: true,
        loadingComplete: false,
        format: format,
        parseISO: parseISO,
        showInProgressWarning: false,
        confirmStatusId: 0,
        confirmStatusLoading: false,
        routeNext: () => { },
        dropdownsNeeded: ['reviewLevels', 'consultants', 'oneToFour', 'pointOfOrigin', 'dcSummaryAvailableAtCoding', 'dcSummaryAvailableAtReview', 'patientStatus', 'payors', 'patientSex', 'allReasonsForChange', 'proReasonsForChange', 'proReasonsForChangeHeader', 'proReasonsForChangeDx', 'proReasonsForChangeCpt', 'proReasonsForChangeMod', 'reviewStatus', 'clients', 'RebuttalStatus', 'placeOfService', 'ProPatientStatus', 'proExam_ExamTypes', 'proExam_ExamTypesSystemAreasCrossover', 'proExam_SystemAreas', 'ProRiskDefinitionsPP', 'ProRiskDefinitionsDPO', 'ProRiskDefinitionsMOS', 'ProRiskDefinitionsPP2021', 'ProRiskDefinitionsMOS2021', 'modifiers', 'codeTypes', 'recordTags', 'clientChecklistsPro']
      }
    },
    components: {
      proReviewDemographics,
      proReviewDx,
      proReviewCpts,
      proReviewHistory,
      proReviewExam97,
      proReviewDecisionMaking,
      proReviewDecisionMaking2021,
      ApplyChecklist,
      AuditFindings,
      Rebuttals,
      ResearchPane,
      physicianFeeSchedule,
      proReviewFooter
    },
    methods: {
      ...mapActions('proReviews/', ['GET_PRO_REVIEW', 'POST_PRO_DX', 'PUT_PRO_DX', 'POST_PRO_CPT', 'PUT_PRO_CPT', 'GET_EXAM_ELEMENTS', 'PUT_PRO_REVIEW']),
      ...mapMutations('rebuttals/', ['SET_REBUTTAL']),
      ...mapMutations('auditFindings/', ['SET_FINDINGS']),
      ...mapMutations('auditChecklists/', ['SET_CHECKLISTS']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      async initialize() {
        try {
          this.loading = true
          await Promise.all([this.GET_PRO_REVIEW(this.$route.params.proRecordId), this.GET_DROPDOWNS(this.dropdownsNeeded), this.GET_EXAM_ELEMENTS()])
          // update vuex rebuttal - rebuttals and findings are managed in vuex after this call
          this.SET_REBUTTAL(this.proReview.rebuttal)
          this.SET_FINDINGS(this.proReview.auditFindings)
          this.SET_CHECKLISTS(this.proReview.checklistItems)
          this.saveToLocalStorageForTruCode(this.proReview)
          this.loadingComplete = true
          this.loading = false
          this.requiredFieldsValidation()
        } catch (e) {
          // show error message
          this.loading = false
          console.log(e)
        }
      },

      async handleStatus(command) {
        try {
          // on Complete check valid required fields
          if (command === 4 || command === 3) {
            this.requiredFieldsValidation()
          }

          if (!this.isValidationError() || command === 2 || command === 1) {
            this.proReview.reviewStatusId = parseInt(command)
            const payload = { proReview: this.proReview, changedField: 'reviewStatus' }
            await this.PUT_PRO_REVIEW(payload)
            this.saveToLocalStorageForTruCode(this.proReview)
            this.$message({
              message: 'Record Updated Successfully',
              type: 'success'
            })
            // this.$refs.proDemographics.$refs.form.clearValidate()
          }
        } catch (e) {
          this.$message({
            message: 'Record Update Error',
            type: 'error'
          })
        }
      },
      cancelStatusConfirm() {
        this.showInProgressWarning = false
      },
      async handleConfirmStatus() {
        this.confirmStatusLoading = true
        try {
          const copy = JSON.parse(JSON.stringify(this.proReview))
          copy.reviewStatusId = this.confirmStatusId
          const payload = { proReview: copy, changedField: 'reviewStatus' }
          await this.PUT_PRO_REVIEW(payload)
          this.saveToLocalStorageForTruCode(this.proReview)
          this.showInProgressWarning = false
          this.confirmStatusLoading = false
          this.routeNext(true)
          this.$message({
            message: 'Record Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.confirmStatusLoading = false
          this.$message({
            message: 'Record Update Error',
            type: 'error'
          })
        }
      },
      getHistoryByLevel(level) {
        const history = this.proReview.proRecordHistory.find(x => x.codeLevel === level)
        if (history) {
          return history
        }
        return { historyLevel: null }
      },
      getExamByLevelandExamType97(level, examTypeId) {
        const exam = this.proReview.examScoring.find(x => x.codeLevel === level && x.examTypeId === examTypeId)
        if (exam) {
          return exam
        }
        return {
          examScore: 'Unknown'
        }
      },
      getDecisionMakingByLevel(level) {
        const decisionMaking = this.proReview.proRecordDecisionMaking.find(x => x.codeLevel === level)
        if (decisionMaking) {
          return decisionMaking
        }
        return { decisionComplexity: null }
      },
      formatDate(date) {
        if (!date) {
          return ''
        }

        if (isDate(date)) {
          return format(date, 'MM-dd-yyyy')
        }

        return format(parseISO(date), 'MM-dd-yyyy')
      },
      toggleResearch() {
        this.toggleReseach = !this.toggleReseach
      },
      convertReviewModetoInt(mode) {
        let revmodeint
        switch (mode) {
          case 'original':
            revmodeint = 0
            break
          case 'recommended':
            revmodeint = 1
            break
          case 'level2':
            revmodeint = 2
            break
          default:
            revmodeint = 0
        }
        return revmodeint
      },
      saveToLocalStorageForTruCode(review, truCodeQuery) {
        if (!review.proRecordId) {
          return
        }
        // Because of IE/Edge issues, store a subset of the review instead of the whole thing
        const codeBookBus = {
          reviewSubset: {
            proRecordId: review.proRecordId,
            dischargeDate: review.dischargeDate,
            proRecordDxs: review.proRecordDxs,
            proRecordCpts: review.proRecordCpts,
            project: review.project,
            disableDxPx: false,
            reviewLevel: review.reviewLevel,
            disabled: !!this.readOnly() || this.proReview.reviewStatusId !== 2
          },
          truCodeQuery: truCodeQuery,
          parent: 'Pro Review',
          from: 'Pro Review',
          reviewLevel: review.reviewLevel
        }

        this.$ls.set(`codeBookBus_pro_${review.proRecordId}`, JSON.stringify(codeBookBus))
        this.$ls.set('truCodeAuthKey', review.truCodeAuthKey)
      },
      handleChangedFieldResearchPane() {
        this.changedFieldResearchPane = ''
      },
      handleTruCodeReferenceClick(refEvent) {
        let url
        if (refEvent.code && refEvent.articleId) {
          url = '/trucode/references?code=' + refEvent.code + '&book=' + refEvent.book + '&articleId=' + refEvent.articleId
        } else if (refEvent.code) {
          url = '/trucode/references?code=' + refEvent.code + '&book=' + refEvent.book
        } else {
          url = '/trucode/references?book=' + refEvent.book
        }
        const cacheBuster = new Date().getTime()
        this.referencesWindow = window.open(url + '&dt=' + cacheBuster, 'reference', 'height=812,width=1600')
      },
      handleDxPxGridCodeFocus(focusedCodeObject) {
        const truCodeQuery = this.getTruCodeQuery(focusedCodeObject.code, focusedCodeObject.type)
        this.truCodeQuery = truCodeQuery
        this.saveToLocalStorageForTruCode(this.proReview, truCodeQuery)
      },
      handleEllipsisClick(code) {
        if (!this.codebooksWindow || !this.codebooksWindow.parent) {
          this.openCodeBooks()
        }
      },
      handleTruCodeCodeClick(event) {
        const truCodeQuery = this.getTruCodeQuery(event.code, null, event.codeType)
        this.saveToLocalStorageForTruCode(this.proReview, truCodeQuery)
        if (!this.codebooksWindow || !this.codebooksWindow.parent) {
          this.openCodeBooks()
        }
      },
      handleReviewMode(reviewMode) {
        this.$ls.set('reviewMode', reviewMode)
      },
      setupLocalStorageListenerForCodeBook() {
        // try to turn off any previous listeners so that they don't double up
        try {
          this.$ls.off(`codeBookBus_pro_${this.$route.params.proRecordId}`)
        } catch {
          // nothing - its ok if this fails
        }

        this.$ls.on(`codeBookBus_pro_${this.$route.params.proRecordId}`, storedData => {
          this.parseProReviewDataFromLocalStorage(storedData)
        })
      },
      async parseProReviewDataFromLocalStorage(storedData) {
        const codeBookBus = JSON.parse(storedData)
        const payload = {}
        if (codeBookBus.parent !== 'Pro Review') {
          return
        }

        // use "from" to avoid infinite loops
        if (codeBookBus.reviewSubset && codeBookBus.reviewSubset.proRecordId === this.proReview.proRecordId && codeBookBus.from === 'CodeBook') {
          switch (codeBookBus.event) {
            case 'putDxCode':
              try {
                payload.dxRow = codeBookBus.payload.dxCode
                await this.PUT_PRO_DX(payload)
                this.$message({
                  message: 'DX Code Updated Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'DX Code Updated Error',
                  type: 'error'
                })
              }
              break
            case 'postDxCode':
              try {
                codeBookBus.payload.dxCode[0].level2NewPrimary = false
                codeBookBus.payload.dxCode[0].newPrimary = false
                await this.POST_PRO_DX([codeBookBus.payload.dxCode[0]])
                this.$message({
                  message: 'DX Code Added Successful',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'DX Code Added Error',
                  type: 'error'
                })
              }
              break
            case 'putCptCode':
              try {
                payload.cptRow = codeBookBus.payload.cptCode
                await this.PUT_PRO_CPT(payload)
                this.$message({
                  message: 'CPT Code Updated Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'CPT Code Updated Error',
                  type: 'error'
                })
              }
              break
            case 'postCptCode':
              try {
                await this.POST_PRO_CPT([codeBookBus.payload.cptCodes[0]])
                this.$message({
                  message: 'CPT Code Added Successful',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'CPT Code Added Error',
                  type: 'error'
                })
              }
              break
          }
        }

        this.saveToLocalStorageForTruCode(this.proReview)
        if (codeBookBus.referenceEvent) {
          this.handleTruCodeReferenceClick(codeBookBus.referenceEvent)
        }
      },
      createWindowEventListeners() {
        window.onunload = event => {
          // clear the localStorage listner
          this.$ls.off(`codeBookBus_pro_${this.$route.params.proRecordId}`)
          this.handleCloseOrBackEvent()
        }

        window.onpopstate = event => {
          this.handleCloseOrBackEvent()
        }
      },
      handleCloseOrBackEvent() {
        // first clear the window callbacks so they don't run for other components since they are attached to the
        // global window object
        window.onunload = undefined
        window.onpopstate = undefined
        this.closeCodeBookAndReferences()
        this.$ls.off(`codeBookBus_pro_${this.proReview.proRecordId}`)
        this.$ls.remove(`codeBookBus_pro_${this.proReview.proRecordId}`)
      },
      closeCodeBookAndReferences() {
        if (this.codebooksWindow) {
          this.codebooksWindow.close()
        }
        if (this.referencesWindow) {
          this.referencesWindow.close()
        }
        if (this.worksheetWindow) {
          this.worksheetWindow.close()
        }
      },
      openCodeBooks() {
        // in case users have opened codebooks already via another page, then also do a save here
        const codeBookBus = JSON.parse(this.$ls.get(`codeBookBus_pro_${this.proReview.proRecordId}`))
        if (codeBookBus.parent !== 'Pro Review') {
          this.saveToLocalStorageForTruCode(this.proReview)
        }
        const cacheBuster = new Date().getTime()
        this.codebooksWindow = window.open(`/trucode/codebooks?dt=${cacheBuster}&type=pro&id=${this.proReview.proRecordId}`, 'codebook', 'height=850,width=1600')
      },
      openWorksheet() {
        const cacheBuster = new Date().getTime()
        this.worksheetWindow = window.open(`/reviews/reports/pro/worksheets?fullscreen=true&dt=${cacheBuster}&ids=${this.proReview.proRecordId}`, 'worksheet', 'height=850,width=1600')
      },
      handleAction(action, e) {
        if (this.readOnly()) {
          return false
        }
        if (action === 'addMultipleCodes') {
          this.showAddMultipleCodes = true
        }
        if (action === 'cptAddMultipleCodes') {
          this.showCptAddMultipleCodes = true
        }
      },
      handleCloseAddMultipleCodes(payload) {
        this.showAddMultipleCodes = false
        this.showCptAddMultipleCodes = false
      },
      handleClosePoaAssignments(payload) {
        this.showPoaAssignments = false
      },
      openPrincipalDXAnalysis() {
        this.togglePrincipalDxAnalysis = !this.togglePrincipalDxAnalysis
      },
      confirmLeave(next) {
        this.showInProgressWarning = true
        this.confirmStatusId = this.proReview.reviewStatusId
        this.routeNext = next
      },
      requiredFieldsValidation() {
        this.$refs.proDemographics.$refs.form.validate(async (valid, fields) => {
          // toggle alert banner
          // this.requiredFieldAlertToggle = !valid
          // generate error list
          this.requiredFieldsErrorList = []
          if (this.proReview.requiredFields) {
            const errorFields = Object.keys(fields)
            const requiredFieldsData = this.proReview.requiredFields.requiredFieldsData
            requiredFieldsData.forEach((item, index, array) => {
              if (errorFields.some(v => v === item.field)) {
                this.requiredFieldsErrorList.push(item)
              }
            })
          }
        })
      },
      isValidationError() {
        return !!this.requiredFieldsErrorList.length
      },
      handleTableCommands(command) {
        const visibleComponent = 'proReviewFooter'
        if (command.ref === 'ipSummary' || command.ref.includes('opSummary_') || command.ref.includes('proSummary_') || command.ref.includes('ascSummary_')) {
          const component = this.$refs[visibleComponent].$refs[command.ref][0] ? this.$refs[visibleComponent].$refs[command.ref][0].$refs[command.ref] : this.$refs[visibleComponent].$refs[command.ref].$refs[command.ref]
          this.tableToCsv(component, `${command.csvName}.csv`, command.headers, command.excludeHeaders)
        }
      }
    },
    async beforeRouteLeave(to, from, next) {
      if (this.proReview.reviewStatusId === 2 && !this.readOnly()) {
        this.confirmLeave(next)
      } else {
        next()
      }
    },
    activated: function () {
      this.initialize()
      this.createWindowEventListeners()
      this.setupLocalStorageListenerForCodeBook()
    },
    deactivated: function () {
      this.activeNames = []
      this.handleCloseOrBackEvent()
    },
    computed: {
      ...mapState('proReviews/', ['proReview']),
      ...mapState('user/', ['user']),
      ...mapState('auditFindings/', ['auditFindings']),
      ...mapState('auditChecklists/', ['auditChecklists']),
      ...mapState('dropdowns/', ['dropdowns']),
      disabled: function () {
        return !!this.readOnly()
      },
      headerTable: function () {
        return [
          {
            client: this.proReview.clientSmall ? this.proReview.clientSmall.clientName : '',
            reviewDate: this.proReview.reviewDate,
            patientNumber: this.proReview.patientNumber,
            dateOfService: this.proReview.dateOfService
            // startedBy: this.proReview.reviewAccountsComplete[0].userName,
            // startDate: this.proReview.reviewAccountsComplete[0].startDate
          }
        ]
      },
      isLevel2: function () {
        if (this.proReview.reviewLevel !== '1' && this.proReview.reviewLevel !== 1) {
          return true
        }
        return false
      }
    },
    watch: {}
  }
</script>

<style scoped>
  #proButtons .el-dropdown {
    float: left;
  }

  .el-button-group>.el-dropdown>.el-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-left-color: #dcdfe6;
  }

  .el-button-group>.el-dropdown>.el-button--primary {
    border-left-color: #99bbd5;
  }

  .el-button-group>.el-dropdown>.el-button--success {
    border-left-color: #cbe5bf;
  }

  .el-button-group>.el-dropdown>.el-button--warning {
    border-left-color: #f5dab1;
  }

  .el-button-group>.el-dropdown>.el-button--info {
    border-left-color: #d3d4d6;
  }

  #proButtons .el-button--primary:hover,
  #proButtons .el-button--success:hover,
  #proButtons .el-button--warning:hover,
  #proButtons .el-button--info:hover {
    color: white !important;
  }

  #proButtons .el-dropdown {
    margin-right: -1px;
  }

  #proButtons .el-button--danger {
    border-left-color: #fbc4c4 !important;
  }

  .collapseContent {
    margin: 15px 0px 0px 5px;
  }

  >>>.hiaTable tr td {
    border: none !important;
  }

  >>>.hiaTable th {
    border: none !important;
  }

  .sticky {
    position: sticky;
    top: 5px;
  }

  .collapse-header {
    padding-right: 10px;
    float: right;
    font-weight: bold;
    color: #005695;
  }

  .greenIcon {
    color: #63b344;
  }
</style>