<template>
  <div>
    <el-row :gutter="40" v-loading="loading" element-loading-spinner="atom-audit-loader">
      <el-col :span="6">
        <h4 class="headerWithBorder" style="margin-top: 20px;">Applied Checklists</h4>

        <v-client-table v-if="toggleGrid" class="hiaTable" id="checklistGrid" :data="auditChecklists" element-loading-spinner="atom-audit-loader" name="checklistGrid"
          ref="checklistGrid" :columns="calcAppliedChecklistsColumns" :options="checklistGridOptions" @row-click="handleRowClick">

          <template slot="checklistName" slot-scope="props">
            {{ localGetValueFromKey(calcChecklists, props.row.checklistId) }}
          </template>

          <template slot="codeType" slot-scope="props">
            {{ GET_VALUEFROMKEY('codeTypes', props.row.codeTypeId) }}
          </template>

          <template slot="actions" slot-scope="props">
            <i v-if="!disabled" style="font-size: 16px; margin-left: 5px; cursor: pointer; color: #005695; opacity: .6;" title="Delete Checklist"
              class="el-icon-close delete-button" @click="deleteChecklist(props.row.checklistId)"></i>
          </template>

        </v-client-table>

        <el-popover @show="handleShowPopover" placement="top-start" title="Add Checklist" width="300" trigger="click" v-model="showAddChecklistPopover">
          <el-form size="mini" id="addForm" inline ref="addForm" label-width="250px" label-position="left">

            <el-form-item prop="codeType" label="Code Type" v-if="recordType !== 'IP'">
              <hia-el-select style="margin-top: 10px; width: 280px;" v-model="addForm.codeTypeId" placeholder="Code Type" :filterable="true" itemkey="key" label="value"
                :dropdowns="dropdowns.codeTypes.list">
              </hia-el-select>
            </el-form-item>

            <el-form-item prop="checklistId" label="Checklist">
              <hia-el-select style="margin-top: 10px; width: 280px;" v-model="addForm.checklistId" placeholder="Checklist" :filterable="true" itemkey="key" label="value"
                :dropdowns="calcChecklists">
              </hia-el-select>
              <el-alert type="warning" :closable="false" v-if="this.message">{{this.message}}</el-alert>
            </el-form-item>

            <center>
              <el-button-group style="margin-top: 10px;">
                <el-button class="hiaButton" plain round type="text" size="mini" @click="closeAddChecklistPopover">Cancel</el-button>
                <el-button class="hiaButton" round :disabled="!addForm.checklistId || (['OP', 'PRO'].includes(recordType) && !addForm.codeTypeId)" size="mini"
                  @click="applyCheckList()">Add
                </el-button>
              </el-button-group>
            </center>
          </el-form>

          <el-button slot="reference" :disabled="disabled" style="margin-top: 5px; margin-bottom: 10px; float: right;" size="mini" icon="el-icon-plus" round class="hiaButton">
            Add
          </el-button>
        </el-popover>

      </el-col>

      <el-col :span="18">
        <h4 class="headerWithBorder" style="margin-top: 20px;">{{ activeChecklist.checklistId ? localGetValueFromKey(calcChecklists, activeChecklist.checklistId) :
          'No Checklist Added' }}</h4>

        <div v-loading="checklistLoading" element-loading-spinner="atom-audit-loader">
          <div class="flex-container" v-for="item in activeChecklist.checklistItems" :key="item.checklistRecordId">
            <div class="column">{{ item.clientChecklistItem.name }}</div>
            <div class="radioColumn">
              <input :disabled="disabled" id="overwriteTagsFalse" type="radio" :name="item.itemId" value="1" v-model="item.answer" @change="putItem(item)">
              Yes
              <input :disabled="disabled" id="overwriteTagsTrue" type="radio" :name="item.itemId" value="2" v-model="item.answer" @change="putItem(item)"> No
              <input :disabled="disabled" id="overwriteTagsTrue" type="radio" :name="item.itemId" value="3" v-model="item.answer" @change="putItem(item)"> N/A
            </div>
          </div>

          <el-form v-if="activeChecklist && activeChecklist.codeTypeId" size="mini" label-position="left" label-width="300px" style="margin-top: 15px;">
            <el-form-item label="Checklist Code Type">
              <hia-el-select @input="handeCodeTypeUpdate" :disabled="disabled" v-model="activeChecklist.codeTypeId" placeholder="Code Type" :filterable="true" itemkey="key"
                label="value" :dropdowns="dropdowns.codeTypes.list" style="width: 150px;">
              </hia-el-select>
            </el-form-item>
          </el-form>

        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'ApplyChecklist',
    props: {
      recordType: {
        type: String,
        required: true,
        validator: (value) => {
          return ['IP', 'OP', 'PRO'].indexOf(value) !== -1
        }
      },
      recordId: {
        type: Number
      },
      disabled: {
        type: Boolean
      }
    },
    data: function () {
      return {
        checklistToApply: null,
        showAddChecklistPopover: false,
        loading: false,
        checklistLoading: false,
        toggleGrid: true,
        message: null,
        addForm: {
          checklistId: null,
          codeTypeId: null
        },
        checklistGridOptions: {
          filterable: false,
          filterByColumn: false,
          headings: {
            codeType: 'Code Type',
            checklist: 'Checklist',
            checklistName: 'Checklist Name',
            actions: ''
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...',
            noResults: 'No Checklists Added'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' },
          rowClassCallback: row => {
            if (row.checklistId === this.activeChecklist.checklistId) {
              return 'activeChecklist'
            } else {
              return 'inactiveChecklist'
            }
          }
        }
      }
    },
    methods: {
      ...mapActions('auditChecklists/', ['ADD_CHECKLIST', 'DELETE_CHECKLIST', 'PUT_RECORD_CHECKLIST_ITEM', 'PUT_CHECKLIST_CODETYPE']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      ...mapMutations('auditChecklists/', ['SET_ACTIVE_CHECKLIST', 'REMOVE_CHECKLIST']),
      initialize() {
      },
      handleShowPopover() {
        if (this.addForm.codeTypeId) {
          return
        }

        switch (this.recordType) {
          case 'PRO':
            this.addForm.codeTypeId = this.proReview?.project?.defaultCodeType
            break
          case 'IP':
            this.addForm.codeTypeId = null
            break
          case 'OP':
            this.addForm.codeTypeId = this.outpatientReview?.project?.defaultCodeType
            break
          case 'AMB':
            this.addForm.codeTypeId = this.outpatientReview?.project?.defaultCodeType
            break
        }
      },
      async deleteChecklist(checklistId) {
        let recordId
        switch (this.recordType) {
          case 'PRO':
            recordId = this.proReview.proRecordId
            break
          case 'IP':
            recordId = this.inpatientReview.drgTableId
            break
          case 'OP':
            recordId = this.outpatientReview.ascTableId
            break
          case 'AMB':
            recordId = this.outpatientReview.ascTableId
            break
        }

        const payload = {
          recordId: recordId,
          checklistId: checklistId,
          recordType: this.recordType
        }

        try {
          this.checklistLoading = true
          await this.DELETE_CHECKLIST(payload)
          this.checklistLoading = false
          this.REMOVE_CHECKLIST(checklistId)
          if (this.activeChecklist.checklistId === checklistId && this.auditChecklists.length > 0) {
            this.SET_ACTIVE_CHECKLIST(this.auditChecklists[0])
          } else {
            this.SET_ACTIVE_CHECKLIST({})
          }

          this.$message({
            message: 'Checklist Deleted Successfully',
            type: 'success'
          })
        } catch (e) {
          this.checklistLoading = false
          this.$message({
            message: 'Error Deleting Checklist',
            type: 'error'
          })
        }
      },
      closeAddChecklistPopover() {
        this.addForm.checklistId = null
        this.showAddChecklistPopover = false
      },
      handleRowClick(e) {
        this.SET_ACTIVE_CHECKLIST(e.row)
      },
      async handeCodeTypeUpdate() {
        let recordId
        switch (this.recordType) {
          case 'PRO':
            recordId = this.proReview.proRecordId
            break
          case 'IP':
            recordId = this.inpatientReview.drgTableId
            break
          case 'OP':
            recordId = this.outpatientReview.ascTableId
            break
          case 'AMB':
            recordId = this.outpatientReview.ascTableId
            break
        }

        const payload = {
          recordId: recordId,
          recordType: this.recordType,
          checklistId: this.activeChecklist.checklistId,
          codeTypeId: this.activeChecklist.codeTypeId
        }

        try {
          await this.PUT_CHECKLIST_CODETYPE(payload)
          this.$message({
            message: 'Checklist Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.$message({
            message: 'Error Updating Checklist',
            type: 'error'
          })
        }
      },
      async putItem(row) {
        if (row) {
          try {
            await this.PUT_RECORD_CHECKLIST_ITEM(row)
            this.$message({
              message: 'Checklist Item Updated Successfully',
              type: 'success'
            })
          } catch (e) {
            this.$message({
              message: 'Error Updating Checklist Item',
              type: 'errpr'
            })
          }
        }
      },
      async applyCheckList() {
        if (this.auditChecklists.some(x => x.checklistId === this.addForm.checklistId)) {
          this.message = 'The Checklist has already been added'
          return
        }

        const payload = {
          checklistId: this.addForm.checklistId,
          codeTypeId: this.addForm.codeTypeId,
          recordType: this.recordType
        }

        switch (this.recordType) {
          case 'PRO':
            payload.proRecordId = this.proReview.proRecordId
            break
          case 'IP':
            payload.drgTableId = this.inpatientReview.drgTableId
            break
          case 'OP':
            payload.ascTableId = this.outpatientReview.ascTableId
            break
          case 'AMB':
            payload.ascTableId = this.outpatientReview.ascTableId
            break
        }

        try {
          this.checklistLoading = true
          await this.ADD_CHECKLIST(payload)
          this.checklistLoading = false
          this.message = null
          this.showAddChecklistPopover = false
        } catch (e) {
          this.checklistLoading = false
          this.message = null
          this.showAddChecklistPopover = false
          this.$message({
            message: 'Error Adding Checklist',
            type: 'errpr'
          })
        }
      },
      localGetValueFromKey(list, key) {
        const localList = list.filter(c => c.key === key)
        if (localList.length > 0) {
          return localList[0].value
        }
        return null
      }
    },
    mounted: function () {
    },
    activated: function () {
      // reset on keep-alive activated
      this.addForm.codeTypeId = null
    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview']),
      ...mapState('proReviews/', ['proReview']),
      ...mapState('outpatientReviews/', ['outpatientReview']),
      ...mapState('auditChecklists/', ['auditChecklists', 'activeChecklist']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapGetters('dropdowns/', ['GET_VALUEFROMKEY']),
      calcAppliedChecklistsColumns() {
        if (this.recordType === 'IP') {
          return ['checklistName', 'actions']
        }
        return ['codeType', 'checklistName', 'actions']
      },
      calcChecklists() {
        if (this.recordType === 'PRO') {
          return this.dropdowns.clientChecklistsPro.list
        }

        if (this.recordType === 'IP') {
          return this.dropdowns.clientChecklistsIP.list
        }

        if (this.recordType === 'OP') {
          return this.dropdowns.clientChecklistsOP.list
        }

        if (this.recordType === 'AMB') {
          return this.dropdowns.clientChecklistsAMB.list
        }

        return []
      }
    },
    components: {
    },
    watch: {
    }
  }
</script>

<style scoped>
  >>>.activeChecklist {
    background: rgba(255, 196, 12, 0.2) !important;
  }

  >>>.inactiveChecklist {
    cursor: pointer;
  }

  .flex-container {
    display: flex;
    width: fit-content;
    padding-bottom: 3px;
    padding-top: 3px;
    margin-bottom: 6px;
    align-items: center;
    border-bottom: 1px solid #eee;
  }

  .flex-container:hover {
    background: rgba(255, 196, 12, 0.2) !important;
  }

  .column {
    flex: 1 1 200px;
    width: 400px;
    padding: 0px 20px 0px 0px;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: #48576a;
    height: 100%
  }

  .radioColumn {
    height: 100%
  }

  >>>.VuePagination__count {
    display: none;
  }
</style>