<template>
  <div>
    <!-- <el-card> -->
    <h4 class="headerWithBorder">Pricing and Grouping</h4>
    <el-form size="mini" label-position="left" label-width="80px" v-if="isTrucodeEnabled()">
      <el-row :gutter="40" v-if="!hideGroupers">
        <el-col :span="7">
          <el-form-item size="mini" label="Grouper">
            <el-select :disabled="disabled" :default-first-option="true" size="mini" v-model="inpatientReview.grouper" v-on:input="putInpatientReview('grouper')" filterable placeholder="Grouper">
              <el-option v-for="item in dropdowns.groupers.list" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item size="mini">
            <label slot="label">
              Version
              <el-tooltip effect="dark" content="The grouper version will default to the correct version based on Discharge Date, if not set here" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </label>
            <el-select :disabled="disabled" :default-first-option="true" size="mini" v-model="inpatientReview.grouperVersion" v-on:input="putInpatientReview('grouperVersion')" clearable filterable placeholder="Grouper Version">
              <el-option v-for="item in grouperVersions" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item size="mini" label="Pricer">
            <el-select :disabled="disabled" :default-first-option="true" size="mini" v-model="inpatientReview.pricer" v-on:input="putInpatientReview('pricer')" clearable filterable placeholder="Pricer">
              <el-option v-for="item in grouperPricers" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="drgInfoSection" :gutter="20">
      <el-col :lg="21" :md="18">
        <el-table :data="groupingResults" class="tableStyle" stripe :span-method="calculateSpan">
          <el-table-column width="110" prop="reviewLevel" label="Level">
            <template slot-scope="scope">
              {{ getReviewLevel(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column prop="grouperType" label="Type" width="65"></el-table-column>
          <el-table-column prop="drg" label="DRG" width="60"> </el-table-column>
          <el-table-column label="PSIs" v-if="inpatientReview.ipreviewType !== 'MS-LTC-DRG'" width="80">
            <template slot-scope="scope">
              <template v-if="scope.row.grouperType !== 'CMS'">
                ---
              </template>
              <template v-if="scope.row.grouperType === 'CMS'">
                <template v-if="getPSIByReviewLevel(scope.row).length > 0">
                  <span style="display: block" v-for="item in getPSIByReviewLevel(scope.row)" :key="item.psiType.shortDescription" :title="item.psiType.longDescription"
                    ><a :href="item.psiType.href" target="_blank"><i class="mdi mdi-alert" style="color: #F56C6C;"></i> {{ item.psiType.shortDescription }}</a></span
                  >
                </template>
                <i v-if="getPSIByReviewLevel(scope.row).length === 0" title="None" class="mdi mdi-check" style="color: #63b344;"></i>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="QMs" v-if="inpatientReview.ipreviewType !== 'MS-LTC-DRG'" width="90">
            <template slot-scope="scope">
              <template v-if="scope.row.grouperType !== 'CMS'">
                ---
              </template>
              <template v-if="scope.row.grouperType === 'CMS'">
                <template v-if="getQMsByReviewLevel(scope.row).length > 0">
                  <el-popover placement="bottom" title="CMS.gov QualityNet" width="360" trigger="click">
                    <a href="https://www.qualitynet.org/inpatient/measures/mortality/methodology" target="_blank">
                      <el-button size="mini" type="primary">Mortality Methodology</el-button>
                    </a>
                    <a style="margin-left: 10px;" href="https://www.qualitynet.org/inpatient/measures/readmission/methodology" target="_blank">
                      <el-button size="mini" type="primary">Readmission Methodology</el-button>
                    </a>
                    <span slot="reference" style="display: block; cursor: pointer; color: #337AB7" v-for="item in getQMsByReviewLevel(scope.row)" :key="getQMShortDescription(item)" :title="`${item.qualityMeasureType.longDescription}: Readmission: ${item.qualityMeasureType.isReadmission}, Mortality: ${item.qualityMeasureType.isMortality}`"><i class="mdi mdi-alert" style="color: #F56C6C;"></i> {{ getQMShortDescription(item) }}</span>
                  </el-popover>
                </template>
                <i v-if="getQMsByReviewLevel(scope.row).length === 0" title="None" class="mdi mdi-check" style="color: #63b344;"></i>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="drgDescription" label="Description">
            <template slot-scope="scope">
              <span :title="scope.row.drgDescription">{{ scope.row.drgDescription }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="drgWeight" label="Weight" width="70"></el-table-column>
          <el-table-column v-if="groupingResults && groupingResults.some(x => x.grouperType === 'APR')" prop="severityOfIllness" label="SOI" width="50"></el-table-column>
          <el-table-column v-if="groupingResults && groupingResults.some(x => x.grouperType === 'APR')" prop="riskOfMortality" label="ROM" width="50"></el-table-column>
          <el-table-column prop="mdc" label="MDC" width="50"></el-table-column>
          <el-table-column prop="amlos" label="AMLOS" width="70"></el-table-column>
          <el-table-column prop="gmlos" label="GMLOS" width="70"></el-table-column>
          <el-table-column prop="drgReimbursement" label="Reimbursement" width="130">
            <template slot-scope="scope">
              {{ localFormatMoney(scope.row.drgReimbursement, 2) }}
            </template>
          </el-table-column>
          <el-table-column prop="AddtlInfo" label="Details" width="90" v-if="isTrucodeEnabled()">
            <template slot-scope="scope">
              <el-popover placement="right" width="800" trigger="click">
                <el-card class="box-card">
                  <el-row>
                    <el-col :span="2">
                      DRG:
                    </el-col>
                    <el-col :span="22">
                      {{ scope.row.drg + ' ' + scope.row.drgDescription }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2">
                      MDC:
                    </el-col>
                    <el-col :span="22">
                      {{ scope.row.mdc + ' ' + scope.row.mdcDescription }}
                    </el-col>
                  </el-row>
                  <el-row v-if="scope.row.grouperType === 'APR'">
                    <el-col :span="2">
                      SOI:
                    </el-col>
                    <el-col :span="2">
                      {{ scope.row.severityOfIllness }}
                    </el-col>
                    <el-col :span="2">
                      ROM:
                    </el-col>
                    <el-col :span="2">
                      {{ scope.row.riskOfMortality }}
                    </el-col>
                  </el-row>
                  <hr />
                  <el-row>
                    <el-col :span="6">
                      DRG Weight:
                    </el-col>
                    <el-col :span="6">
                      {{ scope.row.drgWeight }}
                    </el-col>
                    <el-col :span="6">
                      MCO/HMO Amt:
                    </el-col>
                    <el-col :span="6">
                      {{ localFormatMoney(scope.row.mcohomoAmt, 2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      AMLOS:
                    </el-col>
                    <el-col :span="6">
                      {{ scope.row.amlos }}
                    </el-col>
                    <el-col :span="6">
                      Total Oper Amt:
                    </el-col>
                    <el-col :span="6">
                      {{ localFormatMoney(scope.row.totalOperAmt, 2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      GMLOS:
                    </el-col>
                    <el-col :span="6">
                      {{ scope.row.gmlos }}
                    </el-col>
                    <el-col :span="6">
                      Total Capital Amt:
                    </el-col>
                    <el-col :span="6">
                      {{ localFormatMoney(scope.row.totalCapitalAmt, 2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      Hospital Wage Index:
                    </el-col>
                    <el-col :span="6">
                      {{ scope.row.wageIndex }}
                    </el-col>
                    <el-col :span="6">
                      Low Volume Amt:
                    </el-col>
                    <el-col :span="6">
                      {{ localFormatMoney(scope.row.lowVolumeAmt, 2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      &nbsp;
                    </el-col>
                    <el-col :span="6">
                      &nbsp;
                    </el-col>
                    <el-col :span="6">
                      Pass Thru Amt:
                    </el-col>
                    <el-col :span="6">
                      {{ localFormatMoney(scope.row.passThruAmt, 2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      &nbsp;
                    </el-col>
                    <el-col :span="6">
                      &nbsp;
                    </el-col>
                    <el-col :span="6">
                      New Tech Amt:
                    </el-col>
                    <el-col :span="6">
                      {{ localFormatMoney(scope.row.newTechAmt, 2) }}
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6">
                      &nbsp;
                    </el-col>
                    <el-col :span="6">
                      &nbsp;
                    </el-col>
                    <el-col :span="6">
                      DRG Reimbursement:
                    </el-col>
                    <el-col :span="6">
                      {{ localFormatMoney(scope.row.drgReimbursement, 2) }}
                    </el-col>
                  </el-row>
                </el-card>
                <div slot="reference">
                  <i class="mdi mdi-information"></i>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <!-- <el-table-column label="Audit Result" width="120">
          <template>

          </template>
        </el-table-column> -->
        </el-table>
      </el-col>
      <el-col :lg="3" :md="6">
        <h4 class="headerWithBorder" style="margin: 12px 0px 12px 0px; padding-bottom: 11px; font-size: 12px; font-weight: bold;">Audit Result</h4>
        <el-button type="success" v-if="inpatientReview.auditResult === 'No Change'" title="There are no recommended changes on the record" plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ inpatientReview.auditResult }}</el-button>
        <el-button type="warning" v-if="inpatientReview.auditResult === 'FYI'" title="The record contains recommendations that do not change the DRG" plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ inpatientReview.auditResult }}</el-button>
        <el-button type="danger" v-if="inpatientReview.auditResult === 'DRG Change'" title="The record contains recommendations that change the DRG" plain round size="mini" @click="showSummary" icon="mdi mdi-table-eye"> {{ inpatientReview.auditResult }}</el-button>
      </el-col>
    </el-row>
    <!-- </el-card> -->

    <el-dialog title="Record Summary" :visible.sync="summaryDialogVisible" destroy-on-close width="75%">
      <div style="min-height: 475px;" v-loading="summaryLoading" element-loading-spinner="atom-audit-loader">
        <IPSummary ref="ipSummary" v-if="!summaryLoading && summaryDialogVisible" :item="summaryRow" @handleTableCommands="handleTableCommands"></IPSummary>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import formatMoney from '@/mixins/money'
  import { mapGetters, mapState, mapActions } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import IPSummary from '../../reports/dashboards/IP_Summary'
  import axios from '@/utilities/axios-global'

  export default {
    name: 'InpatientReviewFooter',
    mixins: [formatMoney, HiaAuthorization],
    data: function() {
      return {
        hideGroupers: false,
        summaryLoading: true,
        summaryDialogVisible: false,
        summaryRow: {}
      }
    },
    components: {
      IPSummary
    },
    methods: {
      ...mapActions('inpatientReviews/', ['PUT_INPATIENT_REVIEW']),
      async showSummary() {
        this.summaryDialogVisible = true
        const payload = {
          drgTableIds: this.inpatientReview.drgTableId,
          includeAccts: false,
          statType: 'test'
        }
        try {
          this.summaryLoading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipBasicStat', payload)
          this.summaryRow = response.data[0]
        } catch (err) {
          this.tableData = []
        } finally {
          this.summaryLoading = false
        }
      },
      putInpatientReview: function(changedField) {
        this.inpatientReview.pricer = this.isPricerValid(this.inpatientReview.pricer) ? this.inpatientReview.pricer : ''
        this.inpatientReview.grouperVersion = this.isGrouperVersionValid(this.inpatientReview.grouperVersion) ? this.inpatientReview.grouperVersion : ''
        const payload = { inpatientReview: this.inpatientReview, changedField: changedField }
        this.PUT_INPATIENT_REVIEW(payload)
      },
      drgReimbursementFormat: function(row, column, cellValue) {
        return '$' + formatMoney(cellValue, 2)
      },
      isGrouperVersionValid(grouperVersion) {
        if (this.grouperVersions.findIndex(gv => gv.key === grouperVersion) > -1) {
          return true
        }
        return false
      },
      isPricerValid(pricer) {
        if (this.grouperPricers.findIndex(gp => gp.key === pricer) > -1) {
          return true
        }
        return false
      },
      getPSIByReviewLevel(row) {
        const psiResult = this.inpatientReview.psiResults.filter(x => row.grouperType === 'CMS' && x.reviewLevel === row.reviewLevel)
        if (psiResult) {
          return psiResult
        }
        return []
      },
      getQMsByReviewLevel(row) {
        const qmResult = this.inpatientReview.qualityMeasureResults.filter(x => row.grouperType === 'CMS' && x.reviewLevel === row.reviewLevel)
        if (qmResult) {
          return qmResult
        }
        return []
      },
      getQMShortDescription(row) {
        const qm = row.qualityMeasureType
        return `${qm.shortDescription}-${qm.isMortality ? 'M' : ''}${qm.isReadmission ? 'R' : ''}`
      },
      getReviewLevel(row) {
        if (row.reviewLevel === 0) {
          return 'Original'
        } else if (row.reviewLevel === 1) {
          return 'Recommended'
        } else {
          return 'Level 2'
        }
      },
      localFormatMoney(value, decimalPlaces) {
        return '$' + this.formatMoney(value, decimalPlaces)
      },
      calculateSpan({ row, column, rowIndex, columnIndex }) {
        if (column.label === 'Audit Result') {
          const rowspan = this.groupingResults.length + 1
          return {
            rowspan: rowspan,
            colspan: 1
          }
        }
        return {
          rowspan: 1,
          colspan: 1
        }
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapGetters('dropdowns/', ['GET_GROUPERVERSIONSFORGROUPER', 'GET_GROUPERPRICERS']),
      ...mapState('inpatientReviews/', ['inpatientReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['baseApiUrl']),
      disabled() {
        return !!this.readOnly() || this.inpatientReview.reviewStatusId !== 2
      },
      grouperVersions: function() {
        return this.GET_GROUPERVERSIONSFORGROUPER(this.inpatientReview.grouper)
      },
      grouperPricers: function() {
        return this.GET_GROUPERPRICERS(this.inpatientReview.grouper, this.inpatientReview.grouperVersion)
      },
      groupingResults: function() {
        if (this.inpatientReview.groupingResults) {
          const res = this.inpatientReview.groupingResults.map(x =>
            Object.assign(
              x,
              this.inpatientReview.pricingResults.find(y => y.reviewLevel === x.reviewLevel && y.grouperType === x.grouperType)
            )
          )
          return res
        }
        return null
      }
    }
  }
</script>

<style scoped>
  .tableStyle {
    font-size: 12px;
    width: 100%;
    margin: 0px 0px 10px 0px;
  }

  .drgInfoSection {
    margin: 10px 0px 0px 0px;
  }

  >>> .el-table .cell {
    white-space: nowrap;
  }
</style>
