te<template>
  <div>
    <template v-if="!isLevel2">
      <div v-loading="loading" element-loading-spinner="atom-audit-loader">
        <v-client-table v-if="toggleGrid" key="modifiersGrid" class="hiaTable" ref="modifiersGrid" id="modifiersGrid" :data="cpt.proRecordModifiers" :columns="columns"
          :options="options" uniqueKey="dxId">

          <template slot="lineSequence" slot-scope="props">
            <span class="dragIcon drag-handle" style="margin-top: 3px; float: left">{{props.row.lineSequence}}</span>
          </template>

          <template slot="codeType" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="putProRecordModifier(props.row, $event)" v-model="props.row.codeType" placeholder="Code Type" itemkey="key"
              label="value" :dropdowns="dropdowns.codeTypes.list" clearable filterable>
            </hia-el-select>
          </template>
          <template slot="originalModifier" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="putProRecordModifier(props.row, $event)" v-model="props.row.originalModifier" placeholder="Mod" itemkey="key"
              label="value" :dropdowns="dropdowns.modifiers.list" clearable filterable>
            </hia-el-select>
          </template>
          <template slot="originalDesc" slot-scope="props">
            <div style="margin-top: 3px;">
              <span :title="modDescription(props.row.originalModifier)"> {{modDescription(props.row.originalModifier)}}</span>
            </div>
          </template>
          <template slot="recommendedModifier" slot-scope="props">
            <hia-el-select :placeholder="`${props.row.originalModifier} | ${modDescription(props.row.originalModifier)}`" :class="{deleteCode: props.row.deleteCode}"
              :disabled="props.row.deleteCode || disabled" @input="putProRecordModifier(props.row, $event)" v-model="props.row.recommendedModifier" itemkey="key" label="value"
              :dropdowns="dropdowns.modifiers.list" clearable filterable>
            </hia-el-select>
          </template>
          <template slot="recDesc" slot-scope="props">
            <div style="margin-top: 3px;" :title="!props.row.deleteCode ? modDescription(props.row.recommendedModifier) : 'Delete Modifier'">{{!props.row.deleteCode ?
              modDescription(props.row.recommendedModifier) : 'Delete Modifier'}}</div>
          </template>
          <template slot="actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.deleteCode" title="Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled"
                @input="putProRecordModifier(props.row, 'deleteModifier')">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.exclude" title="Exclude Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled"
                @input="putProRecordModifier(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.validated" title="Validated by Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled"
                @input="putProRecordModifier(props.row)">
              </iconCheckBox>
            </div>
          </template>
          <template slot="reasonForChange" slot-scope="props">
            <hia-el-select style="width: 82%" :title="disableReasonForChange(props.row) ? 'No change on line' : null" :disabled="disableReasonForChange(props.row) || disabled"
              @input="putProRecordModifier(props.row, $event)" v-model="props.row.reasonForChange" placeholder="Reason for Change" itemkey="key" label="value"
              :dropdowns="dropdowns.proReasonsForChangeMod.list" clearable filterable>
            </hia-el-select>
            <i style="float: right; cursor: pointer; margin-top: -18px;" title="Delete Modifer Row" class="el-icon-close delete-button" @click="deleteModifier(props.row)"></i>
          </template>
        </v-client-table>

        <v-client-table v-if="toggleGrid" key="modifiersGridAddRow" class="hiaTable" ref="modifiersGridAddRow" id="modifiersGridAddRow" :data="addRow" :columns="addRowColumns"
          :options="options">
          <template slot="codeType" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="setAddRow(props.row, $event)" v-model="props.row.codeType" placeholder="Code Type" itemkey="key" label="value"
              :dropdowns="dropdowns.codeTypes.list" clearable filterable>
            </hia-el-select>
          </template>
          <template slot="originalModifier" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="setAddRow(props.row, $event)" v-model="props.row.originalModifier" placeholder="Mod" itemkey="key" label="value"
              :dropdowns="dropdowns.modifiers.list" clearable filterable>
            </hia-el-select>
          </template>
          <template slot="originalDesc">
            <div>&nbsp;</div>
          </template>
          <template slot="recommendedModifier" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="setAddRow(props.row, $event)" v-model="props.row.recommendedModifier" placeholder="Rec. Mod" itemkey="key"
              label="value" :dropdowns="dropdowns.modifiers.list" clearable filterable>
            </hia-el-select>
          </template>
          <template slot="recDesc">
            <div>&nbsp;</div>
          </template>
          <template slot="actions">

          </template>
          <template slot="reasonForChange" slot-scope="props">
            <el-popover ref="addPopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover"
              :content="addPopoverContent" v-model="showAddPopover" @after-enter="handleHidePopOver('addPopover')">
              <el-button :disabled="disabled" round icon="el-icon-plus" slot="reference" style="float: right;" type="primary" size="mini" @click="addModifierRow(props.row)">
                Add Mod
              </el-button>
            </el-popover>
          </template>
        </v-client-table>

      </div>

    </template>

  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import Sortable from 'sortablejs'
  import iconCheckBox from '@/components/controls/iconCheckBox'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'proReviewModifiers',
    props: ['cpt'],
    mixins: [HiaAuthorization],
    data: function () {
      return {
        toggleGrid: true,
        loading: false,
        // disabled: false,
        theSortable: null,
        addPopoverContent: '',
        showAddPopover: false,
        addRow: [{ codeType: null, originalModifier: null, recommendedModifier: null }],
        addRowColumns: ['lineSequence', 'codeType', 'originalModifier', 'originalDesc', 'recommendedModifier', 'recDesc', 'actions', 'reasonForChange'],
        columns: ['lineSequence', 'codeType', 'originalModifier', 'originalDesc', 'recommendedModifier', 'recDesc', 'actions', 'reasonForChange'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'modLineSequenceCol',
            codeType: 'modCodeTypeCol',
            originalModifier: 'oneHundredPx',
            originalDesc: 'descriptionCol',
            recommendedModifier: 'oneHundredPx',
            recDesc: 'descriptionCol',
            reasonForChange: 'reasonForChangeCol',
            actions: 'modActionsCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            codeType: 'Type',
            originalModifier: 'Mod',
            originalDesc: 'Description',
            recommendedModifier: 'Rec. Mod',
            recDesc: 'Description',
            delete: '',
            reasonForChange: 'Reason For Change',
            add: ''
          }
        }
      }
    },
    components: {
      iconCheckBox
    },
    methods: {
      ...mapActions('proReviews/', ['PUT_PRO_MODIFIER', 'DELETE_PRO_MODIFIER', 'POST_PRO_MODIFIER', 'PUT_PRO_MODIFIERS_FOR_RESEQUENCE']),
      ...mapMutations('proReviews/', ['SET_PRO_MODIFIER_ROW', 'SET_PRO_CPT_ROW']),
      setAddRow(row) {
        this.$set(this.addRow, 0, row)
      },
      async putProRecordModifier(row, changedField) {
        if (changedField === 'deleteModifier' && row.recommendedModifier && row.deleteCode) {
          try {
            await this.$confirm(`This will clear the Recommended Modifier ${row.recommendedModifier}. Continue?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.recommendedModifier = null
          } catch (e) {
            row.deleteCode = false
            this.SET_PRO_MODIFIER_ROW(row)
            return
          }
        }

        if (row.reasonForChange && !row.recommendedModifier && !row.deleteCode) {
          try {
            await this.$confirm('This will clear the Reason for Change. Continue?', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.reasonForChange = null
          } catch (e) {
            const cptRow = this.proReview.proRecordCpts.find(x => x.cptId === row.cptId)
            const backupRow = cptRow.proRecordModifiers.find(x => x.modifierId === row.modifierId)
            this.SET_PRO_MODIFIER_ROW(backupRow)
            return
          }
        }

        if (changedField === 'deleteModifierLevel2' && row.level2Dx && row.level2DeleteCode) {
          try {
            await this.$confirm(`This will clear the Level 2 Recommended Modifier ${row.level2Dx}. Continue?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.level2Dx = null
          } catch (e) {
            row.level2DeleteCode = false
            this.SET_PRO_MODIFIER_ROW(row)
            return
          }
        }

        this.SET_PRO_MODIFIER_ROW(row)
        const payload = { modRow: row, changedField: changedField }

        this.loading = true
        try {
          await this.PUT_PRO_MODIFIER(payload)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Modifier Code Update Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Modifier Code Update Error',
            type: 'error'
          })
        }
      },
      async addModifierRow(row) {
        if (!row.codeType) {
          this.addPopoverContent = 'Code Type must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (!this.isLevel2 && !row.originalModifier && !row.recommendedModifier) {
          this.addPopoverContent = 'Either Original Modifier or Recommended Modifier must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (this.isLevel2 && !row.originalModifier && !row.recommendedModifier && !row.level2Modifier) {
          this.addPopoverContent = 'Either Original Modifier, Recommended Modifier or Level 2 Modifier must be populated to add row.'
          this.showAddPopover = true
          return
        }

        row.modifierId = 0
        row.cptId = this.cpt.cptId
        row.proRecordId = this.proReview.proRecordId
        row.lineSequence = 1

        this.loading = true
        try {
          await this.POST_PRO_MODIFIER(row)
          this.$set(this.addRow, 0, { codeType: row.codeType, originalModifier: null, recommendedModifier: null })
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Modifier Code Added Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Modifier Code Add Error',
            type: 'error'
          })
        }
      },
      async deleteModifier(row) {
        this.loading = true
        try {
          await this.DELETE_PRO_MODIFIER(row)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Modifier Code Deleted Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Modifier Code Delete Error',
            type: 'error'
          })
        }
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = (e) => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      modDescription(modifier) {
        if (modifier !== null && modifier.length) {
          let desc = ''
          for (const i in this.dropdowns.modifiers.list) {
            if (modifier === this.dropdowns.modifiers.list[i].key) {
              desc = this.dropdowns.modifiers.list[i].value.split('|')[1].trim().toLowerCase()
              break
            }
          }
          return desc
        }
      },
      codeType(codeType) {
        if (codeType !== null) {
          let desc = ''
          for (const i in this.dropdowns.codeTypes.list) {
            if (codeType === this.dropdowns.codeTypes.list[i].key) {
              desc = this.dropdowns.codeTypes.list[i].value
              break
            }
          }
          return desc
        }
      },
      async handleDragStop(e) {
        const temp = this.cpt.proRecordModifiers[e.oldIndex]
        this.cpt.proRecordModifiers.splice(e.oldIndex, 1)
        this.cpt.proRecordModifiers.splice(e.newIndex, 0, temp)
        this.updateLineSequence(this.cpt.proRecordModifiers)
        this.SET_PRO_CPT_ROW(this.cpt)

        // force the grid to re-initialize by destroying and recreating it
        // and then reattaching the sortable
        this.toggleGrid = false
        this.theSortable.destroy()
        this.$nextTick(() => {
          this.toggleGrid = true
          this.$nextTick(() => {
            this.attachSortable()
          })
        })

        this.loading = true
        try {
          await this.PUT_PRO_MODIFIERS_FOR_RESEQUENCE(this.cpt.proRecordModifiers)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Modifier Code Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = true
          this.$message({
            message: 'Modifier Code Update Error',
            type: 'error'
          })
        }
      },
      updateLineSequence(array) {
        array.map((o, i) => {
          o.lineSequence = (i + 1)
          return o
        })
      },
      attachSortable() {
        const gridElementName = this.isLevel2 ? 'modifiersGridLevel2' : 'modifiersGrid'
        const gridDiv = document.getElementById(gridElementName)
        if (gridDiv) {
          const tbody = gridDiv.getElementsByTagName('tbody')
          this.theSortable = Sortable.create(tbody[0], {
            animation: 200,
            draggable: 'tr',
            handle: '.drag-handle',
            onEnd: (e) => {
              this.handleDragStop(e)
            }
          })
        }
      },
      handleCloseAddMultipleCodes() {
        this.$emit('handleCloseAddMultipleCodes', false)
      },
      disableReasonForChange(row) {
        if (!row.recommendedModifier && !row.deleteCode) {
          return true
        }
        return false
      }
    },
    updateLineSequence(array) {
      array.map((o, i) => {
        o.lineSequence = (i + 1)
        return o
      })
    },
    mounted: function () {
      this.$nextTick(() => {
        this.attachSortable()
        if (this.proReview.project) {
          this.addRow = [{ codeType: this.proReview.project.defaultCodeType, originalModifier: '', recommendedModifier: '' }]
        }
      })
    },
    watch: {
      isLevel2(val) {
        this.$nextTick(() => {
          if (this.theSortable) {
            this.attachSortable()
          }
        })
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      disabled() {
        return !!this.readOnly() || this.proReview.reviewStatusId !== 2
      },
      isLevel2: function () {
        return this.proReview.reviewLevel > 1
      }
    }
  }
</script>

<style scoped>
  .dragIcon {
    cursor: move;
    margin: 1px 0px 0px 1px;
    padding: 0px 0px 0px 0px;
    float: left;
    height: 20px;
    color: #1f2d3d;
  }

  .drag-handle-mod {
    cursor: move;
    display: block;
    float: left;
  }

  >>>.modLineSequenceCol {
    width: 15px;
  }

  >>>.modCodeTypeCol {
    width: 100px;
  }

  >>>.modActionsCol {
    width: 130px;
  }

  >>>.reasonForChangeCol {
    min-width: 120px;
    max-width: 140px;
  }

  >>>.deleteCol {
    max-width: 30px;
  }

  >>>.addCol {
    min-width: 150px;
  }

  >>>.thirtyPx {
    min-width: 20px;
  }

  >>>.fiftyPx {
    width: 50px;
  }

  >>>.seventyFivePx {
    width: 75px;
  }

  >>>.oneHundredPx {
    width: 100px;
  }

  >>>.descriptionCol {
    width: 25%;
    max-width: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  >>>.descOverflow {
    word-break: break-word;
  }

  .hiaTable .el-icon-close {
    border: 1px solid transparent;
  }

  .hiaTable tr:nth-child(even) .el-icon-close {
    border: 1px solid #606266;
    border-radius: 100%;
  }

  >>>#modifiersGridAddRow thead,
  >>>#modifiersGridLevel2AddRow thead {
    visibility: hidden;
  }

  >>>#modifiersGridAddRow table,
  >>>#modifiersGridLevel2AddRow table {
    margin-top: -1px;
  }

  >>>#modifiersGridAddRow tbody>tr {
    height: 40px;
    border-top: 1px solid #ebeef5;
    background-color: #fff !important;
  }

  >>>#modifiersGridAddRow th,
  >>>#modifiersGridLevel2AddRow th {
    line-height: 0px;
    padding: 0px 5px 0px 5px;
  }

  >>>#modifiersGrid tr,
  >>>#modifiersGridLevel2 tr {
    vertical-align: top;
  }

  /* >>>#modifiersGrid ::placeholder,
  >>>#modifiersGridLevel2 ::placeholder {
    opacity: 0.8;
  } */

  >>>.VuePagination__count {
    display: none;
  }
</style>