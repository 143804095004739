<template>
  <div class="poaAssignments">
    <el-dialog class="poaAssignmentsDialog" :visible="show" title="POA Assignments" width="80%" :before-close="handleClose" :close-on-click-modal="false" :show-close="false">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-button-group style="float: right; margin: 0px 0px 5px 0px;">
            <el-button class="hiaButton" type="text" @click="handleClose" size="mini" plain round>Cancel</el-button>
            <el-button class="hiaButton" :disabled="disabled" @click="putDxCodesPoaAssignments" size="mini" plain round>Update POAs</el-button>
          </el-button-group>
          <v-client-table ref="poaDxGrid" class="hiaTable poaDxGrid" v-model="localDxCodes" :columns="dx.columns" :options="dx.options">
            <template slot="lineSequence" slot-scope="props">
              <div style="float: left;">{{ props.row.lineSequence }}</div>
            </template>
            <template slot="Edit" slot-scope="props">
              <div v-html="dxCodeEdits(props.row.edits)"></div>
            </template>
            <template slot="R Edit" slot-scope="props">
              <div v-html="dxCodeEdits(props.row.recEdits)"></div>
            </template>
            <template slot="L2 Edit" slot-scope="props">
              <div v-html="dxCodeEdits(props.row.level2Edits)"></div>
            </template>
            <template slot="h__originalPoa">
              <div style="float: left; height: 12px; margin: 3px 0px 0px 0px;">
                <i class="mdi mdi-alpha-y poaMassIcon green" @click="massPoaAssignment('Y', 'original')"></i>
                <i class="mdi mdi-alpha-n poaMassIcon magenta" @click="massPoaAssignment('N', 'original')"></i>
                <i class="mdi mdi-alpha-u poaMassIcon navy" @click="massPoaAssignment('U', 'original')"></i>
                <i class="mdi mdi-alpha-w poaMassIcon gold" @click="massPoaAssignment('W', 'original')"></i>
                <i class="mdi mdi-alpha-e poaMassIcon" @click="massPoaAssignment('E', 'original')"></i>
                <!-- <span class="poaDelete" @click="massPoaAssignment(null, 'original')" title="clear all"></span> -->
                <i @click="massPoaAssignment(null, 'original')" title="clear all" class="el-icon-close poaDelete"></i>
              </div>
            </template>
            <template slot="h__recommendedPoa">
              <div style="float: left; height: 12px; margin: 3px 0px 0px 0px;">
                <i class="mdi mdi-alpha-y poaMassIcon green" @click="massPoaAssignment('Y', 'rec')"></i>
                <i class="mdi mdi-alpha-n poaMassIcon magenta" @click="massPoaAssignment('N', 'rec')"></i>
                <i class="mdi mdi-alpha-u poaMassIcon navy" @click="massPoaAssignment('U', 'rec')"></i>
                <i class="mdi mdi-alpha-w poaMassIcon gold" @click="massPoaAssignment('W', 'rec')"></i>
                <i class="mdi mdi-alpha-e poaMassIcon" @click="massPoaAssignment('E', 'rec')"></i>
                <!-- <span class="poaDelete" @click="massPoaAssignment(null, 'rec')" title="clear all"></span> -->
                <i @click="massPoaAssignment(null, 'rec')" title="clear all" class="el-icon-close poaDelete"></i>
              </div>
            </template>
            <template slot="h__level2Poa">
              <div style="float: left; height: 12px; margin: 3px 0px 0px 0px;">
                <i class="mdi mdi-alpha-y poaMassIcon green" @click="massPoaAssignment('Y', 'lvl2')"></i>
                <i class="mdi mdi-alpha-n poaMassIcon magenta" @click="massPoaAssignment('N', 'lvl2')"></i>
                <i class="mdi mdi-alpha-u poaMassIcon navy" @click="massPoaAssignment('U', 'lvl2')"></i>
                <i class="mdi mdi-alpha-w poaMassIcon gold" @click="massPoaAssignment('W', 'lvl2')"></i>
                <i class="mdi mdi-alpha-e poaMassIcon" @click="massPoaAssignment('E', 'lvl2')"></i>
                <!-- <span class="poaDelete" @click="massPoaAssignment(null, 'lvl2')" title="clear all"></span> -->
                <i @click="massPoaAssignment(null, 'lvl2')" title="clear all" class="el-icon-close poaDelete"></i>
              </div>
            </template>
            <template slot="originalPoa" slot-scope="props">
              <el-radio-group :disabled="disabled" class="poaRadioGroup" :title="props.row.orPOAExempt ? 'Diagnosis code is POA exempt' : ''" v-model="props.row.originalPoa" @input="handlePoaChange($event, props.index, props, 'originalPoa')">
                <el-radio :disabled="props.row.orPOAExempt || !props.row.originalDx" label="Y"></el-radio>
                <el-radio :disabled="props.row.orPOAExempt || !props.row.originalDx" label="N"></el-radio>
                <el-radio :disabled="props.row.orPOAExempt || !props.row.originalDx" label="U"></el-radio>
                <el-radio :disabled="props.row.orPOAExempt || !props.row.originalDx" label="W"></el-radio>
                <el-radio :disabled="props.row.orPOAExempt || !props.row.originalDx" label="E"></el-radio>
                <el-radio :label="null"></el-radio>
              </el-radio-group>
            </template>
            <template slot="recommendedPoa" slot-scope="props">
              <el-radio-group :disabled="disabled" class="poaRadioGroup" :title="props.row.recPOAExempt ? 'Diagnosis code is POA exempt' : ''" v-model="props.row.recommendedPoa" @input="handlePoaChange($event, props.index, props, 'recommendedPoa')">
                <el-radio :disabled="props.row.recPOAExempt || props.row.deleteCode" label="Y"></el-radio>
                <el-radio :disabled="props.row.recPOAExempt || props.row.deleteCode" label="N"></el-radio>
                <el-radio :disabled="props.row.recPOAExempt || props.row.deleteCode" label="U"></el-radio>
                <el-radio :disabled="props.row.recPOAExempt || props.row.deleteCode" label="W"></el-radio>
                <el-radio :disabled="props.row.recPOAExempt || props.row.deleteCode" label="E"></el-radio>
                <el-radio :label="null"></el-radio>
              </el-radio-group>
            </template>
            <template slot="level2Poa" slot-scope="props">
              <el-radio-group :disabled="disabled" class="poaRadioGroup" :title="props.row.level2POAExempt ? 'Diagnosis code is POA exempt' : ''" v-model="props.row.level2Poa" @input="handlePoaChange($event, props.index, props, 'level2Poa')">
                <el-radio :disabled="props.row.level2POAExempt || !props.row.recommendedDx" label="Y"></el-radio>
                <el-radio :disabled="props.row.level2POAExempt || !props.row.recommendedDx" label="N"></el-radio>
                <el-radio :disabled="props.row.level2POAExempt || !props.row.recommendedDx" label="U"></el-radio>
                <el-radio :disabled="props.row.level2POAExempt || !props.row.recommendedDx" label="W"></el-radio>
                <el-radio :disabled="props.row.level2POAExempt || !props.row.recommendedDx" label="E"></el-radio>
                <el-radio :label="null"></el-radio>
              </el-radio-group>
            </template>
          </v-client-table>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'poaAssignments',
    props: {
      show: { type: Boolean, default: false },
      review: { type: Object },
      service: { type: String, default: 'coding' },
      disabled: { type: Boolean, default: false }
    },
    data() {
      return {
        hideShow: true,
        localDxCodes: [],
        dx: {
          columns: [],
          options: {
            columnsClasses: {
              lineSequence: 'lineSequenceWidth',
              Edit: 'lineSequenceWidth',
              originalPoa: 'poaWidth',
              originalDx: 'dxWidth',
              originalDescCalc: 'dxDescWidth',
              'R Edit': 'lineSequenceWidth',
              recommendedPoa: 'poaWidth',
              recommendedDx: 'dxWidth',
              recDescCalc: 'dxDescWidth',
              'L2 Edit': 'lineSequenceWidth',
              level2Poa: 'poaWidth',
              level2Dx: 'dxWidth',
              level2DescCalc: 'dxDescWidth'
            },
            // editableColumns: ['originalPoa'],
            // hiddenColumns: this.reviewSubset.reviewLevel !== 2 ? ['level2Dx', 'level2DescCalc', 'level2Poa', 'level2Revise'] : [],
            sortable: [],
            filterable: false,
            orderBy: {
              column: 'lineSequence',
              ascending: true
            },
            headings: {
              lineSequence: function(h) {
                return 'Line'
              },
              originalDx: function(h) {
                return 'Original Dx'
              },
              originalDescCalc: function(h) {
                return 'Original Desc'
              },
              originalPoa: function(h) {
                return 'Original POA'
              },
              recommendedDx: function(h) {
                return 'Rec. Dx'
              },
              recDescCalc: function(h) {
                return 'Rec. Desc'
              }
              // recommendedPoa: function (h) {
              //   return 'Rec. POA'
              // },
              // level2Dx: function (h) {
              //   return 'Level 2 Dx'
              // },
              // level2DescCalc: function (h) {
              //   return 'Level 2 Desc'
              // },
              // level2Poa: function (h) {
              //   return 'Level 2 POA'
              // },
              // revise: function (h) {
              //   return 'Revise'
              // },
              // level2Revise: function (h) {
              //   return 'Level 2 Revise'
              // }
            },
            perPage: 50,
            sortIcon: { is: 'none' }
          }
        }
      }
    },
    mounted: function() {},
    created: function() {},
    methods: {
      handleClose(done) {
        this.$emit('close')
      },
      handlePoaChange(e, index, props, column) {
        this.localDxCodes[this.localDxCodes.findIndex(f => f.dxId === props.row.dxId)][column] = e
      },
      putDxCodesPoaAssignments(e) {
        this.$emit('putDxCodesPoaAssignments', this.localDxCodes)
        this.$emit('close')
      },
      massPoaAssignment(value, column) {
        if (this.disabled) {
          return
        }
        this.localDxCodes.forEach((v, i, arr) => {
          if (column === 'original' && v.originalDx) {
            v.originalPoa = v.orPOAExempt ? '1' : value
          }
          if (column === 'rec' && !v.deleteCode) {
            v.recommendedPoa = value === null ? null : v.originalPoa === '1' ? null : v.recPOAExempt && v.originalDx ? '1' : value
          }
          if (column === 'lvl2' && v.recommendedDx) {
            v.level2Poa = value === null ? null : v.level2POAExempt && v.level2Dx ? '1' : value
          }
        })
      },
      dynamicColumns() {
        if (this.service === 'review') {
          return this.review.reviewLevel === 2 ? ['lineSequence', 'Edit', 'originalPoa', 'originalDx', 'originalDescCalc', 'R Edit', 'recommendedPoa', 'recommendedDx', 'recDescCalc', 'L2 Edit', 'level2Poa', 'level2Dx', 'level2DescCalc'] : ['lineSequence', 'Edit', 'originalPoa', 'originalDx', 'originalDescCalc', 'R Edit', 'recommendedPoa', 'recommendedDx', 'recDescCalc']
        } else {
          return ['lineSequence', 'Edit', 'originalPoa', 'originalDx', 'originalDescCalc']
        }
      },
      dxCodeEdits: function(edits) {
        if (edits.length) {
          let dxDesc = ''
          for (const i in edits) {
            dxDesc += edits[i].description.replace(/<[^>]*>/g, '') + ' \n'
          }
          return '<div style="background: url(/static/trucode/css/img/icons/warning.png); width: 16px; height: 16px; margin: auto;"  title="' + dxDesc.trim() + '"></div>'
        }
        return '<div>&nbsp;</div>'
      }
    },
    computed: {},
    watch: {
      show() {
        if (this.show) {
          this.localDxCodes = JSON.parse(JSON.stringify(this.review.drgRecommendationsDx))
          this.dx.columns = this.dynamicColumns()
        }
      }
    }
  }
</script>

<style scoped>
  .poaDxGrid >>> .el-radio__label {
    display: none;
  }

  .poaDxGrid >>> .el-radio + .el-radio {
    margin-left: 5px;
  }

  .poaDxGrid >>> label {
    margin-bottom: 0px;
  }

  .poaMassIcon {
    font-size: 20px;
  }

  .poaMassIcon.first {
  }

  .poaMassIcon::before {
    margin: 0px 0px 0px -4.5px;
    width: 24px;
    height: 10px;
    float: left;
    cursor: pointer;
  }

  .hiadelete__button.poaAssignment {
    /* right: 0; */
  }

  /*.poaDelete {
  background-image: url(/static/images/cross-inverse.svg);
  background-size: 8px;
  background-repeat: no-repeat;
  color: #333;
  height: 12px;
  width: 20px;
  float: left;
  opacity: 0.6;
  cursor: pointer;
  margin-top: 2px;
} */

  .poaDelete {
    float: left;
    margin: 0px 0px 0px -3px;
    font-size: 13px;
    font-weight: bold;
  }

  .green {
    color: #63b344;
  }

  .navy {
    color: rgb(3, 40, 77);
  }

  .magenta {
    color: #f90089;
  }

  .gold {
    color: #e6a23c;
  }

  >>> .lineSequenceWidth {
    width: 44px;
  }

  >>> .poaWidth {
    width: 130px;
  }

  >>> .dxWidth {
    width: 75px;
  }

  >>> .dxDescWidth {
    width: 200px;
    /* white-space: nowrap; */
    overflow: hidden;
  }

  >>> .poaRadioGroup label {
    margin-right: 0px;
  }
</style>
