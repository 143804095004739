<!--
This is designed to be a reusable component to pick a Recommendation from the Recommendation Library
-->

<template>
  <el-dialog title="Select Recommendation" :visible="showDialog" :before-close="handleClose" width="75%" :append-to-body="true">
    <el-form size="mini" label-position="left" label-width="150px">
      <el-row>
        <el-col :span="24">
          <v-client-table v-loading="loading" element-loading-spinner="atom-audit-loader" class="hiaTable" ref="recommendationsGrid" id="recommendationsGrid" :data="recommendationLibrary" :columns="recommendationsColumns" :options="options">
            <template slot-scope="props" slot="title">
              <el-button type="text" @click="selectRecommendation(props.row.recommendation)" size="mini">{{ props.row.title }}</el-button>
            </template>
            <template slot-scope="props" slot="recommendation">
              <span v-html="getRecommendation(props.row.recommendation)"></span>
            </template>
            <template slot-scope="props" slot="recommendationType">
              {{ getDropdownLabel(dropdowns.RecommendationTypes.list, props.row.recommendationType) }}
            </template>
            <template slot-scope="props" slot="recordType">
              {{ props.row.recordType ? getDropdownLabel(dropdowns.RecommendationRecordTypes.list, props.row.recordType) : 'Any' }}
            </template>
            <template slot-scope="props" slot="codeGroup">
              {{ props.row.codeGroup ? getDropdownLabel(dropdowns.RecommendationCodeGroups.list, props.row.codeGroup) : 'Any' }}
            </template>
          </v-client-table>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex'
  import DropdownHelper from '@/mixins/dropdown-helper'

  export default {
    name: 'RecommendationLibraryPicker',
    props: {
      recommendationType: String,
      recordTypes: Array,
      defaultRecordTypeFilter: String,
      showDialog: Boolean,
      value: String
    },
    mixins: [DropdownHelper],
    data() {
      return {
        loading: true,
        localRichText: '',
        recommendations: [],
        recommendationsColumns: ['title', 'recommendation', 'recommendationType', 'recordType', 'codeGroup', 'createdByName'],
        options: {
          // see the options API
          filterByColumn: true,
          initFilters: {
            recordType: this.defaultRecordTypeFilter ? this.defaultRecordTypeFilter : ''
          },
          orderBy: {
            column: 'title',
            ascending: true
          },
          listColumns: {
            recommendationType: [],
            recordType: [],
            codeGroup: []
          },
          columnsClasses: {
            title: 'twenty-percent',
            recommendation: 'fifty-percent'
          },
          headings: {
            recommendationType: 'Recommendation Type',
            recordType: 'Record Type',
            codeGroup: 'Code Group',
            createdByName: 'Created By'
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        dropdownsNeeded: ['RecommendationTypes', 'RecommendationRecordTypes', 'RecommendationCodeGroups']
      }
    },
    methods: {
      ...mapActions('recommendationLibrary/', ['GET_RECOMMENDATION_LIBRARY']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      intialize() {
        this.loading = true
        const query = {
          recommendationType: this.recommendationType,
          recordTypes: this.recordTypes
        }
        Promise.all([this.GET_RECOMMENDATION_LIBRARY(query), this.GET_DROPDOWNS(this.dropdownsNeeded)]).then(() => {
          this.options.listColumns.recommendationType = this.GET_COLUMNFILTERARRAY('RecommendationTypes')
          this.options.listColumns.recordType = this.GET_COLUMNFILTERARRAY('RecommendationRecordTypes')
          this.options.listColumns.codeGroup = this.GET_COLUMNFILTERARRAY('RecommendationCodeGroups')
          this.loading = false
        })
      },
      selectRecommendation(recommendation) {
        if (this.localRichText) {
          this.localRichText += '<p>'
          this.localRichText += recommendation
          this.localRichText += '</p>'
        } else {
          this.localRichText = recommendation
        }

        this.$emit('input', this.localRichText) // updates v-model in the parent
        this.$emit('change')
        this.$emit('close')
      },
      handleClose() {
        this.$emit('close')
      },
      getRecommendation(recommendationHtml) {
        const rec = recommendationHtml.length > 500 ? recommendationHtml.substring(0, 500) + '...' : recommendationHtml
        return rec
      }
    },
    created: function () {
      this.localRichText = this.value
      this.intialize()
    },
    computed: {
      ...mapState('recommendationLibrary/', ['recommendationLibrary']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapGetters('dropdowns/', ['GET_COLUMNFILTERARRAY'])
    }
  }

</script>

<style scoped>
>>> .twenty-percent {
  width: 20%;
}

>>> .fifty-percent {
  width: 50%;
}

>>> thead {
  word-break: break-word;
}
</style>
