<template>
  <div>
    <template v-if="!isLevel2">
      <div v-loading="cptLoading" element-loading-spinner="atom-audit-loader" id="cptCodeList">
        <v-client-table v-if="toggleGrid" key="cptGrid" class="hiaTable" ref="cptGrid" id="cptGrid" :data="outpatientReview.ascRecommendationsCptsApcs" :columns="columns" :options="options">
          <template slot="h__originalCpt">
            <div>Orig. CPT</div>
            <div class="feeScheduleHeader">
              <div v-if="outpatientReview.outpatientGrouperId === 1" class="feeScheduleHeaderChild">APC</div>
              <div v-if="outpatientReview.outpatientGrouperId === 2" class="feeScheduleHeaderChild">ASC PI</div>
            </div>
          </template>

          <template slot="h__originalMods">
            <div>Mods</div>
            <div class="feeScheduleHeader">
              <div v-if="outpatientReview.outpatientGrouperId === 1" class="feeScheduleHeaderChild">APC Wt.</div>
              <div v-if="outpatientReview.outpatientGrouperId === 2" class="feeScheduleHeaderChild">ASC Wt.</div>
            </div>
          </template>

          <template slot="h__originalDescCalc">
            <div>Desc</div>
            <div style="display: flex" class="feeScheduleHeader">
              <div class="feeScheduleHeaderChild" style="flex: 1 1">
                Pay Rate
              </div>
              <div class="feeScheduleHeaderChild" style="flex: 1 1">
                <div v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" style="margin-left: 10px;">
                  SI
                </div>
              </div>
              <div v-if="outpatientReview.outpatientGrouperId === 1" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
                APC Pay
              </div>
              <div v-if="outpatientReview.outpatientGrouperId === 2" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
                ASC Pay
              </div>
            </div>
          </template>

          <template slot="h__recommendedCpt">
            <div>Rec. CPT</div>
            <div class="feeScheduleHeader">
              <div v-if="outpatientReview.outpatientGrouperId === 1">Rec. APC</div>
              <div v-if="outpatientReview.outpatientGrouperId === 2">Rec. ASC PI</div>
            </div>
          </template>

          <template slot="h__recommendedMods">
            <div>Mods</div>
            <div class="feeScheduleHeader">
              <div v-if="outpatientReview.outpatientGrouperId === 1">Rec. APC Wt.</div>
              <div v-if="outpatientReview.outpatientGrouperId === 2">Rec. ASC Wt.</div>
            </div>
          </template>

          <template slot="h__recDescCalc">
            <div>Desc</div>
            <div style="display: flex" class="feeScheduleHeader">
              <div class="feeScheduleHeaderChild" style="flex: 1 1">
                Rec. Pay Rate
              </div>
              <div class="feeScheduleHeaderChild" style="flex: 1 1">
                <div v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" style="margin-left: 10px;">
                  Rec. SI
                </div>
              </div>
              <div v-if="outpatientReview.outpatientGrouperId === 1" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
                Rec. APC Pay
              </div>
              <div v-if="outpatientReview.outpatientGrouperId === 2" class="feeScheduleHeaderChild" style="flex: 1 1; margin-left: 10px;">
                Rec. ASC Pay
              </div>
            </div>
          </template>

          <template slot="h__reasonForChange">
            <div>Reason For Change</div>
            <div class="feeScheduleHeader">
              <div v-if="outpatientReview.outpatientGrouperId === 1">APC Change</div>
              <div v-if="outpatientReview.outpatientGrouperId === 2">ASC Change</div>
            </div>
          </template>

          <template slot="lineSequence" slot-scope="props">
            <span class="dragIcon drag-handle" style="margin-top: 3px; float: left">{{ props.row.lineSequence }}</span>
          </template>

          <!-- <template slot="procedureDate" slot-scope="props">
            <el-date-picker :disabled="disabled" size="mini" type="date" id="revDate" v-model="props.row.procedureDate" @input="putOutpatientCpt(props.row, 'cptCode')"
              format="MM/dd/yyyy">
            </el-date-picker>
          </template> -->

          <template slot="codeType" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="putOutpatientCpt(props.row, $event)" v-model="props.row.codeType" placeholder="Code Type" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list" filterable> </hia-el-select>
          </template>

          <template slot="originalCpt" slot-scope="props">
            <div>
              <HiaCodeInput :ref="props.row.cptId + 'origCpt'" style="width: 112px;" :inputId="`originalCpt_${props.index}`" class="noRightBorder borderLeftRadius" v-model="props.row.originalCpt" :disabled="disabled" @ellipsisClick="emitEllipsisClick(props.row.originalCpt)" @focus="emitFocusedCode(props.row.originalCpt)" @change="putOutpatientCpt(props.row, 'cptCode', $event)"> </HiaCodeInput>
            </div>
            <div style="margin-top: 1px;" v-html="cptCodeEdits(props.row.edits)"></div>
            <div class="feeScheduleData" title="Original APC" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apc', 0)"></div>
            <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" style="min-height: 12px;" :title="getApcTitle(props.row.origApc)">
              {{ props.row.origApc }}
            </div>
            <div class="feeScheduleData" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" v-html="getASCData(props.row, 'paymentIndicator', 0)"></div>
            <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" style="min-height: 12px;" :title="getPaymentIndicatorTitle(props.row.origPaymentIndicator)">
              {{ props.row.origPaymentIndicator }}
            </div>
          </template>

          <template slot="originalMods" slot-scope="props">
            <div style="margin-top: 3px;">
              <div ref="originalModifier" v-html="cptModifiers(props.row.ascRecommendationsModifiers, 'originalModifier')"></div>
            </div>
            <div title="Original APC Weight" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" v-html="getAPCData(props.row, 'apcWeight', 0)"></div>
            <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" style="min-height: 12px;">
              {{ props.row.origApcWeight }}
            </div>
            <div title="Original ASC Weight" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" v-html="getASCData(props.row, 'ascWeight', 0)"></div>
            <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" style="min-height: 12px;">
              {{ props.row.origAscWeight }}
            </div>
          </template>

          <template slot="originalDescCalc" slot-scope="props">
            <div style="margin-top: 3px;" class="overflow" :id="'OrDesc_' + props.row.cptId" @click="expandDesc(props.row.cptId)">{{ props.row.originalDescCalc }}</div>
            <div style="display: flex;">
              <div style="flex: 1 1;">
                <div title="Original APC Payment Rate" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apcPaymentRate', 0)" class="feeScheduleData"></div>
                <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" style="min-height: 12px;">
                  {{ props.row.origApcPayRate || props.row.origApcPayRate === 0 ? `${props.row.origApcPayRate * 100}%` : null }}
                </div>
                <div title="Original ASC Payment Rate" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" v-html="getASCData(props.row, 'ascPaymentRate', 0)" class="feeScheduleData"></div>
                <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" style="min-height: 12px;">
                  {{ props.row.origAscPayRate || props.row.origAscPayRate === 0 ? `${props.row.origAscPayRate * 100}%` : null }}
                </div>
              </div>
              <div style="flex: 1 1;">
                <div :title="getAPCData(props.row, 'statusIndicatorDescription', 0)" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData">
                  <div v-html="getAPCData(props.row, 'statusIndicator', 0)"></div>
                </div>
              </div>
              <div style="flex: 1 1;">
                <div>
                  <div title="Original APC Payment" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apcPayment', 0)" class="feeScheduleData"></div>
                  <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData">
                    <div id="origApcPayment" style="min-height: 12px;">{{ getOrigApcPayment(props.row) }}</div>
                  </div>
                  <div title="Original ASC Payment" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" v-html="getASCData(props.row, 'ascPayment', 0)" class="feeScheduleData"></div>
                  <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData">
                    <div id="origAscPayment" style="min-height: 12px;">{{ getOrigAscPayment(props.row) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template slot="recommendedCpt" slot-scope="props">
            <div>
              <HiaCodeInput :ref="props.row.cptId + 'recCpt'" style="width: 112px;" :placeholder="props.row.originalCpt" :inputId="`recommendedCpt_${props.index}`" :class="{ deleteCode: props.row.deleteCode }" v-model="props.row.recommendedCpt" :disabled="props.row.deleteCode || disabled" @ellipsisClick="emitEllipsisClick(props.row.recommendedCpt)" @focus="emitFocusedCode(props.row.recommendedCpt)" @change="putOutpatientCpt(props.row, 'cptCode', $event)"> </HiaCodeInput>
            </div>
            <div style="margin-top: 1px;" v-html="cptCodeEdits(props.row.recEdits)"></div>
            <div class="feeScheduleData" title="Recommended APC" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apc', 1)"></div>
            <div id="recApc" v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" style="min-height: 12px;" :title="getApcTitle(props.row.recApc)">
              {{ props.row.deleteApc ? 'DEL' : props.row.recApc }}
            </div>
            <div class="feeScheduleData" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" v-html="getASCData(props.row, 'paymentIndicator', 1)"></div>
            <div id="recAsc" v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" style="min-height: 12px;" :title="getPaymentIndicatorTitle(props.row.recPaymentIndicator)">
              {{ props.row.deleteAsc ? 'DEL' : props.row.recPaymentIndicator }}
            </div>
          </template>

          <template slot="recommendedMods" slot-scope="props">
            <div style="margin-top: 3px;">
              <div style="margin-top: 3px; display: inline-block" ref="recommendedModifier" v-html="recCptModifers(props.row.ascRecommendationsModifiers, 'originalModifier', 'recommendedModifier')"></div>
            </div>
            <div title="Recommended APC Weight" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" v-html="getAPCData(props.row, 'apcWeight', 1)"></div>
            <div id="recApcWeight" v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" style="min-height: 12px;">
              {{ props.row.recApcWeight }}
            </div>
            <div title="Recommended ASC Weight" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" v-html="getASCData(props.row, 'ascWeight', 1)"></div>
            <div id="recApcWeight" v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" style="min-height: 12px;">
              {{ props.row.recAscWeight }}
            </div>
          </template>

          <template slot="recDescCalc" slot-scope="props">
            <div style="margin-top: 3px;" class="overflow" :id="'RecDesc_' + props.row.cptId" @click="expandDesc(props.row.cptId)">{{ !props.row.deleteCode ? props.row.recDescCalc : 'Delete Code' }}</div>
            <div style="display: flex;">
              <div style="flex: 1 1;">
                <div title="Recommended APC Payment Rate" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apcPaymentRate', 1)" class="feeScheduleData"></div>
                <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData" style="min-height: 12px;">
                  {{ props.row.recApcPayRate || props.row.recApcPayRate === 0 ? `${props.row.recApcPayRate * 100}%` : null }}
                </div>
                <div title="Recommended ASC Payment Rate" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" v-html="getASCData(props.row, 'ascPaymentRate', 1)" class="feeScheduleData"></div>
                <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData" style="min-height: 12px;">
                  {{ props.row.recAscPayRate || props.row.recAscPayRate === 0 ? `${props.row.recAscPayRate * 100}%` : null }}
                </div>
              </div>
              <div style="flex: 1 1;">
                <div :title="getAPCData(props.row, 'statusIndicatorDescription', 1)" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData">
                  <div v-html="getAPCData(props.row, 'statusIndicator', 1)"></div>
                </div>
              </div>
              <div style="flex: 1 1;">
                <div>
                  <div title="Recommended APC Payment" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" v-html="getAPCData(props.row, 'apcPayment', 1)" class="feeScheduleData"></div>
                  <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 1" class="feeScheduleData">
                    <div id="recApcPayment" style="min-height: 12px;">{{ getRecApcPayment(props.row) }}</div>
                  </div>
                  <div title="Recommended ASC Payment" v-if="isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" v-html="getASCData(props.row, 'ascPayment', 1)" class="feeScheduleData"></div>
                  <div v-if="!isTrucodeEnabled() && outpatientReview.outpatientGrouperId === 2" class="feeScheduleData">
                    <div id="recAscPayment" style="min-height: 12px;">{{ getRecAscPayment(props.row) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.deleteCode" title="Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled" @change="putOutpatientCpt(props.row, 'deleteCpt', $event)"> </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.exclude" title="Exclude Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled" @change="putOutpatientCpt(props.row)"> </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.validated" title="Validated by Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled" @change="putOutpatientCpt(props.row)"> </iconCheckBox>
              <i class="mdi mdi-alpha-m" @click="openModModal(props.row)" style="cursor: pointer; float: left; font-size: 20px; color: grey;" title="Edit Modifiers"></i>
              <div class="feeScheduleData" style="max-height: 12px;" v-if="!isTrucodeEnabled() || props.row.hasApcRecommendation">
                <i class="mdi mdi-alpha-g" @click="openApcModal(props.row)" style="cursor: pointer; float: left; font-size: 20px; color: grey;" title="Edit Grouping"></i>
              </div>
            </div>
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <hia-el-select style="width: 82%" :title="disableReasonForChange(props.row) ? 'No change on line' : null" :disabled="disableReasonForChange(props.row) || disabled" @input="putOutpatientCpt(props.row, $event)" v-model="props.row.reasonForChange" placeholder="Reason for Change" itemkey="key" label="value" :dropdowns="dropdowns.outpatientReasonsForChangeCpt.list" clearable filterable> </hia-el-select>
            <i v-if="!disabled" style="float: right; cursor: pointer; margin-top: -20px;" title="Delete CPT Row" class="el-icon-close delete-button" @click="deleteCpt(props.row) || disabled"></i>
            <div style="display: flex;" class="feeScheduleSelect">
              <hia-el-select style="width: 83%" :title="disableReasonForChange(props.row) ? 'No change on line' : null" :disabled="disableReasonForChange(props.row) || disabled" @input="putOutpatientCpt(props.row, $event)" v-model="props.row.apcReasonForChange" placeholder="Reason for Change" itemkey="key" label="value" :dropdowns="dropdowns.outpatientReasonsForChangeApc.list" clearable filterable> </hia-el-select>
            </div>
          </template>
        </v-client-table>

        <v-client-table v-if="toggleGrid" key="cptGridAddRow" class="hiaTable" ref="cptGridAddRow" id="cptGridAddRow" :data="addRow" :columns="addRowColumns" :options="addOptions">
          <template slot="lineSequence">
            <div>&nbsp;</div>
          </template>

          <template slot="codeType" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="setAddRow(props.row)" v-model="props.row.codeType" placeholder="Code Type" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list" filterable> </hia-el-select>
          </template>

          <template slot="originalCpt" slot-scope="props">
            <input style="width: 100px; text-transform: uppercase; font-size: 12px" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="originalCpt" maxlength="5" minlength="3" v-model="props.row.originalCpt" v-on:keyup.enter="addCptRow(props.row)" />
          </template>

          <template slot="originalMods" slot-scope="props">
            <el-select :disabled="disabled" class="addMultiModifiers" @input="setMultiMods(props.row, 'originalModifiers', 'Recommended', 'recommendedModifiers', $event)" v-model="props.row.originalModifiers" filterable multiple clearable placeholder="Multiple Mods">
              <el-option v-for="item in dropdowns.modifiers.list" :key="item.key" :label="item.key" :value="item.key" :title="item.value"> </el-option>
            </el-select>
          </template>

          <template slot="originalDescCalc">
            <div>&nbsp;</div>
          </template>

          <template slot="recommendedCpt" slot-scope="props">
            <input style="width: 100px; text-transform: uppercase; font-size: 12px" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="recommendedCpt" maxlength="5" minlength="3" v-model="props.row.recommendedCpt" v-on:keyup.enter="addCptRow(props.row)" />
          </template>

          <template slot="recommendedMods" slot-scope="props">
            <el-select :disabled="disabled" class="addMultiModifiers" @input="setMultiMods(props.row, 'recommendedModifiers', 'Original', 'originalModifiers', $event)" v-model="props.row.recommendedModifiers" filterable multiple clearable placeholder="Multiple Mods">
              <el-option v-for="item in dropdowns.modifiers.list" :key="item.key" :label="item.key" :value="item.key" :title="item.value"> </el-option>
            </el-select>
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <el-popover ref="addPopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="addPopoverContent" v-model="showAddPopover" @after-enter="handleHidePopOver('addPopover')">
              <el-button :disabled="disabled" round icon="el-icon-plus" slot="reference" style="float: right;" type="primary" size="mini" @click="addCptRow(props.row)">Add </el-button>
            </el-popover>
          </template>
        </v-client-table>
      </div>
    </template>

    <div class="dialogs">
      <addMultipleCodes :disabled="disabled" :defaultCodeType="outpatientReview.project.defaultCodeType" :codeTypeRequired="true" :show="showAddMultipleCodes" :type="'CPT'" :review="outpatientReview" @close="handleCloseAddMultipleCodes" @postAddMultipleCodes="postAddMultipleCodes"> </addMultipleCodes>
    </div>

    <el-dialog
      v-if="modalCpt !== null"
      class="modDialog"
      :visible="showModModal"
      :title="`Edit Modifiers for CPT Line ${modalCpt.lineSequence}`"
      width="80%"
      :before-close="
        () => {
          showModModal = false
        }
      "
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-form size="mini" label-position="left" label-width="140px">
        <el-row style="margin-bottom: 20px;">
          <el-col :span="12">
            <el-form-item label="Original Cpt">
              <div style="width: 100%;">
                <el-input style="width: 112px; display: inline-block;" inputId="originalCptMod" v-model="modalCpt.originalCpt" disabled> </el-input>
                <span style=" margin-left: 5px; margin-top: 3px; display: inline-block" ref="originalModifier" v-html="cptModifiers(modalCpt.ascRecommendationsModifiers, 'originalModifier')"> </span>
              </div>
              <div>
                <div style="margin: 3px 5px 0px 0px; display: inline-block"><span style="float: left; margin-top: 1px;" v-html="cptCodeEdits(modalCpt.edits)"></span></div>
                <div style="margin-top: 3px; display: inline-block" class="overflow" :id="'OrDesc_Mod_' + modalCpt.cptId" @click="expandDesc(modalCpt.cptId, true)">{{ modalCpt.originalDescCalc }}</div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Recommended Cpt">
              <div style="width: 100%;">
                <el-input style="width: 112px; display: inline-block;" :placeholder="modalCpt.originalCpt" inputId="recCptMod" v-model="modalCpt.recommendedCpt" disabled :class="{ deleteCode: modalCpt.level2DeleteCode || modalCpt.deleteCode }"> </el-input>
                <span style="margin-left: 5px; margin-top: 3px; display: inline-block" ref="originalModifier" v-html="recCptModifers(modalCpt.ascRecommendationsModifiers, 'originalModifier', 'recommendedModifier')"> </span>
              </div>
              <div style="margin: 3px 5px 0px 0px; display: inline-block"><span style="float: left; margin-top: 1px;" v-html="cptCodeEdits(modalCpt.recEdits)"></span></div>
              <div style="margin-top: 3px; display: inline-block" class="overflow" :id="'RecDesc_Mod_' + modalCpt.cptId" @click="expandDesc(modalCpt.cptId, true)">{{ !modalCpt.deleteCode ? modalCpt.recDescCalc : 'Delete Code' }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row>
        <el-col :span="12">
          <h4 class="headerWithBorder">Original Modifiers</h4>
        </el-col>
        <el-col :span="12">
          <h4 class="headerWithBorder">Recommended Modifiers</h4>
        </el-col>
      </el-row>
      <outpatientReviewModifiers :cpt="modalCpt"> </outpatientReviewModifiers>

      <span slot="footer">
        <el-button @click="showModModal = false" size="mini" type="text" plain round>Close</el-button>
      </span>
    </el-dialog>

    <outpatientReviewGrouping v-if="showApcModal" :cpt="modalCpt" :showApcModal="showApcModal" @save="putOutpatientGroupingApc" @cancel="showApcModal = false"> </outpatientReviewGrouping>
    <outpatientReviewASCGrouping v-if="showAscModal" :cpt="modalCpt" :showAscModal="showAscModal" @save="putOutpatientGroupingAsc" @cancel="showAscModal = false"> </outpatientReviewASCGrouping>

    <el-popover ref="savePopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent" @after-enter="handleHidePopOver('savePopover')"> </el-popover>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
  import iconCheckBox from '@/components/controls/iconCheckBox'
  import HiaCodeInput from '@/components/controls/HiaCodeInput'
  import outpatientReviewModifiers from './OutpatientReviewModifiers'
  import outpatientReviewGrouping from './OutpatientReviewGrouping'
  import outpatientReviewASCGrouping from './OutpatientReviewASCGrouping'
  import Sortable from 'sortablejs'
  import addMultipleCodes from '@/components/controls/addMultipleCodes'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'outpatientReviewCpts',
    props: ['showAddMultipleCodes'],
    mixins: [HiaAuthorization],
    data: function() {
      return {
        activeName: null,
        toggleGrid: true,
        loading: false,
        // disabled: false,
        theSortable: null,
        addPopoverContent: '',
        savePopoverContent: '',
        showAddPopover: false,
        showModModal: false,
        showApcModal: false,
        showAscModal: false,
        modalCpt: null,
        addRow: [{ codeType: null, originalCpt: '', recommendedCpt: '', originalModifiers: [], recommendedModifiers: [] }],
        addRowColumns: ['lineSequence', 'codeType', 'originalCpt', 'originalMods', 'originalDescCalc', 'recommendedCpt', 'recommendedMods', 'recDescCalc', 'actions', 'reasonForChange'],
        columns: ['lineSequence', 'codeType', 'originalCpt', 'originalMods', 'originalDescCalc', 'recommendedCpt', 'recommendedMods', 'recDescCalc', 'actions', 'reasonForChange'],
        level2Columns: ['lineSequence', 'codeType', 'originalCpt', 'originalDescCalc', 'recommendedCpt', 'recDescCalc', 'actions', 'reasonForChange', 'level2Cpt', 'level2DescCalc', 'level2Actions', 'level2ReasonForChange'],
        level2AddRowColumns: ['lineSequence', 'codeType', 'originalCpt', 'originalDescCalc', 'recommendedCpt', 'recDescCalc', 'actions', 'reasonForChange', 'level2Cpt', 'level2DescCalc', 'level2Actions', 'level2ReasonForChange'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          showChildRowToggler: false,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'lineSequenceCol',
            procedureDate: 'procedureDateCol',
            codeType: 'codeTypeCol',
            originalCpt: 'oneHundredPx',
            originalMods: 'seventyFivePx',
            originalDescCalc: 'twentyPercent',
            recommendedCpt: 'oneHundredPx',
            recDescCalc: 'twentyPercent',
            recommendedMods: 'seventyFivePx',
            actions: 'actionsCol',
            reasonForChange: 'reasonForChangeCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            procedureDate: 'Outpatientcedure Date',
            codeType: 'Type',
            grouperFlags: 'GF',
            recommendedGrouperFlags: 'GF',
            originalCpt: 'Orig. CPT',
            originalMods: 'Mods',
            recommendedCpt: 'Rec. CPT',
            recommendedMods: 'Mods',
            originalDescCalc: 'Desc.',
            recDescCalc: 'Desc.',
            recommendedEdits: 'Edits',
            reasonForChange: 'Reason For Change',
            delete: '',
            add: ''
          }
        },
        level2Options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'lineSequenceCol',
            originalMods: 'seventyFivePx',
            originalCpt: 'seventyFivePx',
            originalDescCalc: 'twentyPercent descOverflow',
            recommendedCpt: 'seventyFivePx',
            recommendedMods: 'seventyFivePx',
            recDescCalc: 'twentyPercent descOverflow',
            actions: 'actionsCol',
            reasonForChange: 'reasonForChangeCol',
            level2Cpt: 'seventyFivePx',
            level2DescCalc: 'twentyPercent descOverflow',
            level2Actions: 'actionsCol',
            level2ReasonForChange: 'reasonForChangeCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            grouperFlags: 'GF',
            recommendedGrouperFlags: 'GF',
            originalCpt: 'Orig. Px',
            recommendedCpt: 'Rec. Px',
            originalDescCalc: 'Desc.',
            recDescCalc: 'Desc.',
            recommendedEdits: 'Edits',
            reasonForChange: 'Reason For Change',
            level2Cpt: 'Lvl. 2 Px',
            level2DescCalc: 'Desc',
            level2Actions: 'Actions',
            level2ReasonForChange: 'Reason For Change',
            delete: '',
            add: ''
          }
        },
        addOptions: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          showChildRowToggler: false,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'lineSequenceCol',
            procedureDate: 'procedureDateCol',
            codeType: 'codeTypeCol',
            originalCpt: 'oneHundredPx',
            originalMods: 'codeTypeCol',
            originalDescCalc: 'tenPercent',
            recommendedCpt: 'oneHundredPx',
            recDescCalc: 'tenPercent',
            recommendedMods: 'codeTypeCol',
            actions: 'actionsCol',
            reasonForChange: 'reasonForChangeCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            procedureDate: 'Outpatientcedure Date',
            codeType: 'Type',
            grouperFlags: 'GF',
            recommendedGrouperFlags: 'GF',
            originalCpt: 'Orig. CPT',
            originalMods: 'Mods',
            recommendedCpt: 'Rec. CPT',
            recommendedMods: 'Mods',
            originalDescCalc: 'Desc.',
            recDescCalc: 'Desc.',
            recommendedEdits: 'Edits',
            reasonForChange: 'Reason For Change',
            delete: '',
            add: ''
          }
        }
      }
    },
    components: {
      iconCheckBox,
      HiaCodeInput,
      addMultipleCodes,
      outpatientReviewModifiers,
      outpatientReviewGrouping,
      outpatientReviewASCGrouping
    },
    methods: {
      ...mapActions('outpatientReviews/', ['PUT_OUTPATIENT_CPT', 'DELETE_OUTPATIENT_CPT', 'POST_OUTPATIENT_CPT', 'PUT_OUTPATIENT_CPTS_FOR_RESEQUENCE']),
      ...mapMutations('outpatientReviews/', ['SET_OUTPATIENT_CPT_ROW', 'PUSH_CPT_ROW', 'SET_OUTPATIENT_CPTS']),
      setAddRow(row) {
        this.$set(this.addRow, 0, row)
      },
      putOutpatientGroupingAsc(cpt, e) {
        this.modalCpt = JSON.parse(JSON.stringify(cpt))
        this.putOutpatientCpt(this.modalCpt, 'nonTruCodeAscGrouping')
      },
      putOutpatientGroupingApc(cpt, e) {
        this.modalCpt = JSON.parse(JSON.stringify(cpt))
        this.putOutpatientCpt(this.modalCpt, 'nonTruCodeApcGrouping')
      },
      openModModal(row) {
        this.modalCpt = this.outpatientReview.ascRecommendationsCptsApcs.find(x => x.cptId === row.cptId)
        this.showModModal = true
      },
      openApcModal(row) {
        this.modalCpt = this.outpatientReview.ascRecommendationsCptsApcs.find(x => x.cptId === row.cptId)
        if (this.outpatientReview.outpatientGrouperId === 1) {
          this.showApcModal = true
        }
        if (this.outpatientReview.outpatientGrouperId === 2) {
          this.showAscModal = true
        }
      },
      getOrigApcPayment(row) {
        if (row.origApc && row.origApcWeight && (row.origApcPayRate || row.origApcPayRate === 0)) {
          return `$${row.origApcPayment === 0 || row.origApcPayment > 0 ? row.origApcPayment.toFixed(2) : null}`
        }
      },
      getRecApcPayment(row) {
        if (row.recApc && row.recApcWeight && (row.recApcPayRate || row.recApcPayRate === 0)) {
          return `$${row.recApcPayment === 0 || row.recApcPayment > 0 ? row.recApcPayment.toFixed(2) : null} `
        }
        if (row.deleteApc) {
          return '$0.00'
        }
      },
      getOrigAscPayment(row) {
        if (row.origPaymentIndicator && row.origAscWeight && (row.origAscPayRate || row.origAscPayRate === 0)) {
          return `$${row.origAscPayment === 0 || row.origAscPayment > 0 ? row.origAscPayment.toFixed(2) : null}`
        }
      },
      getRecAscPayment(row) {
        if (row.recPaymentIndicator && row.recAscWeight && (row.recAscPayRate || row.recAscPayRate === 0)) {
          return `$${row.recAscPayment === 0 || row.recAscPayment > 0 ? row.recAscPayment.toFixed(2) : null} `
        }
        if (row.deleteAsc) {
          return '$0.00'
        }
      },
      getApcTitle(apc) {
        const apcListRow = this.apcList.find(x => x.apc === apc)
        if (apcListRow) {
          return apcListRow.description
        }
      },
      getPaymentIndicatorTitle(pi) {
        const piRow = this.dropdowns.ascPaymentIndicators.list.find(x => x.key === pi)
        if (piRow) {
          return piRow.value
        }
      },
      async putOutpatientCpt(row, changedField, e) {
        if (!row.recommendedCpt && !row.originalCpt) {
          this.savePopoverContent = 'Either Original CPT or Recommended CPT must be populated. To delete the entire row, use the X button on the right side.'
          this.openSavePopover(e)
          const backupRow = this.outpatientReview.ascRecommendationsCptsApcs.find(x => x.cptId === row.cptId)
          this.SET_OUTPATIENT_CPT_ROW(backupRow)
          this.$refs[row.cptId + 'recCpt'].$forceUpdate()
          this.$refs[row.cptId + 'origCpt'].$forceUpdate()
          return
        }

        if (!row.originalCpt && changedField === 'deleteCpt') {
          this.savePopoverContent = 'The Delete Code recommendation cannot be used if there is no Original CPT.'
          this.openSavePopover(e)
          const backupRow = this.outpatientReview.ascRecommendationsCptsApcs.find(x => x.cptId === row.cptId)
          this.SET_OUTPATIENT_CPT_ROW(backupRow)
          return
        }

        if (row.reasonForChange && !row.recommendedCpt && !row.deleteCode) {
          try {
            await this.$confirm('This will clear the Reason for Change. Continue?', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.reasonForChange = null
            row.apcReasonForChange = null
          } catch (e) {
            const backupRow = this.outpatientReview.ascRecommendationsCptsApcs.find(x => x.cptId === row.cptId)
            this.SET_OUTPATIENT_CPT_ROW(backupRow)
            this.$refs[row.cptId + 'recCpt'].$forceUpdate()
            return
          }
        }

        if (changedField === 'deleteCpt' && row.recommendedCpt && row.deleteCode) {
          try {
            await this.$confirm(`This will clear the Recommended CPT ${row.recommendedCpt}. Continue ?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.recommendedCpt = null
          } catch (e) {
            row.deleteCode = false
            this.SET_OUTPATIENT_CPT_ROW(row)
            return
          }
        }

        if (changedField === 'deleteCptLevel2' && row.level2Cpt && row.level2DeleteCode) {
          try {
            await this.$confirm(`This will clear the Level 2 Recommended CPT ${row.level2Cpt}. Continue ?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.level2Cpt = null
          } catch (e) {
            row.level2DeleteCode = false
            this.SET_OUTPATIENT_CPT_ROW(row)
            return
          }
        }

        this.SET_OUTPATIENT_CPT_ROW(row)
        const payload = { cptRow: row, changedField: changedField }

        this.loading = true
        try {
          await this.PUT_OUTPATIENT_CPT(payload)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.outpatientReview)
          this.$message({
            message: 'CPT Code Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'CPT Code Update Error',
            type: 'error'
          })
        }
      },
      async addCptRow(row) {
        if (!this.isLevel2 && !row.codeType && !row.originalCpt && !row.recommendedCpt) {
          this.addPopoverContent = 'Code Type and either Original CPT or Recommended CPT must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (!this.isLevel2 && !row.originalCpt && !row.recommendedCpt) {
          this.addPopoverContent = 'Either Original CPT or Recommended CPT must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (this.isLevel2 && !row.codeType && !row.originalCpt && !row.recommendedCpt && !row.level2Cpt) {
          this.addPopoverContent = 'Code Type and either Original CPT, Recommended CPT or Level 2 CPT must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (this.isLevel2 && !row.originalCpt && !row.recommendedCpt && !row.level2Cpt) {
          this.addPopoverContent = 'Either Original CPT, Recommended CPT or Level 2 CPT must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (!row.codeType) {
          this.addPopoverContent = 'Code Type must be populated to add row.'
          this.showAddPopover = true
          return
        }

        row.cptId = 0
        row.ascTableId = this.outpatientReview.ascTableId
        row.newPrimary = false
        row.level2NewPrimary = false
        row.originalCpt = row.originalCpt
          ? row.originalCpt
              .toUpperCase()
              .replace('.', '')
              .trim()
          : null
        row.recommendedCpt = row.recommendedCpt
          ? row.recommendedCpt
              .toUpperCase()
              .replace('.', '')
              .trim()
          : null

        // handle multi modifiers
        let version = null
        let modifiers = []
        if (row.originalModifiers.length) {
          version = 'originalModifier'
          modifiers = row.originalModifiers
        }
        if (row.recommendedModifiers.length) {
          version = 'recommendedModifier'
          modifiers = row.recommendedModifiers
        }
        if (version) {
          row.ascRecommendationsModifiers = this.buildModifiers(modifiers, version, row.codeType)
        }

        this.loading = true
        try {
          await this.POST_OUTPATIENT_CPT([row])
          this.$set(this.addRow, 0, { codeType: row.codeType, originalCpt: '', recommendedCpt: '', originalModifiers: [], recommendedModifiers: [] })
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.outpatientReview)
          this.$message({
            message: 'CPT Code Added Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'CPT Code Added Error',
            type: 'error'
          })
        }
      },
      async deleteCpt(row) {
        this.loading = true
        try {
          await this.DELETE_OUTPATIENT_CPT(row)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.outpatientReview)
          this.$message({
            message: 'CPT Code Deleted Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'CPT Code Delete Error',
            type: 'error'
          })
        }
      },
      disableReasonForChange(row) {
        if (!row.recommendedCpt && !row.deleteCode) {
          return true
        }
        return false
      },
      openSavePopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.savePopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      emitEllipsisClick(code) {
        this.emitFocusedCode(code)
        this.$emit('ellipsisClick', code)
      },
      emitFocusedCode(focusedCode) {
        if (focusedCode && focusedCode !== 'DEL') {
          const truCodeQueryObject = {
            code: focusedCode,
            type: 'CPTAPC'
          }
          this.$emit('onCodeFocus', truCodeQueryObject)
        }
      },
      cptCodeEdits(edits) {
        if (edits.length) {
          let pxDesc = ''
          for (const i in edits) {
            pxDesc += edits[i].description.replace(/<[^>]*>/g, '') + ' \n'
          }
          return '<div style="background: url(/static/trucode/css/img/icons/warning.png); width: 16px; height: 16px; margin-top: 2px"  title="' + pxDesc.trim() + '"></div>'
        }
      },
      getASCData(cpt, field, level) {
        if (level === 1 && cpt.orAscGroupingProcResult && cpt.recAscGroupingProcResult && cpt.orAscGroupingProcResult.payment_indicator === cpt.recAscGroupingProcResult.payment_indicator && cpt.orAscPricingProcResult.payment === cpt.recAscPricingProcResult.payment) {
          return '&nbsp;'
        }

        if (level === 0 && !cpt.orAscGroupingProcResult) {
          return '&nbsp;'
        }

        if (level === 0 && cpt.orAscGroupingProcResult && !cpt.orAscGroupingProcResult.payment_indicator) {
          return '&nbsp;'
        }

        if (level === 1 && !cpt.orAscGroupingProcResult && !cpt.recAscGroupingProcResult) {
          return '&nbsp;'
        }

        if (level === 1 && cpt.recAscGroupingProcResult && !cpt.recAscGroupingProcResult.payment_indicator) {
          return '&nbsp;'
        }

        switch (field) {
          case 'paymentIndicator':
            switch (level) {
              case 0:
                return cpt.orAscGroupingProcResult ? `<span title="${cpt.orAscGroupingProcResult.payment_indicator_description}"> ${cpt.orAscGroupingProcResult.payment_indicator}</span>` : '&nbsp;'
              case 1:
                if (cpt.orAscGroupingProcResult && !cpt.recAscGroupingProcResult) {
                  return '<span title="ASC Deleted">DEL</span>'
                }
                if (cpt.orAscGroupingProcResult && cpt.recAscGroupingProcResult && !cpt.recAscGroupingProcResult.payment_indicator) {
                  return '<span title="ASC Deleted">DEL</span>'
                }
                return cpt.recAscGroupingProcResult ? `<span title="${cpt.recAscGroupingProcResult.payment_indicator_description}"> ${cpt.recAscGroupingProcResult.payment_indicator}</span>` : '&nbsp;'
            }
            break
          case 'ascWeight':
            switch (level) {
              case 0:
                return cpt.orAscPricingProcResult ? cpt.orAscPricingProcResult.weight : '&nbsp;'
              case 1:
                if (cpt.orAscGroupingProcResult && !cpt.recAscGroupingProcResult) {
                  return '&nbsp;'
                }
                if (cpt.orAscGroupingProcResult && cpt.recAscGroupingProcResult && !cpt.recAscGroupingProcResult.payment_indicator) {
                  return '&nbsp;'
                }
                return cpt.recAscGroupingProcResult && cpt.recAscPricingProcResult ? cpt.recAscPricingProcResult.weight : '&nbsp;'
            }
            break
          case 'ascPaymentRate':
            switch (level) {
              case 0:
                return cpt.orAscPricingProcResult ? cpt.orAscPricingProcResult.percentage * 100 + '%' : '&nbsp;'
              case 1:
                if (cpt.orAscGroupingProcResult && !cpt.recAscGroupingProcResult) {
                  return '&nbsp;'
                }
                if (cpt.orAscGroupingProcResult && cpt.recAscGroupingProcResult && !cpt.recAscGroupingProcResult.payment_indicator) {
                  return '&nbsp;'
                }
                return cpt.recAscGroupingProcResult && cpt.recAscPricingProcResult ? cpt.recAscPricingProcResult.percentage * 100 + '%' : '&nbsp;'
            }
            break
          case 'ascPayment':
            switch (level) {
              case 0:
                return cpt.orAscPricingProcResult && cpt.orAscPricingProcResult.payment !== null ? '$' + cpt.orAscPricingProcResult.payment.toFixed(2) : '&nbsp;'
              case 1:
                return cpt.recAscPricingProcResult && cpt.recAscPricingProcResult.payment !== null ? '$' + cpt.recAscPricingProcResult.payment.toFixed(2) : '&nbsp;'
            }
            break
        }
      },
      getAPCData(cpt, field, level) {
        if (level === 1 && cpt.orGroupingProcResult && cpt.recGroupingProcResult && cpt.orGroupingProcResult.apc === cpt.recGroupingProcResult.apc && cpt.orPricingProcResult.payment === cpt.recPricingProcResult.payment) {
          return '&nbsp;'
        }

        if (level === 0 && !cpt.orGroupingProcResult) {
          return '&nbsp;'
        }

        if (level === 1 && !cpt.orGroupingProcResult && !cpt.recGroupingProcResult) {
          return '&nbsp;'
        }

        switch (field) {
          case 'apc':
            switch (level) {
              case 0:
                return cpt.orGroupingProcResult ? `<span title="${cpt.orGroupingProcResult.apc_description}"> ${cpt.orGroupingProcResult.apc}</span>` : '&nbsp;'
              case 1:
                if (!cpt.recGroupingProcResult && cpt.orGroupingProcResult) {
                  return '<span title="APC Deleted">DEL</span>'
                }
                return cpt.recGroupingProcResult ? `<span title="${cpt.recGroupingProcResult.apc_description}"> ${cpt.recGroupingProcResult.apc}</span>` : '&nbsp;'
            }
            break
          case 'apcWeight':
            switch (level) {
              case 0:
                return cpt.orPricingProcResult ? cpt.orPricingProcResult.weight : '&nbsp;'
              case 1:
                return cpt.recGroupingProcResult && cpt.recPricingProcResult ? cpt.recPricingProcResult.weight : '&nbsp;'
            }
            break
          case 'apcPaymentRate':
            switch (level) {
              case 0:
                return cpt.orPricingProcResult ? cpt.orPricingProcResult.percentage * 100 + '%' : '&nbsp;'
              case 1:
                return cpt.recGroupingProcResult && cpt.recPricingProcResult ? cpt.recPricingProcResult.percentage * 100 + '%' : '&nbsp;'
            }
            break
          case 'statusIndicator':
            switch (level) {
              case 0:
                return cpt.orGroupingProcResult ? cpt.orGroupingProcResult.status_indicator : '&nbsp;'
              case 1:
                return cpt.recGroupingProcResult ? cpt.recGroupingProcResult.status_indicator : '&nbsp;'
            }
            break
          case 'apcPayment':
            switch (level) {
              case 0:
                return cpt.orPricingProcResult && cpt.orPricingProcResult.payment !== null ? '$' + cpt.orPricingProcResult.payment.toFixed(2) : '&nbsp;'
              case 1:
                return cpt.recPricingProcResult && cpt.recPricingProcResult.payment !== null ? '$' + cpt.recPricingProcResult.payment.toFixed(2) : '&nbsp;'
            }
            break
          case 'statusIndicatorDescription':
            switch (level) {
              case 0:
                return cpt.orGroupingProcResult ? cpt.orGroupingProcResult.status_indicator_description : 'Original Status Indicator'
              case 1:
                return cpt.recGroupingProcResult ? cpt.recGroupingProcResult.status_indicator_description : 'Recommended Status Indicator'
            }
            break
        }
      },
      async handleDragStop(e) {
        const temp = this.outpatientReview.ascRecommendationsCptsApcs[e.oldIndex]
        this.outpatientReview.ascRecommendationsCptsApcs.splice(e.oldIndex, 1)
        this.outpatientReview.ascRecommendationsCptsApcs.splice(e.newIndex, 0, temp)
        this.updateLineSequence(this.outpatientReview.ascRecommendationsCptsApcs)
        this.SET_OUTPATIENT_CPTS(this.outpatientReview.ascRecommendationsCptsApcs)

        // force the grid to re-initialize by destroying and recreating it
        // and then reattaching the sortable
        this.toggleGrid = false
        this.theSortable.destroy()
        this.$nextTick(() => {
          this.toggleGrid = true
          this.$nextTick(() => {
            this.attachSortable()
          })
        })

        this.loading = true
        try {
          await this.PUT_OUTPATIENT_CPTS_FOR_RESEQUENCE(this.outpatientReview.ascRecommendationsCptsApcs)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.outpatientReview)
        } catch (e) {
          this.loading = true
        }
      },
      updateLineSequence(array) {
        array.map((o, i) => {
          o.lineSequence = i + 1
          return o
        })
      },
      codeType(codeType) {
        if (codeType !== null) {
          let desc = ''
          for (const i in this.dropdowns.codeTypes.list) {
            if (codeType === this.dropdowns.codeTypes.list[i].key) {
              desc = this.dropdowns.codeTypes.list[i].value
              break
            }
          }
          return desc
        }
      },
      cptModifiers(modifiers, version) {
        // remove blank mods
        modifiers = modifiers.filter(function(n) {
          if (n[version] !== undefined && n[version] !== null) {
            return true
          }
          return false
        })
        if (modifiers !== null) {
          const modLength = modifiers.length
          let modifer = ''
          let comma = ''
          if (modLength) {
            const cptId = modifiers[0].cptid
            for (let i = 0; i < modLength; i++) {
              if (modifiers[i][version] !== null && modifiers[i][version].trim().length > 1) {
                comma = modLength > 1 && i + 1 !== modLength ? ',' : ''
                modifer += '<span id="' + version + '_' + i + '" cptId="' + cptId + '" title="' + this.modDescription(modifiers[i][version]) + '">' + modifiers[i][version] + '</span>' + comma
              }
            }
          }
          // modifer += '&nbsp;'
          return modifer
        }
        return null
      },
      recCptModifers(row, originalModifier, recommendedModifier) {
        if (row.length) {
          let modifiers = []
          row.forEach((v, i) => {
            const row = v
            row.displayMod = v[recommendedModifier] || v[originalModifier]
            modifiers.push(row)
          })
          modifiers = modifiers.filter(function(n) {
            if (n.displayMod !== undefined && n.displayMod !== null) {
              return true
            }
            return false
          })
          if (modifiers !== null) {
            const modLength = modifiers.length
            let modifer = ''
            let comma = ''
            if (modLength) {
              for (let i = 0; i < modLength; i++) {
                if (modifiers[i].displayMod !== null && modifiers[i].displayMod.trim().length > 1) {
                  comma = modLength > 1 && i + 1 !== modLength ? ',' : ''
                  modifer += `<span id="recommendedModifier_${i}" ${modifiers[i].deleteCode ? 'class="strikethrough"' : null} title="${this.modDescription(modifiers[i].displayMod, modifiers[i].deleteCode)}">${modifiers[i].displayMod}</span>${comma}`
                }
              }
            }
            // modifer += '&nbsp;'
            return modifer
          }
        }
        return null
      },
      modDescription(modifier, deleteCode = false) {
        if (deleteCode) {
          return 'Delete Modifier'
        }

        if (modifier !== null && modifier.length) {
          let desc = ''
          for (const i in this.dropdowns.modifiers.list) {
            if (modifier === this.dropdowns.modifiers.list[i].key) {
              desc = this.dropdowns.modifiers.list[i].value
                .split('|')[1]
                .trim()
                .toLowerCase()
              break
            }
          }
          return desc
        }
      },
      attachSortable() {
        const gridElementName = this.isLevel2 ? 'cptGridLevel2' : 'cptGrid'
        const gridDiv = document.getElementById(gridElementName)
        if (gridDiv) {
          const tbody = gridDiv.getElementsByTagName('tbody')
          this.theSortable = Sortable.create(tbody[0], {
            animation: 200,
            draggable: 'tr.VueTables__row',
            handle: '.drag-handle',
            onEnd: e => {
              this.handleDragStop(e)
            }
          })
        }
      },
      handleCloseAddMultipleCodes() {
        this.$emit('handleCloseAddMultipleCodes', false)
      },
      async postAddMultipleCodes(data) {
        if (data.codes) {
          // populate new codes
          const newCodes = []
          let startLineSequence = this.outpatientReview.ascRecommendationsCptsApcs.length + 1
          data.codes.forEach((v, i) => {
            const newCode = {
              ascTableId: this.outpatientReview.ascTableId,
              lineSequence: startLineSequence,
              add: true,
              originalCpt: data.isRecommended ? null : v.trim(),
              validated: false,
              exclude: false,
              recommendedCpt: data.isRecommended ? v.trim() : null,
              reasonForChange: '',
              level2Cpt: null,
              procedureDate: null,
              level2ReasonForChange: '',
              codeType: data.codeType
            }
            newCodes.push(newCode)
            startLineSequence++
          })
          data.codes = newCodes
          data.changedField = 'addMultipleCodes'

          this.loading = true
          try {
            await this.POST_OUTPATIENT_CPT(data.codes)
            this.$message({
              message: 'Multiple Codes Added Successful',
              type: 'success'
            })
          } catch (e) {
            this.$message({
              message: 'Multiple Codes Added Error',
              type: 'error'
            })
          }
          this.loading = false
        }
      },
      expandDesc(cptId, isMod = false) {
        // const targetId = $event.currentTarget.id
        // console.log(targetId)
        const orTargetId = isMod ? 'OrDesc_Mod_' + cptId : 'OrDesc_' + cptId
        const recTargetId = isMod ? 'RecDesc_Mod_' + cptId : 'RecDesc_' + cptId

        const orEl = document.getElementById(orTargetId)
        const recEl = document.getElementById(recTargetId)
        orEl.classList.toggle('overflow')
        recEl.classList.toggle('overflow')
      },
      async setMultiMods(row, version, otherLabel, otherVersion, event) {
        // handle clear logic for original vs recommended
        if (row[otherVersion] && row[otherVersion].length) {
          try {
            await this.$confirm(`This will clear the ${otherLabel} Modifier ${row[otherVersion]}. Continue?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row[otherVersion] = []
            this.setAddRow(row)
          } catch (e) {
            row[version] = []
            this.setAddRow(row)
          }
        } else {
          this.setAddRow(row)
        }
      },
      buildModifiers(modifiers, version, codeType) {
        // populate new mods
        const newMods = []
        let startLineSequence = 1
        modifiers.forEach((v, i) => {
          const newMod = {
            lineSequence: startLineSequence,
            [version]: v ? v.trim() : null,
            codeType: codeType
          }
          newMods.push(newMod)
          startLineSequence++
        })

        return newMods
      }
    },
    mounted: function() {
      // not needed any more now that we watch outpatientReview.outpatientGrouperId
      // this.$nextTick(() => {
      //   this.attachSortable()
      // })
    },
    watch: {
      isLevel2(val) {
        this.$nextTick(() => {
          if (this.theSortable) {
            this.attachSortable()
          }
        })
      },
      'outpatientReview.outpatientGrouperId': function(val) {
        // needed to reactively update ASC vs APC labels in the table
        this.toggleGrid = false
        if (this.theSortable) {
          this.theSortable.destroy()
        }

        this.$nextTick(() => {
          this.toggleGrid = true
          this.$nextTick(() => {
            this.attachSortable()
          })
        })
      },
      'outpatientReview.project.defaultCodeType': function(val) {
        if (this.outpatientReview.project) {
          this.addRow = [{ codeType: this.outpatientReview.project.defaultCodeType, originalCpt: '', recommendedCpt: '', originalModifiers: [], recommendedModifiers: [] }]
        }
      }
    },
    computed: {
      ...mapState('outpatientReviews/', ['outpatientReview', 'cptLoading', 'apcList']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user']),
      ...mapGetters('dropdowns/', ['GET_VALUEFROMKEY']),
      disabled() {
        return !!this.readOnly() || this.outpatientReview.reviewStatusId !== 2
      },
      isLevel2: function() {
        return this.outpatientReview.reviewLevel > 1
      }
    }
  }
</script>

<style scoped>
  >>> .strikethrough {
    text-decoration: line-through;
  }

  .dragIcon {
    cursor: move;
    margin: 1px 0px 0px 1px;
    padding: 0px 0px 0px 0px;
    float: left;
    height: 20px;
    color: #1f2d3d;
  }

  .drag-handle {
    cursor: move;
    display: block;
    float: left;
  }

  .modButton {
    padding: 3px 7px;
  }

  >>> .feeScheduleHeader {
    color: #005695;
    border-top: 1px solid rgb(204, 204, 204);
    margin-top: 2px;
    padding-top: 2px;
    font-weight: normal;
    font-size: 11px;
  }

  >>> .feeScheduleData {
    border-top: 1px solid rgb(204, 204, 204);
    padding-top: 7px;
    font-size: 11px;
    position: absolute;
    bottom: 0px;
    margin: 10px 0px 10px 0px;
    width: 100%;
    color: #005695;
  }

  >>> .feeScheduleSelect {
    border-top: 1px solid rgb(204, 204, 204);
    padding-top: 3px;
    font-size: 11px;
    position: absolute;
    bottom: -6px;
    margin: 10px 0px 10px 0px;
    width: 97%;
    color: #005695;
  }

  >>> .lineSequenceCol {
    width: 15px !important;
  }

  >>> .procedureDateCol {
    width: 130px;
  }

  >>> .codeTypeCol {
    width: 130px;
  }

  >>> .actionsCol {
    max-width: 90px;
    min-width: 90px;
  }

  >>> .reasonForChangeCol {
    min-width: 140px;
    max-width: 180px;
  }

  >>> .deleteCol {
    max-width: 30px;
  }

  >>> .addCol {
    min-width: 150px;
  }

  >>> .thirtyPx {
    min-width: 20px;
  }

  >>> .fiftyPx {
    width: 50px;
  }

  >>> .seventyFivePx {
    width: 75px;
  }

  >>> .oneHundredPx {
    width: 100px;
  }

  >>> .twentyPercent {
    width: 17%;
  }

  >>> .tenPercent {
    width: 13.5%;
  }

  >>> .descOverflow {
    word-break: break-word;
  }

  .hiaTable .el-icon-close {
    border: 1px solid transparent;
  }

  .hiaTable tr:nth-child(even) .el-icon-close {
    border: 1px solid #606266;
    border-radius: 100%;
  }

  >>> #cptGrid td {
    position: relative;
    padding-bottom: 32px;
  }

  >>> #cptGridAddRow thead,
  >>> #cptGridLevel2AddRow thead {
    visibility: hidden;
  }

  >>> #cptGridAddRow table,
  >>> #cptGridLevel2AddRow table {
    margin-top: -1px;
  }

  >>> #cptGridAddRow tbody > tr {
    height: 40px;
    border-top: 1px solid #ebeef5;
    background-color: #fff !important;
  }

  >>> #cptGridAddRow th,
  >>> #cptGridLevel2AddRow th {
    line-height: 0px;
    padding: 0px 5px 0px 5px;
  }

  >>> #cptGrid tr,
  >>> #cptGridLevel2 tr {
    vertical-align: top;
  }

  >>> #cptGrid ::placeholder,
  >>> #cptGridLevel2 ::placeholder {
    opacity: 0.8;
  }

  >>> .VuePagination__count {
    display: none;
  }

  >>> .VueTables__child-row:hover {
    background-color: #fff;
  }

  /* #cptCodeList>>>.el-collapse-item {
    height: 20px;
  }

  #cptCodeList>>>.el-collapse-item .is-active {
    height: 100%;
  } */

  #cptCodeList >>> .el-collapse-item__header {
    height: 20px !important;
    line-height: 20px !important;
  }

  /* #cptCodeList>>>.el-collapse-item__header {
    height: 20px;
    line-height: 20px;
    padding-left: 15px;
    background-color: #fff;
    color: #48576a;
    cursor: pointer;
    border-bottom: 1px solid #dfe6ec;
    font-size: 13px;
  }

  #cptCodeList li:nth-child(odd)>>>.el-collapse-item__header {
    height: 20px;
    line-height: 20px;
    padding-left: 15px;
    background-color: rgba(109, 207, 246, 0.02);
    color: #48576a;
    cursor: pointer;
    border-bottom: 1px solid #dfe6ec;
    font-size: 13px;
  }

  #cptCodeList>>>.el-collapse-item__header .el-collapse-item__arrow {
    line-height: 20px !important;
  }

  #cptCodeList>>>.el-collapse-item__header>i {
    font-size: 11px !important;
  } */

  .overflow {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 48px;
    text-overflow: ellipsis;
    cursor: zoom-in;
  }

  >>> .addMultiModifiers > .el-select__tags {
    overflow: hidden;
  }
</style>
