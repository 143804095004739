<template>
  <el-form ref="form" label-position="left" size="mini" label-width="220px" :rules="rules" :model="form" :show-message="false">
    <el-row style="margin-top: 20px;">
      <el-col :span="24">
        <h4 class="headerWithBorder">Chief Complaint</h4>

        <el-form-item label="Chief Complaint Documented">
          <el-col>
            <input :disabled="disabled" type="radio" name="hpichiefComplaint" value="Yes" v-model="history.hpichiefComplaint"
              v-on:change="putProReviewCalculate('hpichiefComplaint', history)">Yes
            <input :disabled="disabled" type="radio" name="hpichiefComplaint" value="No" v-model="history.hpichiefComplaint"
              v-on:change="putProReviewCalculate('hpichiefComplaint', history)">No
            <input :disabled="disabled" type="radio" name="hpichiefComplaint" value="Inferred" v-model="history.hpichiefComplaint"
              v-on:change="putProReviewCalculate('hpichiefComplaint', history)">Inferred
          </el-col>
        </el-form-item>
        <el-form-item v-if="isLevel2" label="Lvl 2 Chief Complaint Documented">
          <el-col>
            <input :disabled="disabled" type="radio" name="level2hpichiefComplaint" value="Yes" v-model="level2History.hpichiefComplaint"
              v-on:change="putProReviewCalculate('hpichiefComplaint', level2History)">Yes
            <input :disabled="disabled" type="radio" name="level2hpichiefComplaint" value="No" v-model="level2History.hpichiefComplaint"
              v-on:change="putProReviewCalculate('hpichiefComplaint', level2History)">No
            <input :disabled="disabled" type="radio" name="level2hpichiefComplaint" value="Inferred" v-model="level2History.hpichiefComplaint"
              v-on:change="putProReviewCalculate('hpichiefComplaint', level2History)">Inferred
          </el-col>
        </el-form-item>

      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 35px;">
      <el-col :span="8">
        <el-row style="">
          <el-col :span="17">
            <h4 class="headerWithBorder">History of Present Illness (HPI)</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{history.hpi}}
              <span v-if="isLevel2">/ {{level2History.hpi}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="proReview.emguidelines === '2021' || proReview.emguidelines === '2023'">
          <el-col :span="24">
            <el-form-item label="History of Present Illness Present">
              <el-col>
                <input :disabled="disabled" type="radio" name="hpiPresent" :value="true" v-model="proReview.hpiPresent" @input="checkOverrides('hpiOverride', $event)">Yes
                <input :disabled="disabled" type="radio" name="hpiPresent" :value="false" v-model="proReview.hpiPresent" @input="checkOverrides('hpiOverride', $event)">No
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-row :gutter="10" :style="proReview.emguidelines === '2021' || proReview.emguidelines === '2023' ?'margin: 10px 0px 0px 0px;' : ''">
              <el-col :span="isLevel2 ? 12 : 24">
                <h5 class="headerWithBorder" style="margin: 10px 10px 10px 0px;" v-if="isLevel2">Level 1</h5>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpilocation" type="checkbox" v-model="history.hpilocation"
                    v-on:change="putProReviewCalculate('hpilocation', history)"> Location
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpiquality" type="checkbox" v-model="history.hpiquality"
                    v-on:change="putProReviewCalculate('hpiquality', history)"> Quality
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpiserverity" type="checkbox" v-model="history.hpiserverity"
                    v-on:change="putProReviewCalculate('hpiserverity', history)">
                  Severity
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpitiming" type="checkbox" v-model="history.hpitiming"
                    v-on:change="putProReviewCalculate('hpitiming', history)"> Timing
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpiduration" type="checkbox" v-model="history.hpiduration"
                    v-on:change="putProReviewCalculate('hpiduration', history)"> Duration
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpicontext" type="checkbox" v-model="history.hpicontext"
                    v-on:change="putProReviewCalculate('hpicontext', history)"> Context
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpimodifyingFactors" type="checkbox" v-model="history.hpimodifyingFactors"
                    v-on:change="putProReviewCalculate('hpimodifyingFactors', history)"> Modifying Factors
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable || hpiDisabled" id="hpiassocSigns" type="checkbox" v-model="history.hpiassocSigns"
                    v-on:change="putProReviewCalculate('hpiassocSigns', history)">
                  Associated Signs and Symptoms
                </el-col>
                <el-col>
                  <input :disabled="disabled || hpiUnobtainableDisable  || hpiDisabled" id="hpichronicConditionsStatus" type="checkbox"
                    v-model="history.hpichronicConditionsStatus" v-on:change="putProReviewCalculate('hpichronicConditionsStatus', history)"> 3 Chronic Conditions
                  with status
                </el-col>
                <el-col>
                  <input :disabled="disabled  || hpiDisabled" id="hpiunobtainable" type="checkbox" v-model="history.hpiunobtainable"
                    v-on:change="putProReviewCalculate('hpiunobtainable', history)"> Unobtainable
                </el-col>
                <el-col v-if="history.hpiunobtainable">
                  <input :disabled="disabled" id="hpiunobtainableCreditGiven" type="checkbox" v-model="history.hpiunobtainableCreditGiven"
                    v-on:change="putProReviewCalculate('hpiunobtainableCreditGiven', history)"> Unobtainable from any
                  source
                </el-col>
                <template v-if="history.hpiunobtainableCreditGiven && history.hpiunobtainable">
                  <label style="margin-top: 5px;">Reason</label>
                  <el-input :disabled="disabled" type="textarea" :rows="4" id="hpiunobtainableCreditGivenReason" v-model="history.hpiunobtainableCreditGivenReason"
                    v-on:change.native="putProReviewCalculate('hpiunobtainableCreditGivenReason', history)"></el-input>
                  <label style="margin-top: 5px;">History Level</label>
                  <hia-el-select ref="hpimanualScore" :disabled="disabled" v-on:input="putProReviewCalculate('hpimanualScore', history)" v-model="history.hpimanualScore"
                    :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="HPIValues">
                  </hia-el-select>
                </template>
              </el-col>
              <el-col :span="12" v-if="isLevel2">
                <h5 class="headerWithBorder" style="margin: 10px 10px 10px 0px;">Level 2</h5>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpilocation" type="checkbox" v-model="level2History.hpilocation"
                    v-on:change="putProReviewCalculate('hpilocation', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpiquality" type="checkbox" v-model="level2History.hpiquality"
                    v-on:change="putProReviewCalculate('hpiquality', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpiserverity" type="checkbox" v-model="level2History.hpiserverity"
                    v-on:change="putProReviewCalculate('hpiserverity', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpitiming" type="checkbox" v-model="level2History.hpitiming"
                    v-on:change="putProReviewCalculate('hpitiming', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpiduration" type="checkbox" v-model="level2History.hpiduration"
                    v-on:change="putProReviewCalculate('hpiduration', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpicontext" type="checkbox" v-model="level2History.hpicontext"
                    v-on:change="putProReviewCalculate('hpicontext', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpimodifyingFactors" type="checkbox" v-model="level2History.hpimodifyingFactors"
                    v-on:change="putProReviewCalculate('hpimodifyingFactors', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpiassocSigns" type="checkbox" v-model="level2History.hpiassocSigns"
                    v-on:change="putProReviewCalculate('hpiassocSigns', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled || level2HpiUnobtainableDisable" id="hpichronicConditionsStatus" type="checkbox"
                    v-model="level2History.hpichronicConditionsStatus" v-on:change="putProReviewCalculate('hpichronicConditionsStatus', level2History)">
                </el-col>
                <el-col>
                  <input :disabled="disabled" id="hpiunobtainable" type="checkbox" v-model="level2History.hpiunobtainable"
                    v-on:change="putProReviewCalculate('hpiunobtainable', level2History)">
                </el-col>
                <el-col v-if="level2History.hpiunobtainable">
                  <input :disabled="disabled" id="hpiunobtainableCreditGiven" type="checkbox" v-model="level2History.hpiunobtainableCreditGiven"
                    v-on:change="putProReviewCalculate('hpiunobtainableCreditGiven', level2History)">
                </el-col>
                <template v-if="level2History.hpiunobtainableCreditGiven && level2History.hpiunobtainable">
                  <label style="margin-top: 5px;">Reason</label>
                  <el-input :disabled="disabled" type="textarea" :rows="4" id="hpiunobtainableCreditGivenReason" v-model="level2History.hpiunobtainableCreditGivenReason"
                    v-on:change.native="putProReviewCalculate('hpiunobtainableCreditGivenReason', level2History)">
                  </el-input>
                  <label style="margin-top: 5px;">History Level</label>
                  <hia-el-select ref="hpimanualScore" :disabled="disabled" v-on:input="putProReviewCalculate('hpimanualScore', level2History)"
                    v-model="level2History.hpimanualScore" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="HPIValues">
                  </hia-el-select>
                </template>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>

        </el-row>
      </el-col>

      <el-col :span="8">
        <el-row>
          <el-col :span="17">
            <h4 class="headerWithBorder">Review of Systems (ROS)</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{history.ros}}
              <span v-if="isLevel2">/ {{level2History.ros}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="proReview.emguidelines === '2021' || proReview.emguidelines === '2023'">
          <el-col :span="24">
            <el-form-item label="Review of Systems Present">
              <el-col>
                <input :disabled="disabled" type="radio" name="rosPresent" :value="true" v-model="proReview.rosPresent" @input="checkOverrides('rosOverride', $event)">Yes
                <input :disabled="disabled" type="radio" name="rosPresent" :value="false" v-model="proReview.rosPresent" @input="checkOverrides('rosOverride', $event)">No
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" :style="proReview.emguidelines === '2021' || proReview.emguidelines === '2023' ?'margin: 10px 0px 0px 0px;' : ''">
          <el-col :span="isLevel2 ? 12: 24">
            <h5 class="headerWithBorder" style="margin: 10px 10px 10px 0px;" v-if="isLevel2">Level 1</h5>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosconstit" type="checkbox" v-model="history.rosconstit"
                v-on:change="putProReviewCalculate('rosconstit', history)"> Constitutional
              Symptoms
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="roseyes" type="checkbox" v-model="history.roseyes"
                v-on:change="putProReviewCalculate('roseyes', history)"> Eyes
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosenmt" type="checkbox" v-model="history.rosenmt"
                v-on:change="putProReviewCalculate('rosenmt', history)"> Ears, Nose, Mouth,
              Throat
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="roscardio" type="checkbox" v-model="history.roscardio"
                v-on:change="putProReviewCalculate('roscardio', history)"> Cardiovascular
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosresp" type="checkbox" v-model="history.rosresp"
                v-on:change="putProReviewCalculate('rosresp', history)"> Respiratory
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosgi" type="checkbox" v-model="history.rosgi"
                v-on:change="putProReviewCalculate('rosgi', history)">
              Gastrointestinal
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosgu" type="checkbox" v-model="history.rosgu"
                v-on:change="putProReviewCalculate('rosgu', history)">
              Genitourinary
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosmuscSkel" type="checkbox" v-model="history.rosmuscSkel"
                v-on:change="putProReviewCalculate('rosmuscSkel', history)">
              Musculoskeletal
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosintegumet" type="checkbox" v-model="history.rosintegumet"
                v-on:change="putProReviewCalculate('rosintegumet', history)">
              Integumentary
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosneuro" type="checkbox" v-model="history.rosneuro"
                v-on:change="putProReviewCalculate('rosneuro', history)"> Neurological
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rospsych" type="checkbox" v-model="history.rospsych"
                v-on:change="putProReviewCalculate('rospsych',history)"> Psychiatric
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosendocrine" type="checkbox" v-model="history.rosendocrine"
                v-on:change="putProReviewCalculate('rosendocrine', history)"> Endocrine
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="roshem" type="checkbox" v-model="history.roshem"
                v-on:change="putProReviewCalculate('roshem', history)"> Hematologic / Lymphatic
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosallergy" type="checkbox" v-model="history.rosallergy"
                v-on:change="putProReviewCalculate('rosallergy', history)">
              Allergic/Immunologic
            </el-col>
            <el-col>
              <input :disabled="disabled || rosUnobtainableDisable || rosDisabled" id="rosallOther" type="checkbox" v-model="history.rosallOther"
                v-on:change="putProReviewCalculate('rosallOther', history)">
              <b>All other systems reviewed and negative</b>
            </el-col>
            <el-col>
              <input :disabled="disabled || rosDisabled" id="rosunobtainable" type="checkbox" v-model="history.rosunobtainable"
                v-on:change="putProReviewCalculate('rosunobtainable', history)"> Unobtainable
            </el-col>
            <el-col v-if="history.rosunobtainable">
              <input :disabled="disabled || rosDisabled" id="rosunobtainableCreditGiven" type="checkbox" v-model="history.rosunobtainableCreditGiven"
                v-on:change="putProReviewCalculate('rosunobtainableCreditGiven', history)"> Unobtainable from any source
            </el-col>
            <template v-if="history.rosunobtainableCreditGiven && history.rosunobtainable">
              <label style="margin-top: 5px;">Reason</label>
              <el-input :disabled="disabled  || rosDisabled" type="textarea" :rows="4" id="rosunobtainableCreditGivenReason" v-model="history.rosunobtainableCreditGivenReason"
                v-on:change.native="putProReviewCalculate('rosunobtainableCreditGivenReason', history)"></el-input>
              <label style="margin-top: 5px;">ROS Level</label>
              <hia-el-select ref="rosmanualScore" :disabled="disabled  || rosDisabled" v-on:input="putProReviewCalculate('rosmanualScore', history)"
                v-model="history.rosmanualScore" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="ROSValues">
              </hia-el-select>
            </template>
          </el-col>
          <el-col v-if="isLevel2" :span="12">
            <h5 class="headerWithBorder" style="margin: 10px 10px 10px 0px;">Level 2</h5>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosconstit" type="checkbox" v-model="level2History.rosconstit"
                v-on:change="putProReviewCalculate('rosconstit', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="roseyes" type="checkbox" v-model="level2History.roseyes"
                v-on:change="putProReviewCalculate('roseyes', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosenmt" type="checkbox" v-model="level2History.rosenmt"
                v-on:change="putProReviewCalculate('rosenmt', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="roscardio" type="checkbox" v-model="level2History.roscardio"
                v-on:change="putProReviewCalculate('roscardio', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosresp" type="checkbox" v-model="level2History.rosresp"
                v-on:change="putProReviewCalculate('rosresp', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosgi" type="checkbox" v-model="level2History.rosgi"
                v-on:change="putProReviewCalculate('rosgi', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosgu" type="checkbox" v-model="level2History.rosgu"
                v-on:change="putProReviewCalculate('rosgu', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosmuscSkel" type="checkbox" v-model="level2History.rosmuscSkel"
                v-on:change="putProReviewCalculate('rosmuscSkel', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosintegumet" type="checkbox" v-model="level2History.rosintegumet"
                v-on:change="putProReviewCalculate('rosintegumet', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosneuro" type="checkbox" v-model="level2History.rosneuro"
                v-on:change="putProReviewCalculate('rosneuro', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rospsych" type="checkbox" v-model="level2History.rospsych"
                v-on:change="putProReviewCalculate('rospsych',level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosendocrine" type="checkbox" v-model="level2History.rosendocrine"
                v-on:change="putProReviewCalculate('rosendocrine', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="roshem" type="checkbox" v-model="level2History.roshem"
                v-on:change="putProReviewCalculate('roshem', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosallergy" type="checkbox" v-model="level2History.rosallergy"
                v-on:change="putProReviewCalculate('rosallergy', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2RosUnobtainableDisable  || rosDisabled" id="rosallOther" type="checkbox" v-model="level2History.rosallOther"
                v-on:change="putProReviewCalculate('rosallOther', level2History)">
            </el-col>
            <el-col>
              <input style="margin-top: 26px;" :disabled="disabled  || rosDisabled" id="rosunobtainable" type="checkbox" v-model="level2History.rosunobtainable"
                v-on:change="putProReviewCalculate('rosunobtainable', level2History)">
            </el-col>
            <el-col v-if="level2History.rosunobtainable">
              <input :disabled="disabled  || rosDisabled" id="rosunobtainableCreditGiven" type="checkbox" v-model="level2History.rosunobtainableCreditGiven"
                v-on:change="putProReviewCalculate('rosunobtainableCreditGiven', level2History)">
            </el-col>
            <template v-if="level2History.rosunobtainableCreditGiven && level2History.rosunobtainable">
              <label style="margin-top: 5px;">Reason</label>
              <el-input :disabled="disabled  || rosDisabled" type="textarea" :rows="4" id="rosunobtainableCreditGivenReason"
                v-model="level2History.rosunobtainableCreditGivenReason" v-on:change.native="putProReviewCalculate('rosunobtainableCreditGivenReason', level2History)">
              </el-input>
              <label style="margin-top: 5px;">ROS Level</label>
              <hia-el-select ref="rosmanualScore" :disabled="disabled  || rosDisabled" v-on:input="putProReviewCalculate('rosmanualScore', level2History)"
                v-model="level2History.rosmanualScore" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="ROSValues">
              </hia-el-select>
            </template>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="8">
        <el-row>
          <el-col :span="17">
            <h4 class="headerWithBorder">Past, Family, and Social History (PFSH)</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{history.pfsh}}
              <span v-if="isLevel2">/ {{level2History.pfsh}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="proReview.emguidelines === '2021' || proReview.emguidelines === '2023'">
          <el-col :span="24">
            <el-form-item label="Past, Family, and Social History Present">
              <el-col>
                <input :disabled="disabled" type="radio" name="pfshPresent" :value="true" v-model="proReview.pfshPresent" @input="checkOverrides('pfshOverride', $event)">Yes
                <input :disabled="disabled" type="radio" name="pfshPresent" :value="false" v-model="proReview.pfshPresent" @input="checkOverrides('pfshOverride', $event)">No
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" :style="proReview.emguidelines === '2021' || proReview.emguidelines === '2023' ?'margin: 10px 0px 0px 0px;' : ''">
          <el-col :span="isLevel2 ? 12: 24">
            <h5 class="headerWithBorder" style="margin: 10px 10px 10px 0px;" v-if="isLevel2">Level 1</h5>
            <el-col>
              <input :disabled="disabled || pfshUnobtainableDisable || pfshDisabled" id="pfshpast" type="checkbox" v-model="history.pfshpast"
                v-on:change="putProReviewCalculate('pfshpast', history)"> Past
            </el-col>
            <el-col>
              <input :disabled="disabled || pfshUnobtainableDisable || pfshDisabled" id="pfshfamily" type="checkbox" v-model="history.pfshfamily"
                v-on:change="putProReviewCalculate('pfshfamily', history)"> Family
            </el-col>
            <el-col>
              <input :disabled="disabled || pfshUnobtainableDisable || pfshDisabled" id="pfshsocial" type="checkbox" v-model="history.pfshsocial"
                v-on:change="putProReviewCalculate('pfshsocial', history)"> Social
            </el-col>
            <el-col>
              <input :disabled="disabled || pfshDisabled" id="pfshunobtainable" type="checkbox" v-model="history.pfshunobtainable"
                v-on:change="putProReviewCalculate('pfshunobtainable', history)"> Unobtainable
            </el-col>
            <el-col v-if="history.pfshunobtainable">
              <input :disabled="disabled || pfshDisabled" id="pfshunobtainableCreditGiven" type="checkbox" v-model="history.pfshunobtainableCreditGiven"
                v-on:change="putProReviewCalculate('pfshunobtainableCreditGiven', history)"> Unobtainable from any
              source
            </el-col>
            <template v-if="history.pfshunobtainableCreditGiven && history.pfshunobtainable">
              <label style="margin-top: 5px;">Reason</label>
              <el-input :disabled="disabled || pfshDisabled" type="textarea" :rows="4" id="pfshunobtainableCreditGivenReason"
                v-model="history.pfshunobtainableCreditGivenReason" v-on:change.native="putProReviewCalculate('pfshunobtainableCreditGivenReason', history)"></el-input>
              <label style="margin-top: 5px;">PFSH level</label>
              <hia-el-select ref="pfshmanualScore" :disabled="disabled || pfshDisabled" v-on:input="putProReviewCalculate('pfshmanualScore', history)"
                v-model="history.pfshmanualScore" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="PFSHValues">
              </hia-el-select>
            </template>
          </el-col>
          <el-col v-if="isLevel2" :span="12">
            <h5 class="headerWithBorder" style="margin: 10px 10px 10px 0px;">Level 2</h5>
            <el-col>
              <input :disabled="disabled || level2PfshUnobtainableDisable || pfshDisabled" id="pfshpast" type="checkbox" v-model="level2History.pfshpast"
                v-on:change="putProReviewCalculate('pfshpast', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2PfshUnobtainableDisable || pfshDisabled" id="pfshfamily" type="checkbox" v-model="level2History.pfshfamily"
                v-on:change="putProReviewCalculate('pfshfamily', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || level2PfshUnobtainableDisable || pfshDisabled" id="pfshsocial" type="checkbox" v-model="level2History.pfshsocial"
                v-on:change="putProReviewCalculate('pfshsocial', level2History)">
            </el-col>
            <el-col>
              <input :disabled="disabled || pfshDisabled" id="pfshunobtainable" type="checkbox" v-model="level2History.pfshunobtainable"
                v-on:change="putProReviewCalculate('pfshunobtainable', level2History)">
            </el-col>
            <el-col v-if="level2History.pfshunobtainable">
              <input :disabled="disabled || pfshDisabled" id="pfshunobtainableCreditGiven" type="checkbox" v-model="level2History.pfshunobtainableCreditGiven"
                v-on:change="putProReviewCalculate('pfshunobtainableCreditGiven', level2History)">
            </el-col>
            <template v-if="level2History.pfshunobtainableCreditGiven && level2History.pfshunobtainable">
              <label style="margin-top: 5px;">Reason</label>
              <el-input :disabled="disabled || pfshDisabled" type="textarea" :rows="4" id="pfshunobtainableCreditGivenReason"
                v-model="level2History.pfshunobtainableCreditGivenReason" v-on:change.native="putProReviewCalculate('pfshunobtainableCreditGivenReason', level2History)">
              </el-input>
              <label style="margin-top: 5px;">PFSH level</label>
              <hia-el-select ref="pfshmanualScore" :disabled="disabled || pfshDisabled" v-on:input="putProReviewCalculate('pfshmanualScore', level2History)"
                v-model="level2History.pfshmanualScore" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="PFSHValues">
              </hia-el-select>
            </template>
          </el-col>
        </el-row>
        <el-row style="padding: 20px 0px 0px 0px;">
          <el-col :span="17">
            <h4 class="headerWithBorder">History Level</h4>
          </el-col>
          <el-col :span="7">
            <div class="historyResultsBox">{{history.historyLevel}}
              <span v-if="isLevel2">/ {{level2History.historyLevel}}</span>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProReviewHistory',
    props: ['isLevel2', 'changedField', 'disabled'],
    data() {
      return {
        form: {},
        rules: {},
        HPIValues: [{ key: 0, value: 'None' }, { key: 1, value: 'Brief' }, { key: 4, value: 'Extended' }],
        ROSValues: [{ key: 0, value: 'None' }, { key: 1, value: 'Pertinent' }, { key: 3, value: 'Extended' }, { key: 10, value: 'Complete' }],
        PFSHValues: [{ key: 0, value: 'None' }, { key: 1, value: 'Pertinent' }, { key: 4, value: 'Complete' }]
      }
    },
    created: function () {
    },
    methods: {
      ...mapActions('proReviews/', ['PUT_PRO_HISTORY', 'PUT_PRO_REVIEW']),
      async putProReviewCalculate(changedField, history) {
        const payload = { history: history, changedField: changedField }
        try {
          await this.PUT_PRO_HISTORY(payload)
          this.$message({
            message: 'History Update Successful',
            type: 'success'
          })
        } catch {
          this.$message({
            message: 'History Update Error',
            type: 'error'
          })
        }
      },
      async putProReview(changedField) {
        try {
          this.loading = true
          const payload = { proReview: this.proReview, changedField: changedField }
          await this.PUT_PRO_REVIEW(payload)
          this.loading = false
          this.$message({
            message: 'History Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'History Update Error',
            type: 'error'
          })
        }
      },
      async checkOverrides(changedField, event) {
        if (changedField === 'hpiOverride') {
          if (event.target.value === 'false' && this.proReview.hpiPresent) {
            try {
              await this.$confirm('This will reset History of Present Illness (HPI) scoring.  Proceed?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
              })
            } catch {
              this.proReview.hpiPresent = !this.proReview.hpiPresent
              return
            }
          }
          this.proReview.hpiPresent = event.target.value
          this.putProReview(changedField)
          return
        }

        if (changedField === 'rosOverride') {
          if (event.target.value === 'false' && this.proReview.rosPresent) {
            try {
              await this.$confirm('This will reset Review of Systems (ROS) scoring.  Proceed?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
              })
            } catch {
              this.proReview.rosPresent = !this.proReview.rosPresent
              return
            }
          }
          this.proReview.rosPresent = event.target.value
          this.putProReview(changedField)
          return
        }

        if (changedField === 'pfshOverride') {
          if (event.target.value === 'false' && this.proReview.pfshPresent) {
            try {
              await this.$confirm('This will reset Past, Family, and Social History (PFSH) scoring.  Proceed?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
              })
            } catch {
              this.proReview.pfshPresent = !this.proReview.pfshPresent
              return
            }
          }
          this.proReview.pfshPresent = event.target.value
          this.putProReview(changedField)
        }
      },
      hpiUnobtainableConstraints() {
        // handle options
        // eslint-disable-next-line
        if (!this.history.hpiunobtainable && this.history.hpiunobtainableCreditGiven || !this.history.hpiunobtainable && !this.history.hpiunobtainableCreditGiven || this.history.hpiunobtainable && !this.history.hpiunobtainableCreditGiven) {
          this.hpiUnobtainableDisable = false
          this.history.hpiunobtainableCreditGiven = false
          this.history.hpiunobtainableCreditGivenReason = null
          this.history.hpimanualScore = null
        }
        if (this.history.hpiunobtainable || this.history.hpiunobtainableCreditGiven) {
          this.history.hpiassocSigns = false
          this.history.hpicontext = false
          this.history.hpiduration = false
          this.history.hpilocation = false
          this.history.hpimodifyingFactors = false
          this.history.hpiquality = false
          this.history.hpiserverity = false
          this.history.hpitiming = false
          this.history.hpichronicConditionsStatus = false
          this.hpiUnobtainableDisable = true
        } else {
          this.hpiUnobtainableDisable = false
        }
      },
      rosUnobtainableConstraints() {
        // handle options
        // eslint-disable-next-line
        if (!this.history.rosunobtainable && this.history.rosunobtainableCreditGiven || !this.history.rosunobtainable && !this.history.rosunobtainableCreditGiven || this.history.rosunobtainable && !this.history.rosunobtainableCreditGiven) {
          this.rosUnobtainableDisable = false
          this.history.rosunobtainableCreditGiven = false
          this.history.rosunobtainableCreditGivenReason = null
          this.history.rosmanualScore = null
        }
        if (this.history.rosunobtainable || this.history.rosunobtainableCreditGiven) {
          this.history.rosallOther = false
          this.history.rosallergy = false
          this.history.roscardio = false
          this.history.rosconstit = false
          this.history.rosendocrine = false
          this.history.rosenmt = false
          this.history.roseyes = false
          this.history.rosgi = false
          this.history.rosgu = false
          this.history.roshem = false
          this.history.rosintegumet = false
          this.history.rosmuscSkel = false
          this.history.rosneuro = false
          this.history.rospsych = false
          this.history.rosresp = false
          this.rosUnobtainableDisable = true
        } else {
          this.rosUnobtainableDisable = false
        }
      },
      pfshUnobtainableConstraints() {
        // handle options
        // eslint-disable-next-line
        if (!this.history.pfshunobtainable && this.history.pfshunobtainableCreditGiven || !this.history.pfshunobtainable && !this.history.pfshunobtainableCreditGiven || this.history.pfshunobtainable && !this.history.pfshunobtainableCreditGiven) {
          this.pfshUnobtainableDisable = false
          this.history.pfshunobtainableCreditGiven = false
          this.history.pfshunobtainableCreditGivenReason = null
          this.history.pfshmanualScore = null
        }
        if (this.history.pfshunobtainable || this.history.pfshunobtainableCreditGiven) {
          this.history.pfshfamily = false
          this.history.pfshpast = false
          this.history.pfshsocial = false

          this.pfshUnobtainableDisable = true
        } else {
          this.pfshUnobtainableDisable = false
        }
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      history: function () {
        const history = this.proReview.proRecordHistory.find(x => x.codeLevel === 1)
        if (history) {
          return history
        }
        return { historyLevel: null }
      },
      level2History: function () {
        const history = this.proReview.proRecordHistory.find(x => x.codeLevel === 2)
        if (history) {
          return history
        }
        return { historyLevel: null }
      },
      hpiUnobtainableDisable: function () {
        return this.history.hpiunobtainable || this.history.hpiunobtainableCreditGiven
      },
      rosUnobtainableDisable: function () {
        return this.history.rosunobtainable || this.history.rosunobtainableCreditGiven
      },
      pfshUnobtainableDisable: function () {
        return this.history.pfshunobtainable || this.history.pfshunobtainableCreditGiven
      },
      level2HpiUnobtainableDisable: function () {
        return this.level2History.hpiunobtainable || this.level2History.hpiunobtainableCreditGiven
      },
      level2RosUnobtainableDisable: function () {
        return this.level2History.rosunobtainable || this.level2History.rosunobtainableCreditGiven
      },
      level2PfshUnobtainableDisable: function () {
        return this.level2History.pfshunobtainable || this.level2History.pfshunobtainableCreditGiven
      },
      hpiDisabled: function () {
        return ((this.proReview.emguidelines === '2021' || this.proReview.emguidelines === '2023') && !this.proReview.hpiPresent)
      },
      rosDisabled: function () {
        return ((this.proReview.emguidelines === '2021' || this.proReview.emguidelines === '2023') && !this.proReview.rosPresent)
      },
      pfshDisabled: function () {
        return ((this.proReview.emguidelines === '2021' || this.proReview.emguidelines === '2023') && !this.proReview.pfshPresent)
      }
    },
    watch: {
      review: {
        handler: function () {
          this.hpiUnobtainableConstraints()
          this.rosUnobtainableConstraints()
          this.pfshUnobtainableConstraints()
        },
        deep: true
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .resultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #63b344;
    margin: -8px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
  }

  .hideLabel>>>div {
    color: transparent;
    font-size: 0px;
    padding: 2px;
  }

  .historyResultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    color: #005695;
    margin: -8px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
</style>