<template>
  <el-form ref="form" label-position="left" label-width="40%" :rules="rules" :model="form" size="mini">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row id="typeProblems">
          <el-col :span="17">
            <h4 class="headerWithBorder">Number and Complexity of Problems Addressed</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{getLevelLabel(decisionMaking.presentingProblemsLevel2021)}}
              <span v-if="isLevel2"> / {{getLevelLabel(level2DecisionMaking.presentingProblemsLevel2021)}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-left: 40%" v-if="isLevel2">
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 1</h5>
            </el-col>
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 2</h5>
            </el-col>
          </div>

          <el-form-item label="Presenting Problem(s)">
            <el-col :span="isLevel2 ? 12 : 24">
              <hia-el-select style="margin-right: 2px" :disabled="disabled" v-model="decisionMaking.presentingProblem2021"
                @input="putProReviewCalculate('presentingProblem2021', decisionMaking)" placeholder="Presenting Problems" :clearable="true" :filterable="true" itemkey="key"
                label="value" :dropdowns="proRiskDefinitionsPPCalc">
              </hia-el-select>
            </el-col>
            <el-col :span="12" v-if="isLevel2">
              <hia-el-select style="margin-right: 2px" :disabled="disabled" v-model="level2DecisionMaking.presentingProblem2021"
                @input="putProReviewCalculate('presentingProblem2021', level2DecisionMaking)" placeholder="Presenting Problems" :clearable="true" :filterable="true"
                itemkey="key" label="value" :dropdowns="proRiskDefinitionsPPCalc">
              </hia-el-select>
            </el-col>
          </el-form-item>
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-row>
          <el-col :span="17">
            <h4 class="headerWithBorder">Table of Risk</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{getLevelLabel(decisionMaking.managementOptionsLevel2021)}}
              <span v-if="isLevel2"> / {{getLevelLabel(level2DecisionMaking.managementOptionsLevel2021)}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row id="proRiskDefinitions">
          <div style="margin-left: 20%" v-if="isLevel2">
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 1</h5>
            </el-col>
            <el-col :span="12">
              <h5 class="headerWithBorder" style="margin: 5px 5px 5px 2px;">Level 2</h5>
            </el-col>
          </div>
          <el-form-item label="Management Options Selected">
            <el-row :gutter="5">
              <el-col :span="isLevel2 ? 12 : 24">
                <hia-el-select :disabled="disabled" v-model="decisionMaking.managementOptionsSelected2021"
                  @input="putProReviewCalculate('managementOptionsSelected2021', decisionMaking)" placeholder="Mgmt Options" :clearable="true" :filterable="true" itemkey="key"
                  label="value" :dropdowns="dropdowns.ProRiskDefinitionsMOS2021.list">
                </hia-el-select>
              </el-col>
              <el-col :span="12" v-if="isLevel2">
                <hia-el-select :disabled="disabled" v-model="level2DecisionMaking.managementOptionsSelected2021"
                  @input="putProReviewCalculate('managementOptionsSelected2021', level2DecisionMaking)" placeholder="Mgmt Options" :clearable="true" :filterable="true"
                  itemkey="key" label="value" :dropdowns="dropdowns.ProRiskDefinitionsMOS2021.list">
                </hia-el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-row style="margin: 10px 0px 0px 0px;">
          <el-col :span="17">
            <h4 class="headerWithBorder">Amount and Complexity of Data to be Reviewed and Analyzed</h4>
          </el-col>
          <el-col :span="7">
            <div class="resultsBox">{{getLevelLabelV2(decisionMaking.documentationComplexity2021)}}
              <span v-if="isLevel2"> / {{getLevelLabelV2(level2DecisionMaking.documentationComplexity2021)}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="18" :span="3">
            <h5 v-if="isLevel2" class="headerWithBorder" style="margin: 5px 0px 5px 0px;">Level 1</h5>
          </el-col>
          <el-col :span="3">
            <h5 v-if="isLevel2" class="headerWithBorder" style="margin: 5px 0px 5px 10px;">Level 2</h5>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="isLevel2 ? 21 : 24" id="textAndDocuments">
            <el-form-item label="Review of prior external note(s) from each unique source">
              <el-input :disabled="disabled" type="number" v-model="decisionMaking.priorNoteSources2021"
                @change="putProReviewCalculate('priorNoteSources2021', decisionMaking)"></el-input>
            </el-form-item>
            <el-form-item label="Review of the result(s) of each unique test">
              <el-input :disabled="disabled" type="number" v-model="decisionMaking.uniqueTests2021" @change="putProReviewCalculate('priorNoteSources2021', decisionMaking)">
              </el-input>
            </el-form-item>
            <el-form-item label="Ordering of each unique test">
              <el-input :disabled="disabled" type="number" v-model="decisionMaking.orderingTests2021" @change="putProReviewCalculate('priorNoteSources2021', decisionMaking)">
              </el-input>
            </el-form-item>
            <el-form-item label="An individual (eg, parent, guardian, surrogate, spouse, witness) who provides
            a history in addition to patient">
              <el-checkbox :disabled="disabled" type="checkbox" v-model="decisionMaking.nonPatientHistory2021"
                @change="putProReviewCalculate('priorNoteSources2021', decisionMaking)">
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Independent interpretation of a test performed by another physician/other
            qualified health care professional (not separately reported)">
              <el-checkbox :disabled="disabled" type="checkbox" v-model="decisionMaking.interpretationOfTests2021"
                @change="putProReviewCalculate('priorNoteSources2021', decisionMaking)">
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Discussion of Management or test interpretation with external physician/other
            qualified health care professional/appropriate source (not separately reported)">
              <el-checkbox :disabled="disabled" type="checkbox" v-model="decisionMaking.discussionOfTests2021"
                @change="putProReviewCalculate('priorNoteSources2021', decisionMaking)">
              </el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="3" v-if="isLevel2" id="textAndDocumentsLevel2">
            <el-form-item>
              <el-input :disabled="disabled" type="number" v-model="level2DecisionMaking.priorNoteSources2021"
                @change="putProReviewCalculate('priorNoteSources2021', level2DecisionMaking)">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input :disabled="disabled" type="number" v-model="level2DecisionMaking.uniqueTests2021"
                @change="putProReviewCalculate('priorNoteSources2021', level2DecisionMaking)">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input :disabled="disabled" type="number" v-model="level2DecisionMaking.orderingTests2021"
                @change="putProReviewCalculate('priorNoteSources2021', level2DecisionMaking)">
              </el-input>
            </el-form-item>
            <el-form-item style="margin-bottom: 17px;">
              <el-checkbox :disabled="disabled" type="checkbox" v-model="level2DecisionMaking.nonPatientHistory2021"
                @change="putProReviewCalculate('priorNoteSources2021', level2DecisionMaking)">
              </el-checkbox>
            </el-form-item>
            <el-form-item style="margin-bottom: 18px;">
              <el-checkbox :disabled="disabled" type="checkbox" v-model="level2DecisionMaking.interpretationOfTests2021"
                @change="putProReviewCalculate('priorNoteSources2021', level2DecisionMaking)">
              </el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-checkbox :disabled="disabled" type="checkbox" v-model="level2DecisionMaking.discussionOfTests2021"
                @change="putProReviewCalculate('priorNoteSources2021', level2DecisionMaking)">
              </el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-row>
          <el-col :span="24">
            <h4 class="headerWithBorder" style="margin: 10px 10px 0px 0px;">EM Scoring Summary</h4>
          </el-col>
        </el-row>
        <el-row style="padding: 20px 0px 0px 0px;">
          <el-col :span="17">
            <h4 class="headerWithBorder blue">Decision Complexity</h4>
          </el-col>
          <el-col :span="7">
            <div class="blueResultsBox">{{decisionMaking.decisionComplexity2021}}
              <span v-if="isLevel2"> / {{level2DecisionMaking.decisionComplexity2021}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row id="timeBasedCalculationRow" style="padding: 20px 0px 0px 0px;">
          <el-col :span="6">
            <h4 class="headerWithBorder blue">Time Based Calculation</h4>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="10">
              <el-col :span="2">
                <input :disabled="disabled" id="timeBasedCalculation" type="checkbox" v-model="decisionMaking.timeBasedCalculation"
                  v-on:change="putProReviewCalculate('timeBasedCalculation', decisionMaking)">
              </el-col>
              <el-col :span="11">
                <el-form-item label="Total Time Spent">
                  <el-input :disabled="disabled" id="totalTimeSpent" type="number" size="mini" v-model="decisionMaking.timeSpentTotal"
                    v-on:change.native="putProReviewCalculate('timeSpentTotal', decisionMaking)">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row id="timeBasedCalculationRow" v-if="isLevel2" style="padding: 20px 0px 0px 0px;">
          <el-col :span="6">
            <h4 class="headerWithBorder blue">Level 2 Time Based Calculation</h4>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="10">
              <el-col :span="2">
                <input :disabled="disabled" id="timeBasedCalculation" type="checkbox" v-model="level2DecisionMaking.timeBasedCalculation"
                  v-on:change="putProReviewCalculate('timeBasedCalculation', level2DecisionMaking)">
              </el-col>
              <el-col :span="11">
                <el-form-item label="Total Time Spent">
                  <el-input :disabled="disabled" id="totalTimeSpent" type="number" size="mini" v-model="level2DecisionMaking.timeSpentTotal"
                    v-on:change.native="putProReviewCalculate('timeSpentTotal', level2DecisionMaking)">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

      </el-col>
    </el-row>
  </el-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProReviewDecisionMaking',
    props: ['isLevel2', 'level2DecisionMaking', 'examLevel', 'level2ExamLevel', 'decisionMaking', 'changedField', 'disabled'],
    data() {
      return {
        form: {},
        rules: {
          addCoder: [
            { required: true, message: 'Please Set Coder' }
          ]
        },
        numberDropDown: [
          { key: 0, value: 0 },
          { key: 1, value: 1 },
          { key: 2, value: 2 }
        ],
        numberDropDown2: [
          { key: 0, value: 0 },
          { key: 1, value: 1 },
          { key: 2, value: 2 },
          { key: 3, value: 3 },
          { key: 4, value: 4 },
          { key: 5, value: 5 },
          { key: 6, value: 6 },
          { key: 7, value: 7 },
          { key: 8, value: 8 },
          { key: 9, value: 9 }
        ]
      }
    },
    created: function () {
    },
    methods: {
      ...mapActions('proReviews/', ['PUT_PRO_DECISION_MAKING']),
      async putProReviewCalculate(changedField, decisionMaking) {
        const payload = { decisionMaking: decisionMaking, changedField: changedField }
        try {
          await this.PUT_PRO_DECISION_MAKING(payload)
          this.$message({
            message: 'Decision Making Update Successful',
            type: 'success'
          })
        } catch {
          this.$message({
            message: 'Decision Making Update Error',
            type: 'error'
          })
        }
      },
      getLevelLabel(levelInt) {
        const map = {
          1: 'Minimal',
          2: 'Low',
          3: 'Moderate',
          4: 'High'
        }
        return map[levelInt]
      },
      getLevelLabelV2(levelInt) {
        const map = {
          1: 'Minimal',
          2: 'Limited',
          3: 'Moderate',
          4: 'High'
        }
        return map[levelInt]
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      proRiskDefinitionsPPCalc() {
        if (this.proReview && this.proReview.decisionMaking2023 && [31, 32].some(x => x === this.proReview.placeOfService)) {
          if (this.dropdowns.ProRiskDefinitionsPP2021.list) {
            return this.dropdowns.ProRiskDefinitionsPP2021.list
          }
        }

        return this.dropdowns.ProRiskDefinitionsPP2021.list.filter(x => x.value !== 'Nursing facility care - Multiple morbidities requiring intensive management | High')
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .resultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #63b344;
    margin: -8px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
  }

  .blueResultsBox {
    text-align: center;
    border: 1px solid #eeeeee;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #005695;
    margin: -8px 0px 0px 0px;
    height: 25px;
    font-size: 14px;
    font-weight: bold;
  }

  .blue {
    color: #005695 !important;
    font-weight: bold !important;
  }
</style>
<style>
  #proRiskDefinitions .el-form-item__label {
    width: 20% !important;
  }

  #proRiskDefinitions .el-form-item__content {
    margin-left: 30% !important;
  }

  #textAndDocuments .el-form-item__label {
    width: 80% !important;
  }

  #textAndDocuments .el-form-item__content {
    margin-left: 85% !important;
  }

  #textAndDocumentsLevel2 .el-form-item__content {
    margin: 0px 0 1px 0 !important;
  }

  #timeBasedCalculationRow .el-form-item__label {
    width: 60% !important;
  }

  #timeBasedCalculationRow .el-form-item__content {
    margin-left: 60% !important;
  }

  #timeBasedCalculationRow h4 {
    padding-top: 4px;
  }
</style>