<template>
  <el-form :model="proReview" size="mini" ref="form" label-position="left" label-width="120px" v-loading="loading" :rules="mapRequiredFieldsRules()"
    :validate-on-rule-change="false" element-loading-spinner="atom-audit-loader">
    <el-row :gutter="20">
      <el-col :span="12">
        <h4 class="headerWithBorder">Review Information</h4>

        <el-form-item size="mini" label="Facility" prop="clientId">
          <hia-el-select v-model="proReview.clientId" :disabled="disabled" placeholder="Facility" :clearable="false" :filterable="true" itemkey="key" label="value"
            :dropdowns="dropdowns.clients.list" @input="putProReview('facility')"></hia-el-select>
        </el-form-item>

        <el-form-item size="mini" ref="dateFormItem" label="Project" prop="projectID">
          <projectSelector @input="putProReview('project')" :clearable="false" @selected="handleProjectSelected" v-model="proReview.projectID" :project="proReview.project"
            :disabled="disabled" :defaultQuery="{ projectTypeId: proReview.project.projectTypeId }"> </projectSelector>
        </el-form-item>

        <el-form-item size="mini" label="Auditor" prop="consultantNumber">
          <hia-el-select :disabled="disabled" @input="putProReview('ConsNum')" v-model="proReview.consultantNumber" placeholder="Auditor" :clearable="true" :filterable="true"
            itemkey="key" label="value" :dropdowns="dropdowns.consultants.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Review Date" prop="reviewDate">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="revDate" :clearable="false" v-model="proReview.reviewDate" @change="putProReview('revDate')"
            format="MM/dd/yyyy"> </el-date-picker>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <h4 class="headerWithBorder">Original Coding Details</h4>

        <el-form-item size="mini" label="Place of Service" prop="placeOfService">
          <hia-el-select :disabled="disabled" @input="putProReview('placeOfService')" v-model="proReview.placeOfService" placeholder="Place of Service" :filterable="true"
            itemkey="key" label="value" :dropdowns="dropdowns.placeOfService.list"> </hia-el-select>
        </el-form-item>

        <el-form-item label="EM Guidelines" prop="emguidelines">
          <el-input disabled v-model="proReview.emguidelines" @input="putProReview('placeOfService')" placeholder="EM Guidelines"> </el-input>
        </el-form-item>

        <el-form-item label="Override 2021 MDM"
          v-if="!proReview.decisionMaking2023 && (proReview.decisionMaking2021 || proReview.overrideDecisionMaking2021 || changedField === 'overrideDecisionMaking2021')"
          prop="overrideDecisionMaking2021">
          <input type="checkbox" :disabled="disabled" v-model="proReview.overrideDecisionMaking2021" @change="putProReview('overrideDecisionMaking2021')" />
        </el-form-item>

        <el-form-item label="Patient Status" prop="patientStatus">
          <hia-el-select :disabled="disabled" v-model="proReview.patientStatus" @input="putProReview('patientStatus')" placeholder="Patient Status" :filterable="true"
            itemkey="key" label="value" :dropdowns="dropdowns.ProPatientStatus.list"> </hia-el-select>
        </el-form-item>

        <el-form-item label=" " prop="billType">
          <label slot="label">
            Bill Type
            <el-tooltip effect="dark" content="Outpatient Code Edits are only available for Bill Types beginning with 013X and 083X" placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </label>
          <el-input :disabled="disabled" size="mini" :maxlength="4" type="text" id="providerSpecialty" v-model="proReview.billType" @change.native="putProReview('billType')">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="top-margin">
      <el-col :span="12">
        <h4 class="headerWithBorder">Chart Demographic</h4>

        <el-form-item size="mini" label="MR Number" prop="mrnumber">
          <el-input :disabled="disabled" size="mini" :maxlength="15" type="text" id="mrNumber" v-model="proReview.mrnumber" @change.native="putProReview('mrNumber')">
          </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Patient Number" prop="patientNumber">
          <div :class="disabled ? 'el-input el-input--mini is-disabled' : 'el-input el-input--mini'">
            <input id="patientNumber" :class="'el-input__inner'" :disabled="disabled" maxlength="15" type="text" :value="proReview.patientNumber"
              @change="putPatientNumber($event, 'patientNumber')" />
          </div>
        </el-form-item>

        <el-form-item size="mini" label="Payor" prop="payorNumber">
          <hia-el-select :disabled="disabled" @input="putProReview('payorNumber')" v-model="proReview.payorNumber" placeholder="Payor" :clearable="true" :filterable="true"
            itemkey="key" label="value" :dropdowns="dropdowns.payors.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Payor Detail" prop="payorDetailId">
          <payorDetailSelector :disabled="disabled || !proReview.payorNumber" @input="putProReview('payorDetailId')" v-model="proReview.payorDetailId"
            :payorNumberId="proReview.payorNumber"
            :payorNumberlabel="dropdowns.payors.list.find(f => f.key === proReview.payorNumber) ? dropdowns.payors.list.find(f => f.key === proReview.payorNumber).value : null"
            :payorDetail="proReview.payorDetail"> </payorDetailSelector>
        </el-form-item>

        <el-form-item size="mini" label="Date of Service" prop="dateOfService">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="dischargeDate" v-model="proReview.dateOfService" @change="putProReview('dateOfService')"
            format="MM/dd/yyyy"> </el-date-picker>
        </el-form-item>

        <el-form-item size="mini" label="Patient Age" prop="patientAge">
          <el-row>
            <el-col :span="patientAgeZero() ? 12 : 24">
              <el-input @keydown.native="digitsOnlyInput($event)" :disabled="disabled" size="mini" type="text" maxlength="3" id="patientAge" v-model="patientAgeYears"
                @change.native="putProReview('patientAge')"></el-input>
            </el-col>
            <el-col :span="12" v-if="patientAgeZero()">
              <el-input disabled size="mini" type="text" id="patientAgeDays" :value="patientAgeDays"></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item size="mini" label="Patient DOB" v-if="patientAgeZero()" prop="patientDob">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="patientDob" v-model="proReview.patientDob" @change="putProReview('patientDob')" format="MM/dd/yyyy">
          </el-date-picker>
        </el-form-item>

        <el-form-item size="mini" label="Newborn Weight (grams)" v-if="patientAgeZero()" prop="newbornWeight">
          <el-input :disabled="disabled" size="mini" type="number" id="patientWeight" v-model="proReview.newbornWeight" @change="handleNewbornWeight">
          </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Patient Sex" prop="sex">
          <hia-el-select :disabled="disabled" @input="putProReview('sex')" v-model="proReview.sex" placeholder="Patient Sex" :clearable="true" :filterable="true" itemkey="key"
            label="value" :dropdowns="dropdowns.patientSex.list"> </hia-el-select>
        </el-form-item>

        <el-form-item label="Patient Name" prop="patientName">
          <el-input :disabled="disabled" size="mini" :maxlength="100" type="text" id="campus" v-model="proReview.patientName" @change.native="putProReview('patientName')">
          </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Provider" prop="providerId">
          <providerSelector :disabled="disabled" v-model="proReview.providerId" @input="putProReview('providerId')" :provider="proReview.provider"> </providerSelector>
        </el-form-item>

        <el-form-item size="mini" label="Coder" prop="coderId">
          <coderSelector :disabled="disabled" v-model="proReview.coderId" @input="putProReview('coder')" :clientcoder="proReview.clientCoder"> </coderSelector>
        </el-form-item>

        <el-form-item size="mini" label="Service Line" prop="clientServiceLineId">
          <serviceLineSelector :disabled="disabled" v-model="proReview.clientServiceLineId" @input="putProReview('serviceLine')" :serviceLine="proReview.serviceLine">
          </serviceLineSelector>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <h4 class="headerWithBorder">Audit Notes</h4>

        <el-form-item size="mini" label="Reason For Change" prop="reasonForChange">
          <hia-el-select :disabled="disabled" @input="putProReview('DemographicsreasonForChange')" v-model="proReview.reasonForChange" clearable
            placeholder="Reason for Change" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.proReasonsForChangeHeader.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Comments" prop="comments">
          <el-input :disabled="disabled" type="textarea" :rows="5" placeholder="" v-model="proReview.comments" @change="putProReview('comments')"> </el-input>
          <!-- <textarea style="width: 100%;" class="el-textarea__inner" :disabled="disabled" type="textarea" :rows="5" id="comments" v-model.lazy="proReview.comments" @change="putProReview('comments')"></textarea> -->
        </el-form-item>

        <el-form-item size="mini" label="Record Tags" prop="identifierSelectionsArray">
          <el-select class="multiSelect" :disabled="disabled" size="mini" v-model="proReview.identifierSelectionsArray" filterable multiple placeholder="Tags"
            @visible-change="tagsSelectHideShow($event)" @remove-tag="tagsSelectRemoveTag($event)">
            <el-option v-for="item in dropdowns.recordTags.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-popover ref="savePopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent"
      @after-enter="handleHidePopOver('savePopover')"> </el-popover>
  </el-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { differenceInDays, parseISO } from 'date-fns'

  import inputValidations from '@/mixins/inputValidations'
  import inpatientHelper from '@/mixins/inpatientHelper'

  import providerSelector from '@/components/controls/providerSelector'
  import coderSelector from '@/components/controls/coderSelector'
  import serviceLineSelector from '@/components/controls/serviceLineSelector'
  import payorDetailSelector from '@/components/controls/payorDetailSelector'

  import projectSelector from '@/components/controls/projectSelector'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'proReviewDemographics',
    mixins: [inpatientHelper, inputValidations, HiaAuthorization],
    components: {
      providerSelector,
      coderSelector,
      projectSelector,
      serviceLineSelector,
      payorDetailSelector
    },
    data() {
      return {
        savePopoverContent: '',
        loading: false,
        backupIdentifierSelectionsArray: [],
        changedField: ''
      }
    },
    methods: {
      ...mapActions('proReviews/', ['PUT_PRO_REVIEW']),
      async putProReview(changedField) {
        if (changedField === 'payorNumber') {
          this.proReview.payorDetailId = null
          this.proReview.payorDetail = {}
        }

        try {
          this.loading = true
          const payload = { proReview: this.proReview, changedField: changedField }
          await this.PUT_PRO_REVIEW(payload)
          this.loading = false
          this.$emit('requiredFieldsValidation', {})
          this.$message({
            message: 'Demographics Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: e.message ? e.message : 'Demographics Update Error',
            type: 'error'
          })
        }
      },
      patientAgeZero() {
        if (this.proReview.patientAge === 0 || this.proReview.patientAge === '0') {
          return true
        }
        return false
      },
      handleProjectSelected(project) {
        this.proReview.project = project
      },
      tagsSelectHideShow(show) {
        if (show) {
          this.backupIdentifierSelectionsArray = this.proReview.identifierSelectionsArray
        } else {
          if (JSON.stringify(this.backupIdentifierSelectionsArray) !== JSON.stringify(this.proReview.identifierSelectionsArray)) {
            this.identifiersOnChange()
          }
        }
      },
      tagsSelectRemoveTag() {
        this.identifiersOnChange(this.proReview)
      },
      identifiersOnChange() {
        this.proReview.identifierSelectionsArray.forEach(element => {
          // if element doesn't exist in, add it with add flag
          const matchedElements = this.proReview.identifierSelections.some(function (i) {
            return i.identifierID === element
          })
          if (!matchedElements) {
            const newValue = {
              id: 0,
              identifierID: element,
              add: true
            }

            this.proReview.identifierSelections.push(newValue)
          }
        })

        this.proReview.identifierSelections.forEach(element => {
          // if element doesn't exists in Array, set deleted flag
          if (!this.proReview.identifierSelectionsArray.includes(element.identifierID)) {
            element.delete = true
          }
        })

        this.putProReview('recordTags')
      },
      mapRequiredFieldsRules() {
        const requiredFields = this.proReview.requiredFields
        if (requiredFields && requiredFields.requiredFields) {
          const requiredFieldsData = requiredFields.requiredFieldsData
          const rules = {}
          requiredFieldsData.forEach((item, index, array) => {
            if (item.required) {
              rules[item.field] = [
                {
                  required: item.required,
                  trigger: 'change',
                  message: item.message
                }
              ]
            }
          })
          return rules
        }
        return {}
      },
      handleNewbornWeight() {
        this.proReview.newbornWeight = !isNaN(this.proReview.newbornWeight) ? parseInt(this.proReview.newbornWeight) : null
        this.putProReview('newbornWeight')
      },
      putPatientNumber(event, field) {
        if (!event.target.value) {
          this.savePopoverContent = 'Patient Number must always contain a value.'
          this.openSavePopover(event)
          event.target.value = this.proReview.patientNumber
          return false
        }
        this.proReview.patientNumber = event.target.value
        this.putProReview(field)
      },
      openSavePopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.savePopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user']),
      disabled() {
        return !!this.readOnly() || this.proReview.reviewStatusId !== 2
      },
      coderComputed: {
        get: function () {
          if (this.proReview.hiaCoderId) {
            return this.proReview.hiaCoderId
          }
          return this.proReview.coderId
        },
        set: function (val) {
          if (!val) {
            this.proReview.hiaCoderId = null
            this.proReview.coderId = null
          }

          if (typeof val === 'string') {
            this.proReview.hiaCoderId = val
            this.proReview.coderId = null
          } else {
            this.proReview.hiaCoderId = null
            this.proReview.coderId = val
          }
        }
      },
      icdRadio: {
        get: function () {
          if (this.proReview.icd9) {
            return 'ICD9'
          }
          return 'ICD10'
        },
        set: function (newValue) {
          if (newValue === 'ICD9') {
            this.proReview.icd9 = true
            this.proReview.icd10 = false
          } else {
            this.proReview.icd9 = false
            this.proReview.icd10 = true
          }
        }
      },
      patientAgeYears: {
        get: function () {
          return this.proReview.patientAge
        },
        set: function (val) {
          this.proReview.patientAge = val
          if (this.proReview.patientAge !== 0) {
            this.proReview.patientDob = null
          }
        }
      },
      patientAgeDays: function () {
        const a = parseISO(this.proReview.admitDate)
        const b = parseISO(this.proReview.patientDob)
        const diff = differenceInDays(a, b)
        if (diff) {
          return `${diff} days`
        } else {
          return '0 days'
        }
      },
      disableSubClients: function () {
        if (this.dropdowns.SubClients.length < 1) {
          return true
        }
        return false
      },
      reviewLevelComputed: {
        get: function () {
          return this.proReview.reviewLevel.toString()
        },
        set: function (newValue) {
          this.proReview.reviewLevel = parseInt(newValue)
        }
      },
      // actionPlansComputed: {
      //   get: function () {
      //     let simpleArray = []
      //     let length = this.proReview.actionPlansRecommendations.length
      //     for (var i = 0; i < length; i++) {
      //       let id = this.proReview.actionPlansRecommendations[i].actionPlanId
      //       simpleArray.push(id)
      //     }
      //     return simpleArray
      //   },
      //   set: function (newValue) {
      //     // set the deleted flags for any that are deleted
      //     let length = this.proReview.actionPlansRecommendations.length
      //     for (var i = 0; i < length; i++) {
      //       if (!newValue.includes(this.proReview.actionPlansRecommendations[i].actionPlanId)) {
      //         this.proReview.actionPlansRecommendations[i].delete = true
      //       }
      //     }

      //     // add any new ones
      //     let newValueLength = newValue.length
      //     for (var j = 0; j < newValueLength; j++) {
      //       let newActionPlan = true
      //       for (var k = 0; k < length; k++) {
      //         if (newValue[j] === this.proReview.actionPlansRecommendations[k].actionPlanId) {
      //           newActionPlan = false
      //           break
      //         }
      //       }

      //       if (newActionPlan) {
      //         this.proReview.actionPlansRecommendations.push({
      //           actionPlanId: parseInt(newValue[j]),
      //           patientType: 'IP',
      //           add: true
      //         })
      //       }
      //       newActionPlan = false
      //     }
      //   }
      // },
      origDrgComputed: {
        get: function () {
          return this.getGroupingResultValue(this.proReview.ipreviewType, 0, 'drg', this.proReview.groupingResults)
        }
      },
      origDrgWtComputed: {
        get: function () {
          return this.getGroupingResultValue(this.proReview.ipreviewType, 0, 'drgWeight', this.proReview.pricingResults)
        }
      },
      origSoiCalc: {
        get: function () {
          return this.getGroupingResultValue('APR', 0, 'severityOfIllness', this.proReview.groupingResults)
        }
      },
      origRomCalc: {
        get: function () {
          return this.getGroupingResultValue('APR', 0, 'riskOfMortality', this.proReview.groupingResults)
        }
      },
      origOtherDrgCalc: {
        get: function () {
          if (this.proReview.otherDrgtype) {
            return this.getGroupingResultValue(this.proReview.otherDrgtype, 0, 'drg', this.proReview.groupingResults)
          }
          return null
        }
      }
    }
  }
</script>