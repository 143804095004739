<template>
  <div>
    <template v-if="!isLevel2">
      <div v-loading="pxLoading" element-loading-spinner="atom-audit-loader">
        <v-client-table v-if="toggleGrid" key="pxGrid" class="hiaTable" ref="pxGrid" id="pxGrid" :data="inpatientReview.drgRecommendationsPx" :columns="columns" :options="options" uniqueKey="pxId">

          <template slot="lineSequence" slot-scope="props">
            <span class="dragIcon drag-handle" style="margin-top: 3px; float: left">{{props.row.lineSequence}}</span>
          </template>

          <template slot="edits" slot-scope="props">
            <div v-html="pxCodeEdits(props.row.edits)"></div>
          </template>

          <template slot="procedureDate" slot-scope="props">
            <el-date-picker :disabled="disabled" size="mini" type="date" id="revDate" v-model="props.row.procedureDate" @input="putInpatientPx(props.row, 'pxCode')" format="MM/dd/yyyy">
            </el-date-picker>
          </template>

          <template slot="originalPx" slot-scope="props">
            <div>
              <HiaCodeInput :ref="props.row.pxId + 'origPx'" style="width: 112px;" :inputId="`originalPx_${props.index}`" class="noRightBorder borderLeftRadius" v-model="props.row.originalPx" :disabled="disabled" @ellipsisClick="emitEllipsisClick(props.row.originalPx)" @focus="emitFocusedCode(props.row.originalPx)" @change="putInpatientPx(props.row, 'pxCode', $event)">
              </HiaCodeInput>
            </div>
            <IpPxGf style="float: left; margin: 5px 0px 0px 0px;" ref="originalGrouper" :px="props.row" :reviewLevel="0" :grouperType="inpatientReview.grouper"></IpPxGf>
            <span style="float: left; margin-top: 1px;" v-html="pxCodeEdits(props.row.edits)"></span>
          </template>

          <template slot="originalDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{props.row.originalDescCalc}}</div>
          </template>

          <template slot="recommendedPx" slot-scope="props">
            <div>
              <HiaCodeInput :ref="props.row.pxId + 'recPx'" style="width: 112px;" :placeholder="props.row.originalPx" :inputId="`recommendedPx_${props.index}`" :class="{deleteCode: props.row.deleteCode}" v-model="props.row.recommendedPx" :disabled="props.row.deleteCode || disabled" @ellipsisClick="emitEllipsisClick(props.row.recommendedPx)" @focus="emitFocusedCode(props.row.recommendedPx)" @change="putInpatientPx(props.row, 'pxCode', $event)">
              </HiaCodeInput>
            </div>
            <IpPxGf style="float: left; margin: 5px 0px 0px 0px;" ref="recommendedGrouper" :px="props.row" :reviewLevel="1" :grouperType="inpatientReview.grouper"></IpPxGf>
            <span style="float: left" v-html="pxCodeEdits(props.row.recEdits)"></span>
          </template>

          <template slot="recDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.deleteCode ? props.row.recDescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.deleteCode" title="Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled" @change="putInpatientPx(props.row, 'deletePx', $event)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.exclude" title="Exclude Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled" @change="putInpatientPx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.validated" title="Validated by Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled" @change="putInpatientPx(props.row)">
              </iconCheckBox>
            </div>
          </template>

          <template slot="recDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.deleteCode ? props.row.recDescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <hia-el-select style="width: 82%" :title="disableReasonForChange(props.row) ? 'No change on line' : null" :disabled="disableReasonForChange(props.row) || disabled" @input="putInpatientPx(props.row, $event)" v-model="props.row.reasonForChange" placeholder="Reason for Change" itemkey="key" label="value" :dropdowns="dropdowns.inpatientReasonsForChangePx.list" clearable filterable>
            </hia-el-select>
            <i v-if="!disabled" style="float: right; cursor: pointer; margin-top: -20px;" title="Delete Px Row" class="el-icon-close delete-button" @click="deletePx(props.row)"></i>
          </template>

        </v-client-table>

        <v-client-table v-if="toggleGrid" key="pxGridAddRow" class="hiaTable" ref="pxGridAddRow" id="pxGridAddRow" :data="addRow" :columns="addRowColumns" :options="options">

          <template slot="originalPx" slot-scope="props">
            <input style="width: 100px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="originalPx" maxlength="7" minlength="3" v-model="props.row.originalPx" v-on:keyup.enter="addPxRow(props.row)">
          </template>

          <template slot="originalDescCalc">
            <div>&nbsp;</div>
          </template>

          <template slot="recommendedPx" slot-scope="props">
            <input style="width: 100px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="recommendedPx" maxlength="7" minlength="3" v-model="props.row.recommendedPx" v-on:keyup.enter="addPxRow(props.row)">
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <el-popover ref="addPopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="addPopoverContent" v-model="showAddPopover" @after-enter="handleHidePopOver('addPopover')">
              <el-button :disabled="disabled" round icon="el-icon-plus" slot="reference" style="float: right;" type="primary" size="mini" @click="addPxRow(props.row)">Add
              </el-button>
            </el-popover>
          </template>

        </v-client-table>
      </div>

    </template>

    <!-- <template v-if="isLevel2">
      <div v-loading="loading" element-loading-spinner="atom-audit-loader">
        <v-client-table v-if="toggleGrid" key="pxGridLevel2" class="hiaTable" ref="pxGridLevel2" id="pxGridLevel2" :data="inpatientReview.drgRecommendationsPx"
          :columns="level2Columns" :options="level2Options">

          <template slot="lineSequence" slot-scope="props">
            <span class="dragIcon drag-handle" style="margin-top: 3px; float: left">{{props.row.lineSequence}}</span>
          </template>

          <template slot="originalPx" slot-scope="props">
            <div>
              <HiaCodeInput style="width: 77px;" :inputId="`originalPx_${props.index}`" class="noRightBorder borderLeftRadius" v-model="props.row.originalPx"
                :disabled="disabled" @ellipsisClick="emitEllipsisClick(props.row.originalPx)" @focus="emitFocusedCode(props.row.originalPx)"
                @change="putInpatientPx(props.row, 'pxCode')">
              </HiaCodeInput>
            </div>
            <IpPxGf v-if="props.row.grouperFlags.some(x => x.reviewLevel === 0)" style="float: left; margin: 5px 0px 0px 0px;" ref="originalGrouper" :px="props.row"
              :reviewLevel="0" :grouperType="inpatientReview.grouper"></IpPxGf>
            <span style="float: left; margin-top: 1px;" v-html="pxCodeEdits(props.row.edits)"></span>
          </template>

          <template slot="originalDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{props.row.originalDescCalc}}</div>
          </template>

          <template slot="recommendedPx" slot-scope="props">
            <div>
              <HiaCodeInput style="width: 77px;" :placeholder="props.row.originalPx" :inputId="`recommendedPx_${props.index}`" :class="{deleteCode: props.row.deleteCode}"
                v-model="props.row.recommendedPx" :disabled="props.row.deleteCode" @ellipsisClick="emitEllipsisClick(props.row.recommendedPx)"
                @focus="emitFocusedCode(props.row.recommendedPx)" @change="putInpatientPx(props.row, 'pxCode')">
              </HiaCodeInput>
            </div>
            <IpPxGf style="float: left; margin: 5px 0px 0px 0px;" ref="recommendedGrouper" :px="props.row" :reviewLevel="1" :grouperType="inpatientReview.grouper">
            </IpPxGf>
            <span style="float: left" v-html="pxCodeEdits(props.row.recEdits)"></span>
          </template>

          <template slot="recDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.deleteCode ? props.row.recDescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.deleteCode" title="Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled"
                @input="putInpatientPx(props.row, 'deletePx')">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.exclude" title="Exclude Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled"
                @input="putInpatientPx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.validated" title="Validated by Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled"
                @input="putInpatientPx(props.row)">
              </iconCheckBox>
            </div>
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <hia-el-select style="width: 100px;" :disabled="disabled" @input="putInpatientPx(props.row, $event)" v-model="props.row.reasonForChange"
              placeholder="Reason for Change" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.inpatientReasonsForChange.list">
            </hia-el-select>
          </template>

          <template slot="level2Px" slot-scope="props">
            <div>
              <HiaCodeInput style="width: 77px;" :placeholder="props.row.recommendedPx ? props.row.recommendedPx : props.row.originalPx" :inputId="`level2Px_${props.index}`"
                :class="{deleteCode: props.row.level2DeleteCode || props.row.deleteCode}" v-model="props.row.level2Px" :disabled="props.row.level2DeleteCode"
                @ellipsisClick="emitEllipsisClick(props.row.level2Px)" @focus="emitFocusedCode(props.row.level2Px)" @change="putInpatientPx(props.row, 'pxCode')">
              </HiaCodeInput>
            </div>
            <IpPxGf style="float: left; margin: 5px 0px 0px 0px;" ref="level2Grouper" :px="props.row" :reviewLevel="2" :grouperType="inpatientReview.grouper"></IpPxGf>
            <span style="float: left" v-html="pxCodeEdits(props.row.level2Edits)"></span>
          </template>

          <template slot="level2DescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.level2DeleteCode ? props.row.level2DescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="level2Actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.level2DeleteCode" title="Level 2 Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled"
                @input="putInpatientPx(props.row, 'deletePxLevel2')">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.level2Exclude" title="Exclude Level 2 Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled"
                @input="putInpatientPx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.level2Validated" title="Validated by Level 2 Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled"
                @input="putInpatientPx(props.row)">
              </iconCheckBox>
            </div>
          </template>

          <template slot="level2ReasonForChange" slot-scope="props">
            <hia-el-select style="width: 82%" :disabled="disabled" @input="putInpatientPx(props.row, $event)" v-model="props.row.level2ReasonForChange"
              placeholder="Reason for Change" itemkey="key" label="value" :dropdowns="dropdowns.inpatientReasonsForChange.list" clearable filterable>
            </hia-el-select>
            <i style="float: right; cursor: pointer; margin-top: -20px;" title="Delete Px Row" class="el-icon-close delete-button" @click="deletePx(props.row)"></i>

          </template>

        </v-client-table>

        <v-client-table v-if="toggleGrid" key="pxGridLevel2AddRow" class="hiaTable" ref="pxGridLevel2AddRow" id="pxGridLevel2AddRow" :data="addRow"
          :columns="level2AddRowColumns" :options="level2Options">

          <template slot="originalPx" slot-scope="props">
            <input style="width: 65px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="originalPx" maxlength="7" minlength="3"
              v-model="props.row.originalPx" @keyup.enter="addPxRow(props.row)">
          </template>

          <template slot="originalDescCalc">
          </template>

          <template slot="recommendedPx" slot-scope="props">
            <input style="width: 65px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="recommendedPx" maxlength="7" minlength="3"
              v-model="props.row.recommendedPx" @keyup.enter="addPxRow(props.row)">

          </template>

          <template slot="actions">
            <div>&nbsp;</div>
          </template>

          <template slot="reasonForChange">
            <hia-el-select style="width: 100px; visibility: hidden;"></hia-el-select>
          </template>

          <template slot="level2Px" slot-scope="props">
            <input style="width: 65px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="level2Px" maxlength="7" minlength="3"
              v-model="props.row.level2Px" @keyup.enter="addPxRow(props.row)">
          </template>

          <template slot="level2ReasonForChange" slot-scope="props">
            <el-popover ref="addPopoverLevel2" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover"
              :content="addPopoverContent" v-model="showAddPopover" @after-enter="handleHidePopOver('addPopoverLevel2')">
              <el-button slot="reference" style="float: right;" type="primary" size="mini" @click="addPxRow(props.row)">Add</el-button>
            </el-popover>
          </template>
        </v-client-table>
      </div>
    </template> -->

    <div class="dialogs">
      <addMultipleCodes :disabled="disabled" :show="showAddMultipleCodes" :type="'PX'" :review="inpatientReview" @close="handleCloseAddMultipleCodes" @postAddMultipleCodes="postAddMultipleCodes">
      </addMultipleCodes>
    </div>

    <el-popover ref="savePopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent" @after-enter="handleHidePopOver('savePopover')">
    </el-popover>

  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import IpPxGf from './IpPxGf'
  import iconCheckBox from '@/components/controls/iconCheckBox'
  import HiaCodeInput from '@/components/controls/HiaCodeInput'
  import Sortable from 'sortablejs'
  import addMultipleCodes from '@/components/controls/addMultipleCodes'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'InpatientReviewPx',
    props: ['showAddMultipleCodes'],
    mixins: [HiaAuthorization],
    data: function () {
      return {
        toggleGrid: true,
        loading: false,
        // disabled: false,
        theSortable: null,
        addPopoverContent: '',
        savePopoverContent: '',
        showAddPopover: false,
        addRow: [{ originalPx: '', recommendedPx: '' }],
        addRowColumns: ['lineSequence', 'procedureDate', 'originalPx', 'originalDescCalc', 'recommendedPx', 'recDescCalc', 'actions', 'reasonForChange'],
        columns: ['lineSequence', 'procedureDate', 'originalPx', 'originalDescCalc', 'recommendedPx', 'recDescCalc', 'actions', 'reasonForChange'],
        level2Columns: ['lineSequence', 'procedureDate', 'originalPx', 'originalDescCalc', 'recommendedPx', 'recDescCalc', 'actions', 'reasonForChange', 'level2Px', 'level2DescCalc', 'level2Actions', 'level2ReasonForChange'],
        level2AddRowColumns: ['lineSequence', 'procedureDate', 'originalPx', 'originalDescCalc', 'recommendedPx', 'recDescCalc', 'actions', 'reasonForChange', 'level2Px', 'level2DescCalc', 'level2Actions', 'level2ReasonForChange'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'lineSequenceCol',
            procedureDate: 'procedureDateCol',
            originalPx: 'oneHundredPx',
            originalDescCalc: 'twentyPercent',
            recommendedPx: 'oneHundredPx',
            recDescCalc: 'twentyPercent',
            actions: 'actionsCol',
            reasonForChange: 'reasonForChangeCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            procedureDate: 'Procedure Date',
            grouperFlags: 'GF',
            recommendedGrouperFlags: 'GF',
            originalPx: 'Orig. Px',
            recommendedPx: 'Rec. Px',
            originalDescCalc: 'Desc.',
            recDescCalc: 'Desc.',
            recommendedEdits: 'Edits',
            reasonForChange: 'Reason For Change',
            delete: '',
            add: ''
          }
        },
        level2Options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'lineSequenceCol',
            originalPx: 'seventyFivePx',
            originalDescCalc: 'twentyPercent descOverflow',
            recommendedPx: 'seventyFivePx',
            recDescCalc: 'twentyPercent descOverflow',
            actions: 'actionsCol',
            reasonForChange: 'reasonForChangeCol',
            level2Px: 'seventyFivePx',
            level2DescCalc: 'twentyPercent descOverflow',
            level2Actions: 'actionsCol',
            level2ReasonForChange: 'reasonForChangeCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            grouperFlags: 'GF',
            recommendedGrouperFlags: 'GF',
            originalPx: 'Orig. Px',
            recommendedPx: 'Rec. Px',
            originalDescCalc: 'Desc.',
            recDescCalc: 'Desc.',
            recommendedEdits: 'Edits',
            reasonForChange: 'Reason For Change',
            level2Px: 'Lvl. 2 Px',
            level2DescCalc: 'Desc',
            level2Actions: 'Actions',
            level2ReasonForChange: 'Reason For Change',
            delete: '',
            add: ''
          }
        }
      }
    },
    components: {
      IpPxGf,
      iconCheckBox,
      HiaCodeInput,
      addMultipleCodes
    },
    methods: {
      ...mapActions('inpatientReviews/', ['PUT_INPATIENT_PX', 'DELETE_INPATIENT_PX', 'POST_INPATIENT_PX', 'PUT_INPATIENT_PXS_FOR_RESEQUENCE']),
      ...mapMutations('inpatientReviews/', ['SET_INPATIENT_PX_ROW', 'PUSH_PX_ROW', 'SET_INPATIENT_PXS']),
      async putInpatientPx(row, changedField, e) {
        if (!row.recommendedPx && !row.originalPx) {
          this.savePopoverContent = 'Either Original Px or Recommended Px must be populated. To delete the entire row, use the X button on the right side.'
          this.openSavePopover(e)
          const backupRow = this.inpatientReview.drgRecommendationsPx.find(x => x.pxId === row.pxId)
          this.SET_INPATIENT_PX_ROW(backupRow)
          this.$refs[row.pxId + 'recPx'].$forceUpdate()
          this.$refs[row.pxId + 'origPx'].$forceUpdate()
          return
        }

        if (!row.originalPx && changedField === 'deletePx') {
          this.savePopoverContent = 'The Delete Code recommendation cannot be used if there is no Original Px.'
          this.openSavePopover(e)
          const backupRow = this.inpatientReview.drgRecommendationsPx.find(x => x.pxId === row.pxId)
          this.SET_INPATIENT_PX_ROW(backupRow)
          return
        }

        if (row.reasonForChange && !row.recommendedPx && !row.deleteCode) {
          try {
            await this.$confirm('This will clear the Reason for Change. Continue?', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.reasonForChange = null
          } catch (e) {
            const backupRow = this.inpatientReview.drgRecommendationsPx.find(x => x.pxId === row.pxId)
            this.SET_INPATIENT_PX_ROW(backupRow)
            // need to force update on the Rec DX HiaCodeInput so that restoring the backupRow will be reactive
            this.$refs[row.pxId + 'recPx'].$forceUpdate()
            return
          }
        }

        if (changedField === 'deletePx' && row.recommendedPx && row.deleteCode) {
          try {
            await this.$confirm(`This will clear the Recommended Px ${row.recommendedPx}. Continue?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.recommendedPx = null
          } catch (e) {
            row.deleteCode = false
            this.SET_INPATIENT_PX_ROW(row)
            return
          }
        }

        if (changedField === 'deletePxLevel2' && row.level2Px && row.level2DeleteCode) {
          try {
            await this.$confirm(`This will clear the Level 2 Recommended Px ${row.level2Px}. Continue?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.level2Px = null
          } catch (e) {
            row.level2DeleteCode = false
            this.SET_INPATIENT_PX_ROW(row)
            return
          }
        }

        this.SET_INPATIENT_PX_ROW(row)
        const payload = { pxRow: row, changedField: changedField }

        this.loading = true
        try {
          await this.PUT_INPATIENT_PX(payload)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.inpatientReview)
          this.$message({
            message: 'Procedure Code Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Procedure Code Update Error',
            type: 'error'
          })
        }
      },
      async addPxRow(row) {
        if (!this.isLevel2 && !row.originalPx && !row.recommendedPx) {
          this.addPopoverContent = 'Either Original PX or Recommended PX must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (this.isLevel2 && !row.originalPx && !row.recommendedPx && !row.level2Px) {
          this.addPopoverContent = 'Either Original PX, Recommended PX or Level 2 PX must be populated to add row.'
          this.showAddPopover = true
          return
        }

        row.pxId = 0
        row.drgTableId = this.inpatientReview.drgTableId
        row.newPrimary = false
        row.level2NewPrimary = false
        row.originalPx = row.originalPx ? row.originalPx.toUpperCase().replace('.', '').trim() : null
        row.recommendedPx = row.recommendedPx ? row.recommendedPx.toUpperCase().replace('.', '').trim() : null

        this.loading = true
        try {
          await this.POST_INPATIENT_PX([row])
          this.$set(this.addRow, 0, { originalPx: '', recommendedPx: '' })
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.inpatientReview)
          this.$message({
            message: 'Procedure Code Added Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Procedure Code Added Error',
            type: 'error'
          })
        }
      },
      async deletePx(row) {
        this.loading = true
        try {
          await this.DELETE_INPATIENT_PX(row)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.inpatientReview)
          this.$message({
            message: 'Procedure Code Deleted Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Procedure Code Delete Error',
            type: 'error'
          })
        }
      },
      openSavePopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.savePopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = (e) => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      emitEllipsisClick(code) {
        this.emitFocusedCode(code)
        this.$emit('ellipsisClick', code)
      },
      emitFocusedCode(focusedCode) {
        if (focusedCode && focusedCode !== 'DEL') {
          const truCodeQueryObject = {
            code: focusedCode,
            type: 'PX'
          }
          this.$emit('onCodeFocus', truCodeQueryObject)
        }
      },
      pxCodeEdits(edits) {
        if (edits.length) {
          let pxDesc = ''
          for (const i in edits) {
            pxDesc += edits[i].description.replace(/<[^>]*>/g, '') + ' \n'
          }
          return '<div style="background: url(/static/trucode/css/img/icons/warning.png); width: 16px; height: 16px; margin-top: 2px"  title="' + pxDesc.trim() + '"></div>'
        }
      },
      disableReasonForChange(row) {
        if (!row.recommendedPx && !row.deleteCode) {
          return true
        }
        return false
      },
      async handleDragStop(e) {
        const temp = this.inpatientReview.drgRecommendationsPx[e.oldIndex]
        this.inpatientReview.drgRecommendationsPx.splice(e.oldIndex, 1)
        this.inpatientReview.drgRecommendationsPx.splice(e.newIndex, 0, temp)
        this.updateLineSequence(this.inpatientReview.drgRecommendationsPx)
        this.SET_INPATIENT_PXS(this.inpatientReview.drgRecommendationsPx)

        // force the grid to re-initialize by destroying and recreating it
        // and then reattaching the sortable
        this.toggleGrid = false
        this.theSortable.destroy()
        this.$nextTick(() => {
          this.toggleGrid = true
          this.$nextTick(() => {
            this.attachSortable()
          })
        })

        this.loading = true
        try {
          await this.PUT_INPATIENT_PXS_FOR_RESEQUENCE(this.inpatientReview.drgRecommendationsPx)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.inpatientReview)
        } catch (e) {
          this.loading = true
        }
      },
      updateLineSequence(array) {
        array.map((o, i) => {
          o.lineSequence = (i + 1)
          return o
        })
      },
      attachSortable() {
        const gridElementName = this.isLevel2 ? 'pxGridLevel2' : 'pxGrid'
        const gridDiv = document.getElementById(gridElementName)
        if (gridDiv) {
          const tbody = gridDiv.getElementsByTagName('tbody')
          this.theSortable = Sortable.create(tbody[0], {
            animation: 200,
            draggable: 'tr',
            handle: '.drag-handle',
            onEnd: (e) => {
              this.handleDragStop(e)
            }
          })
        }
      },
      handleCloseAddMultipleCodes() {
        this.$emit('handleCloseAddMultipleCodes', false)
      },
      async postAddMultipleCodes(data) {
        if (data.codes) {
          // populate new codes
          const newCodes = []
          let startLineSequence = (this.inpatientReview.drgRecommendationsPx.length + 1)
          data.codes.forEach((v, i) => {
            const newCode = {
              drgtableId: this.inpatientReview.drgTableId,
              lineSequence: startLineSequence,
              add: true,
              originalPx: data.isRecommended ? null : v.trim(),
              validated: false,
              exclude: false,
              recommendedPx: data.isRecommended ? v.trim() : null,
              reasonForChange: '',
              level2Px: null,
              procedureDate: null,
              level2ReasonForChange: ''
            }
            newCodes.push(newCode)
            startLineSequence++
          })
          data.codes = newCodes
          data.changedField = 'addMultipleCodes'

          this.loading = true
          try {
            await this.POST_INPATIENT_PX(data.codes)
            this.$message({
              message: 'Multiple Codes Added Successfully',
              type: 'success'
            })
          } catch (e) {
            this.$message({
              message: 'Multiple Codes Added Error',
              type: 'error'
            })
          }
          this.loading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(() => {
        this.attachSortable()
      })
    },
    watch: {
      isLevel2(val) {
        this.$nextTick(() => {
          if (this.theSortable) {
            this.attachSortable()
          }
        })
      }
    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview', 'pxLoading']),
      ...mapState('dropdowns/', ['dropdowns']),
      disabled() {
        return !!this.readOnly() || this.inpatientReview.reviewStatusId !== 2
      },
      isLevel2: function () {
        return this.inpatientReview.reviewLevel > 1
      }
    }
  }
</script>

<style scoped>
.dragIcon {
  cursor: move;
  margin: 1px 0px 0px 1px;
  padding: 0px 0px 0px 0px;
  float: left;
  height: 20px;
  color: #1f2d3d;
}

.drag-handle {
  cursor: move;
  display: block;
  float: left;
}

>>> .lineSequenceCol {
  width: 15px;
}

>>> .procedureDateCol {
  width: 130px;
}

>>> .actionsCol {
  max-width: 90px;
  min-width: 90px;
}

>>> .reasonForChangeCol {
  min-width: 140px;
}

>>> .deleteCol {
  max-width: 30px;
}

>>> .addCol {
  min-width: 150px;
}

>>> .thirtyPx {
  min-width: 20px;
}

>>> .fiftyPx {
  width: 50px;
}

>>> .seventyFivePx {
  width: 75px;
}

>>> .oneHundredPx {
  width: 100px;
}

>>> .twentyPercent {
  width: 17%;
}

>>> .descOverflow {
  word-break: break-word;
}

.hiaTable .el-icon-close {
  border: 1px solid transparent;
}

.hiaTable tr:nth-child(even) .el-icon-close {
  border: 1px solid #606266;
  border-radius: 100%;
}

>>> #pxGridAddRow thead,
>>> #pxGridLevel2AddRow thead {
  visibility: hidden;
}

>>> #pxGridAddRow table,
>>> #pxGridLevel2AddRow table {
  margin-top: -1px;
}

>>> #pxGridAddRow tbody > tr {
  height: 40px;
  border-top: 1px solid #ebeef5;
  background-color: #fff !important;
}

>>> #pxGridAddRow th,
>>> #pxGridLevel2AddRow th {
  line-height: 0px;
  padding: 0px 5px 0px 5px;
}

>>> #pxGrid tr,
>>> #pxGridLevel2 tr {
  vertical-align: top;
}

>>> #pxGrid ::placeholder,
>>> #pxGridLevel2 ::placeholder {
  opacity: 0.8;
}

>>> .VuePagination__count {
  display: none;
}
</style>
