<template>
  <div class="ellipsis-wrapper">
    <input v-mask="inputId.toLowerCase().includes('cpt') ? 'NNNNN' : 'NNNNNNN'" :id="inputId" class="code" :placeholder="placeholder" :disabled="disabled"
      :maxlength="inputId.toLowerCase().includes('cpt') ? 5 : 7" :value="value" @focus="$emit('focus')"
      @input="$emit('input', $event.target.value ? $event.target.value.toUpperCase() : null)" @change="$emit('change', $event)">
    <i :title="`Open Codebook for ${value}`" v-if="value && value !== 'DEL' && isTrucodeEnabled()" class="mdi mdi-dots-horizontal" @click="$emit('ellipsisClick', value)"></i>
  </div>
</template>

<script>
  export default {
    name: 'HiaCodeInput',
    props: ['value', 'disabled', 'inputId', 'placeholder'],
    methods: {}
  }
</script>

<style scoped>
  .ellipsis-wrapper {
    position: relative;
  }

  .ellipsis-wrapper>i {
    visibility: hidden;
  }

  .ellipsis-wrapper:hover>i {
    visibility: visible;
  }

  .ellipsis-wrapper>i:active {
    visibility: visible;
  }

  .deleteCode> ::placeholder {
    text-decoration: line-through;
  }

  i {
    font-size: 16px;
    position: absolute;
    right: 2px;
    top: 4px;
    cursor: pointer;
    color: #1f2d3d;
  }

  input {
    text-transform: uppercase;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #b4bccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: #1f2d3d;
    display: block;
    font-size: inherit;
    height: 20px;
    line-height: 1;
    outline: 0;
    padding: 3px 3px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
</style>
