import { render, staticRenderFns } from "./InpatientReviewPx.vue?vue&type=template&id=58d6c617&scoped=true&"
import script from "./InpatientReviewPx.vue?vue&type=script&lang=js&"
export * from "./InpatientReviewPx.vue?vue&type=script&lang=js&"
import style0 from "./InpatientReviewPx.vue?vue&type=style&index=0&id=58d6c617&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d6c617",
  null
  
)

export default component.exports