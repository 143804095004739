<template>
  <el-form :model="inpatientReview" :validate-on-rule-change="false" size="mini" ref="form" label-position="left" label-width="120px" :rules="mapRequiredFieldsRules()"
    v-loading="loading" element-loading-spinner="atom-audit-loader">
    <el-row :gutter="20">
      <el-col :span="12">
        <h4 class="headerWithBorder">Review Information</h4>

        <el-form-item size="mini" label="Facility" prop="clientId">
          <hia-el-select v-model="inpatientReview.clientId" :disabled="disabled" placeholder="Facility" :clearable="false" :filterable="true" itemkey="key" label="value"
            :dropdowns="dropdowns.clients.list" @input="putInpatientReview('facility')"></hia-el-select>
        </el-form-item>

        <el-form-item size="mini" ref="dateFormItem" label="Project" prop="projectID">
          <projectSelector @input="putInpatientReview('project')" :clearable="false" @selected="handleProjectSelected" v-model="inpatientReview.projectID"
            :project="inpatientReview.project" :disabled="disabled" :defaultQuery="{ projectTypeId: inpatientReview.project.projectTypeId }"> </projectSelector>
        </el-form-item>

        <el-form-item size="mini" label="Review Type" prop="patientTypeId">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('patientTypeId')" v-model="inpatientReview.patientTypeId" placeholder="Review Type" :filterable="true"
            :itemkey="'key'" :label="'value'" :dropdowns="dropdowns.ipPatientTypes.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Auditor" prop="consultantNumber">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('ConsNum')" v-model="inpatientReview.consultantNumber" placeholder="Auditor" :clearable="true"
            :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.consultants.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Review Date" prop="reviewDate">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="revDate" :clearable="false" v-model="inpatientReview.reviewDate"
            @change="putInpatientReview('revDate')" format="MM/dd/yyyy"> </el-date-picker>
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <el-col>
              <input :disabled="disabled" id="prebill" type="checkbox" v-model="inpatientReview.prebill" @change="putInpatientReview('prebill')" />
              Prebill
            </el-col>

            <el-col>
              <input :disabled="disabled" id="noUBAvailable" type="checkbox" v-model="inpatientReview.noUbavailable" @change="putInpatientReview('noUbavailable')" />
              No UB Available
            </el-col>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>
      </el-col>

      <el-col :span="12">
        <h4 class="headerWithBorder">Original Coding Details</h4>

        <el-form-item size="mini" label="DRG System" prop="ipreviewType">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('ipreviewType')" v-model="inpatientReview.ipreviewType" placeholder="DRG System" :clearable="false"
            :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.drgSystems.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="DRG" prop="originalDrg">
          <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;padding-right: 0px">
            <el-input style="z-index: 1;" :disabled="disabled" size="mini" type="number" id="originalDrg" v-model="inpatientReview.originalDrg"
              @change.native="putInpatientReview('originalDrg')"> </el-input>
          </el-col>

          <template v-if="isTrucodeEnabled()">
            <el-col :span="2" style="padding-left: 0px; text-align: center;">
              <i v-if="parseInt(inpatientReview.originalDrg, 10) === parseInt(origDrgComputed, 10)" title="DRG Matches TruCode" class="mdi mdi-check"
                style="color: #63b344;"></i>
              <i v-if="parseInt(inpatientReview.originalDrg, 10) !== parseInt(origDrgComputed, 10)" title="DRG Mismatch" class="mdi mdi-alert" style="color: #F56C6C;"></i>
            </el-col>
            <el-col :span="11" style="padding-right: 0px;padding-right: 0px">
              <el-input disabled size="mini" id="origDrgCalc" v-model="origDrgComputed"></el-input>
            </el-col>
          </template>
        </el-form-item>

        <el-form-item size="mini" label="Weight" prop="originalWeight">
          <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;padding-right: 0px">
            <el-input style="z-index: 1;" :disabled="disabled" size="mini" type="number" step=".0001" id="originalWeight" v-model="inpatientReview.originalWeight"
              @change.native="putInpatientReview('originalWeight')"></el-input>
          </el-col>

          <template v-if="isTrucodeEnabled()">
            <el-col :span="2" style="padding-left: 0px; text-align: center;">
              <i v-if="parseFloat(inpatientReview.originalWeight) === parseFloat(origDrgWtComputed)" title="DRG Wt. Matches TruCode" class="mdi mdi-check"
                style="color: #63b344;"></i>
              <i v-if="parseFloat(inpatientReview.originalWeight) !== parseFloat(origDrgWtComputed)" title="DRG Wt. Mismatch" class="mdi mdi-alert"
                style="color: #F56C6C;"></i>
            </el-col>
            <el-col :span="11" style="padding-right: 0px;">
              <el-input disabled size="mini" id="origDrgWtCalc" v-model="origDrgWtComputed"></el-input>
            </el-col>
          </template>
        </el-form-item>

        <el-form-item size="mini" label="SOI" v-if="inpatientReview.ipreviewType === 'APR-DRG'" prop="originalSoi">
          <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;">
            <hia-el-select style="z-index: 1;" :disabled="disabled" @input="putInpatientReview('originalSOI')" v-model="inpatientReview.originalSoi" placeholder="Or. SOI"
              :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.oneToFour.list"> </hia-el-select>
          </el-col>

          <template v-if="isTrucodeEnabled()">
            <el-col :span="2" style="padding-left: 0px; text-align: center;">
              <i v-if="String(inpatientReview.originalSoi) === String(origSoiCalc)" title="SOI Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
              <i v-if="String(inpatientReview.originalSoi) !== String(origSoiCalc)" title="SOI Mismatch" class="mdi mdi-alert" style="color: #F56C6C;"></i>
            </el-col>
            <el-col :span="11" style="padding-right: 0px;">
              <el-input disabled size="mini" id="origSoiCalc" v-model="origSoiCalc"></el-input>
            </el-col>
          </template>
        </el-form-item>

        <el-form-item size="mini" label="ROM" v-if="inpatientReview.ipreviewType === 'APR-DRG'" prop="originalRom">
          <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;">
            <hia-el-select style="z-index: 1;" :disabled="disabled" @input="putInpatientReview('originalROM')" v-model="inpatientReview.originalRom" placeholder="Or. ROM"
              :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.oneToFour.list"> </hia-el-select>
          </el-col>
          <template v-if="isTrucodeEnabled()">
            <el-col :span="2" style="padding-left: 0px; text-align: center;">
              <i v-if="String(inpatientReview.originalRom) === String(origRomCalc)" title="ROM Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
              <i v-if="String(inpatientReview.originalRom) !== String(origRomCalc)" title="ROM Mismatch" class="mdi mdi-alert" style="color: #F56C6C;"></i>
            </el-col>
            <el-col :span="11" style="padding-right: 0px;">
              <el-input disabled size="mini" id="origRomCalc" v-model="origRomCalc"></el-input>
            </el-col>
          </template>
        </el-form-item>

        <el-form-item size="mini" label="Patient Status" prop="disposition">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('orPatStat')" v-model="inpatientReview.disposition" placeholder="Patient Status" :clearable="true"
            :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.patientStatus.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Point of Origin" prop="originalPoo">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('originalPoo')" v-model="inpatientReview.originalPoo" placeholder="Point of Origin" :clearable="true"
            :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.pointOfOrigin.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="D/C Summ. Avail at Coding" prop="dcsummAvailable">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('dcsummAvailable')" v-model="inpatientReview.dcsummAvailable" placeholder="DC Summ Available"
            :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.dcSummaryAvailableAtCoding.list"> </hia-el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="top-margin">
      <el-col :span="12">
        <h4 class="headerWithBorder">Chart Demographic</h4>

        <el-form-item size="mini" label="MR Number" prop="mrNumber">
          <el-input :disabled="disabled" size="mini" :maxlength="15" type="text" id="mrNumber" v-model="inpatientReview.mrNumber"
            @change.native="putInpatientReview('mrNumber')"> </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Patient Number" prop="patientNumber">
          <div :class="disabled ? 'el-input el-input--mini is-disabled' : 'el-input el-input--mini'">
            <input id="patientNumber" :class="'el-input__inner'" :disabled="disabled" maxlength="15" type="text" :value="inpatientReview.patientNumber"
              @change="putPatientNumber($event, 'patientNumber')" />
          </div>
        </el-form-item>

        <el-form-item size="mini" label="Payor" prop="payorNumber">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('payorNumber')" v-model="inpatientReview.payorNumber" placeholder="Payor" :clearable="true"
            :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.payors.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Payor Detail" prop="payorDetailId">
          <payorDetailSelector :disabled="disabled || !inpatientReview.payorNumber" @input="putInpatientReview('payorDetailId')" v-model="inpatientReview.payorDetailId"
            :payorNumberId="inpatientReview.payorNumber"
            :payorNumberlabel="dropdowns.payors.list.find(f => f.key === inpatientReview.payorNumber) ? dropdowns.payors.list.find(f => f.key === inpatientReview.payorNumber).value : null"
            :payorDetail="inpatientReview.payorDetail"> </payorDetailSelector>
        </el-form-item>

        <el-form-item size="mini" label="Discharge Date" prop="dischargeDate">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="dischargeDate" v-model="inpatientReview.dischargeDate" @change="putInpatientReview('dcDate')"
            format="MM/dd/yyyy"> </el-date-picker>
        </el-form-item>

        <el-form-item size="mini" label="IP Admit Date" prop="admitDate">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="admitDate" v-model="inpatientReview.admitDate" @change="putInpatientReview('admitDate')"
            format="MM/dd/yyyy"></el-date-picker>
        </el-form-item>

        <el-form-item size="mini" label="OBS Admit Date" prop="obsAdmitDate">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="obsAdmitDate" v-model="inpatientReview.obsAdmitDate" @change="putInpatientReview('obsAdmitDate')"
            format="MM/dd/yyyy"></el-date-picker>
        </el-form-item>

        <el-form-item size="mini" label="LOS">
          <el-input :disabled="true" size="mini" type="text" id="LOS"
            :value="inpatientReview.dischargeDate && inpatientReview.admitDate ? Math.floor(Math.abs(new Date(inpatientReview.dischargeDate) - new Date(inpatientReview.admitDate)) / (1000 * 60 * 60 * 24)) : 0">
          </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Patient Age" prop="patientAge">
          <el-row>
            <el-col :span="patientAgeZero() ? 12 : 24">
              <el-input @keydown.native="digitsOnlyInput($event)" :disabled="disabled" size="mini" type="text" maxlength="3" id="patientAge" v-model="patientAgeYears"
                @change.native="putInpatientReview('patientAge')"></el-input>
            </el-col>
            <el-col :span="12" v-if="patientAgeZero()">
              <el-input disabled size="mini" type="text" id="patientAgeDays" :value="patientAgeDays"></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item size="mini" label="Patient DOB" v-if="patientAgeZero()" prop="patientDob">
          <el-date-picker :disabled="disabled" size="mini" type="date" id="patientDob" v-model="inpatientReview.patientDob" @change="putInpatientReview('patientDob')"
            format="MM/dd/yyyy"></el-date-picker>
        </el-form-item>

        <el-form-item size="mini" label="Newborn Weight (grams)" v-if="patientAgeZero()" prop="newbornWeight">
          <el-input :disabled="disabled" size="mini" type="number" id="patientWeight" v-model="inpatientReview.newbornWeight" @change="handleNewbornWeight"> </el-input>
        </el-form-item>

        <el-form-item size="mini" label="Patient Sex" prop="sex">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('sex')" v-model="inpatientReview.sex" placeholder="Patient Sex" :clearable="true" :filterable="true"
            itemkey="key" label="value" :dropdowns="dropdowns.patientSex.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Provider" prop="providerId">
          <providerSelector :disabled="disabled" v-model="inpatientReview.providerId" @input="putInpatientReview('providerId')" :provider="inpatientReview.provider">
          </providerSelector>
        </el-form-item>

        <el-form-item size="mini" label="Coder" prop="coderId">
          <coderSelector :disabled="disabled" v-model="inpatientReview.coderId" @input="putInpatientReview('coder')" :clientcoder="inpatientReview.clientCoder">
          </coderSelector>
        </el-form-item>

        <el-form-item size="mini" label="CDI Staff" prop="cdiStaffId">
          <cdiSelector :disabled="disabled" v-model="inpatientReview.cdiStaffId" @input="putInpatientReview('cdiStaff')" :cdiStaff="inpatientReview.clientCDIStaff">
          </cdiSelector>
        </el-form-item>

        <el-form-item size="mini" label="Service Line" prop="clientServiceLineId">
          <serviceLineSelector :disabled="disabled" v-model="inpatientReview.clientServiceLineId" @input="putInpatientReview('serviceLine')"
            :serviceLine="inpatientReview.serviceLine"> </serviceLineSelector>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <h4 class="headerWithBorder">Audit Notes</h4>

        <el-form-item size="mini" label="Reason For Change" prop="reasonForChange">
          <hia-el-select :disabled="disabled" @input="putInpatientReview('DemographicsreasonForChange')" v-model="inpatientReview.reasonForChange" clearable
            placeholder="Reason for Change" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.inpatientReasonsForChangeHeader.list"> </hia-el-select>
        </el-form-item>

        <el-form-item size="mini" label="Comments" prop="comments">
          <el-input :disabled="disabled" type="textarea" :rows="5" placeholder="" v-model="inpatientReview.comments" @change="putInpatientReview('comments')"> </el-input>
          <!-- <textarea style="width: 100%;" class="el-textarea__inner" :disabled="disabled" type="textarea" :rows="5" id="comments" v-model="inpatientReview.comments" @change="putInpatientReview('comments')"></textarea> -->
        </el-form-item>

        <el-form-item size="mini" label="Record Tags" prop="identifierSelectionsArray">
          <el-select class="multiSelect" :disabled="disabled" size="mini" v-model="inpatientReview.identifierSelectionsArray" filterable multiple placeholder="Tags"
            @visible-change="tagsSelectHideShow($event)" @remove-tag="tagsSelectRemoveTag($event)">
            <el-option v-for="item in dropdowns.recordTags.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-popover ref="savePopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent"
      @after-enter="handleHidePopOver('savePopover')"> </el-popover>
  </el-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { differenceInDays, parseISO } from 'date-fns'

  import inputValidations from '@/mixins/inputValidations'
  import inpatientHelper from '@/mixins/inpatientHelper'

  import providerSelector from '@/components/controls/providerSelector'
  import coderSelector from '@/components/controls/coderSelector'
  import cdiSelector from '@/components/controls/cdiSelector'
  //   import massMultipleSelector from '@/components/controls/massMultipleSelector'
  import serviceLineSelector from '@/components/controls/serviceLineSelector'
  import payorDetailSelector from '@/components/controls/payorDetailSelector'

  import projectSelector from '@/components/controls/projectSelector'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'InpatientReviewDemographics',
    mixins: [inpatientHelper, inputValidations, HiaAuthorization],
    components: {
      providerSelector,
      coderSelector,
      projectSelector,
      cdiSelector,
      serviceLineSelector,
      // massMultipleSelector,
      payorDetailSelector
    },
    data() {
      return {
        savePopoverContent: '',
        loading: false,
        backupIdentifierSelectionsArray: [],
        rules: {
          reviewType: [{ required: true, trigger: 'none' }],
          description: [{ required: true, trigger: 'none' }]
        }
      }
    },
    methods: {
      ...mapActions('inpatientReviews/', ['PUT_INPATIENT_REVIEW']),
      async putInpatientReview(changedField) {
        if (changedField === 'payorNumber') {
          this.inpatientReview.payorDetailId = null
          this.inpatientReview.payorDetail = {}
        }

        try {
          this.loading = true
          const payload = { inpatientReview: this.inpatientReview, changedField: changedField }
          await this.PUT_INPATIENT_REVIEW(payload)
          this.loading = false
          this.$emit('requiredFieldsValidation', {})
          this.$message({
            message: 'Demographics Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: e.message ? e.message : 'Demographics Update Error',
            type: 'error'
          })
        }
      },
      patientAgeZero() {
        if (this.inpatientReview.patientAge === 0 || this.inpatientReview.patientAge === '0') {
          return true
        }
        return false
      },
      handleProjectSelected(project) {
        this.inpatientReview.project = project
      },
      tagsSelectHideShow(show) {
        if (show) {
          this.backupIdentifierSelectionsArray = this.inpatientReview.identifierSelectionsArray
        } else {
          if (JSON.stringify(this.backupIdentifierSelectionsArray) !== JSON.stringify(this.inpatientReview.identifierSelectionsArray)) {
            this.identifiersOnChange()
          }
        }
      },
      tagsSelectRemoveTag() {
        this.identifiersOnChange(this.inpatientReview)
      },
      identifiersOnChange() {
        this.inpatientReview.identifierSelectionsArray.forEach(element => {
          // if element doesn't exist in, add it with add flag
          const matchedElements = this.inpatientReview.identifierSelections.some(function (i) {
            return i.identifierID === element
          })
          if (!matchedElements) {
            const newValue = {
              id: 0,
              identifierID: element,
              add: true
            }

            this.inpatientReview.identifierSelections.push(newValue)
          }
        })

        this.inpatientReview.identifierSelections.forEach(element => {
          // if element doesn't exists in Array, set deleted flag
          if (!this.inpatientReview.identifierSelectionsArray.includes(element.identifierID)) {
            element.delete = true
          }
        })

        this.putInpatientReview('recordTags')
      },
      mapRequiredFieldsRules() {
        const requiredFields = this.inpatientReview.requiredFields
        if (requiredFields && requiredFields.requiredFieldsData) {
          const requiredFieldsData = requiredFields.requiredFieldsData
          const rules = {}
          requiredFieldsData.forEach((item, index, array) => {
            if (item.required) {
              rules[item.field] = [
                {
                  required: item.required,
                  trigger: 'change',
                  message: item.message
                }
              ]
            }
          })
          return rules
        }
        // code to make checkbox boolean required
        // checkboxField: [
        //         { type: 'enum', enum: ['true'], required: true, message: 'Required Field', trigger: 'change',transform(value) {
        //   return value.toString()
        // } }
        return {}
      },
      handleNewbornWeight() {
        this.inpatientReview.newbornWeight = !isNaN(this.inpatientReview.newbornWeight) ? parseInt(this.inpatientReview.newbornWeight) : null
        this.putInpatientReview('newbornWeight')
      },
      putPatientNumber(event, field) {
        if (!event.target.value) {
          this.savePopoverContent = 'Patient Number must always contain a value.'
          this.openSavePopover(event)
          event.target.value = this.inpatientReview.patientNumber
          return false
        }
        this.inpatientReview.patientNumber = event.target.value
        this.putInpatientReview(field)
      },
      openSavePopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.savePopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      }
    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user']),
      disabled() {
        return !!this.readOnly() || this.inpatientReview.reviewStatusId !== 2
      },
      coderComputed: {
        get: function () {
          if (this.inpatientReview.hiaCoderId) {
            return this.inpatientReview.hiaCoderId
          }
          return this.inpatientReview.coderId
        },
        set: function (val) {
          if (!val) {
            this.inpatientReview.hiaCoderId = null
            this.inpatientReview.coderId = null
          }

          if (typeof val === 'string') {
            this.inpatientReview.hiaCoderId = val
            this.inpatientReview.coderId = null
          } else {
            this.inpatientReview.hiaCoderId = null
            this.inpatientReview.coderId = val
          }
        }
      },
      icdRadio: {
        get: function () {
          if (this.inpatientReview.icd9) {
            return 'ICD9'
          }
          return 'ICD10'
        },
        set: function (newValue) {
          if (newValue === 'ICD9') {
            this.inpatientReview.icd9 = true
            this.inpatientReview.icd10 = false
          } else {
            this.inpatientReview.icd9 = false
            this.inpatientReview.icd10 = true
          }
        }
      },
      patientAgeYears: {
        get: function () {
          return this.inpatientReview.patientAge
        },
        set: function (val) {
          this.inpatientReview.patientAge = val
          if (this.inpatientReview.patientAge !== 0) {
            this.inpatientReview.patientDob = null
          }
        }
      },
      patientAgeDays: function () {
        const a = parseISO(this.inpatientReview.admitDate)
        const b = parseISO(this.inpatientReview.patientDob)
        const diff = differenceInDays(a, b)
        if (diff) {
          return `${diff} days`
        } else {
          return '0 days'
        }
      },
      disableSubClients: function () {
        if (this.dropdowns.SubClients.length < 1) {
          return true
        }
        return false
      },
      reviewLevelComputed: {
        get: function () {
          return this.inpatientReview.reviewLevel.toString()
        },
        set: function (newValue) {
          this.inpatientReview.reviewLevel = parseInt(newValue)
        }
      },
      origDrgComputed: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 0, 'drg', this.inpatientReview.groupingResults)
        }
      },
      origDrgWtComputed: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 0, 'drgWeight', this.inpatientReview.pricingResults)
        }
      },
      origSoiCalc: {
        get: function () {
          return this.getGroupingResultValue('APR', 0, 'severityOfIllness', this.inpatientReview.groupingResults)
        }
      },
      origRomCalc: {
        get: function () {
          return this.getGroupingResultValue('APR', 0, 'riskOfMortality', this.inpatientReview.groupingResults)
        }
      },
      origOtherDrgCalc: {
        get: function () {
          if (this.inpatientReview.otherDrgtype) {
            return this.getGroupingResultValue(this.inpatientReview.otherDrgtype, 0, 'drg', this.inpatientReview.groupingResults)
          }
          return null
        }
      }
    }
  }
</script>

<style scope>
  .el-form-item.is-error.disabled>.el-form-item__content>.el-form-item__error {
    display: none;
  }
</style>