<template>
  <div v-html="dxCodeGf">
  </div>
</template>

<script>
  export default {
    name: 'IpDxGf',
    props: ['dx', 'reviewLevel', 'grouperType'],
    methods: {
      createGrouperFlagObject(type, description) {
        const grouperFlag = {
          flagType: type,
          flagDescription: description
        }

        return grouperFlag
      },
      getROMSOILevelDetail(level) {
        let levelDetail = ''
        switch (level) {
          case '0':
            levelDetail = 'No class specified'
            break
          case '1':
            levelDetail = 'Minor'
            break
          case '2':
            levelDetail = 'Moderate'
            break
          case '3':
            levelDetail = 'Major'
            break
          case '4':
            levelDetail = 'Extreme'
            break
          case 'C':
            levelDetail = 'Excluded Complication of Care'
            break
          case 'D':
            levelDetail = 'Duplicate SDX'
            break
          case 'P':
            levelDetail = 'Indicates a program-designated PDX'
            break
          case 'X':
            levelDetail = 'Excluded'
            break
        }

        return levelDetail
      }
    },
    computed: {
      dxCodeGf: function () {
        // var dxAffectsDRG = ''
        let dxFlagType = '&nbsp;'
        let dxShow = false

        const grouperflags = []
        let level = 0
        let gfRow = null
        if (this.dx.grouperFlags) {
          gfRow = this.dx.grouperFlags.filter(function (obj) {
            return (obj.reviewLevel === this.reviewLevel) && (obj.grouperType === this.grouperType)
          }, {
            reviewLevel: this.reviewLevel,
            grouperType: this.grouperType
          })[0]
        }

        if (gfRow) {
          if (gfRow.affectsDrg) {
            // dxAffectsDRG = 'AD: Affects DRG' + '&#13;'
            grouperflags.push(this.createGrouperFlagObject('AD', 'AD: Affects DRG'))
            dxShow = true
          }

          // cc_flag
          if (gfRow.cc) {
            grouperflags.push(this.createGrouperFlagObject('CC', 'CC: Complication/Comorbidity'))
            dxShow = true
          }
          // hac_flag
          if (gfRow.hac) {
            // if (dx.hac) {
            grouperflags.push(this.createGrouperFlagObject('HC', 'HC: Hospital-Acquired Condition'))
            dxShow = true
          }

          // mcc_flag
          if (gfRow.mcc) {
            grouperflags.push(this.createGrouperFlagObject('MC', 'MC: Major Complication/Comorbidity'))
            dxShow = true
          }

          if (gfRow.rom) {
            dxFlagType = 'R'
            level = gfRow.rom
            dxFlagType += level
            grouperflags.push(this.createGrouperFlagObject(dxFlagType, 'R: Affects Risk of Mortality, ' + this.getROMSOILevelDetail(level)))
            dxShow = true
          }

          if (gfRow.soi) {
            // dxFlagDesc = 'S: Affects Severity of Illness'
            dxFlagType = 'S'
            level = gfRow.soi
            dxFlagType += level
            grouperflags.push(this.createGrouperFlagObject(dxFlagType, 'S: Affects Severity of Illness,' + this.getROMSOILevelDetail(level)))
            dxShow = true
          }

          if (gfRow.affectsPsi) {
            dxFlagType = 'AP'
            grouperflags.push(this.createGrouperFlagObject(dxFlagType, `AP: Affects Patient Safety Indicators ${gfRow.affectsPsi}`))
            dxShow = true
          }

          if (gfRow.excludesPsi) {
            dxFlagType = 'EP'
            grouperflags.push(this.createGrouperFlagObject(dxFlagType, `EP: Excludes Patient Safety Indicators ${gfRow.excludesPsi}`))
            dxShow = true
          }

          if (gfRow.affectsQM) {
            dxFlagType = 'AQ'
            grouperflags.push(this.createGrouperFlagObject(dxFlagType, `AQ: Affects Quality Measures ${gfRow.affectsQM}`))
            dxShow = true
          }

          if (gfRow.excludesQM) {
            dxFlagType = 'EQ'
            grouperflags.push(this.createGrouperFlagObject(dxFlagType, `EQ: Excludes Quality Measures ${gfRow.excludesQM}`))
            dxShow = true
          }
        }
        if (dxShow) {
          // build html
          const string = '<div style=""  title="' + grouperflags.map(x => x.flagDescription).join(String.fromCharCode(13)) + '">' + grouperflags.map(x => x.flagType).join(',') + '</div>'
          return string
        }
        return null
      }
    }
  }
</script>

<style scoped>
</style>
