<template>
  <div>
    <el-row>
      <!-- <el-col :span="21">
        <el-input size="mini" v-model="calcPayorDetail" disabled></el-input>
      </el-col>
      <el-col :span="3" class="coderButtons">
        <el-button-group class="coder-selector-buttons">
          <el-button icon="el-icon-search" size="mini" @click="searchPayorDetail" :disabled="disabled"></el-button>
          <el-button icon="el-icon-circle-close" @click="selectPayorDetail(null)" size="mini" :disabled="disabled">
          </el-button>
        </el-button-group>
      </el-col> -->
      <el-col :span="24">
        <el-input :disabled="disabled" size="mini" type="text" id="payorDetail" v-model="calcPayorDetail" :readonly="true">
          <el-button slot="append" class="search" icon="el-icon-search" size="mini" @click="searchPayorDetail" :disabled="disabled">
          </el-button>
          <el-button slot="append" class="clear" icon="el-icon-circle-close" @click="selectPayorDetail(null)" size="mini" :disabled="disabled">
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-dialog :id="getUniqueDialogId()" :title="payorNumberlabel ? `Select Payor ${payorNumberlabel} Detail` : `Select Payor Detail`" :visible.sync="showSearch" width="60%" :append-to-body="true" @opened="handleOpen">
      <!-- <el-tabs v-model="activeName">
        <el-tab-pane :label="payorNumberlabel ? `Payor ${payorNumberlabel} Details` :  `Payor Detail`" name="payorDetail"> -->
      <el-popover trigger="click" placement="right" v-model="addPayorDetailPopOverVisible">
        <el-form size="mini" ref="payorDetailForm" label-position="top" label-width="120px" :rules="payorDetailRules" :model="payorDetailForm">
          <el-form-item size="mini" label="Add Payor Detail" prop="payorDetail" id="addPayorDetailInput">
            <el-input :disabled="disabled" size="mini" :maxlength="50" type="text" id="addPayorDetal" v-model="payorDetailForm.payorDetail">
            </el-input>
          </el-form-item>
        </el-form>
        <br>
        <el-button-group style="margin: 0px 0px 0px 10px;">
          <el-button type="text" class="hiaButton" plain round @click="addPayorDetailPopOverVisible = false; payorDetailForm.payorDetail = ''; payorDetailForm.payorNumberId = null" size="mini">
            Cancel</el-button>
          <el-button class="hiaButton" plain round @click="addPayorDetail()" size="mini">
            Confirm</el-button>
        </el-button-group>
        <div slot="reference" class="addButton">
          <el-button class="hiaButton" plain round size="mini" icon="el-icon-plus" title="Add serviceLine">Add New
            Payor Detail
          </el-button>
        </div>
      </el-popover>
      <el-form size="mini" label-position="left" label-width="150px" v-loading="codersLoading" element-loading-spinner="atom-audit-loader">
        <v-server-table class="hiaTable" v-loading="tableLoading" @loading="tableLoading = true" element-loading-spinner="atom-audit-loader" @loaded="loaded" name="payorDetailListGrid" ref="payorDetailListGrid" id="payorDetailListGrid" :columns="payorDetailColumns" :options="payorDetailOptions">
          <template slot="payorDetail" slot-scope="props">
            <el-button tabindex="0" class="select-button" type="text" :disabled="props.row.inactive" @click="selectPayorDetail(props.row.payorDetailId)" size="mini">
              {{props.row.payorDetail + (props.row.inactive ? ' (Inactive)': '')}}</el-button>
          </template>
        </v-server-table>
      </el-form>
      <!-- </el-tab-pane>
      </el-tabs> -->

    </el-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'payorDetailSelector',
    props: ['value', 'payorNumberId', 'payorNumberlabel', 'payorDetail', 'hiacoder', 'disabled'],
    data() {
      return {
        activeName: 'payorDetail',
        addPayorDetailPopOverVisible: false,
        payorDetailForm: {
          payorDetail: '',
          payorNumberId: null
        },
        payorDetailRules: {
          payorDetail: [
            { required: true, message: 'Please Set Payor Detail' }
          ]
        },
        localProvoiders: [],
        codersLoading: false,
        showSearch: false,
        tableLoading: false,
        payorDetailColumns: ['payorDetail'],
        payorDetailOptions: {
          requestFunction: async (data) => {
            try {
              const payload = {
                data: data
              }
              data.query.payorNumberId = this.payorNumberId
              return await this.LOAD_PAYAORDETAIL_PAGINATED(payload)
            } catch (err) {

            }
          },
          filterByColumn: true,
          // headings: {
          //   name: 'Coder Name'
          // },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/payorDetails/', ['LOAD_PAYAORDETAIL_PAGINATED', 'POST_PAYORDETAIL']),
      getUniqueDialogId() {
        return `payorDetailSelectorDialog${this._uid}`
      },
      loaded() {
        this.tableLoading = false
        const dialog = document.getElementById(this.getUniqueDialogId())
        const tds = dialog.getElementsByTagName('td')
        for (let i = 0; i < tds.length; i++) {
          tds[i].tabIndex = -1
        }
      },
      handleOpen() {
        const dialogId = this.getUniqueDialogId()
        const input = document.querySelector(`#${dialogId} #payorDetailListGrid input[name="vf__name"]`)
        if (input) {
          input.focus()
        }
      },
      searchPayorDetail() {
        this.showSearch = true
      },
      selectPayorDetail(payorDetailId) {
        this.$emit('input', payorDetailId)
        this.showSearch = false
      },
      addPayorDetail() {
        this.$refs.payorDetailForm.validate(async (valid) => {
          if (valid) {
            const payload = {
              payorDetail: {
                payorDetail: this.payorDetailForm.payorDetail.toUpperCase(),
                payorNumberId: this.payorNumberId
              }
            }
            const response = await this.POST_PAYORDETAIL(payload)
            this.addPayorDetailPopOverVisible = false
            this.$refs.payorDetailForm.resetFields()
            this.selectPayorDetail(response.payorDetailId)
            this.$refs.payorDetailListGrid.getData()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      initialize() {
      }
    },
    computed: {
      calcPayorDetail: {
        get: function () {
          if (this.payorDetail) {
            return this.payorDetail.payorDetail
          }
          return null
        }// ,
        // set: function (val) {
        //   if (!val) {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = null
        //   }

        //   if (typeof val === 'string') {
        //     this.review.hiaCoderId = val
        //     this.review.coderId = null
        //   } else {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = val
        //   }
        // }
      }
    },
    watch: {
      payorNumberId: function () {
        if (this.$refs.payorDetailListGrid) {
          this.$refs.payorDetailListGrid.getData()
        }
      }
    },
    created: function () {
      this.initialize()
    }
  }
</script>

<style scoped>
.coder-selector-buttons >>> .el-button.el-button--default.el-button--mini {
  padding: 4px 9px;
  margin-top: -1px;
}

.coderButtons {
  padding: 1px 0px 0px 5px;
}

.addButton {
  width: 115px;
  margin: 0px 0px 10px 0px;
}

>>> .select-button {
  border: 2px solid rgba(255, 255, 255, 0);
  padding: 7px 5px 7px 5px;
  width: 100%;
  text-align: left;
}

>>> .select-button:focus {
  border: 2px solid #409eff;
  border-radius: 0px;
}
</style>
