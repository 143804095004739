<template>
  <div>
    <i :class="[{
            cursorNotAllowed: cursorNotAllowed(),
            opacity: newOpacity(),
            icon: true,
            iconSelected: value
            }, iconToShow]" :title="title" @click="updateValue($event)"> </i>
  </div>
</template>

<script>

  export default {
    name: 'iconCheckBox',
    props: ['value', 'icon', 'title', 'disabled'],
    methods: {
      cursorNotAllowed() {
        return this.disabled
      },
      newOpacity() {
        return this.cursorNotAllowed()
      },
      updateValue(e) {
        if (this.disabled) {
          return false
        }
        // set's the value into the parent's v-model
        this.$emit('input', !this.value)
        // allows the parent to get the clicked element from $event.target
        this.$emit('change', e)
      }
    },
    computed: {
      iconToShow: function () {
        if (this.value) {
          // its selected, use the circle version:
          return this.icon + '-circle'
        } else {
          return this.icon
        }
      }
    }
  }
</script>

<style scoped>
.icon {
  font-size: 20px;
  color: grey;
  cursor: pointer;
}

.iconSelected {
  color: #005695;
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.opacity {
  opacity: 0.5;
}
</style>
