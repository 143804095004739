// When using this component, make sure to set a unique html element ID because the watch method depends on ID
<template>
  <textarea v-bind:value="value"></textarea>
</template>
<script>
  /* eslint no-undef: 0 */ // --> OFF
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'Tinymce',
    props: ['value', 'disabled', 'imagePath', 'height'],
    data() {
      return {
        deactivated: false
      }
    },
    methods: {
      ...mapActions('globals/', ['POST_BLOB']),
      updateValue: function (value) {
        this.$emit('input', value.trim())
        this.$emit('change')
      },
      setDirty(val) {
        const mce = tinymce.get(this.$el.id)
        if (mce) {
          mce.setDirty(val)
        }
      },
      handleDisabledProp: function (disabled) {
        try {
          const mceInstance = tinymce.get(this.$el.id)
          if (mceInstance) {
            // set the body to disabled and hide the toolbar buttons
            if (disabled) {
              mceInstance.mode.set('readonly')
              // otherwise set the body to enabled and show the toolbar buttons
            } else {
              mceInstance.mode.set('design')
            }
          }
        } catch (e) {
          console.log('Error setting disabled prop for Tinymce', e)
        }
      },
      initialize() {
        const component = this
        const height = this.height ? this.height : '400'
        tinymce.init({
          menubar: false,
          target: this.$el,
          height: height,
          plugins: 'preview lists tinymcespellchecker powerpaste',
          newline_behavior: 'linebreak',
          powerpaste_allow_local_images: true,
          powerpaste_word_import: 'clean',
          powerpaste_html_import: 'clean',
          browser_spellcheck: true,
          spellchecker_dialog: true,
          spellchecker_languages: 'US English=en',
          spellchecker_active: false,
          spellchecker_on_load: true,
          spellchecker_whitelist: this.spellCheckDictionary,
          spellchecker_rpc_url: 'https://tiny-spellcheck-linux.azurewebsites.net/',
          paste_as_text: false,
          fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
          convert_fonts_to_spans: false,
          toolbar: 'spellchecker undo redo | forecolor backcolor | bold italic underline strikethrough | fontsize  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | preview image code',
          images_upload_handler: async (blobInfo) => {
            const payload = {
              blobPathPrefix: this.imagePath ? this.imagePath : 'misc/',
              fileExtension: 'png',
              blob: blobInfo.blob()
            }

            return new Promise((resolve, reject) => {
              this.POST_BLOB(payload).then(response => {
                resolve(response.data.location)
              }).catch(error => {
                reject(error)
              })
            })
          },
          paste_preprocess: (plugin, source) => {
            source.content = source.content
              .replace(/<h1.*?>/g, '<p style="font-weight: bold; font-size: 26px;">')
              .replace(/<h2.*?>/g, '<p style="font-weight: bold; font-size: 20px;">')
              .replace(/<h3.*?>/g, '<p style="font-weight: bold; font-size: 16px;">')
              .replace(/<h4.*?>/g, '<p style="font-weight: bold; font-size: 14px;">')
              .replace(/<h5.*?>/g, '<p style="font-weight: bold; font-size: 14px;">')
              .replace(/<h6.*?>/g, '<p style="font-weight: bold; font-size: 14px;">')
              .replace(/<\/h.+?>/g, '</p>')
              // replace table border and padding 0 so that tables will render in Aspose reports
              .replace(/border="0"/g, 'border="1"')
              .replace(/cellpadding="0"/g, 'cellpadding="2"')
          },
          setup: (editor) => {
            editor.on('blur', (e) => {
              if (!e.target.isNotDirty && this.value !== editor.getContent()) {
                component.updateValue(editor.getContent())
              }
            })
            editor.on('init', (e) => {
              this.handleDisabledProp(this.disabled)
            })
            editor.on('dirty', (e) => {
              this.$emit('dirty')
            })
          },
          init_instance_callback: (editor) => {
            // after the v7 upgrade, the HTML 5 spellcheck was set to false
            // preventing chrome's naitve red-underline spell checking. This reenables it.
            editor.getBody().setAttribute('spellcheck', true)
          }
        })
      }
    },
    mounted: function () {
      this.initialize()
    },
    activated: function () {
      if (this.deactivated) {
        this.initialize()
      }
    },
    computed: {
      ...mapState('globals/', ['spellCheckDictionary'])
    },
    watch: {
      value() {
        const mce = tinymce.get(this.$el.id)
        if (this.value) {
          if (mce) {
            mce.setContent(this.value)
            this.$emit('dirty', true)
          }
        } else {
          // tinymce yells if you give it a null, replace with emty string
          if (mce) {
            mce.setContent('')
          }
        }
      },
      disabled() {
        this.handleDisabledProp(this.disabled)
      }
    },
    beforeDestroy: function () {
      // if value exists update when navigating away using browser back button
      const mce = tinymce.get(this.$el.id)

      if (mce) {
        if (!mce.isNotDirty && this.value !== mce.getContent()) {
          this.updateValue(mce.getContent())
        }
        mce.destroy()
      }
    },
    deactivated: function () {
      // doing this solves the TinyMCE not loading properly during navigation
      // this is due to keep alive; exluding does not work the behavior varies
      // works for now until better logic.

      const mce = tinymce.get(this.$el.id)

      if (mce) {
        if (!mce.isNotDirty && this.value !== mce.getContent()) {
          this.updateValue(mce.getContent())
        }
        mce.destroy()
      }

      this.deactivated = true
    }
  }
</script>

<style>
  .tox-statusbar__branding {
    display: none;
  }

  .tox-tinymce {
    border-radius: 5px !important;
    border: 1px solid #dcdfe6 !important;
  }

  .tox-tbtn:focus {
    box-shadow: none !important;
  }

  button[data-mce-name="fontsize"]:focus::after {
    box-shadow: none !important;
  }
</style>