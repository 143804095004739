<template>
  <el-row :gutter="10">
    <el-col :lg="toggleReseach && isTrucodeEnabled() ? 18 : 24" :md="24" :sm="24">
      <el-col :span="16">
        <h3 class="el-page-header">Review Details</h3>
        <div v-show="!loading">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/projects/' }">Projects</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/projects/${inpatientReview.projectID}` }"> {{ inpatientReview.project.projectID }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: `/reviews/inpatient/${$route.params.drgTableId}` }"> {{ inpatientReview.patientNumber }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
      <el-col :span="24" v-if="loading">
        <div style="margin-top: 20px;" v-loading="loading" element-loading-spinner="atom-audit-loader"></div>
      </el-col>
      <el-col class="top-margin" v-show="!loading">
        <el-card class="recordContainer" style="padding: 0px 0px 20px 0px;">
          <el-col style="margin: 0px 0px 10px 0px;" :span="24" v-if="!loading">
            <el-button-group class="pull-right inpatientButtons">
              <el-dropdown @command="handleStatus">
                <el-button class="hiaButton" size="mini" :type="getStatusButtonType(inpatientReview)" plain round><i :class="getCurrentReviewStatus(inpatientReview).iconClass" style="margin-right:4px;"></i>{{ getCurrentReviewStatus(inpatientReview).status }}</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="status.reviewStatusId === inpatientReview.reviewStatusId || disabled || (isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4))" v-for="status in reviewStatus" :key="status.reviewStatusId" :command="status.reviewStatusId"> <i :class="status.iconClass"></i>{{ status.status }} {{ isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4) ? ` - Validation Errors` : `` }} </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button :disabled="!isValidationError()" :class="isValidationError() ? '' : 'hiaButton'" :type="isValidationError() ? 'danger' : ''" size="mini" @click="toggleRequiredFieldsErrorList = true" plain round :title="isValidationError() ? 'validation errors' : 'record is valid'"> <i :class="isValidationError() ? 'mdi mdi-alert' : 'mdi mdi-check-bold greenIcon'"></i> Validations</el-button>
              <el-button class="hiaButton" icon="el-icon-document" size="mini" @click="openWorksheet" plain round>Worksheet</el-button>
              <el-button class="hiaButton" icon="el-icon-document" v-if="isTrucodeEnabled()" size="mini" @click="openPrincipalDXAnalysis" plain round>Principal DX</el-button>
              <el-button class="hiaButton" icon="el-icon-collection" v-if="isTrucodeEnabled()" size="mini" @click="openCodeBooks()" plain round>Codebook</el-button>
            </el-button-group>
          </el-col>
          <el-col>
            <!-- <h4 class="headerWithBorder">Details</h4> -->
            <el-collapse id="reviewAccordion" v-model="activeNames" class="ipCollapse">
              <el-collapse-item title="Demographics" name="1">
                <InpatientReviewDemographics ref="ipDemographics" class="collapseContent" @requiredFieldsValidation="requiredFieldsValidation"></InpatientReviewDemographics>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template slot="title">
                  <span style="width: 100%;">
                    Diagnosis Codes
                    <span class="codeTotalHeader"> - {{ inpatientReview.drgRecommendationsDx.length }} Total</span>
                    <span @click.stop="1 === 1" style="float: right; height:30px; " v-show="activeNames.some(s => s === '2')">
                      <el-dropdown ref="dxDropdown" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <span class="actionsHamburger mdi mdi-menu"></span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="poaAssignments">POA Assignments</el-dropdown-item>
                          <el-dropdown-item command="addMultipleCodes">Add Multiple Codes</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </template>
                <InpatientReviewDx @onCodeFocus="handleDxPxGridCodeFocus" @ellipsisClick="handleEllipsisClick" @handleCloseAddMultipleCodes="handleCloseAddMultipleCodes" @handleClosePoaAssignments="handleClosePoaAssignments" :showAddMultipleCodes="showAddMultipleCodes" :showPoaAssignments="showPoaAssignments" @handleSaveToLocalStorageForTruCode="saveToLocalStorageForTruCode"> </InpatientReviewDx>
              </el-collapse-item>
              <el-collapse-item title="Procedure Codes" name="3">
                <template slot="title">
                  <span style="width: 100%;">
                    Procedure Codes
                    <span class="codeTotalHeader"> - {{ inpatientReview.drgRecommendationsPx.length }} Total</span>
                    <span @click.stop="1 === 1" style="float: right; height:30px;" v-show="activeNames.some(s => s === '3')">
                      <el-dropdown ref="pxDropdown" trigger="click" @command="handleAction">
                        <span class="el-dropdown-link">
                          <span class="actionsHamburger mdi mdi-menu"></span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="pxAddMultipleCodes">Add Multiple Codes</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </template>
                <InpatientReviewPx @onCodeFocus="handleDxPxGridCodeFocus" @ellipsisClick="handleEllipsisClick" @handleCloseAddMultipleCodes="handleCloseAddMultipleCodes" :showAddMultipleCodes="showPxAddMultipleCodes" @handleSaveToLocalStorageForTruCode="saveToLocalStorageForTruCode"></InpatientReviewPx>
              </el-collapse-item>
              <el-collapse-item title="Recommendations" name="4-a">
                <InpatientReviewRecommendations class="collapseContent"></InpatientReviewRecommendations>
              </el-collapse-item>
              <el-collapse-item title="Audit Findings" name="4">
                <template slot="title">
                  <span style="width: 100%;">
                    Audit Findings
                    <span class="codeTotalHeader"> - {{ auditFindings ? auditFindings.length : 0 }} Findings</span>
                  </span>
                </template>
                <AuditFindings :disabled="readOnly()" class="collapseContent" :recordId="inpatientReview.drgTableId" recordType="IP"> </AuditFindings>
              </el-collapse-item>
              <el-collapse-item name="8">
                <template slot="title">
                  <span style="width: 100%;">
                    Coder Responses
                    <span class="codeTotalHeader"> - {{ inpatientReview.rebuttal ? inpatientReview.rebuttal.rebuttalComments.length : 0 }} Comments</span>
                  </span>
                </template>
                <Rebuttals :disabled="readOnly()" style="margin: 5px 0px 0px 10px;" class="collapseContent" :recordId="inpatientReview.drgTableId" recordType="IP"> </Rebuttals>
              </el-collapse-item>
              <!-- <el-collapse-item name="9">
                <template slot="title">
                  Required Fields
                  <template v-if="requiredFieldsErrorList.length > 0">
                    <span style="margin: 0px 0px 0px 10px;"><i class="mdi mdi-alert" style="color: #f56c6c"></i> - {{ requiredFieldsErrorList.length }} Total </span>
                  </template>
                  <template v-else>
                    <span style="margin: 0px 0px 0px 10px;"><i class="el-icon-check" style="color: #63b344"></i> </span>
                  </template>
                </template>
                <div>
                  <el-table :data="requiredFieldsErrorList" height="auto">
                    <el-table-column prop="group" label="Group" width=""></el-table-column>
                    <el-table-column prop="fieldLabel" label="Field" width=""></el-table-column>
                    <el-table-column prop="message" label="Error Message" width=""></el-table-column>
                  </el-table>
                </div>
              </el-collapse-item> -->
            </el-collapse>
            <InpatientReviewFooter ref="InpatientReviewFooter" style="margin: 30px 0px 30px 0px;" @handleTableCommands="handleTableCommands"></InpatientReviewFooter>
          </el-col>
        </el-card>
      </el-col>
    </el-col>
    <el-col class="sticky" v-if="isTrucodeEnabled()" :lg="6" :md="24" :sm="24" v-show="toggleReseach">
      <ResearchPane v-if="!loading" :review="inpatientReview" :isLevel2="isLevel2" :truCodeAuthKey="inpatientReview.truCodeAuthKey" :truCodeQuery="truCodeQuery" :showReviewRadioButtons="true" :parent="'inpatient'" @onChangedFieldResearchPane="handleChangedFieldResearchPane" @onReferenceClick="handleTruCodeReferenceClick" @contentClicked="handleTruCodeCodeClick" @onReviewMode="handleReviewMode" encounterType="inpatient" :changedFieldResearchPane="changedFieldResearchPane"> </ResearchPane>
    </el-col>
    <div class="dialogs">
      <el-dialog class="principalDxAnalysis" :visible.sync="togglePrincipalDxAnalysis" title="Principal DX Analysis" width="80%" :close-on-click-modal="false" :show-close="true">
        <principalDx :show="togglePrincipalDxAnalysis"></principalDx>
      </el-dialog>
      <el-dialog :visible.sync="showInProgressWarning" title="Confirm Record Status" width="30%" :close-on-click-modal="false" :show-close="true" v-if="!loading" :before-close="cancelStatusConfirm">
        <div v-loading="confirmStatusLoading" element-loading-spinner="atom-audit-loader">
          <el-row>
            <el-col :span="12">
              <div v-for="status in reviewStatus" :key="status.reviewStatusId" style="margin: -15px 0px 20px 15px;">
                <el-radio :disabled="isValidationError() && (status.reviewStatusId === 3 || status.reviewStatusId === 4)" v-model="confirmStatusId" :label="status.reviewStatusId"> <i :class="status.iconClass" style="margin-right: 6px;"></i>{{ status.status }} </el-radio>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="text-align: center; margin: -30px 0px 0px 0px;" v-if="isValidationError()">
                <p style="font-weight: bold; margin: 0px 0px 0px 0px;font-size: 15px;">Validation Errors</p>
                <p style="margin: 5px 0px 5px 0px;word-break: break-word;">The following validations are required for these statuses:</p>
                <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;">
                  <li style="text-align: left;"><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
                  <li style="text-align: left;"><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
                </ul>
                <el-button type="danger" size="mini" @click="toggleRequiredFieldsErrorList = true" plain round> <i class="mdi mdi-alert"></i> Validations</el-button>
              </div>
            </el-col>
          </el-row>
          <div style="margin: 40px 0px 0px 0px; height: 15px;" class="inpatientButtons">
            <el-button-group style="float: right; padding-bottom: 20px;">
              <el-button class="hiaButton" plain round type="text" @click="cancelStatusConfirm">Cancel</el-button>
              <el-button class="hiaButton" plain round @click="handleConfirmStatus">Continue</el-button>
            </el-button-group>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="Validation Errors" :visible.sync="toggleRequiredFieldsErrorList" width="50%">
        <div>
          <!-- <p v-if="isValidationError()" style="margin: 5px 0px 5px 0px;word-break: break-word;">Record cannot be Ready for Coder or Completed.</p> -->
          <p style="margin: 5px 0px 5px 0px;word-break: break-word;">The following validations are required for these statuses:</p>
          <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;">
            <li><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
            <li><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
          </ul>
          <el-table :data="requiredFieldsErrorList" empty-text="No Validation Errors">
            <el-table-column prop="group" label="Group" width=""></el-table-column>
            <el-table-column prop="fieldLabel" label="Field" width=""></el-table-column>
            <el-table-column prop="message" label="Error Message" width=""></el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
  </el-row>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { format, parseISO, isDate } from 'date-fns'
  import InpatientReviewDemographics from './InpatientReviewDemographics'
  import InpatientReviewDx from './InpatientReviewDx'
  import InpatientReviewPx from './InpatientReviewPx'
  import InpatientReviewRecommendations from './InpatientReviewRecommendations'
  import InpatientReviewFooter from './InpatientReviewFooter'
  import Rebuttals from '../Rebuttals'
  import ResearchPane from '@/components/controls/ResearchPane'
  import truCodeHelper from '@/mixins/trucode-helper'
  import reviewStatusHelper from '@/mixins/reviewStatusHelper'
  import PrincipalDx from '@/components/controls/PrincipalDx.vue'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import AuditFindings from '../AuditFindings'
  import csvGenerator from '@/mixins/csvGenerator'

  export default {
    name: 'InpatientReview',
    mixins: [truCodeHelper, HiaAuthorization, reviewStatusHelper, csvGenerator],
    data: function() {
      return {
        toggleRequiredFieldsErrorList: false,
        requiredFieldsErrorList: [],
        requiredFieldAlertToggle: false,
        togglePrincipalDxAnalysis: false,
        showPxAddMultipleCodes: false,
        showAddMultipleCodes: false,
        showPoaAssignments: false,
        codebooksWindow: undefined,
        referencesWindow: undefined,
        worksheetWindow: undefined,
        changedFieldResearchPane: '',
        showInProgressWarning: false,
        confirmStatusId: 0,
        confirmStatusLoading: false,
        truCodeQuery: {},
        floatResearch: true,
        toggleReseach: true,
        activeNames: [],
        loading: true,
        format: format,
        parseISO: parseISO,
        routeNext: () => {},
        dropdownsNeeded: ['reviewLevels', 'consultants', 'oneToFour', 'pointOfOrigin', 'dcSummaryAvailableAtCoding', 'dcSummaryAvailableAtReview', 'patientStatus', 'drgSystems', 'otherDrgSystems', 'payors', 'patientSex', 'allReasonsForChange', 'inpatientReasonsForChange', 'inpatientReasonsForChangeHeader', 'inpatientReasonsForChangeDx', 'inpatientReasonsForChangePx', 'reasonsForChangeOtherDrg', 'groupers', 'grouperVersions', 'pricers', 'ipPatientTypes', 'reviewStatus', 'clients', 'RebuttalStatus', 'recordTags']
      }
    },
    components: {
      InpatientReviewDemographics,
      InpatientReviewDx,
      InpatientReviewPx,
      InpatientReviewRecommendations,
      InpatientReviewFooter,
      Rebuttals,
      ResearchPane,
      PrincipalDx,
      AuditFindings
    },
    methods: {
      ...mapActions('inpatientReviews/', ['GET_INPATIENT_REVIEW', 'POST_INPATIENT_DX', 'PUT_INPATIENT_DX', 'POST_INPATIENT_PX', 'PUT_INPATIENT_PX', 'PUT_INPATIENT_REVIEW']),
      ...mapMutations('rebuttals/', ['SET_REBUTTAL']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      ...mapMutations('auditFindings/', ['SET_FINDINGS']),
      async initialize() {
        try {
          this.loading = true
          await Promise.all([this.GET_INPATIENT_REVIEW(this.$route.params.drgTableId), this.GET_DROPDOWNS(this.dropdownsNeeded)])
          // update vuex rebuttal - rebuttals and findings are managed in vuex after this call
          this.SET_REBUTTAL(this.inpatientReview.rebuttal)
          this.SET_FINDINGS(this.inpatientReview.auditFindings)
          this.saveToLocalStorageForTruCode(this.inpatientReview)
          // this.$refs.ipDemographics.$refs.form.clearValidate()
          this.requiredFieldsValidation()
          this.loading = false
        } catch (e) {
          // show error message
          this.loading = false
          console.log(e)
        }
      },
      async handleStatus(command) {
        try {
          // on Complete check valid required fields
          if (command === 4 || command === 3) {
            this.requiredFieldsValidation()
          }

          if (!this.isValidationError() || command === 2 || command === 1) {
            this.inpatientReview.reviewStatusId = parseInt(command)
            const payload = { inpatientReview: this.inpatientReview, changedField: 'reviewStatus' }
            await this.PUT_INPATIENT_REVIEW(payload)
            this.saveToLocalStorageForTruCode(this.inpatientReview)
            this.$message({
              message: 'Record Updated Successfully',
              type: 'success'
            })
          }
        } catch (e) {
          this.$message({
            message: 'Record Update Error',
            type: 'error'
          })
        }
      },
      cancelStatusConfirm() {
        this.showInProgressWarning = false
      },
      async handleConfirmStatus() {
        this.confirmStatusLoading = true
        try {
          const copy = JSON.parse(JSON.stringify(this.inpatientReview))
          copy.reviewStatusId = this.confirmStatusId
          const payload = { inpatientReview: copy, changedField: 'reviewStatus' }
          await this.PUT_INPATIENT_REVIEW(payload)
          this.saveToLocalStorageForTruCode(this.inpatientReview)
          this.showInProgressWarning = false
          this.confirmStatusLoading = false
          this.routeNext(true)
          this.$message({
            message: 'Record Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.confirmStatusLoading = false
          this.$message({
            message: 'Record Update Error',
            type: 'error'
          })
        }
      },
      formatDate(date) {
        if (!date) {
          return ''
        }

        if (isDate(date)) {
          return format(date, 'MM-dd-yyyy')
        }

        return format(parseISO(date), 'MM-dd-yyyy')
      },
      toggleResearch() {
        this.toggleReseach = !this.toggleReseach
      },
      convertReviewModetoInt(mode) {
        let revmodeint
        switch (mode) {
          case 'original':
            revmodeint = 0
            break
          case 'recommended':
            revmodeint = 1
            break
          case 'level2':
            revmodeint = 2
            break
          default:
            revmodeint = 0
        }
        return revmodeint
      },
      saveToLocalStorageForTruCode(review, truCodeQuery) {
        if (!review.drgTableId) {
          return
        }
        // Because of IE/Edge issues, store a subset of the review instead of the whole thing
        const codeBookBus = {
          reviewSubset: {
            drgtableId: review.drgTableId,
            dischargeDate: review.dischargeDate,
            drgrecommendationsDx: review.drgRecommendationsDx,
            drgrecommendationsPx: review.drgRecommendationsPx,
            drgrecommendationsCPTs: review.drgRecommendationsCPTs,
            disableDxPx: false,
            reviewLevel: review.reviewLevel,
            disabled: !!this.readOnly() || this.inpatientReview.reviewStatusId !== 2
          },
          truCodeQuery: truCodeQuery,
          parent: 'Inpatient Review',
          from: 'Inpatient Review',
          reviewLevel: review.reviewLevel
        }

        this.$ls.set(`codeBookBus_ip_${review.drgTableId}`, JSON.stringify(codeBookBus))
        this.$ls.set('truCodeAuthKey', review.truCodeAuthKey)
      },
      handleChangedFieldResearchPane() {
        this.changedFieldResearchPane = ''
      },
      handleTruCodeReferenceClick(refEvent) {
        let url
        if (refEvent.code && refEvent.articleId) {
          url = '/trucode/references?code=' + refEvent.code + '&book=' + refEvent.book + '&articleId=' + refEvent.articleId
        } else if (refEvent.code) {
          url = '/trucode/references?code=' + refEvent.code + '&book=' + refEvent.book
        } else {
          url = '/trucode/references?book=' + refEvent.book
        }
        const cacheBuster = new Date().getTime()
        this.referencesWindow = window.open(url + '&dt=' + cacheBuster, 'reference', 'height=812,width=1600')
      },
      handleDxPxGridCodeFocus(focusedCodeObject) {
        const truCodeQuery = this.getTruCodeQuery(focusedCodeObject.code, focusedCodeObject.type)
        this.truCodeQuery = truCodeQuery
        this.saveToLocalStorageForTruCode(this.inpatientReview, truCodeQuery)
      },
      handleEllipsisClick(code) {
        if (!this.codebooksWindow || !this.codebooksWindow.parent) {
          this.openCodeBooks()
        }
      },
      handleTruCodeCodeClick(event) {
        const truCodeQuery = this.getTruCodeQuery(event.code, null, event.codeType)
        this.saveToLocalStorageForTruCode(this.inpatientReview, truCodeQuery)
        if (!this.codebooksWindow || !this.codebooksWindow.parent) {
          this.openCodeBooks()
        }
      },
      handleReviewMode(reviewMode) {
        this.$ls.set('reviewMode', reviewMode)
      },
      setupLocalStorageListenerForCodeBook() {
        // try to turn off any previous listeners so that they don't double up
        try {
          this.$ls.off(`codeBookBus_ip_${this.$route.params.drgTableId}`)
        } catch {
          // nothing - its ok if this fails
        }
        this.$ls.on(`codeBookBus_ip_${this.$route.params.drgTableId}`, storedData => {
          this.parseInpatientReviewDataFromLocalStorage(storedData)
        })
      },
      async parseInpatientReviewDataFromLocalStorage(storedData) {
        const codeBookBus = JSON.parse(storedData)
        const payload = {}
        if (codeBookBus.parent !== 'Inpatient Review') {
          return
        }

        // use "from" to avoid infinite loops
        if (codeBookBus.reviewSubset && codeBookBus.reviewSubset.drgtableId === this.inpatientReview.drgTableId && codeBookBus.from === 'CodeBook') {
          switch (codeBookBus.event) {
            case 'putDxCode':
              try {
                payload.dxRow = codeBookBus.payload.dxCode
                await this.PUT_INPATIENT_DX(payload)
                this.$message({
                  message: 'DX Code Updated Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'DX Code Updated Error',
                  type: 'error'
                })
              }
              break
            case 'postDxCode':
              try {
                codeBookBus.payload.dxCode[0].level2NewPrimary = false
                codeBookBus.payload.dxCode[0].newPrimary = false
                await this.POST_INPATIENT_DX([codeBookBus.payload.dxCode[0]])
                this.$message({
                  message: 'DX Code Added Successful',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'DX Code Added Error',
                  type: 'error'
                })
              }
              break
            case 'putPxCode':
              try {
                payload.pxRow = codeBookBus.payload.pxCode
                await this.PUT_INPATIENT_PX(payload)
                this.$message({
                  message: 'PX Code Updated Successfully',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'px Code Updated Error',
                  type: 'error'
                })
              }
              break
            case 'postPxCode':
              try {
                await this.POST_INPATIENT_PX([codeBookBus.payload.pxCode[0]])
                this.$message({
                  message: 'PX Code Added Successful',
                  type: 'success'
                })
              } catch (e) {
                this.$message({
                  message: 'px Code Added Error',
                  type: 'error'
                })
              }
              break
            case 'putCptCode':
              this.putCptCode(codeBookBus.payload)
              break
            case 'postCptCode':
              this.postCptCodes(codeBookBus.payload)
              break
          }
        }

        this.saveToLocalStorageForTruCode(this.inpatientReview)
        if (codeBookBus.referenceEvent) {
          this.handleTruCodeReferenceClick(codeBookBus.referenceEvent)
        }
      },
      createWindowEventListeners() {
        window.onunload = event => {
          // clear the localStorage listner
          this.$ls.off(`codeBookBus_ip_${this.$route.params.drgTableId}`)
          this.handleCloseOrBackEvent()
        }

        window.onpopstate = event => {
          this.handleCloseOrBackEvent()
        }

        // document.onkeydown = event => {
        //   if (event.keyCode === 73 && event.ctrlKey) {
        //     this.handleStatus('2')
        //   }
        // }
      },
      handleCloseOrBackEvent() {
        // first clear the window callbacks so they don't run for other components since they are attached to the
        // global window object
        window.onunload = undefined
        window.onpopstate = undefined
        this.closeCodeBookAndReferences()
        this.$ls.off(`codeBookBus_ip_${this.inpatientReview.drgTableId}`)
        this.$ls.remove(`codeBookBus_ip_${this.inpatientReview.drgTableId}`)
      },
      closeCodeBookAndReferences() {
        if (this.codebooksWindow) {
          this.codebooksWindow.close()
        }
        if (this.referencesWindow) {
          this.referencesWindow.close()
        }
        if (this.worksheetWindow) {
          this.worksheetWindow.close()
        }
      },
      openCodeBooks() {
        // in case users have opened codebooks already via another page, then also do a save here
        const codeBookBus = JSON.parse(this.$ls.get(`codeBookBus_ip_${this.inpatientReview.drgTableId}`))
        if (codeBookBus.parent !== 'Inpatient Review') {
          this.saveToLocalStorageForTruCode(this.inpatientReview)
        }
        const cacheBuster = new Date().getTime()
        this.codebooksWindow = window.open(`/trucode/codebooks?dt=${cacheBuster}&type=ip&id=${this.inpatientReview.drgTableId}`, 'codebook', 'height=850,width=1600')
      },
      openWorksheet() {
        const cacheBuster = new Date().getTime()
        this.worksheetWindow = window.open(`/reviews/reports/inpatient/worksheets?fullscreen=true&dt=${cacheBuster}&ids=${this.inpatientReview.drgTableId}`, 'worksheet', 'height=850,width=1600')
      },
      handleAction(action, e) {
        if (this.readOnly()) {
          return false
        }
        if (action === 'poaAssignments') {
          this.showPoaAssignments = true
        }
        if (action === 'addMultipleCodes') {
          this.showAddMultipleCodes = true
        }
        if (action === 'pxAddMultipleCodes') {
          this.showPxAddMultipleCodes = true
        }
        // if (action === 'cptAddMultipleCodes') {
        //   this.showCptAddMultipleCodes = true
        // }
      },
      handleCloseAddMultipleCodes(payload) {
        this.showAddMultipleCodes = false
        this.showPxAddMultipleCodes = false
        // this.showCptAddMultipleCodes = false
      },
      handleClosePoaAssignments(payload) {
        this.showPoaAssignments = false
      },
      openPrincipalDXAnalysis() {
        this.togglePrincipalDxAnalysis = !this.togglePrincipalDxAnalysis
      },
      confirmLeave(next) {
        this.showInProgressWarning = true
        this.confirmStatusId = this.inpatientReview.reviewStatusId
        this.routeNext = next
      },
      requiredFieldsValidation() {
        this.$refs.ipDemographics.$refs.form.validate(async (valid, fields) => {
          // toggle alert banner
          // this.requiredFieldAlertToggle = !valid
          // generate error list
          this.requiredFieldsErrorList = []
          if (this.inpatientReview.requiredFields) {
            const errorFields = Object.keys(fields)
            const requiredFieldsData = this.inpatientReview.requiredFields.requiredFieldsData
            requiredFieldsData.forEach((item, index, array) => {
              if (errorFields.some(v => v === item.field)) {
                this.requiredFieldsErrorList.push(item)
              }
            })
          }
        })
      },
      isValidationError() {
        return !!this.requiredFieldsErrorList.length
      },
      handleTableCommands(command) {
        const visibleComponent = 'InpatientReviewFooter'
        if (command.ref === 'ipSummary' || command.ref.includes('opSummary_') || command.ref.includes('proSummary_') || command.ref.includes('ascSummary_')) {
          const component = this.$refs[visibleComponent].$refs[command.ref][0] ? this.$refs[visibleComponent].$refs[command.ref][0].$refs[command.ref] : this.$refs[visibleComponent].$refs[command.ref].$refs[command.ref]
          this.tableToCsv(component, `${command.csvName}.csv`, command.headers, command.excludeHeaders)
        }
      }
    },
    async beforeRouteLeave(to, from, next) {
      if (this.inpatientReview.reviewStatusId === 2 && !this.readOnly()) {
        this.confirmLeave(next)
      } else {
        next()
      }
    },
    created: function() {
      // this.initialize()
      // this.createWindowEventListeners()
      // this.setupLocalStorageListenerForCodeBook()
    },
    activated: function() {
      this.initialize()
      this.createWindowEventListeners()
      this.setupLocalStorageListenerForCodeBook()
    },
    deactivated: function() {
      this.activeNames = []
      this.handleCloseOrBackEvent()
    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview']),
      ...mapState('user/', ['user']),
      ...mapState('auditFindings/', ['auditFindings']),
      disabled: function() {
        return !!this.readOnly()
      },
      headerTable: function() {
        return [
          {
            client: this.inpatientReview.clientSmall ? this.inpatientReview.clientSmall.clientName : '',
            reviewDate: this.inpatientReview.reviewDate,
            patientNumber: this.inpatientReview.patientNumber,
            admitDate: this.inpatientReview.admitDate,
            dischargeDate: this.inpatientReview.dischargeDate
            // startedBy: this.inpatientReview.reviewAccountsComplete[0].userName,
            // startDate: this.inpatientReview.reviewAccountsComplete[0].startDate
          }
        ]
      },
      isLevel2: function() {
        if (this.inpatientReview.reviewLevel !== '1' && this.inpatientReview.reviewLevel !== 1) {
          return true
        }
        return false
      }
    },
    watch: {}
  }
</script>

<style scoped>
  /* >>> .recordContainer .el-card__body {
  padding: 0px 20px 10px 20px;
} */

  .inpatientButtons .el-dropdown {
    float: left;
  }

  .el-button-group > .el-dropdown > .el-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-left-color: #dcdfe6;
  }

  .el-button-group > .el-dropdown > .el-button--primary {
    border-left-color: #99bbd5;
  }

  .el-button-group > .el-dropdown > .el-button--success {
    border-left-color: #cbe5bf;
  }

  .el-button-group > .el-dropdown > .el-button--warning {
    border-left-color: #f5dab1;
    /* border-right-color: #f5dab1; */
  }

  .el-button-group > .el-dropdown > .el-button--info {
    border-left-color: #d3d4d6;
  }

  .inpatientButtons .el-button--primary:hover,
  .inpatientButtons .el-button--success:hover,
  .inpatientButtons .el-button--warning:hover,
  .inpatientButtons .el-button--info:hover {
    color: white !important;
  }

  .inpatientButtons .el-dropdown {
    margin-right: -1px;
  }

  .inpatientButtons .el-button--danger {
    border-left-color: #fbc4c4 !important;
  }

  .collapseContent {
    margin: 15px 0px 0px 5px;
  }

  >>> .hiaTable tr td {
    border: none !important;
  }

  >>> .hiaTable th {
    border: none !important;
  }

  .sticky {
    position: sticky;
    top: 5px;
  }

  .greenIcon {
    color: #63b344;
  }
</style>
