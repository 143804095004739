<template>
  <div>
    <el-row>
      <!-- <el-col :span="21">
        <el-input size="mini" v-model="calcserviceLineName" disabled></el-input>
      </el-col>
      <el-col :span="3" class="coderButtons">
        <el-button-group class="project-selector-buttons">
          <el-button icon="el-icon-search" size="mini" @click="searchserviceLines" :disabled="disabled"></el-button>
          <el-button icon="el-icon-circle-close" @click="selectserviceLine(null)" size="mini" :disabled="disabled">
          </el-button>
        </el-button-group>
      </el-col> -->
      <el-col :span="24">
        <el-input :disabled="disabled" size="mini" type="text" id="serviceLineselin" v-model="calcserviceLineName" :readonly="true">
          <el-button slot="append" class="search" icon="el-icon-search" size="mini" @click="searchserviceLines" :disabled="disabled">
          </el-button>
          <el-button slot="append" class="clear" icon="el-icon-circle-close" @click="selectserviceLine({clientServiceLineId: null})" size="mini" :disabled="disabled">
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-dialog :id="getUniqueDialogId()" title="Select Service Line" :visible.sync="showSearch" width="60%" :append-to-body="true" @opened="handleOpen">
      <el-popover trigger="click" placement="right" v-model="addserviceLinePopOverVisible">
        <el-form size="mini" ref="serviceLineform" label-position="top" label-width="120px" :rules="serviceLineRules" :model="serviceLineForm">
          <el-form-item size="mini" label="Add serviceLine" prop="addserviceLine" id="addserviceLineInput">
            <el-input :disabled="disabled" size="mini" :maxlength="50" type="text" id="addserviceLine" v-model="serviceLineForm.addserviceLine">
            </el-input>
          </el-form-item>
        </el-form>
        <br>
        <el-button-group style="margin: 0px 0px 0px 10px;">
          <el-button type="text" class="hiaButton" plain round @click="addserviceLinePopOverVisible = false; serviceLineForm.addserviceLine = ''" size="mini">
            Cancel</el-button>
          <el-button class="hiaButton" plain round @click="addserviceLine()" size="mini">
            Confirm</el-button>
        </el-button-group>
        <div slot="reference" class="addButton">
          <el-button class="hiaButton" plain round size="mini" icon="el-icon-plus" title="Add serviceLine">Add New Service Line
          </el-button>
        </div>
      </el-popover>
      <el-form size="mini" label-position="left" label-width="150px" v-loading="serviceLinesLoading" element-loading-spinner="atom-audit-loader">
        <v-server-table class="hiaTable" v-loading="tableLoading" @loading="tableLoading = true" element-loading-spinner="atom-audit-loader" @loaded="loaded" name="serviceLineListGrid" ref="serviceLineListGrid" id="serviceLineListGrid" :columns="serviceLineColumns" :options="serviceLineoptions" :data="localProvoiders">
          <template slot="serviceLineName" slot-scope="props">
            <el-button tabindex="0" class="select-button" type="text" :disabled="props.row.inactive" @click="selectserviceLine(props.row)" size="mini">
              {{props.row.serviceLineName + (props.row.inactive ? ' (Inactive)': '')}}</el-button>
          </template>

        </v-server-table>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'serviceLineSelector',
    props: ['value', 'serviceLine', 'disabled'],
    data() {
      return {
        addserviceLinePopOverVisible: false,
        serviceLineForm: {
          addserviceLine: ''
        },
        serviceLineRules: {
          addserviceLine: [
            { required: true, message: 'Please Set Service Line' }
          ]
        },
        localProvoiders: [],
        serviceLinesLoading: false,
        showSearch: false,
        tableLoading: false,
        serviceLineColumns: ['serviceLineName'],
        serviceLineoptions: {
          requestFunction: async (data) => {
            try {
              const payload = {
                data: data
              }
              return await this.LOAD_SERVICELINE_PAGINATED(payload)
            } catch (err) {

            }
          },
          filterByColumn: true,
          headings: {
            name: 'Service Line'
          },
          serviceLineRules: {
            addserviceLine: [
              { required: true, message: 'Please Set Service Line' }
            ]
          },
          localProvoiders: [],
          serviceLinesLoading: false,
          showSearch: false,
          tableLoading: false,
          serviceLineColumns: ['serviceLineName'],
          serviceLineoptions: {
            requestFunction: (data) => {
              const payload = {
                data: data
              }
              return this.LOAD_SERVICELINE_PAGINATED(payload)
            },
            filterByColumn: true,
            headings: {
              name: 'Service Line'
            },
            perPage: 15,
            texts: {
              filter: '',
              filterBy: '...'
            },
            highlightMatches: true,
            sortIcon: { is: 'none' }
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/serviceLines/', ['LOAD_SERVICELINE_PAGINATED', 'POST_SERVICELINESLIST']),
      getUniqueDialogId() {
        return `serviceLineSelectorDialog${this._uid}`
      },
      handleOpen() {
        const dialogId = this.getUniqueDialogId()
        const input = document.querySelector(`#${dialogId} #serviceLineListGrid input[name="vf__serviceLineName"]`)
        if (input) {
          input.focus()
        }
      },
      loaded() {
        this.tableLoading = false
        const dialog = document.getElementById(this.getUniqueDialogId())
        const tds = dialog.getElementsByTagName('td')
        for (let i = 0; i < tds.length; i++) {
          tds[i].tabIndex = -1
        }
      },
      searchserviceLines() {
        this.showSearch = true
      },
      selectserviceLine(row) {
        this.$emit('input', row.clientServiceLineId)
        this.$emit('selected', row)
        this.showSearch = false
      },
      addserviceLine() {
        this.$refs.serviceLineform.validate(async (valid) => {
          if (valid) {
            try {
              const payload = {
                clientServiceLine: {
                  serviceLineName: this.serviceLineForm.addserviceLine
                }
              }
              const response = await this.POST_SERVICELINESLIST(payload)
              this.addserviceLinePopOverVisible = false
              this.$refs.serviceLineform.resetFields()
              this.selectserviceLine(response)
            } catch (err) {

            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    computed: {
      calcserviceLineName: {
        get: function () {
          if (this.serviceLine) {
            return this.serviceLine.serviceLineName
          }
          return null
        }// ,
        // set: function (val) {
        //   if (!val) {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = null
        //   }

        //   if (typeof val === 'string') {
        //     this.review.hiaCoderId = val
        //     this.review.coderId = null
        //   } else {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = val
        //   }
        // }
      }
    }
  }
</script>

<style scoped>
.project-selector-buttons >>> .el-button.el-button--default.el-button--mini {
  padding: 4px 9px;
  margin-top: -1px;
}

.coderButtons {
  padding: 1px 0px 0px 5px;
}

.addButton {
  width: 115px;
  margin: 0px 0px 10px 0px;
}

>>> .select-button {
  border: 2px solid rgba(255, 255, 255, 0);
  padding: 7px 5px 7px 5px;
  width: 100%;
  text-align: left;
}

>>> .select-button:focus {
  border: 2px solid #409eff;
  border-radius: 0px;
}
</style>
