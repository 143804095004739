<template>
  <div>
    <div v-if="showReviewRadioButtons">
      <label class="radio-inline" for="researchOriginalCoding">
        <el-radio id="researchOriginalCoding" label="original" v-model="reviewRadio" v-on:change.native="handleResearchRadioClick('original')">Original</el-radio>

      </label>
      <label class="radio-inline" for="researchRecommendedCoding">
        <el-radio id="researchRecommendedCoding" label="recommended" v-model="reviewRadio" v-on:change.native="handleResearchRadioClick('recommended')">Recommended</el-radio>

      </label>
      <!-- <label v-if="isLevel2" class="radio-inline" for="researchLevel2Coding">
        <el-radio id="researchLevel2Coding" label="level2" v-model="reviewRadio" v-on:change.native="handleResearchRadioClick('level2')">Level 2</el-radio>

      </label> -->
    </div>
    <div id="tc-research" class="trucode">
      <div id="research-wrapper"></div>
    </div>
  </div>
</template>

<script>
  // IMPORTANT, when using this component, don't render it until the review is async retrieved from the server.
  // This is accomplished by using v-if in the parent template for this TruCodeResearchPane component
  import axios from '@/utilities/axios-global'
  export default {
    name: 'ResearchPane',
    props: ['review', 'isLevel2', 'truCodeQuery', 'truCodeAuthKey', 'showReviewRadioButtons', 'encounterType', 'parent', 'changedFieldResearchPane'],
    data() {
      return {
        rp: {},
        reviewRadio: 'original',
        sticky: {},
        headerFieldsTruCode: ['reviewLevel', 'icd9', 'icd10', 'orPatStat', 'dcDate', 'admitDate', 'dateOfService', 'patientAge', 'sex', 'recPatStatus', 'codeBooksChange', 'grouper', 'grouperVersion', 'pricer', 'ipreviewType', 'billType'],
        dxFieldTruCode: ['origDx', 'orPOA', 'recDx', 'recPOA', 'newPrimary', 'level2Dx', 'level2POA', 'level2NewPrimary', 'deleteDxRow', 'addDxRow', 'moveDxRow'],
        pxFieldTruCode: ['originalPx', 'recommendedPx', 'level2Px', 'addPxRow', 'deletePxRow', 'movePxRow'],
        cptFieldTruCode: ['originalCpt', 'recommendedCpt', 'level2Cpt', 'addCptRow', 'deleteCptRow', 'moveCptRow', 'procedureDate'],
        modFieldTruCode: ['OriginalModifier', 'recommendedModifier', 'Level2Modifier', 'addModRow', 'deleteModRow', 'moveModRow']
      }
    },
    mounted: function () {
      // Because of a Chrome issue with cached JS files, need to force a
      // timeout here so that the TruCode control renders
      // on page refresh
      setTimeout(() => {
        // initialze true code directly, can't use the ready callback since we are using a virtual DOM
        // in the case of an in-app route instead of a refresh, we might not need to initialize, so check
        // to see if the init method exists first
        if (typeof window.TC.init === 'function') {
          window.TC.init({
            authorizationKey: 'Bearer ' + this.truCodeAuthKey,
            env: 'prod',
            authorizationExpired: () => {
              const jwtPromise = new Promise((resolve, reject) => {
                axios.get(this.context.rootState.user.baseApiUrl + '/api/TruCode/jwt').then(response => {
                  resolve(response.data)
                })
              })
              // trucode expects Promise.promise to be a function, so we need to dummy this out here
              jwtPromise.promise = () => { }
              return jwtPromise
            },
            authorizationExpiredParser: function (data) {
              return data.truCodeAuthKey
            }
          })
        }

        this.rp = window.TC.createControl({
          type: 'research',
          activePane: 'edits',
          encounterType: this.encounterType,
          el: '#research-wrapper'
        })

        if (this.parent === 'inpatient' && this.review.drgAnalysis_Or) {
          const reviewLevelZero = this.review.truCodeResponses.find(x => x.reviewLevel === 0)
          if (reviewLevelZero && reviewLevelZero.response) {
            const truCodeEdits = JSON.parse(JSON.stringify(reviewLevelZero.response))
            const drgAnalysis = JSON.parse(this.review.drgAnalysis_Or)
            const apcTruCodeEdits = JSON.parse(JSON.stringify(this.review.orResponse_Object))
            if (apcTruCodeEdits) {
              this.updateInpatientAndApcResearch(truCodeEdits, drgAnalysis, apcTruCodeEdits)
            } else {
              this.updateInpatientResearch(truCodeEdits, drgAnalysis)
            }
          }
        } else if (this.parent === 'outpatient' && this.review.orResponse_Object) {
          const truCodeEdits = JSON.parse(JSON.stringify(this.review.orResponse_Object))
          const truCodeMNEEdits = JSON.parse(JSON.stringify(this.review.orMNEResponse_Object))
          this.updateOutpatientResearch(truCodeEdits, truCodeMNEEdits)
        } else if (this.parent === 'pro' && this.review.orAscResponse_Object) {
          const truCodeEdits = JSON.parse(JSON.stringify(this.review.orAscResponse_Object))
          const truCodeMNEEdits = JSON.parse(JSON.stringify(this.review.orMNEResponse_Object))
          this.updateProResearch(truCodeEdits, truCodeMNEEdits)
        }

        this.rp.tcOn('research:referenceClick', event => {
          this.$emit('onReferenceClick', event)
        })

        this.rp.tcOn('research:contentClicked', event => {
          this.$emit('contentClicked', event)
        })
      }, 0)
    },
    methods: {
      handleResearchRadioClick: function (researchFor) {
        let truCodeEdits
        let apcTruCodeEdits
        if (this.parent === 'inpatient') {
          let drgAnalysis
          let response
          switch (researchFor) {
            case 'original':
              response = this.review.truCodeResponses && this.review.truCodeResponses.length
                ? this.review.truCodeResponses.filter(function (obj) {
                  return (obj.reviewLevel === 0)
                })[0].response
                : null
              truCodeEdits = response !== null ? JSON.parse(JSON.stringify(response)) : null
              drgAnalysis = this.review.drgAnalysis_Or !== undefined ? JSON.parse(this.review.drgAnalysis_Or) : null
              apcTruCodeEdits = this.review.orResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.orResponse_Object)) : null
              // pass to parent and set localstorage listener
              this.$emit('onReviewMode', 'original')
              break
            case 'recommended':
              response = this.review.truCodeResponses && this.review.truCodeResponses.length
                ? this.review.truCodeResponses.filter(function (obj) {
                  return (obj.reviewLevel === 1)
                })[0].response
                : null
              truCodeEdits = response !== null ? JSON.parse(JSON.stringify(response)) : null
              drgAnalysis = this.review.drgAnalysis_Rec !== undefined ? JSON.parse(this.review.drgAnalysis_Rec) : null
              apcTruCodeEdits = this.review.recResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.recResponse_Object)) : null
              // pass to parent and set localstorage listener
              this.$emit('onReviewMode', 'recommended')
              break
            case 'level2':
              response = this.review.truCodeResponses && this.review.truCodeResponses.length
                ? this.review.truCodeResponses.filter(function (obj) {
                  return (obj.reviewLevel === 2)
                })[0].response
                : null
              truCodeEdits = response !== null ? JSON.parse(JSON.stringify(response)) : null
              drgAnalysis = this.review.drgAnalysis_Lvl2 !== undefined ? JSON.parse(this.review.drgAnalysis_Lvl2) : null
              apcTruCodeEdits = this.review.lvl2Response_Object !== undefined ? JSON.parse(JSON.stringify(this.review.lvl2Response_Object)) : null
              // pass to parent and set localstorage listener
              this.$emit('onReviewMode', 'level2')
              break
          }
          if (apcTruCodeEdits) {
            this.updateInpatientAndApcResearch(truCodeEdits, drgAnalysis, apcTruCodeEdits)
          } else {
            this.updateInpatientResearch(truCodeEdits, drgAnalysis)
          }
        }

        if (this.parent === 'outpatient') {
          let truCodeMNEEdits
          switch (researchFor) {
            case 'original':
              truCodeEdits = this.review.orResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.orResponse_Object)) : null
              truCodeMNEEdits = this.review.orMNEResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.orMNEResponse_Object)) : null
              this.$emit('onReviewMode', 'original')
              break
            case 'recommended':
              truCodeEdits = this.review.recResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.recResponse_Object)) : null
              truCodeMNEEdits = this.review.recMNEResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.recMNEResponse_Object)) : null
              this.$emit('onReviewMode', 'recommended')
              break
            case 'level2':
              truCodeEdits = this.review.lvl2Response_Object !== undefined ? JSON.parse(JSON.stringify(this.review.lvl2Response_Object)) : null
              truCodeMNEEdits = this.review.lvl2MNEResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.lvl2MNEResponse_Object)) : null
              this.$emit('onReviewMode', 'level2')
              break
          }
          this.updateOutpatientResearch(truCodeEdits, truCodeMNEEdits)
        }

        if (this.parent === 'pro') {
          let truCodeMNEEdits
          switch (researchFor) {
            case 'original':
              truCodeEdits = this.review.orAscResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.orAscResponse_Object)) : null
              truCodeMNEEdits = this.review.orMNEResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.orMNEResponse_Object)) : null
              this.$emit('onReviewMode', 'original')
              break
            case 'recommended':
              truCodeEdits = this.review.recAscResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.recAscResponse_Object)) : null
              truCodeMNEEdits = this.review.recMNEResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.recMNEResponse_Object)) : null
              this.$emit('onReviewMode', 'recommended')
              break
            case 'level2':
              truCodeEdits = this.review.level2AscResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.level2AscResponse_Object)) : null
              truCodeMNEEdits = this.review.level2MNEResponse_Object !== undefined ? JSON.parse(JSON.stringify(this.review.level2MNEResponse_Object)) : null
              this.$emit('onReviewMode', 'level2')
              break
          }
          this.updateProResearch(truCodeEdits, truCodeMNEEdits)
        }
      },
      updateInpatientResearch(truCodeEdits, drgAnalysis) {
        this.rp.tcTrigger('research:editsSet', { category: 'edits', edits: truCodeEdits })
        this.rp.tcTrigger('research:analyzeDRG', drgAnalysis)
      },
      updateInpatientAndApcResearch(truCodeEdits, drgAnalysis, apcTruCodeEdits) {
        this.rp.tcTrigger('research:editsSet', { category: 'group1', edits: truCodeEdits })
        this.rp.tcTrigger('research:editsSet', { category: 'group2', edits: apcTruCodeEdits })
        this.rp.tcTrigger('research:analyzeDRG', drgAnalysis)
      },
      updateOutpatientResearch(truCodeEdits, truCodeMNEEdits) {
        if (truCodeEdits && truCodeMNEEdits) {
          this.rp.tcTrigger('research:editsSet', { category: 'group1', edits: truCodeEdits })
          this.rp.tcTrigger('research:editsSet', { category: 'group2', edits: truCodeMNEEdits })
        } else if (truCodeEdits) {
          this.rp.tcTrigger('research:editsSet', { category: 'group1', edits: truCodeEdits })
        } else if (truCodeMNEEdits) {
          this.rp.tcTrigger('research:editsSet', { category: 'group2', edits: truCodeMNEEdits })
        }
      },
      updateProResearch(truCodeEdits, truCodeMNEEdits) {
        if (truCodeEdits && truCodeMNEEdits) {
          this.rp.tcTrigger('research:editsSet', { category: 'group1', edits: truCodeEdits })
          this.rp.tcTrigger('research:editsSet', { category: 'group2', edits: truCodeMNEEdits })
        } else if (truCodeEdits) {
          this.rp.tcTrigger('research:editsSet', { category: 'group1', edits: truCodeEdits })
        } else if (truCodeMNEEdits) {
          this.rp.tcTrigger('research:editsSet', { category: 'group2', edits: truCodeMNEEdits })
        }
      }
    },
    beforeDestroy: function () {
      this.rp.tcClose()
      delete this.rp
    },
    watch: {
      truCodeQuery() {
        if (!this.truCodeQuery.term) {
          return
        }
        this.rp.tcTrigger('research:codeInContext', { code: this.truCodeQuery.term, book: this.truCodeQuery.book, pane: 'icd10map' })
        this.rp.tcTrigger('research:selectTab', { name: 'research' })
      },
      'review.truCodeResponses': {
        handler: function () {
          // this watch triggers anytime the virtual dom changes; even on keypress
          // reuse the radio click function if the parent review changes, e.g., for Age based edits
          // if (this.parent === 'outpatient') {
          this.handleResearchRadioClick(this.reviewRadio)
          // }
        },
        deep: true
      },
      changedFieldResearchPane() {
        // only run analyze when these conditions are met; mimics API put.
        if (this.headerFieldsTruCode.indexOf(this.changedFieldResearchPane) !== -1 || this.dxFieldTruCode.indexOf(this.changedFieldResearchPane) !== -1 || this.pxFieldTruCode.indexOf(this.changedFieldResearchPane) !== -1 || this.cptFieldTruCode.indexOf(this.changedFieldResearchPane) !== -1 || this.modFieldTruCode.indexOf(this.changedFieldResearchPane) !== -1 || this.changedFieldResearchPane === 'encoder') {
          this.handleResearchRadioClick(this.reviewRadio)
        }
        // reset changeFieldResearchPane
        this.$emit('onChangedFieldResearchPane', '')
      },
      'review.truCodeTimeStamp': {
        handler: function () {
          this.handleResearchRadioClick(this.reviewRadio)
        },
        deep: true
      }
    }
  }

</script>

<style>
  .trucode .research-pane .research-content.icd10pcs_pr #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10pcs_pr_index #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10pcs_pr_tabular #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10cm_dx #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10cm_dx_index #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10cm_dx_tabular #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10cm_e #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10cm_e_index #reference-ICD10_GUIDELINES,
  .trucode .research-pane .research-content.icd10cm_e_tabular #reference-ICD10_GUIDELINES {
    display: list-item;
  }

  .trucode .research-pane .research-content.icd10pcs_pr #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10pcs_pr_index #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10pcs_pr_tabular #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10cm_dx #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10cm_dx_index #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10cm_dx_tabular #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10cm_e #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10cm_e_index #reference-CC_ICD10,
  .trucode .research-pane .research-content.icd10cm_e_tabular #reference-CC_ICD10 {
    display: list-item;
  }

  .radio-inline {
    padding: 0px 15px 0px 0px;
  }
</style>
