<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <!-- <el-form size="mini" ref="textArea" label-position="left" label-width="120px">
      <el-row :gutter="20">
        <el-col>
          <el-tabs v-model="activeTab" @tab-click="handleTabClick">
            <el-tab-pane label="All" name="all"></el-tab-pane>
            <el-tab-pane label="Action Taken" name="actionTaken"></el-tab-pane>
            <el-tab-pane label="Location of Supporting Documentation" name="supportingDocs"></el-tab-pane>
            <el-tab-pane label="Coding Reference" name="codingRef"></el-tab-pane>
          </el-tabs>
          <el-row :gutter="10">
            <el-col :span="actionTakenCol" id="actionTakenCol">
              <label>Action Taken</label>
              <ul class="editorButtons pull-right">
                <li>
                  <el-button icon="mdi mdi-library" round v-if="!disabled" type="primary" class="" size="mini" @click="recommendationPickerActionTaken = true">Library
                  </el-button>
                </li>
              </ul>
              <div style="margin-top: 10px;">
                <Tinymce :disabled="disabled" id="actionTakenAll" v-model="inpatientReview.actionTaken" :imagePath="tinyMceImagePath" @change="putInpatientReview('actionTaken')"></Tinymce>
              </div>
            </el-col>
            <el-col :span="supportingDocsCol" id="supportingDocsCol">
              <label>Location of Supporting Doc</label>
              <div style="margin-top: 10px;">
                <Tinymce :disabled="disabled" id="locationOfSuppDocAll" v-model="inpatientReview.locationOfSuppDoc" :imagePath="tinyMceImagePath" @change="putInpatientReview('locationOfSuppDoc')"></Tinymce>
              </div>
            </el-col>
            <el-col :span="codingRefCol" id="codingRefCol">
              <label style="margin-bottom: 20px;">Coding Reference</label>
              <ul class="editorButtons pull-right">
                <li>
                  <el-button icon="mdi mdi-library" round v-if="!disabled" type="primary" class="" size="mini" @click="recommendationPickerCodingRef = true">Library
                  </el-button>
                </li>
              </ul>
              <div style="margin-top: 10px;">
                <Tinymce :disabled="disabled" id="codingRefAll" v-model="inpatientReview.codingRef" :imagePath="tinyMceImagePath" @change="putInpatientReview('codingRef')">
                </Tinymce>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form> -->

    <el-form ref="form" label-position="left" label-width="120px" style="padding-top: 10px;">
      <el-row :gutter="20">
        <el-col :span="8">

          <el-form-item size="mini" label="DRG">
            <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;padding-right: 0px">
              <el-input :disabled="disabled" size="mini" type="number" id="recommendedDrg" v-model="inpatientReview.newDrg" v-on:change.native="putInpatientReview('newDrg')">
              </el-input>
            </el-col>

            <template v-if="isTrucodeEnabled()">
              <el-col :span="2" style="padding-left: 0px; text-align: center;">
                <i v-if="(parseInt(inpatientReview.newDrg, 10) === parseInt(recDrgComputed, 10)) || ((origDrgComputed === recDrgComputed) && !inpatientReview.newDrg) "
                  title="DRG Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
                <i v-if="(parseInt(inpatientReview.newDrg, 10) !== parseInt(recDrgComputed, 10)) &&  (inpatientReview.newDrg) " title="DRG Mismatch" class="mdi mdi-alert"
                  style="color: #F56C6C;"></i>
              </el-col>
              <el-col :span="11" style="padding-right: 0px;">
                <el-input disabled size="mini" id="recDrgComputed" v-model="recDrgComputed"></el-input>
              </el-col>
            </template>

          </el-form-item>

          <el-form-item size="mini" label="Weight">
            <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;padding-right: 0px">
              <el-input :disabled="disabled" size="mini" type="number" step=".0001" id="recommendedWeight" v-model="inpatientReview.newWeight"
                v-on:change.native="putInpatientReview('newWeight')"></el-input>
            </el-col>

            <template v-if="isTrucodeEnabled()">
              <el-col :span="2" style="padding-left: 0px; text-align: center;">
                <i v-if="(String(inpatientReview.newWeight) === String(recDrgWtComputed)) || ((origDrgWtComputed === recDrgWtComputed) && !inpatientReview.newWeight) "
                  title="DRG Weight Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
                <i v-if="(String(inpatientReview.newWeight) !== String(recDrgWtComputed)) &&  (inpatientReview.newWeight) " title="DRG Weight Mismatch" class="mdi mdi-alert"
                  style="color: #F56C6C;"></i>
              </el-col>
              <el-col :span="11" style="padding-right: 0px;">
                <el-input disabled size="mini" id="recDrgWtComputed" v-model="recDrgWtComputed"></el-input>
              </el-col>
            </template>

          </el-form-item>

          <el-form-item size="mini" label="SOI" v-if="inpatientReview.ipreviewType ==='APR-DRG'">
            <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;">
              <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('recommendedSoi')" v-model="inpatientReview.recommendedSoi" placeholder="Rec. SOI"
                :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.oneToFour.list">
              </hia-el-select>
            </el-col>
            <template v-if="isTrucodeEnabled()">
              <el-col :span="2" style="padding-left: 0px; text-align: center;">
                <i v-if="(String(inpatientReview.recommendedSoi) === String(recSoiCalc)) || ((origSoiCalc === recSoiCalc) && !inpatientReview.recommendedSoi)"
                  title="SOI Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
                <i v-if="(String(inpatientReview.recommendedSoi) !== String(recSoiCalc)) && inpatientReview.recommendedSoi" title="SOI Mismatch" class="mdi mdi-alert"
                  style="color: #F56C6C;"></i>
              </el-col>
              <el-col :span="11" style="padding-right: 0px;">
                <el-input disabled size="mini" id="recSoiCalc" v-model="recSoiCalc"></el-input>
              </el-col>
            </template>
          </el-form-item>

          <el-form-item size="mini" label="ROM" v-if="inpatientReview.ipreviewType ==='APR-DRG'">
            <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;">
              <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('recommendedRom')" v-model="inpatientReview.recommendedRom" placeholder="Rec. ROM"
                :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.oneToFour.list">
              </hia-el-select>
            </el-col>
            <template v-if="isTrucodeEnabled()">
              <el-col :span="2" style="padding-left: 0px; text-align: center;">
                <i v-if="(String(inpatientReview.recommendedRom) === String(recRomCalc)) || ((origRomCalc === recRomCalc) && !inpatientReview.recommendedRom)"
                  title="ROM Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
                <i v-if="(String(inpatientReview.recommendedRom) !== String(recRomCalc)) && inpatientReview.recommendedRom" title="ROM Mismatch" class="mdi mdi-alert"
                  style="color: #F56C6C;"></i>
              </el-col>
              <el-col :span="11" style="padding-right: 0px;">
                <el-input disabled size="mini" id="recRomCalc" v-model="recRomCalc"></el-input>
              </el-col>
            </template>

          </el-form-item>

          <el-form-item size="mini" label="Reason For Change">

            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('reasonForChange')" v-model="inpatientReview.reasonForChange" placeholder="Reason For Change"
              :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.inpatientReasonsForChangeHeader.list" clearable>
            </hia-el-select>
          </el-form-item>

          <el-form-item size="mini" label="Patient Status">
            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('recommendedPatStatus')" v-model="inpatientReview.recommendedPatStatus"
              placeholder="Patient Status" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.patientStatus.list">
            </hia-el-select>
          </el-form-item>

          <el-form-item size="mini" label="Point of Origin">
            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('recommendedPoo')" v-model="inpatientReview.recommendedPoo" placeholder="Point of Origin"
              :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.pointOfOrigin.list">
            </hia-el-select>
          </el-form-item>

          <el-form-item size="mini" label="Discharge Order Date">
            <el-date-picker :disabled="disabled" size="mini" type="date" id="dischargeOrderDate" v-model="inpatientReview.dischargeOrderDate"
              placeholder="Discharge Order Date" v-on:change="putInpatientReview('dischargeOrderDate')" format="MM/dd/yyyy">
            </el-date-picker>
          </el-form-item>

          <!-- <el-form-item size="mini" label="Avoidable Days Reason">
            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('avoidableDayReason')" v-model="inpatientReview.avoidableDayReason"
              placeholder="Avoidable Days Reason" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.avoidableDayReasons.list" clearable>
            </hia-el-select>
          </el-form-item> -->

          <el-row>
            <el-col>
              <input :disabled="disabled" id="changeResultOfEdit" type="checkbox" v-model="inpatientReview.changeResultOfEdit"
                v-on:change="putInpatientReview('changeResultOfEdit')">
              Change result of edit?
            </el-col>
          </el-row>

        </el-col>

        <el-col :span="8">
          <!-- <el-form-item size="mini" label="System">
            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('otherDrgtype')" v-model="inpatientReview.otherDrgtype" placeholder="DRG System"
              :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.otherDrgSystems.list">
            </hia-el-select>
          </el-form-item>

          <el-form-item size="mini" label="DRG">

            <el-col :span="isTrucodeEnabled() ? 11 : 24" style="padding-left: 0px;">
              <el-input :disabled="disabled" size="mini" type="number" id="recommendedAprDRG" v-model="inpatientReview.recommendedAprdrg"
                v-on:change.native="putInpatientReview('recommendedAprdrg')">
              </el-input>
            </el-col>

            <template v-if="isTrucodeEnabled()">
              <el-col :span="2" style="padding-left: 0px; text-align: center;">
                <i v-if="(parseInt(inpatientReview.recommendedAprdrg, 10) === parseInt(recOtherDrgCalc, 10))" title="DRG Matches TruCode" class="mdi mdi-check"
                  style="color: #63b344;"></i>
                <i v-if="(String(inpatientReview.recommendedAprdrg) !== String(recOtherDrgCalc))" title="DRG Mismatch" class="mdi mdi-alert" style="color: #F56C6C;"></i>
              </el-col>
              <el-col :span="11" style="padding-right: 0px;">
                <el-input disabled size="mini" id="recOtherDrgCalc" v-model="recOtherDrgCalc"></el-input>
              </el-col>
            </template>

          </el-form-item>

          <el-form-item size="mini" label="SOI" v-if="inpatientReview.otherDrgtype ==='APR'">
            <el-col :span="11" style="padding-left: 0px;">
              <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('recommendedSoi')" v-model="inpatientReview.recommendedSoi" placeholder="Rec. SOI"
                :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.oneToFour.list">
              </hia-el-select>
            </el-col>
            <el-col :span="2" style="padding-left: 0px; text-align: center;">
              <i v-if="(String(inpatientReview.recommendedSoi) === String(recSoiCalc)) || ((origSoiCalc === recSoiCalc) && !inpatientReview.recommendedSoi)"
                title="SOI Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
              <i v-if="(String(inpatientReview.recommendedSoi) !== String(recSoiCalc)) && inpatientReview.recommendedSoi" title="SOI Mismatch" class="mdi mdi-alert"
                style="color: #F56C6C;"></i>
            </el-col>
            <el-col :span="11" style="padding-right: 0px;">
              <el-input disabled size="mini" id="recSoiCalc" v-model="recSoiCalc"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item size="mini" label="ROM" v-if="inpatientReview.otherDrgtype ==='APR'">
            <el-col :span="11" style="padding-left: 0px;">
              <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('recommendedRom')" v-model="inpatientReview.recommendedRom" placeholder="Rec. ROM"
                :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.oneToFour.list">
              </hia-el-select>
            </el-col>
            <el-col :span="2" style="padding-left: 0px; text-align: center;">
              <i v-if="(String(inpatientReview.recommendedRom) === String(recRomCalc)) || ((origRomCalc === recRomCalc) && !inpatientReview.recommendedRom)"
                title="ROM Matches TruCode" class="mdi mdi-check" style="color: #63b344;"></i>
              <i v-if="(String(inpatientReview.recommendedRom) !== String(recRomCalc)) && inpatientReview.recommendedRom" title="ROM Mismatch" class="mdi mdi-alert"
                style="color: #F56C6C;"></i>
            </el-col>
            <el-col :span="11" style="padding-right: 0px;">
              <el-input disabled size="mini" id="recRomCalc" v-model="recRomCalc"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item size="mini" label="Reason For Change">
            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('reasonForChangeApr')" v-model="inpatientReview.reasonForChangeApr"
              placeholder="APR Reason For Change" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.reasonsForChangeOtherDrg.list">
            </hia-el-select>
          </el-form-item> -->

          <el-form-item size="mini" label="D/C Summ. Avail at Coding">
            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('dcsummAvailable')" v-model="inpatientReview.dcsummAvailable" placeholder="D/C Summ. Coding"
              :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.dcSummaryAvailableAtCoding.list">
            </hia-el-select>
          </el-form-item>

          <el-form-item size="mini" label="D/C Summ. Avail at Review">
            <hia-el-select :disabled="disabled" v-on:input="putInpatientReview('dcsummAvailableAtReview')" v-model="inpatientReview.dcsummAvailableAtReview"
              placeholder="D/C Summ. Review" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.dcSummaryAvailableAtReview.list">
            </hia-el-select>
          </el-form-item>

        </el-col>
        <el-col :span="8">
          <el-col>
            <input :disabled="disabled" id="auditQuery" type="checkbox" v-model="inpatientReview.auditQuery" v-on:change="putInpatientReview('auditQuery')">
            Auditor Rec. Query Affects DRG? <el-tooltip effect="dark" content="Used in calculating DRG w/o Query Accuracy" placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-col>
          <el-col>
            <input :disabled="disabled" id="auditQuery" type="checkbox" v-model="inpatientReview.auditNonFinancialQuery"
              v-on:change="putInpatientReview('auditNonFinancialQuery')">
            Auditor Rec. Non-Financial Query?
          </el-col>
          <el-col>
            <input :disabled="disabled" id="prevCoderQuery" type="checkbox" v-model="inpatientReview.prevCoderQuery" v-on:change="putInpatientReview('prevCoderQuery')">
            Did the Coder Write a Query?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="coderMissedQuery" type="checkbox" v-model="inpatientReview.coderQueryMissed" v-on:change="putInpatientReview('coderQueryMissed')">
            Did the
            Coder Miss a Query Opportunity?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="queryNotCompliant" type="checkbox" v-model="inpatientReview.queryNotCompliant"
              v-on:change="putInpatientReview('queryNotCompliant')"> Coder
            Query Invalid?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="cdiQuery" type="checkbox" v-model="inpatientReview.cdiQuery" v-on:change="putInpatientReview('cdiQuery')">
            Did CDI Write a Query?
          </el-col>
          <el-col>
            <input :disabled="disabled" id="cdiMissedQuery" type="checkbox" v-model="inpatientReview.cdiQueryMissed" v-on:change="putInpatientReview('cdiQueryMissed')">
            Did CDI Miss a Query Opportunity?
          </el-col>
          <el-col>
            <input :disabled="disabled" id="cdiQueryInvalid" type="checkbox" v-model="inpatientReview.cdiQueryInvalid" v-on:change="putInpatientReview('cdiQueryInvalid')">
            CDI Query Invalid?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="missingAppropriateOrder" type="checkbox" v-model="inpatientReview.missingAppropriateOrder"
              v-on:change="putInpatientReview('missingAppropriateOrder')">
            Missing Appropriate Order?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="incorrectAdmitDischDate" type="checkbox" v-model="inpatientReview.incorrectAdmitDischDate"
              v-on:change="putInpatientReview('incorrectAdmitDischDate')">
            Incorrect Admit Date?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="recordAppearsToHaveCloning" type="checkbox" v-model="inpatientReview.recordAppearsToHaveCloning"
              v-on:change="putInpatientReview('recordAppearsToHaveCloning')"> Contains Cloned Documentation?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="cloningCausedCodingProblem" type="checkbox" v-model="inpatientReview.cloningCausedCodingProblem"
              v-on:change="putInpatientReview('cloningCausedCodingProblem')"> Cloned Doc Caused Inappropriate Code
            Assignment?
          </el-col>

          <el-col>
            <input :disabled="disabled" id="legibilityIssues" type="checkbox" v-model="inpatientReview.legibilityIssues" v-on:change="putInpatientReview('legibilityIssues')">
            Illegible
            Documentation?
          </el-col>
        </el-col>
      </el-row>

    </el-form>

    <!-- <RecommendationLibraryPicker v-if="recommendationPickerActionTaken" :showDialog="recommendationPickerActionTaken" :recordTypes="['IP','OP']"
      recommendationType="ACTIONTAKEN" v-model="inpatientReview.actionTaken" @change="putInpatientReview('actionTaken')" @close="recommendationPickerActionTaken = false">
    </RecommendationLibraryPicker>

    <RecommendationLibraryPicker v-if="recommendationPickerCodingRef" :showDialog="recommendationPickerCodingRef" :recordTypes="['IP','OP']" recommendationType="CODINGREF"
      v-model="inpatientReview.codingRef" @change="putInpatientReview('codingRef')" @close="recommendationPickerCodingRef = false">
    </RecommendationLibraryPicker> -->

  </div>
</template>

<script>
  // import Tinymce from '@/components/controls/Tinymce'
  // import RecommendationLibraryPicker from '@/views/reviews/recommendationLibrary/RecommendationLibraryPicker'
  import inpatientHelper from '@/mixins/inpatientHelper'
  import hiaAuthorization from '@/mixins/hiaAuthorization'
  // import massMultipleSelector from '@/components/controls/massMultipleSelector'

  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'InpatientReviewRecommendations',
    mixins: [inpatientHelper, hiaAuthorization],
    data: function () {
      return {
        loading: false,
        activeTab: 'all',
        actionTakenCol: 8,
        supportingDocsCol: 8,
        codingRefCol: 8,
        // disabled: false,
        recommendationPickerActionTaken: false,
        recommendationPickerCodingRef: false
      }
    },
    methods: {
      ...mapActions('inpatientReviews/', ['PUT_INPATIENT_REVIEW']),
      async putInpatientReview(changedField) {
        try {
          this.loading = true
          const payload = { inpatientReview: this.inpatientReview, changedField: changedField }
          this.PUT_INPATIENT_REVIEW(payload)
          this.loading = false
          this.$message({
            message: 'Recommendations Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Recommendations Update Error',
            type: 'error'
          })
        }
      },
      handleTabClick(tab, event) {
        switch (tab.name) {
          case 'all':
            this.actionTakenCol = 8
            this.supportingDocsCol = 8
            this.codingRefCol = 8
            break
          case 'actionTaken':
            this.actionTakenCol = 24
            this.supportingDocsCol = 0
            this.codingRefCol = 0
            break
          case 'supportingDocs':
            this.actionTakenCol = 0
            this.supportingDocsCol = 24
            this.codingRefCol = 0
            break
          case 'codingRef':
            this.actionTakenCol = 0
            this.supportingDocsCol = 0
            this.codingRefCol = 24
            break
        }
      }
    },
    computed: {
      ...mapState('inpatientReviews/', ['inpatientReview']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['user']),
      disabled() {
        return !!this.readOnly() || this.inpatientReview.reviewStatusId !== 2
      },
      tinyMceImagePath() {
        return 'reviews/inpatient/' + this.inpatientReview.drgTableId + '/'
      },
      recDrgComputed: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 1, 'drg', this.inpatientReview.groupingResults)
        }
      },
      recDrgWtComputed: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 1, 'drgWeight', this.inpatientReview.pricingResults)
        }
      },
      recSoiCalc: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 1, 'severityOfIllness', this.inpatientReview.groupingResults)
        }
      },
      recRomCalc: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 1, 'riskOfMortality', this.inpatientReview.groupingResults)
        }
      },
      recOtherDrgCalc: {
        get: function () {
          if (this.inpatientReview.otherDrgtype) {
            return this.getGroupingResultValue(this.inpatientReview.otherDrgtype, 1, 'drg', this.inpatientReview.groupingResults)
          }
          return null
        }
      },
      origDrgComputed: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 0, 'drg', this.inpatientReview.groupingResults)
        }
      },
      origDrgWtComputed: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 0, 'drgWeight', this.inpatientReview.pricingResults)
        }
      },
      origSoiCalc: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 0, 'severityOfIllness', this.inpatientReview.groupingResults)
        }
      },
      origRomCalc: {
        get: function () {
          return this.getGroupingResultValue(this.inpatientReview.ipreviewType, 0, 'riskOfMortality', this.inpatientReview.groupingResults)
        }
      },
      origOtherDrgCalc: {
        get: function () {
          if (this.inpatientReview.otherDrgtype) {
            return this.getGroupingResultValue(this.inpatientReview.otherDrgtype, 0, 'drg', this.inpatientReview.groupingResults)
          }
          return null
        }
      }
    },
    components: {
      // Tinymce,
      // RecommendationLibraryPicker
      // massMultipleSelector
    }
  }

</script>
