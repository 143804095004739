<template>
  <div>
    <template v-if="!isLevel2">
      <div v-loading="dxLoading" element-loading-spinner="atom-audit-loader">
        <v-client-table v-if="toggleGrid" key="dxGrid" class="hiaTable" ref="dxGrid" id="dxGrid" :data="proReview.proRecordDxs" :columns="columns" :options="options"
          uniqueKey="dxId">

          <template slot="lineSequence" slot-scope="props">
            <span class="dragIcon drag-handle" style="margin-top: 3px; float: left">{{props.row.lineSequence}}</span>
          </template>

          <template slot="codeType" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="putProRecordDx(props.row, $event)" v-model="props.row.codeType" placeholder="Code Type" itemkey="key" label="value"
              :dropdowns="dropdowns.codeTypes.list" filterable>
            </hia-el-select>
          </template>

          <template slot="edits" slot-scope="props">
            <div v-html="dxCodeEdits(props.row.edits)"></div>
          </template>

          <template slot="originalDx" slot-scope="props">
            <div>
              <HiaCodeInput :ref="props.row.dxId + 'origDx'" style="width: 112px;" :inputId="`originalDx_${props.index}`" class="noRightBorder borderLeftRadius"
                v-model="props.row.originalDx" :disabled="disabled" @ellipsisClick="emitEllipsisClick(props.row.originalDx)" @focus="emitFocusedCode(props.row.originalDx)"
                @change="putProRecordDx(props.row, 'dxCode', $event)">
              </HiaCodeInput>
            </div>
            <span style="float: left; margin-top: 1px;" v-html="dxCodeEdits(props.row.edits)"></span>
            <span style="float: left; margin-top: 4px;" v-html="dxCodeHccs(props.row.originalHccs)"></span>
          </template>

          <template slot="originalDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{props.row.originalDescCalc}}</div>
          </template>

          <template slot="recommendedDx" slot-scope="props">
            <div>
              <HiaCodeInput :ref="props.row.dxId + 'recDx'" style="width: 112px;" :placeholder="props.row.originalDx" :inputId="`recommendedDx_${props.index}`"
                :class="{deleteCode: props.row.deleteCode}" v-model="props.row.recommendedDx" :disabled="props.row.deleteCode || disabled"
                @ellipsisClick="emitEllipsisClick(props.row.recommendedDx)" @focus="emitFocusedCode(props.row.recommendedDx)"
                @change="putProRecordDx(props.row, 'dxCode', $event)">
              </HiaCodeInput>
            </div>
            <span style="float: left" v-html="dxCodeEdits(props.row.recEdits)"></span>
            <span style="float: left; margin-top: 5px;" v-html="dxCodeHccs(props.row.recommendedHccs)"></span>
          </template>

          <template slot="recDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.deleteCode ? props.row.recDescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.newPrimary" title="New Primary" icon="mdi mdi-alpha-p" :disabled="disabled"
                @change="putProRecordDx(props.row, 'newPrimary')">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.deleteCode" title="Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled"
                @change="putProRecordDx(props.row, 'deleteDx', $event)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.exclude" title="Exclude Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled"
                @change="putProRecordDx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.validated" title="Validated by Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled"
                @change="putProRecordDx(props.row)">
              </iconCheckBox>
            </div>
          </template>

          <template slot="recDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.deleteCode ? props.row.recDescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <hia-el-select style="width: 82%" :title="disableReasonForChange(props.row) ? 'No change on line' : null" :disabled="disableReasonForChange(props.row) || disabled"
              @input="putProRecordDx(props.row, $event)" v-model="props.row.reasonForChange" placeholder="Reason for Change" itemkey="key" label="value"
              :dropdowns="dropdowns.proReasonsForChangeDx.list" clearable filterable>
            </hia-el-select>
            <i v-if="!disabled" style="float: right; cursor: pointer; margin-top: -20px;" title="Delete Dx Row" class="el-icon-close delete-button"
              @click="deleteDx(props.row)"></i>
          </template>

        </v-client-table>

        <v-client-table v-if="toggleGrid" key="dxGridAddRow" class="hiaTable" ref="dxGridAddRow" id="dxGridAddRow" :data="addRow" :columns="addRowColumns" :options="options">

          <template slot="codeType" slot-scope="props">
            <hia-el-select :disabled="disabled" @input="setAddRow(props.row)" v-model="props.row.codeType" placeholder="Code Type" itemkey="key" label="value"
              :dropdowns="dropdowns.codeTypes.list" filterable>
            </hia-el-select>
          </template>

          <template slot="originalDx" slot-scope="props">
            <input style="width: 100px; text-transform: uppercase; font-size: 12px" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="originalDx" maxlength="7"
              minlength="3" v-model="props.row.originalDx" v-on:keyup.enter="addDxRow(props.row)">
          </template>

          <template slot="originalDescCalc">
            <div>&nbsp;</div>
          </template>

          <template slot="recommendedDx" slot-scope="props">
            <input style="width: 100px; text-transform: uppercase; font-size: 12px" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="recommendedDx"
              maxlength="7" minlength="3" v-model="props.row.recommendedDx" v-on:keyup.enter="addDxRow(props.row)">
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <el-popover ref="addPopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover"
              :content="addPopoverContent" v-model="showAddPopover" @after-enter="handleHidePopOver('addPopover')">
              <el-button :disabled="disabled" round slot="reference" style="float: right;" icon="el-icon-plus" type="primary" size="mini" @click="addDxRow(props.row)">Add
              </el-button>
            </el-popover>
          </template>

        </v-client-table>
      </div>

    </template>

    <!-- <template v-if="isLevel2">
      <div v-loading="loading" element-loading-spinner="atom-audit-loader">
        <v-client-table v-if="toggleGrid" key="dxGridLevel2" class="hiaTable" ref="dxGridLevel2" id="dxGridLevel2" :data="proReview.proRecordDxs" :columns="level2Columns"
          :options="level2Options">

          <template slot="lineSequence" slot-scope="props">
            <span class="dragIcon drag-handle" style="margin-top: 3px; float: left">{{props.row.lineSequence}}</span>
          </template>

          <template slot="originalDx" slot-scope="props">
            <div>
              <HiaCodeInput style="width: 77px;" :inputId="`originalDx_${props.index}`" class="noRightBorder borderLeftRadius" v-model="props.row.originalDx"
                :disabled="disabled" @ellipsisClick="emitEllipsisClick(props.row.originalDx)" @focus="emitFocusedCode(props.row.originalDx)"
                @change="putProRecordDx(props.row, 'dxCode')">
              </HiaCodeInput>
            </div>
            <span style="float: left; margin-top: 1px;" v-html="dxCodeEdits(props.row.edits)"></span>
            <span style="float: left; margin-left: 5px; margin-top: 4px;" v-html="dxCodeHccs(props.row.originalHccs)"></span>
          </template>

          <template slot="originalDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{props.row.originalDescCalc}}</div>
          </template>

          <template slot="recommendedDx" slot-scope="props">
            <div>
              <HiaCodeInput style="width: 77px;" :placeholder="props.row.originalDx" :inputId="`recommendedDx_${props.index}`" :class="{deleteCode: props.row.deleteCode}"
                v-model="props.row.recommendedDx" :disabled="props.row.deleteCode" @ellipsisClick="emitEllipsisClick(props.row.recommendedDx)"
                @focus="emitFocusedCode(props.row.recommendedDx)" @change="putProRecordDx(props.row, 'dxCode')">
              </HiaCodeInput>
            </div>
            <span style="float: left" v-html="dxCodeEdits(props.row.recEdits)"></span>
            <span style="float: left; margin-left: 5px; margin-top: 5px;" v-html="dxCodeHccs(props.row.recommendedHccs)"></span>
          </template>

          <template slot="recDescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.deleteCode ? props.row.recDescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.newPrimary" title="New Primary" icon="mdi mdi-alpha-p" :disabled="disabled"
                @input="putProRecordDx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.deleteCode" title="Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled"
                @input="putProRecordDx(props.row, 'deleteDx')">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.exclude" title="Exclude Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled"
                @input="putProRecordDx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.validated" title="Validated by Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled"
                @input="putProRecordDx(props.row)">
              </iconCheckBox>
            </div>
          </template>

          <template slot="reasonForChange" slot-scope="props">
            <hia-el-select style="width: 100px;" :disabled="disabled" @input="putProRecordDx(props.row, $event)" v-model="props.row.reasonForChange"
              placeholder="Reason for Change" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.proReasonsForChange.list">
            </hia-el-select>
          </template>

          <template slot="level2Dx" slot-scope="props">
            <div>
              <HiaCodeInput style="width: 77px;" :placeholder="props.row.recommendedDx ? props.row.recommendedDx : props.row.originalDx" :inputId="`level2Dx_${props.index}`"
                :class="{deleteCode: props.row.level2DeleteCode || props.row.deleteCode}" v-model="props.row.level2Dx" :disabled="props.row.level2DeleteCode"
                @ellipsisClick="emitEllipsisClick(props.row.level2Dx)" @focus="emitFocusedCode(props.row.level2Dx)" @change="putProRecordDx(props.row, 'dxCode')">
              </HiaCodeInput>
            </div>
            <span style="float: left" v-html="dxCodeEdits(props.row.level2Edits)"></span>
            <span style="float: left; margin-left: 5px; margin-top: 5px;" v-html="dxCodeHccs(props.row.level2Hccs)"></span>
          </template>

          <template slot="level2DescCalc" slot-scope="props">
            <div style="margin-top: 3px;">{{!props.row.level2DeleteCode ? props.row.level2DescCalc : 'Delete Code'}}</div>
          </template>

          <template slot="level2Actions" slot-scope="props">
            <div style="margin-top: 5px;">
              <iconCheckBox style="float: left" v-model="props.row.level2NewPrimary" title="Level 2 New Primary" icon="mdi mdi-alpha-p" :disabled="disabled"
                @input="putProRecordDx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.level2DeleteCode" title="Level 2 Delete Code" icon="mdi mdi-alpha-d" :disabled="disabled"
                @input="putProRecordDx(props.row, 'deleteDxLevel2')">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.level2Exclude" title="Exclude Level 2 Changes from Coder Stats" icon="mdi mdi-alpha-e" :disabled="disabled"
                @input="putProRecordDx(props.row)">
              </iconCheckBox>
              <iconCheckBox style="float: left" v-model="props.row.level2Validated" title="Validated by Level 2 Reviewer" icon="mdi mdi-alpha-v" :disabled="disabled"
                @input="putProRecordDx(props.row)">
              </iconCheckBox>
            </div>
          </template>

          <template slot="level2ReasonForChange" slot-scope="props">
            <hia-el-select style="width: 82%" :disabled="disabled" @input="putProRecordDx(props.row, $event)" v-model="props.row.level2ReasonForChange"
              placeholder="Reason for Change" itemkey="key" label="value" :dropdowns="dropdowns.proReasonsForChange.list" clearable filterable>
            </hia-el-select>
            <i style="float: right; cursor: pointer; margin-top: -20px;" title="Delete Dx Row" class="el-icon-close delete-button" @click="deleteDx(props.row)"></i>

          </template>

        </v-client-table>

        <v-client-table v-if="toggleGrid" key="dxGridLevel2AddRow" class="hiaTable" ref="dxGridLevel2AddRow" id="dxGridLevel2AddRow" :data="addRow"
          :columns="level2AddRowColumns" :options="level2Options">

          <template slot="originalDx" slot-scope="props">
            <input style="width: 65px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="originalDx" maxlength="7" minlength="3"
              v-model="props.row.originalDx" @keyup.enter="addDxRow(props.row)">
          </template>

          <template slot="originalDescCalc">
          </template>

          <template slot="recommendedDx" slot-scope="props">
            <input style="width: 65px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="recommendedDx" maxlength="7" minlength="3"
              v-model="props.row.recommendedDx" @keyup.enter="addDxRow(props.row)">

          </template>

          <template slot="actions">
            <div>&nbsp;</div>
          </template>

          <template slot="reasonForChange">
            <hia-el-select style="width: 100px; visibility: hidden;"></hia-el-select>
          </template>

          <template slot="level2Dx" slot-scope="props">
            <input style="width: 65px; text-transform: uppercase" v-mask="'NNNNNNN'" :disabled="disabled" :readonly="loading" id="level2Dx" maxlength="7" minlength="3"
              v-model="props.row.level2Dx" @keyup.enter="addDxRow(props.row)">
          </template>

          <template slot="level2ReasonForChange" slot-scope="props">
            <el-popover ref="addPopoverLevel2" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover"
              :content="addPopoverContent" v-model="showAddPopover" @after-enter="handleHidePopOver('addPopoverLevel2')">
              <el-button slot="reference" style="float: right;" type="primary" size="mini" @click="addDxRow(props.row)">Add</el-button>
            </el-popover>
          </template>
        </v-client-table>
      </div>
    </template> -->

    <div class="dialogs">
      <addMultipleCodes :disabled="disabled" :defaultCodeType="proReview.project.defaultCodeType" :codeTypeRequired="true" :show="showAddMultipleCodes" :type="'DX'"
        :review="proReview" @close="handleCloseAddMultipleCodes" @postAddMultipleCodes="postAddMultipleCodes">
      </addMultipleCodes>
    </div>

    <el-popover ref="savePopover" style="word-break: normal;" placement="bottom" title="" width="300" trigger="manual" popper-class="popover" :content="savePopoverContent"
      @after-enter="handleHidePopOver('savePopover')">
    </el-popover>

  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import iconCheckBox from '@/components/controls/iconCheckBox'
  import HiaCodeInput from '@/components/controls/HiaCodeInput'
  import Sortable from 'sortablejs'
  import addMultipleCodes from '@/components/controls/addMultipleCodes'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'proReviewDx',
    props: ['showAddMultipleCodes'],
    mixins: [HiaAuthorization],
    data: function () {
      return {
        toggleGrid: true,
        loading: false,
        // disabled: false,
        theSortable: null,
        addPopoverContent: '',
        savePopoverContent: '',
        showAddPopover: false,
        addRow: [{ codeType: '', originalDx: '', recommendedDx: '' }],
        addRowColumns: ['lineSequence', 'codeType', 'originalDx', 'originalDescCalc', 'recommendedDx', 'recDescCalc', 'actions', 'reasonForChange'],
        columns: ['lineSequence', 'codeType', 'originalDx', 'originalDescCalc', 'recommendedDx', 'recDescCalc', 'actions', 'reasonForChange'],
        level2Columns: ['lineSequence', 'originalDx', 'originalDescCalc', 'recommendedDx', 'recDescCalc', 'actions', 'reasonForChange', 'level2Dx', 'level2DescCalc', 'level2Actions', 'level2ReasonForChange'],
        level2AddRowColumns: ['lineSequence', 'originalDx', 'originalDescCalc', 'recommendedDx', 'recDescCalc', 'actions', 'reasonForChange', 'level2Dx', 'level2DescCalc', 'level2Actions', 'level2ReasonForChange'],
        options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          queue: [],
          networkFunction: null,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'lineSequenceCol',
            codeType: 'codeTypeCol',
            originalDx: 'oneHundredPx',
            originalDescCalc: 'twentyPercent',
            recommendedDx: 'oneHundredPx',
            recDescCalc: 'twentyPercent',
            actions: 'actionsCol',
            reasonForChange: 'reasonForChangeCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            grouperFlags: 'GF',
            recommendedGrouperFlags: 'GF',
            originalDx: 'Orig. Dx',
            recommendedDx: 'Rec. Dx',
            originalDescCalc: 'Desc.',
            recDescCalc: 'Desc.',
            recommendedEdits: 'Edits',
            reasonForChange: 'Reason For Change',
            codeType: 'Type',
            delete: '',
            add: ''
          }
        },
        level2Options: {
          filterable: false,
          filterByColumn: false,
          resizableColumns: false,
          perPage: 100,
          columnsClasses: {
            lineSequence: 'lineSequenceCol',
            originalDx: 'seventyFivePx',
            originalDescCalc: 'twentyPercent descOverflow',
            recommendedDx: 'seventyFivePx',
            recDescCalc: 'twentyPercent descOverflow',
            actions: 'actionsCol',
            reasonForChange: 'reasonForChangeCol',
            level2Dx: 'seventyFivePx',
            level2DescCalc: 'twentyPercent descOverflow',
            level2Actions: 'actionsCol',
            level2ReasonForChange: 'reasonForChangeCol',
            add: 'thirtyPx',
            delete: 'thirtyPx'
          },
          headings: {
            lineSequence: '#',
            grouperFlags: 'GF',
            recommendedGrouperFlags: 'GF',
            originalDx: 'Orig. Dx',
            recommendedDx: 'Rec. Dx',
            originalDescCalc: 'Desc.',
            recDescCalc: 'Desc.',
            recommendedEdits: 'Edits',
            reasonForChange: 'Reason For Change',
            level2Dx: 'Lvl. 2 Dx',
            level2DescCalc: 'Desc',
            level2Actions: 'Actions',
            level2ReasonForChange: 'Reason For Change',
            delete: '',
            add: ''
          }
        }
      }
    },
    components: {
      iconCheckBox,
      HiaCodeInput,
      addMultipleCodes
    },
    methods: {
      ...mapActions('proReviews/', ['PUT_PRO_DX', 'DELETE_PRO_DX', 'POST_PRO_DX', 'PUT_PRO_DXS_FOR_RESEQUENCE']),
      ...mapMutations('proReviews/', ['SET_PRO_DX_ROW', 'PUSH_DX_ROW', 'SET_PRO_DXS']),
      async putProRecordDx(row, changedField, e) {
        if (!row.recommendedDx && !row.originalDx) {
          this.savePopoverContent = 'Either Original Dx or Recommended Dx must be populated. To delete the entire row, use the X button on the right side.'
          this.openSavePopover(e)
          const backupRow = this.proReview.proRecordDxs.find(x => x.dxId === row.dxId)
          this.SET_PRO_DX_ROW(backupRow)
          // need to force update on the Rec DX HiaCodeInput so that restoring the backupRow will be reactive
          this.$refs[row.dxId + 'recDx'].$forceUpdate()
          this.$refs[row.dxId + 'origDx'].$forceUpdate()
          return
        }

        if (!row.originalDx && changedField === 'deleteDx') {
          this.savePopoverContent = 'The Delete Code recommendation cannot be used if there is no Original Dx.'
          this.openSavePopover(e)
          const backupRow = this.proReview.proRecordDxs.find(x => x.dxId === row.dxId)
          this.SET_PRO_DX_ROW(backupRow)
          return
        }

        if (row.reasonForChange && !row.recommendedDx && !row.deleteCode && !row.newPrimary) {
          try {
            await this.$confirm('This will clear the Reason for Change. Continue?', 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.reasonForChange = null
          } catch (e) {
            const backupRow = this.proReview.proRecordDxs.find(x => x.dxId === row.dxId)
            this.SET_PRO_DX_ROW(backupRow)
            // need to force update on the Rec DX HiaCodeInput so that restoring the backupRow will be reactive
            this.$refs[row.dxId + 'recDx'].$forceUpdate()
            return
          }
        }

        if (changedField === 'deleteDx' && row.recommendedDx && row.deleteCode) {
          try {
            await this.$confirm(`This will clear the Recommended Dx ${row.recommendedDx}. Continue?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.recommendedDx = null
          } catch (e) {
            row.deleteCode = false
            this.SET_PRO_DX_ROW(row)
            return
          }
        }

        if (changedField === 'deleteDxLevel2' && row.level2Dx && row.level2DeleteCode) {
          try {
            await this.$confirm(`This will clear the Level 2 Recommended Dx ${row.level2Dx}. Continue?`, 'Warning', { confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning' })
            row.level2Dx = null
          } catch (e) {
            row.level2DeleteCode = false
            this.SET_PRO_DX_ROW(row)
            return
          }
        }

        this.SET_PRO_DX_ROW(row)
        const payload = { dxRow: row, changedField: changedField }

        this.loading = true
        try {
          await this.PUT_PRO_DX(payload)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Diagnosis Code Update Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Diagnosis Code Update Error',
            type: 'error'
          })
        }
      },
      setAddRow(row) {
        this.$set(this.addRow, 0, row)
      },
      disableReasonForChange(row) {
        if (!row.recommendedDx && !row.newPrimary && !row.deleteCode) {
          return true
        }
        return false
      },
      async addDxRow(row) {
        if (!this.isLevel2 && !row.codeType && !row.originalDx && !row.recommendedDx) {
          this.addPopoverContent = 'Code Type and either Original DX or Recommended DX must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (!this.isLevel2 && !row.originalDx && !row.recommendedDx) {
          this.addPopoverContent = 'Either Original DX or Recommended DX must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (this.isLevel2 && !row.codeType && !row.originalDx && !row.recommendedDx && !row.level2Dx) {
          this.addPopoverContent = 'Code Type and either Original DX, Recommended DX or Level 2 DX must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (this.isLevel2 && !row.originalDx && !row.recommendedDx && !row.level2Dx) {
          this.addPopoverContent = 'Either Original DX, Recommended DX or Level 2 DX must be populated to add row.'
          this.showAddPopover = true
          return
        }

        if (!row.codeType) {
          this.addPopoverContent = 'Code Type must be populated to add row.'
          this.showAddPopover = true
          return
        }

        row.dxId = 0
        row.proRecordId = this.proReview.proRecordId
        row.newPrimary = false
        row.level2NewPrimary = false
        row.originalDx = row.originalDx ? row.originalDx.toUpperCase().replace('.', '').trim() : null
        row.recommendedDx = row.recommendedDx ? row.recommendedDx.toUpperCase().replace('.', '').trim() : null

        this.loading = true
        try {
          await this.POST_PRO_DX([row])
          this.$set(this.addRow, 0, { codeType: row.codeType, originalDx: '', recommendedDx: '' })
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Diagnosis Code Added Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Diagnosis Code Add Error',
            type: 'error'
          })
        }
      },
      async deleteDx(row) {
        this.loading = true
        try {
          await this.DELETE_PRO_DX(row)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Diagnosis Code Deleted Successful',
            type: 'success'
          })
        } catch (e) {
          this.loading = false
          this.$message({
            message: 'Diagnosis Code Delete Error',
            type: 'error'
          })
        }
      },
      openSavePopover(e) {
        if (!e) {
          return
        }

        const popper = this.$refs.savePopover

        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = e.target
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = (e) => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      emitEllipsisClick(code) {
        this.emitFocusedCode(code)
        this.$emit('ellipsisClick', code)
      },
      emitFocusedCode(focusedCode) {
        if (focusedCode && focusedCode !== 'DEL') {
          const truCodeQueryObject = {
            code: focusedCode,
            type: 'DX'
          }
          this.$emit('onCodeFocus', truCodeQueryObject)
        }
      },
      dxCodeEdits(edits) {
        if (edits.length) {
          let dxDesc = ''
          for (const i in edits) {
            dxDesc += edits[i].description.replace(/<[^>]*>/g, '') + ' \n'
          }
          return '<div style="background: url(/static/trucode/css/img/icons/warning.png); width: 16px; height: 16px; margin-top: 2px"  title="' + dxDesc.trim() + '"></div>'
        }
      },
      dxCodeHccs: function (hccs) {
        if (hccs.length) {
          const copy = JSON.parse(JSON.stringify(hccs))
          const sortByVersionThenValue = (a, b) => {
            if (a.version !== b.version) {
              return a.version - b.version
            }
            return a.value - b.value
          }

          copy.sort(sortByVersionThenValue)

          let html = ''
          for (let i = 0; i < copy.length; i++) {
            const title = copy[i].description
            const value = copy[i].value
            const version = copy[i].version
            if (version) {
              html += `<span class="hcc hcc${version}" title="V${version} - ${title}">${value}</span>`
            } else {
              html += `<span class="hcc" title="${title}">${value}</span>`
            }
          }
          return html
        }
        return '<div>&nbsp;</div>'
      },
      async handleDragStop(e) {
        const temp = this.proReview.proRecordDxs[e.oldIndex]
        this.proReview.proRecordDxs.splice(e.oldIndex, 1)
        this.proReview.proRecordDxs.splice(e.newIndex, 0, temp)
        this.updateLineSequence(this.proReview.proRecordDxs)
        this.SET_PRO_DXS(this.proReview.proRecordDxs)

        // force the grid to re-initialize by destroying and recreating it
        // and then reattaching the sortable
        this.toggleGrid = false
        this.theSortable.destroy()
        this.$nextTick(() => {
          this.toggleGrid = true
          this.$nextTick(() => {
            this.attachSortable()
          })
        })

        this.loading = true
        try {
          await this.PUT_PRO_DXS_FOR_RESEQUENCE(this.proReview.proRecordDxs)
          this.loading = false
          this.$emit('handleSaveToLocalStorageForTruCode', this.proReview)
          this.$message({
            message: 'Diagnosis Code Updated Successfully',
            type: 'success'
          })
        } catch (e) {
          this.loading = true
          this.$message({
            message: 'Diagnosis Code Update Error',
            type: 'error'
          })
        }
      },
      updateLineSequence(array) {
        array.map((o, i) => {
          o.lineSequence = (i + 1)
          return o
        })
      },
      attachSortable() {
        const gridElementName = this.isLevel2 ? 'dxGridLevel2' : 'dxGrid'
        const gridDiv = document.getElementById(gridElementName)
        if (gridDiv) {
          const tbody = gridDiv.getElementsByTagName('tbody')
          this.theSortable = Sortable.create(tbody[0], {
            animation: 200,
            draggable: 'tr',
            handle: '.drag-handle',
            onEnd: (e) => {
              this.handleDragStop(e)
            }
          })
        }
      },
      handleCloseAddMultipleCodes() {
        this.$emit('handleCloseAddMultipleCodes', false)
      },
      async postAddMultipleCodes(data) {
        if (data.codes) {
          // populate new DX codes
          const newCodes = []
          let startLineSequence = (this.proReview.proRecordDxs.length + 1)
          data.codes.forEach((v, i) => {
            const newDxCode = {
              proRecordId: this.proReview.proRecordId,
              lineSequence: startLineSequence,
              add: true,
              originalDx: data.isRecommended ? null : v.trim(),
              exclude: false,
              validated: false,
              recommendedDx: data.isRecommended ? v.trim() : null,
              newPrimary: false,
              reasonForChange: '',
              level2Dx: null,
              level2NewPrimary: 0,
              level2ReasonForChange: '',
              codeType: data.codeType
            }
            newCodes.push(newDxCode)
            startLineSequence++
          })
          data.codes = newCodes
          data.changedField = 'addMultipleCodes'
          this.loading = true
          try {
            await this.POST_PRO_DX(data.codes)
            this.$message({
              message: 'Multiple Codes Added Successfully',
              type: 'success'
            })
          } catch (e) {
            this.$message({
              message: 'Multiple Codes Added Error',
              type: 'error'
            })
          }
          this.loading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(() => {
        this.attachSortable()
      })
    },
    watch: {
      isLevel2(val) {
        this.$nextTick(() => {
          if (this.theSortable) {
            this.attachSortable()
          }
        })
      },
      'proReview.project.defaultCodeType': function (val) {
        if (this.proReview.project) {
          this.addRow = [{ codeType: this.proReview.project.defaultCodeType, originalDx: '', recommendedDx: '' }]
        }
      }
    },
    computed: {
      ...mapState('proReviews/', ['proReview', 'dxLoading']),
      ...mapState('dropdowns/', ['dropdowns']),
      disabled() {
        return !!this.readOnly() || this.proReview.reviewStatusId !== 2
      },
      isLevel2: function () {
        return this.proReview.reviewLevel > 1
      }
    }
  }
</script>

<style scoped>
  .dragIcon {
    cursor: move;
    margin: 1px 0px 0px 1px;
    padding: 0px 0px 0px 0px;
    float: left;
    height: 20px;
    color: #1f2d3d;
  }

  .drag-handle {
    cursor: move;
    display: block;
    float: left;
  }

  >>>.lineSequenceCol {
    width: 15px;
  }

  >>>.codeTypeCol {
    width: 130px;
  }

  >>>.actionsCol {
    max-width: 90px;
    min-width: 90px;
  }

  >>>.reasonForChangeCol {
    min-width: 120px;
    max-width: 140px;
  }

  >>>.deleteCol {
    max-width: 30px;
  }

  >>>.addCol {
    min-width: 150px;
  }

  >>>.thirtyPx {
    min-width: 20px;
  }

  >>>.fiftyPx {
    width: 50px;
  }

  >>>.seventyFivePx {
    width: 75px;
  }

  >>>.oneHundredPx {
    width: 100px;
  }

  >>>.twentyPercent {
    width: 20%;
  }

  >>>.descOverflow {
    word-break: break-word;
  }

  .hiaTable .el-icon-close {
    border: 1px solid transparent;
  }

  .hiaTable tr:nth-child(even) .el-icon-close {
    border: 1px solid #606266;
    border-radius: 100%;
  }

  >>>#dxGridAddRow thead,
  >>>#dxGridLevel2AddRow thead {
    visibility: hidden;
  }

  >>>#dxGridAddRow table,
  >>>#dxGridLevel2AddRow table {
    margin-top: -1px;
  }

  >>>#dxGridAddRow tbody>tr {
    height: 40px;
    border-top: 1px solid #ebeef5;
    background-color: #fff !important;
  }

  >>>#dxGridAddRow th,
  >>>#dxGridLevel2AddRow th {
    line-height: 0px;
    padding: 0px 5px 0px 5px;
  }

  >>>#dxGrid tr,
  >>>#dxGridLevel2 tr {
    vertical-align: top;
  }

  >>>#dxGrid ::placeholder,
  >>>#dxGridLevel2 ::placeholder {
    opacity: 0.8;
  }

  >>>.hcc {
    border-radius: 5%;
    color: DimGray;
    text-align: center;
    border: 1px solid DarkGray;
    font-size: 12px;
    padding: 0px 1px 0px 1px;
    margin: 0px 2px 0px 2px;
  }

  >>>.hcc28 {
    color: #005695;
    border-color: #005695;
    opacity: .8;
  }

  >>>.VuePagination__count {
    display: none;
  }
</style>